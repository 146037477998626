import * as React from "react"
import {  
    Box, 
    Text,  
    Flex, 
    Spacer, 
    Image,  
    HStack,
    VStack, 
    Stack,
    Avatar, 
    Icon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody, 
    ModalFooter,
    ModalCloseButton,
    Button
} from "@chakra-ui/react"     
import {    FaArrowDown, FaPowerOff, FaSignOutAlt, FaTimes, FaUser, FaUserCheck, FaUserEdit, FaUserLock, FaUserShield, FaWallet  } from "react-icons/fa"; 
import G from  '../../components/ADMSocket';  

import BoxPlanos from "../../components/Padrao/BoxPlanos"; 
import BoxPerfil from "../../components/Padrao/BoxPerfil"; 
import BoxSenha from "../../components/Padrao/BoxSenha"; 
import { MdKeyboardArrowDown } from "react-icons/md";
import IconBox from "../../components/VisionUI/Icons/IconBox";
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import BoxWallet from "../../components/Padrao/BoxWallet"; 
class MenuTopo extends React.Component <any,any> {   
    tempUsu = null;  
    buscarUsuario(){
        if(G.painel!= null){ 
            if(this.tempUsu !=JSON.stringify(G.painel)){
                this.tempUsu =JSON.stringify(G.painel); 
                this.setState({})
            }
        }  
    } 
    interD  = null;
    componentDidMount(){
        G.openPlano = ()=>{
            G.viewPlanos = <BoxPlanos cmdFechar={()=>{
                G.viewPlanos = null;
                this.setState({});
            }} />  
            this.setState({});
        }  
        G.openPerfil = ()=>{
            G.viewPlanos = <BoxPerfil cmdFechar={()=>{
                G.viewPlanos = null;
                this.setState({});
            }} />  
            this.setState({});
        } 
        G.openWallet = ()=>{
            G.viewPlanos = <BoxWallet cmdFechar={()=>{
                G.viewPlanos = null;
                this.setState({});
            }} />  
            this.setState({});
        } 
        G.openSenha = ()=>{
            G.viewPlanos = <BoxSenha cmdFechar={()=>{
                G.viewPlanos = null;
                this.setState({});
            }} />  
            this.setState({});
        } 
        this.buscarUsuario();
        this.interD = setInterval(()=>{
            this.buscarUsuario();
        },200);
    }
    componentWillUnmount(){
        clearInterval(this.interD);
    }
    atencao     = null;
    boxlogin    = null;
    menuView    = false;
    gerarMenuUser(){

    }
    render(){ 
        const usu = G.painel;    
        var boxUser= null 
        if(usu!=null){
            if(typeof  usu.usuario !== 'undefined'){    
                boxUser = (
                    <Flex alignItems={'center'} onClick={()=>{
                        if(this.menuView){
                            this.menuView=false;
                        }else{
                            this.menuView =true;
                        }
                        this.setState({})
                    }}  h={'60px'} px={'10px'}  _hover={{bg:'rgba(58, 255, 202,0.3)'}} cursor={'pointer'} >  
                        <HStack>
                            <Avatar
                                display={{base:'none',sm:'flex'}}
                                size={'sm'}
                                name={usu.nome}
                                src={usu.avatar}
                                color={'tema1.100'}
                                bg='tema1.400'
                            />
                            <VStack 
                                alignItems="flex-start"
                                spacing="1px"
                                ml="2">
                                <Text maxW={'120px'} noOfLines={1} textAlign='left'  fontSize="xs" color={(usu.email!==null?"tema1.400":"#FEB70D")}> {(usu.email!==null?usu.email:'Olá, '+usu.nome.split(' ')[0])}</Text>
                                <Text maxW={'120px'} noOfLines={1} textAlign='left'  fontSize="xs" color="green.500">
                                    {usu.usuario}
                                </Text>
                            </VStack>
                            <Box color={'tema1.100'} >
                                <Icon as={MdKeyboardArrowDown} w={'auto'} h='40px' py={'10px'}/>
                            </Box>
                        </HStack>  
                    </Flex>
                )
            }

        } 

        return (  
            <Flex position={'fixed'} flexDir={{base:'row',sm:'row'}} align={'center'} justify={'center'}   w='100vw' h={{base:'60px',sm:'60px'}} bg={{base:'#222222'}} zIndex={'90'} > 
                <Flex  display={{base:'flex',sm:'none'}} color='white'  align={'center'} justify={'center'}   w='auto' h={{base:'60px',sm:'60px',lg:'100px'}} >
                    <Image  h={{base:'40px'}} pl={'50px'}   objectFit='fill' src='/assets/img/logoB.png' alt='Logo inovatyon' />    
                </Flex>  
                <Spacer/>
                <Flex display={{base:'flex'}} w={{base:'auto',md:'240px'}} ml={{base:'15px',md:'0px'}}  align='flex-end' justify='flex-end'>
                    {boxUser}  
                </Flex> 
                <Modal isCentered   isOpen={this.menuView} onClose={()=>{
                    this.menuView = false;
                    this.setState({})
                }}    size={'xs'}>
                        <ModalOverlay
                            bg='rgba(58, 255, 202,0.0)' 
                            backdropFilter='blur(10px) hue-rotate(90deg)'
                        /> 
                        <ModalContent overflow={'hidden'} alignItems={'center'} justifyItems={'center'}  bg={'rgba(58, 255, 202,0.3)'} position='fixed' top={'10px'} right={'0px'}>
                            <ModalCloseButton  color={'tema1.100'}/>
                            <ModalHeader  bg={'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'} color={'green.500'} w='100%' >
                                <Avatar 
                                    size={'xl'}
                                    name={usu.usuario}
                                    src={usu.avatar}
                                    color={'tema1.100'}
                                    bg='tema1.400'
                                />
                                <Text  fontSize={'14px'} py={'5px'} noOfLines={1}  >{usu.usuario}</Text>
                                <Text  fontSize={'16px'} noOfLines={1}  >{usu.email}</Text>
                            </ModalHeader> 
                                <ModalBody  w={'full'} color={'#ffffff'}>
                                    <Flex w={'full'} flexDirection='column' gap='10px' mt='25px' p={'10px'} borderRadius={'20px'} bg={'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'}>
                                        <Stack onClick={()=>{
                                            G.openPerfil(this);
                                        }} flexDirection='row' align='center' justify='left' gap='20px' cursor={'pointer'} borderRadius={'14px'} _hover={{bg:'linear-gradient(127.09deg, rgba(6, 11, 40, 0.0) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'}} p={'5px'} >
                                            <IconBox w= '46px' h='46px' bg= 'tema1.300'>
                                                <Icon as={FaUserEdit}  fontSize={'22px'}></Icon>
                                            </IconBox>
                                            <Text fontSize={'lg'} fontWeight={'600'}> Alterar Dados</Text>
                                        </Stack> 
                                        <Stack onClick={()=>{
                                            G.openWallet(this);
                                        }} flexDirection='row' align='center' justify='left' gap='20px' cursor={'pointer'} borderRadius={'14px'} _hover={{bg:'linear-gradient(127.09deg, rgba(6, 11, 40, 0.0) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'}} p={'5px'} >
                                            <IconBox w= '46px' h='46px' bg= 'tema1.300'>
                                                <Icon as={FaWallet}  fontSize={'22px'}></Icon>
                                            </IconBox>
                                            <Text fontSize={'lg'} fontWeight={'600'}> Alterar Carteira</Text>
                                        </Stack>
                                        <Stack  onClick={()=>{
                                            G.openSenha(this);
                                        }} flexDirection='row' align='center' justify='left' gap='20px' cursor={'pointer'} borderRadius={'14px'} _hover={{bg:'linear-gradient(127.09deg, rgba(6, 11, 40, 0.0) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'}} p={'5px'} >
                                            <IconBox w= '46px' h='46px' bg= 'tema1.300'>
                                                <Icon as={FaUserShield}  fontSize={'26px'}></Icon>
                                            </IconBox>
                                            <Text fontSize={'lg'} fontWeight={'600'}> Alterar Senha</Text>
                                        </Stack>
                                        {/* <Stack flexDirection='row' align='center' justify='left' gap='20px' cursor={'pointer'} borderRadius={'14px'} _hover={{bg:'linear-gradient(127.09deg, rgba(6, 11, 40, 0.0) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'}} p={'5px'} >
                                            <IconBox w= '46px' h='46px' bg= 'tema1.300'>
                                                <Icon as={FaUserCheck}  fontSize={'26px'}></Icon>
                                            </IconBox>
                                            <Text fontSize={'lg'} fontWeight={'600'}> Dois Fatores</Text>
                                        </Stack>  
                                        <Stack flexDirection='row' align='center' justify='left' gap='20px' cursor={'pointer'} borderRadius={'14px'} _hover={{bg:'linear-gradient(127.09deg, rgba(6, 11, 40, 0.0) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'}} p={'5px'} >
                                            <IconBox w= '46px' h='46px' bg= 'tema1.300'>
                                                <Icon as={FaUserEdit}  fontSize={'26px'}></Icon>
                                            </IconBox>
                                            <Text fontSize={'lg'} fontWeight={'600'}> Enviar KYC</Text>
                                        </Stack> */}
                                    </Flex>
                                    <Flex w={'full'} flexDirection='column' gap='10px' mt='25px' p={'10px'} borderRadius={'20px'} bg={'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'}>
                                         
                                        <Stack onClick={(e)=>{
                                            e.preventDefault();    
                                            this.atencao = <BoxWindow titulo = {'Sair do Sistema'} mensagem={'Deseja realmente sair do sistema?'}
                                            botao={
                                                <Stack   align='center' justify={'center'} flexDir={'row'} pt='5' spacing={'0'}>
                                                    <Button fontFamily={'heading'}   w={'full'} bgGradient="linear(to-r, tem1.100,tem1.100)"  color={'white'}  onClick={(e)=>{ 
                                                            localStorage.setItem('skt'+G.sessao,''); 
                                                            G.painel ={};
                                                            this.setState({}); 
                                                        }} _hover={{  bgGradient: 'linear(to-r,tema1.100,tema1.100)',  boxShadow: 'xl', color:'tema1.200' }} 
                                                    >
                                                        Fazer Logoff
                                                    </Button>  
                                                    <Button fontFamily={'heading'}  w={'full'} bg="transparent" color={'red'} _hover={{  bg: 'red', boxShadow: 'xl', color:'tema1.200'  }}
                                                        onClick={(e)=>{ 
                                                            e.preventDefault();   
                                                            this.atencao =null
                                                            this.setState({});
                                                        }}
                                                        cursor={'pointer'}   
                                                        leftIcon={<FaTimes/>}
                                                    >
                                                        Cancelar
                                                    </Button> 
                                                </Stack>
                                            }
                                            onClose={()=>{
                                                this.atencao = null;
                                                this.setState({});
                                            }}/>
                                            this.setState({}); 
                                        }} flexDirection='row' align='center' justify='left' gap='20px' cursor={'pointer'} borderRadius={'14px'} _hover={{bg:'linear-gradient(127.09deg, rgba(6, 11, 40, 0.0) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'}} p={'5px'} >
                                            <IconBox w= '46px' h='46px' bg= 'red.500'>
                                                <Icon as={FaPowerOff}  fontSize={'26px'}></Icon>
                                            </IconBox>
                                            <Text fontSize={'lg'} fontWeight={'600'}> Fazer Logoff</Text>
                                        </Stack>
                                    </Flex>
                                    
                                </ModalBody> 
                            <ModalFooter/>
                        </ModalContent>
                    </Modal>
                {this.atencao}
                {G.viewPlanos} 
            </Flex> 
        )
    }
}
export default MenuTopo;
    