/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Box, Flex, Icon, Spacer, Stack, Text } from "@chakra-ui/react";  
function TransactionRowSaque(props) {
  const { name, date, logo, situa, price,pago } = props; 
  return (
    <Flex   mb='24px' justifyContent='space-between'>
      <Flex alignItems='center'>
        <Box
          me='14px'
          borderRadius='50%'
          color={
            situa == "P"
              ? "orange.500"
              : "green.500"
          }
          border='1px solid'
          display='flex'
          alignItems='center'
          justifyContent='center'
          w='35px'
          h='35px'>
          <Icon as={logo} w='12px' h='12px' />
        </Box>
        <Flex direction='column' textAlign={'left'}>
          <Text fontWeight='500' fontSize='sm' color='#fff' mb='4px'>
            {name}
          </Text>
          <Text fontSize={{ sm: "xs", md: "sm" }} color='gray.400'>
            {date}
          </Text>
        </Flex>
      </Flex>
      <Flex
        direction="column" 
         justifyContent='space-between'
        color={
          situa == "P"
            ? "orange.500"
            : "green.500"
        }>
        <Stack direction="row"  gap={'20px'}>
          <Text as={'span'} fontWeight='bold' color={'tema1.100'} fontSize='xs'>Valor</Text>
          <Spacer/>
          <Text fontWeight='500' fontSize='xs'> 
            {price} 
          </Text>
        </Stack>
        <Stack direction="row" gap={'20px'}>
          <Text as={'span'} fontWeight='bold' color={'tema1.100'}  fontSize='xs'>Pago</Text>
          <Spacer/>
          <Text fontWeight='500' fontSize='xs'> 
            {pago}
          </Text>
        </Stack> 
      </Flex>
    </Flex>
  );
}

export default TransactionRowSaque;
