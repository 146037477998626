import * as React from "react"  
import {Avatar, AvatarGroup, Button,  Flex, Grid, HStack, Icon, Input, InputGroup, InputRightElement, Menu, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Spinner, Stack, Text, Textarea } from "@chakra-ui/react"; 
import {Helmet} from "react-helmet"; 
import G from '../../components/WebSocket';
import { FaMoneyBillTransfer } from "react-icons/fa6"; 
import { GiReceiveMoney, GiTheater } from "react-icons/gi";
import { FaChartBar, FaPaste, FaDollarSign, FaMoneyBill, FaPencilAlt, FaRegCalendarAlt, FaCopy, FaArrowDown, FaArrowUp, FaFileUpload, FaIndent, FaCheck, FaRegCheckSquare, FaCheckSquare, FaCheckCircle, FaClock, FaTrash, FaEraser, FaWallet } from "react-icons/fa";
 
import Card from "../../components/VisionUI/Card/Card";
import CardBody from "../../components/VisionUI/Card/CardBody";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
import GradientBorder from "../../components/VisionUI/GradientBorder";
import { RiMastercardFill } from "react-icons/ri"; 
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import { SiTether } from "react-icons/si";
import { HSeparator } from "../../components/VisionUI/Separator/Separator"; 
import { HiIdentification } from "react-icons/hi2"; 
import TransactionRowSaque from "../../components/VisionUI/Tables/TransactionRowSaque";
import CurrencyInput from "react-currency-input-field";
import { PinInput, PinInputField } from '@chakra-ui/react'
import { BsHandbag } from "react-icons/bs";
import BoxWallet from "../../components/Padrao/BoxWallet";

class BoxSaque extends React.Component { 
    atencao = null;
    doc = {
        comprovante     :   null,
        frente          :   null,
        confF           :   null,
        verso           :   null,
        confV           :   null,
    }
    db={
        saldos:{}, 
    } 
    buscarJogos(){
        var reload = false; 
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.saldos!=='undefined' && this.tempSaldos != JSON.stringify(G.geral.saldos)){
            this.tempSaldos = JSON.stringify(G.geral.saldos); 
            this.db.saldos = G.geral.saldos;  
            this.load = true; 
            reload = true;
        }     
        if(typeof G.painel !=='undefined' && this.tempUsu != JSON.stringify(G.painel)){
            this.tempUsu = JSON.stringify(G.painel)
            reload = true;
        }
        if(reload){
            this.setState({}); 
        }
    } 
    viewKey = false;
    componentDidMount(){
        this.buscarJogos();
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    valorSaque = 0;
    chave ='';
    chaveSend = false
    BoxFase=()=>{
        if(this.chaveSend && this.chave.length==6){
            this.chaveSend = false;
            G.socket.removeAllListeners('sendSaque').on('sendSaque',(response)=>{   
                 
                this.atencao = <BoxWindow titulo = {response.titulo} mensagem={response.mensagem} onClose={()=>{
                    if(!response.erro){
                        this.props.onClose();
                    }
                    this.atencao = null;
                    this.setState({});
                }}/>
                this.setState({}); 
            })
            G.socket.emit("sendSaque", {
                valor   :   this.valorSaque,
                chave   :   this.chave
            }); 
            this.atencao =<BoxWindow load={true}/> 
        } 
        var retorno = {
            titulo:'Solicitação de Saque', 
            conteudo: (
                <Flex direction={'column'} w={'100%'} align={'center'} justify={'center'}  gap={'0px'}>
                    <Flex direction={'row'} gap={'20px'} w={'90%'}>
                        <Stack flexDir={{base:'column'}} w='50%'  textAlign={'left'}>
                            <Text fontSize={'sm'}  mt='5px' color={'tema1.100'}>Valor do Saque </Text>
                            <InputGroup size='md'> 
                                <Input 
                                    placeholder="Valor"
                                    bg={'tema1.300'}
                                    border={0}
                                    name="senha1"
                                    color={'tema1.100'}
                                    value={this.valorSaque}
                                    defaultValue={this.valorSaque}  
                                    onChange={(el)=>{
                                        if(el.target.value == ''){
                                            this.valorSaque = 0;
                                        }else{
                                            this.valorSaque =G.parseValor(el.target.value.replace(',','.'),1).f.replace(',','.');
                                        }
                                        if(this.valorSaque>this.db.saldos.saldo){
                                            this.valorSaque =G.parseValor(this.db.saldos.saldo,1).f.replace(',','.');
                                        } 
                                        this.setState({})
                                    }}
                                    as={CurrencyInput}  decimalSeparator="," groupSeparator=" "  decimalsLimit={2} allowDecimals ={true}    disableGroupSeparators={true}
                                    _placeholder={{
                                        color: 'gray.500',
                                    }}
                                />
                                <InputRightElement width='2.5rem'>
                                    <SiTether/>
                                </InputRightElement>
                            </InputGroup>  
                        </Stack>
                        <Stack flexDir={{base:'column'}}  w='50%'   textAlign={'right'}>
                            <Text fontSize={'sm'}     mt='5px' color={'tema1.100'}>Saldo Disponível</Text>
                            <Text fontSize={'lg'}      mt='5px' lineHeight={'30px'} color={'green.500'}>
                                    {G.parseValor(this.db.saldos.saldo,5).p},
                                <Text as={'span'} fontSize={'xs'}      mt='5px' lineHeight={'30px'} color={'green.500'}>
                                    {G.parseValor(this.db.saldos.saldo,5).d}  USDT
                                </Text> 
                            </Text> 
                        </Stack>
                    </Flex> 
                    <Flex direction='column' w={'90%'} gridColumn={{ md: "1 / 3", lg: "auto" }}>
                        <Text
                        color={'tema1.1000'}
                        fontSize='md'
                        fontWeight='bold'
                        my='22px'>
                            Resumo Saque
                        </Text>
                        <Stack direction='column' spacing='12px'>
                            <Flex align='center' justify='space-between'>
                                <Text fontSize='sm' color='gray.400' fontWeight='500'>
                                Valor a receber:
                                </Text>
                                <Text fontSize='sm' color={'tema1.1000'} fontWeight='bold'>
                                    {G.parseValor(this.valorSaque*0.95,5).p},{G.parseValor(this.valorSaque*0.95,5).d}
                                </Text>
                            </Flex>
                            <Flex align='center' justify='space-between'>
                                <Text fontSize='sm' color='gray.400' fontWeight='500'>
                                Taxas:
                                </Text>
                                <Text fontSize='sm' color={'tema1.1000'} fontWeight='bold'>
                                    {G.parseValor(this.valorSaque*0.05,5).p},{G.parseValor(this.valorSaque*0.05,5).d}
                                </Text>
                            </Flex>
                            <Flex align='center' justify='space-between' mt='10px'>
                                <Text fontSize='md' color='gray.400' fontWeight='500'>
                                    Total:
                                </Text>
                                <Text fontSize='md' color={'green.500'} fontWeight='bold'>
                                    {G.parseValor(this.valorSaque,5).p},{G.parseValor(this.valorSaque,5).d}
                                </Text>
                            </Flex>
                            <HSeparator my='20px' />
                            <Text
                                color={'tema1.1000'}
                                fontSize='md'
                                fontWeight='bold' >
                                    Dados Envio
                            </Text>
                            {G.painel.wallet==''?
                                <>
                                    <Button onClick={async ()=>{ 
                                    this.boxwallet = <BoxWallet cmdFechar={()=>{
                                        this.boxwallet = null;
                                        this.setState({});
                                    }} />  
                                    this.setState({});
                                    // Url.target.value = Url.value;
                                }} fontSize={'sm'}  m='10px auto' leftIcon={<FaWallet/>} w='auto' h={'30px'} color={'green.500'} variant={'outline'} colorScheme="green">Adicionar Wallet</Button>


                                </>:
                                <>
                                    <Flex align='center' justify='space-between' gap={'20px'}>
                                        <Text fontSize='sm' color='gray.400' fontWeight='500'>
                                        Carteira:
                                        </Text>
                                        <Text fontSize='sm' noOfLines={1} color={'green.500'} fontWeight='bold'>
                                            {G.painel.wallet}
                                        </Text>
                                    </Flex>
                                    <Flex   direction={'row'}  w={'100%'} gap={'20px'}>
                                        <Flex align='center' w={'50%'} justify='space-between'>
                                            <Text fontSize='sm' color='gray.400' fontWeight='500'>
                                            Rede:
                                            </Text>
                                            <Text fontSize='sm' color={'green.500'} fontWeight='bold'>
                                                BEP-20
                                            </Text>
                                        </Flex>
                                        <Flex align='center' w={'50%'} justify='space-between'>
                                            <Text fontSize='sm' color='gray.400' fontWeight='500'>
                                            Moeda:
                                            </Text>
                                            <Text fontSize='sm' color={'green.500'} fontWeight='bold'>
                                                USDT
                                            </Text>
                                        </Flex>
                                    </Flex> 
                                </>
                            }
                            
                        </Stack>
                        
                    </Flex> 
                    {(this.sendKey)?
                        <Flex direction={'column'} w={'fit-content'} > 
                            <HSeparator my='20px' />
                            <Text
                                color={'tema1.1000'}
                                fontSize='md'
                                fontWeight='bold'
                                mb='20px'>
                                Chave de Segurança
                            </Text>
                            <Stack w='100%' direction={'row'} align={'center'} justify={'center'}> 
                                <PinInput id='keyPast' size={'lg'}  value={this.chave} defaultValue={this.chave}    onChange={(el)=>{
                                    this.chave = el; 
                                    if(this.chave.length==6){
                                        this.chaveSend = true;
                                    }
                                    this.setState({});
                                    //console.log('mudou',el);
                                }}  >
                                    <PinInputField bg={'tema1.300'} border={0} borderRadius={'10px'} _hover={{border:0}}/>
                                    <PinInputField bg={'tema1.300'} border={0} borderRadius={'10px'} _hover={{border:0}}/>
                                    <PinInputField bg={'tema1.300'} border={0} borderRadius={'10px'} _hover={{border:0}}/>
                                    <Text>-</Text>
                                    <PinInputField bg={'tema1.300'} border={0} borderRadius={'10px'} _hover={{border:0}}/>
                                    <PinInputField bg={'tema1.300'} border={0} borderRadius={'10px'} _hover={{border:0}}/>
                                    <PinInputField bg={'tema1.300'} border={0} borderRadius={'10px'} _hover={{border:0}}/>
                                </PinInput>
                            </Stack> 
                            <Text fontSize='sm' color={'green.500'} fontWeight='bold' mt={'10px'} textAlign={'right'} cursor={'pointer'}> REENVIAR E-MAIL </Text>
                            <Stack w='100%' direction={'row'} align={'center'} justify={'center'} gap='20px' py='10px'>  
                                <Button onClick={async ()=>{ 
                                    try{
                                        if(isNaN(parseInt(await navigator.clipboard.readText()).toString())){
                                            this.chave ='';
                                        }else{ 
                                            this.chave = parseInt(await navigator.clipboard.readText()).toString(); 
                                            
                                        }
                                        if(this.chave.length==6){
                                            this.chaveSend = true;
                                        } 
                                    }catch(e){
                                        this.chave ='';
                                    }
                                    this.setState({})
                                    // Url.target.value = Url.value;
                                }} fontSize={'sm'}  m='10px 0 10px 0' h={'30px'} color={'green.500'} variant={'outline'} colorScheme="green">COLAR</Button>
                                <Button onClick={async ()=>{ 
                                    this.chave = '';
                                    this.setState({})
                                    // Url.target.value = Url.value;
                                }} fontSize={'sm'}  m='10px 0 10px 0' h={'30px'} color={'red.500'} variant={'outline'} colorScheme="red" leftIcon={<FaEraser/>}>LIMPAR</Button>
                            </Stack>
                        </Flex>:
                        <>
                            <Button
                                onClick={()=>{
                                    G.socket.removeAllListeners('sendSaque').on('sendSaque',(response)=>{   
                                        if(response.hash!== null && response.hash.cmd ==='email'){
                                            this.sendKey = true;
                                        } 
                                        this.atencao = <BoxWindow titulo = {response.titulo} mensagem={response.mensagem} onClose={()=>{
                                            this.atencao = null;
                                            this.setState({});
                                        }}/>
                                        this.setState({}); 
                                    })
                                    G.socket.emit("sendSaque", {
                                    valor:this.valorSaque
                                    });
                                }}
                                colorScheme='blue'
                                fontSize='18px'
                                type='submit'
                                mt='20px'
                                bg={'green.500'}
                                leftIcon={<GiReceiveMoney size={'20px'} />}
                                w='fit-content'
                                size='lg' 
                                _hover={{
                                    bg: 'tema1.300',
                                    boxShadow: 'xl',
                                    color:'green.500'
                                }} 
                                mb='0px'>
                                Solicitar Saque
                            </Button> 
                        </>
                    
                    }
                   
                </Flex>
            )
        }
        return  retorno
    }   
    render(){  
        var usu = G.painel; 
        var bg = 'transparent linear-gradient(0deg, rgba(46, 52, 68,0) 20%, rgba(46, 52, 68,1) 100%) 0% 0% no-repeat padding-box;' 
        var dados = this.BoxFase();
        return (
            <Modal isCentered isOpen={true}  size={{base:'sm',lg:'lg' }} onClose={this.props.onClose}>
                <ModalOverlay
                    bg='rgba(58, 255, 202,0.5)' 
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent bg={'tema1.200'}>
                    <ModalCloseButton  color={'tema1.100'}/> 
                    <ModalHeader color={'green.500'}>{dados.titulo}</ModalHeader> 
                    <ModalBody  color={'#ffffff'}>{dados.conteudo}</ModalBody> 
                    <ModalFooter/> 
                </ModalContent> 
                    {this.boxwallet}
                    {this.atencao}
            </Modal>
        )
              
       
    }
}
export default BoxSaque;