 
import {   
    DarkMode, 
    Flex
  } from "@chakra-ui/react"  
import MenuSite from "./MenuSite";
import MenuTopo from "./MenuTopo"; 
import './csss/TemaSite.css'   
import { useLocation } from "react-router-dom"; 
import ServidorSessao from "../../components/ServidorSessao";
import G from '../../components/WebSocket';
import { useEffect, useState } from "react";
let load =  false;
export default function(props){
    const location = useLocation();  
    const [load,setLoad]= useState(false);
    useEffect(() => {
        // Anything in here is fired on component mount.
        if(!G.conectado){
            G.conectarNode(()=>{   
                setLoad(true);  
            }); 
        }else{  
            setLoad(true);
        }  
    }, [load]);
    if(load){
        return (
            <DarkMode >  
                <div className="bgVideo" style={{zIndex:1}} > 
                    <div className="videoBox"  dangerouslySetInnerHTML={{ __html: `
                        <video playsinline loop autoPlay muted autobuffer>
                        <source src="./assets/video/login.mp4"}" type="video/mp4" />  
                        Your browser does not support the video tag. I suggest you upgrade your browser.
                    </video>` }}/>  
                    <div className='mask'></div>
                </div>
                <ServidorSessao logado={true} page={location.pathname} pageLoad = '/login' > 
                    <MenuSite page={location.pathname} /> 
                    <MenuTopo /> 
                    <Flex pos='relative' zIndex={2}  flexDir={'column'} h="100vh" pl={{base:'0',md:'210px'}} pt={{base:'60px'}} px='0px'  overflow={'hidden'} minH={'fit-content'}   overflowY={'auto'} >  
                        {props.children} 
                    </Flex>    
                    {G.viewPlanos}
                </ServidorSessao> 
            </DarkMode> 
        )
    }else{
        return <>Load...</>
    }


};
    