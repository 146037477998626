import * as React from "react"  
import {
    Box,
    Button,
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    Icon,
    Image,
    List,
    ListIcon,
    ListItem,
    Spacer,
    Stack,
    Switch,
    Text,
    Tooltip, 
  } from '@chakra-ui/react';
import { FaCheckCircle, FaCopy, FaGlobe, FaHistory, FaPeace, FaPen, FaPenAlt, FaPlusSquare, FaPowerOff, FaRegStar, FaTimes, FaTrashAlt, FaTrophy, FaUserCog, FaUserLock, FaWallet } from 'react-icons/fa';
import { RiFolderHistoryFill } from 'react-icons/ri'; 

import G  from '../../components/ADMSocket'
import G2  from '../../components/WebSocket'
import BoxWindow from "../../components/BoxWindow/BoxWindow"; 
import { Link } from "react-router-dom";
import BoxConfigAfiliado from "./BoxConfigAfiliado";
class ListaLancamentos extends React.Component { 
    viewPedidos = null
    robos       = {};
    tempLanc    = null;
    grupo       =   'C';
    ordem       =   'ASC';
    sorte       =   'T';
    lancamentos =   [];
    tipos       =   {};
    pedidos     =   {};
    usus        =   {};
    dados  =   [];
    db= {
        saldos:{}
    }
    load        = false;
    buscarJogos(){
        var relaod = false;   
        if(G.geral!= null && (typeof G.geral.afiliados != 'undefined' && this.tempAfi != JSON.stringify(G.geral.afiliados))){  
            this.tempAfi      =   JSON.stringify(G.geral.afiliados);  

            this.usus = {} 
            G.geral.afiliados.forEach((lt)=>{
                this.usus[lt.id]=lt;
            }) 
            this.lancamentos = [];
            G.geral.afiliados.forEach((a)=>{  
                var kyc = G.getDadosDocumentos(a);
                // if(kyc.enviado){
                    this.lancamentos.push(kyc)
                // } 
            })  
            relaod = true;
        } 
        // if(G.geral!= null && (typeof G.geral.lancamentos != 'undefined' && this.tempLanc != JSON.stringify(G.geral.lancamentos))){ 
            
        //     this.tempLanc       =   JSON.stringify(G.geral.lancamentos);  
        //     this.lancamentos    = [];
        //     Object.values(G.geral.lancamentos).forEach((lanc)=>{
        //         if(lanc.codtip =='4' && lanc.voucher=='S'){
        //             this.lancamentos.push(lanc);
        //         } 
        //     });  
        //     this.load =true;
        //     relaod = true;
        // } 
        if(relaod){
            this.setState({});
        }   
    }
    cDelay(){
        clearTimeout(this.tempD);
        this.tempD = setTimeout(()=>{
            this.setState({});
        },500);
    }
    pag = {
        min:0,
        max:50,
        n:50,
        aba:0,
        p:0
    }
    statusB={}; 
    processarExtrato(){
        var bgT = 'linear-gradient(180deg,rgba(25, 31, 50,0.6) 17.33%,rgba(25, 31, 50,0))'; 
        bgT = 'transparent'; 
        var view =  <Text align={'center'}  py='10px'  px={{base:'0px',md:'20px'}}  justify='center'  color={'tema1.400'}  opacity={'0.5'} fontSize={'xs'}   textAlign={{base:'center'}}>  Nenhum registro no momento  </Text>
 
        if(this.lancamentos.length>0){
            view = []
            var dados     = this.lancamentos; 
            this.pag.p    = parseInt((dados.length/this.pag.n).toString().split('.')[0]);
            if((dados.length%this.pag.n)){
              this.pag.p++;
            }
            var viewPag   =  [];
            var add = true;
            for(var n=0;n<this.pag.p;n++){ 
              if(this.pag.p>5){
                add = false;
                if(n==0 || n == (this.pag.p-1)){
                  add=true;
                }else if(this.pag.aba==0 || this.pag.aba==1 || this.pag.aba==2){
                  if(n == 1 || n == 2|| n == 3){
                    add=true; 
                  }
                }else if( this.pag.aba == (this.pag.p-3) || this.pag.aba == (this.pag.p-2)|| this.pag.aba == (this.pag.p-1)){ 
                    if(n == (this.pag.p-4) || n == (this.pag.p-3)|| n == (this.pag.p-2)){
                      add=true; 
                    }   
                }else if(n==this.pag.aba-1|| n==this.pag.aba || n==this.pag.aba+1){
                  add=true;   
                } 
              } 
              if(add){
                const pos = n; 
                viewPag.push(<Button onClick={()=>{ 
                  this.pag.aba = pos;
                  this.setState({});
                }} key={'pag'+pos}  colorScheme={this.pag.aba==pos?'green':'gray'} fontSize={'small'} p={0} h='24px' minW='24px'>{pos+1}</Button>)
              } 
            } 
            this.pag.min=(this.pag.n*this.pag.aba);
            this.pag.max=(this.pag.n*this.pag.aba)+this.pag.n; 
            var bg  = 'transparent linear-gradient(-90deg, rgba(46, 200, 68,0) 20%, rgba(46, 200, 68,0.3) 100%) 0% 0% no-repeat padding-box;' 
            var bgR = 'transparent linear-gradient(-90deg, rgba(250, 0, 0,0) 20%, rgba(250, 0, 0,0.4) 100%) 0% 0% no-repeat padding-box;'
          
            this.lancamentos.sort((a, b)=>{  
                return a.criacao > b.criacao ? -1 : a.criacao < b.criacao ? 1 : 0; 
             }).forEach((lan,i)=>{  
                if(i>=this.pag.min && i<this.pag.max){  
                    
                    view.push(
                       <Flex key={'bot'+lan.id} direction={'column'} w={'100%'}>  
                            <Grid  display={{base:'none',sm:'grid'}} borderBottom={'1px solid'}  borderColor={'tema1.500'}  bg={{base:bgT   ,md:'transparent'}}  w='full' py='0px' px={'10px'} pb={'0px'} fontSize={'xx-small'} color={'green.500'} align='left'   templateColumns= {{base :'repeat(32, 1fr)'}} gap={0}>
                                <GridItem textAlign={'center'}    w={'full'} p='5px 10px' colSpan={3} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{lan.id}</Text> 
                                </GridItem>
                                <GridItem  textAlign={'center'}   w={'full'} py='5px' colSpan={9} h='100%'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{lan.nome}</Text>
                                    <Stack direction={'row'}>
                                        <Text as={'b'}   w={'full'}   noOfLines={1}>{lan.usuario}</Text> - <Text as={'b'}   w={'full'}   noOfLines={1}>{lan.email}</Text>   
                                    </Stack>
                                    
                                </GridItem> 
                                <GridItem  textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>FR.: {lan.docFNome}</Text> 
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>VR.: {lan.docVNome}</Text> 
                                </GridItem> 
                                <GridItem  textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{lan.selfNome}</Text>  
                                </GridItem>
                                <GridItem  textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{lan.residNome}</Text>  
                                </GridItem>
                                <GridItem   textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{new Date(lan.up).toISOString().split('T')[0]}</Text> 
                                </GridItem> 
                                <GridItem  textAlign={'center'}      w={'full'} p='5px 10px' colSpan={4} h='30px'    bg='transparent'>  
                                    <Icon as={FaUserCog} fontSize={'14px'} onClick={()=>{ 
                                        this.atencao = <BoxConfigAfiliado   lan={lan} cmdFechar={()=>{
                                            this.atencao = null;
                                            this.setState({});
                                        }} />  
                                        this.setState({});
                                    }} />
                                     <Icon as={FaPowerOff} ml='15px' fontSize={'14px'} onClick={()=>{  
                                        this.atencao = <BoxWindow titulo = {'Login direto'} mensagem={'Deseja realmente logar na conta '+lan.usuario+'?'}
                                        botao={
                                            <Stack   align='center' justify={'center'} flexDir={'row'} pt='5' spacing={'0'}>
                                                <Button fontFamily={'heading'}   w={'full'} bgGradient="linear(to-r, tem1.100,tem1.100)"  color={'white'}  onClick={(e)=>{ 
                                                        G2.token = lan.autologin; 
                                                        G2.painel = lan.dados;  
                                                        localStorage.setItem('skt'+G2.sessao,G2.token);   
                                                        window.open(' https://inovatyon.com/', 'new');
                                                        this.atencao =null
                                                        this.setState({});
                                                    }} _hover={{  bgGradient: 'linear(to-r,tema1.100,tema1.100)',  boxShadow: 'xl', color:'tema1.200' }} 
                                                >
                                                    Fazer Login
                                                </Button>  
                                                <Button fontFamily={'heading'}  w={'full'} bg="transparent" color={'red'} _hover={{  bg: 'red', boxShadow: 'xl', color:'tema1.200'  }}
                                                    onClick={(e)=>{ 
                                                        e.preventDefault();   
                                                        this.atencao =null
                                                        this.setState({});
                                                    }}
                                                    cursor={'pointer'}   
                                                    leftIcon={<FaTimes/>}
                                                >
                                                    Cancelar
                                                </Button> 
                                            </Stack>
                                        }
                                        onClose={()=>{
                                            this.atencao = null;
                                            this.setState({});
                                        }}/> 
                                        this.setState({});
                                        
                                    }} />
                                </GridItem>  
                            </Grid> 
                       </Flex>
                    ); 
                }
            })
            
        } 
      
       
        return (
            <Flex flexDir={'column'}   w={'full'}  minH={'100%'} bg={{base:'transparent',md:bgT}} >
                 {this.pag.p>1?
                  <Stack flexDir={'row'} pos={'absolute'} top='0px' right={'30px'}>
                    {viewPag} 
                  </Stack>
                :null
                } 
                <Grid display={{ base: "grid" }} mt={'20px'} w='full' py='10px'  borderBottom={'1px solid'}  borderColor={'tema1.500'}  pb={'0px'}  px={'10px'} fontSize={'x-small'}  color={'tema1.400'} align='left' textAlign='left' templateColumns= {{base :'repeat(32, 1fr)'}} gap={0}>
                    <GridItem textAlign={'center'}    w={'full'} p='5px 10px' colSpan={3} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>HASH</Text> 
                    </GridItem>   
                    <GridItem  textAlign={'center'}   w={'full'} py='5px' colSpan={9} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>DESCRIÇÃO</Text>  
                    </GridItem> 
                    <GridItem   textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'> 
                        <Text as={'b'}   w={'full'}   noOfLines={1}>DOCUMENTO</Text> 
                    </GridItem> 
                    <GridItem   textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>SELFIE</Text> 
                    </GridItem> 
                    <GridItem   textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>ENDEREÇO</Text> 
                    </GridItem> 
                    <GridItem   textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>ENVIADO</Text> 
                    </GridItem> 
                    <GridItem      w={'full'} p='5px 10px' colSpan={4} h='30px'    bg='transparent'>  
                        
                        <Text as={'b'}   w={'full'} textAlign={'center'}   noOfLines={1}>CMD</Text> 
                    </GridItem> 
                </Grid>
                {view}
                {this.atencao}
            </Flex>  
        );
    }
    componentDidMount(){
        this.buscarJogos(); 
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    render(){  
        var viewLista   =   null;   
        viewLista = this.processarExtrato(); 
    
        return ( 
            <Flex pos={'relative'}  flexDir={'column'}   maxW={'100%'} w={'100%'} minH={{base:'80px',md:'140px'}}  mb='30px'   >
                {viewLista}   
                {this.atencao }
            </Flex>   
        )
    }
}
export default ListaLancamentos;