import * as React from "react"
import {   
    Grid,
    DarkMode,
    Image,
    Stack,
    Text,
    Flex, 
  } from "@chakra-ui/react"   
import './csss/TemaSite.css'   
import { Link, useLocation } from "react-router-dom"; 
import G from '../../components/ADMSocket';
import AdminSessao from "../../components/AdminSessao";
export default function(props){
    const location = useLocation();  
    const [load,setLoad]= React.useState(false);
    React.useEffect(() => {
        // Anything in here is fired on component mount.
        if(!G.conectado){
            G.conectarNode(()=>{  
                setLoad(true);  
            }); 
        }else{
            setLoad(true);
        }    
    }, [load])
    if(load){
        return (
            <DarkMode >  
                <AdminSessao logado={false}  page={location.pathname} pageLoad = '/skyadm/'>
                    <Grid bg={'tema1.100'} minH="100vh"   >  
                        <div className="bgVideo" > 
                            <div className="videoBox"  dangerouslySetInnerHTML={{ __html: `
                                <video playsinline loop autoPlay muted autobuffer>
                                <source src="/assets/video/login.mp4"}" type="video/mp4" />  
                                Your browser does not support the video tag. I suggest you upgrade your browser.
                            </video>` }}/>  
                            <div className='mask'></div>
                        </div>
                        <Flex flexDir={'column'} bg={'tema1.200'} h="100vh"      overflow={'hidden'} minH={'max-content'}   overflowY={'auto'} > 
                            {props.children} 
                        </Flex>
                    </Grid>   
                </AdminSessao>  
            </DarkMode> 
        )
    }else{
        return <></>
    }
   

};
    