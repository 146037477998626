import * as React from "react"  
import {Avatar, AvatarGroup, Button,  Flex, Grid, Icon, Menu, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Spinner, Stack, Text, Textarea } from "@chakra-ui/react"; 
import {Helmet} from "react-helmet"; 
import G from '../../components/WebSocket';
import { FaMoneyBillTransfer } from "react-icons/fa6"; 
import { GiReceiveMoney } from "react-icons/gi";
import { FaChartBar, FaPaste, FaDollarSign, FaMoneyBill, FaPencilAlt, FaRegCalendarAlt, FaCopy, FaArrowDown, FaArrowUp, FaFileUpload, FaIndent, FaCheck, FaRegCheckSquare, FaCheckSquare, FaCheckCircle, FaClock } from "react-icons/fa";
 
import Card from "../../components/VisionUI/Card/Card";
import CardBody from "../../components/VisionUI/Card/CardBody";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
import GradientBorder from "../../components/VisionUI/GradientBorder";
import { RiMastercardFill } from "react-icons/ri"; 
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import { SiTether } from "react-icons/si";
import { HSeparator } from "../../components/VisionUI/Separator/Separator"; 
import { HiIdentification } from "react-icons/hi2"; 
import TransactionRowSaque from "../../components/VisionUI/Tables/TransactionRowSaque";


class BoxDocumentoKYC extends React.Component { 
    atencao = null;
    doc = {
        comprovante     :   null,
        frente          :   null,
        confF           :   null,
        verso           :   null,
        confV           :   null,
    }
    async gerarFile(event,calback) { 
        var fileS = {};  
        Object.keys(event.target.files).forEach(async (key)=> {
            var file    =   event.target.files[key];   
            var reader      =   new FileReader();
            reader.onload =  (e)=> { 
                fileS= {
                    link:   e.target.result,
                    nome:   new Date().getTime(),
                    tipo:   '.'+file.name.split('.')[file.name.split('.').length-1]
                }; 
                calback(fileS);
            }; 
            reader.readAsDataURL(file); 
        });  
        return fileS;
    }
    resizeImg(base64, maxWidth, maxHeight,callback){ 
        // Max size for thumbnail
        if(typeof(maxWidth) === 'undefined')  maxWidth = 500;
        if(typeof(maxHeight) === 'undefined')  maxHeight = 500;
    
        // Create and initialize two canvas
        var canvas = document.createElement("canvas"); 
        var ctx = canvas.getContext("2d");
        var canvasCopy = document.createElement("canvas");
        var copyContext = canvasCopy.getContext("2d");
    
        // Create original image
        var img = new Image();
        img.src = base64;
        img.onload = function () {
            var ratio = 1;
            if(img.width >img.height){
                if(img.width > maxWidth){
                    ratio = maxWidth / img.width;
                }else if(img.height > maxHeight){
                    ratio = maxHeight / img.height;
                }
            }else{
                if(img.height > maxHeight){
                    ratio = maxHeight / img.height;
                }else if(img.width > maxWidth){
                    ratio = maxWidth / img.width;
                }
            } 
            // Draw original image in second canvas
            canvasCopy.width = img.width;
            canvasCopy.height = img.height;
            copyContext.drawImage(img, 0, 0); 
            // Copy and resize second canvas to first canvas
            canvas.width = img.width * ratio;
            canvas.height = img.height * ratio; 
            ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);
            callback(canvas.toDataURL()); 
        } 
    } 
    
    BoxDocumento(){
        var dados ={
            titulo      :   'Selecione ou Arraste a imagem do seu documento e identificação',
            conteudo    :    null
        } 
        if(this.doc.frente== null){
            dados.titulo = 'Selecione ou Arraste a frente do seu documento de identificação';
            dados.conteudo = (
                <>
                    <Text fontSize='sm' lineHeight='30px' color='#fff' fontWeight='bold' m={'10px 0'}>
                        Frente do Documento
                    </Text> 
                    <GradientBorder 
                        w='100%'
                        borderRadius='10px' 
                        >
                            
                            <Flex  
                                border='transparent'
                                borderRadius='10px'
                                align='center' 
                                h={'300px'}
                                p='0'
                                w='100%'>
                                <div className={"boxSendUpload "+this.hoverFile} onDragOver={()=>{this.hoverFile="boxSendUploadDrag";this.setState({})}} onDragLeave={()=>{this.hoverFile="";this.setState({})}} onDrop={()=>{this.hoverFile="";this.setState({})}} >
                                    <input className="sendFile"  type="file"  accept='image/*'  onChange={async (e)=>{ 
                                        this.gerarFile(e,(file)=>{   
                                            switch(file.tipo){
                                                case '.png':case '.jpg':case '.jpeg':
                                                    this.resizeImg(file.link,500,500,(img)=>{ 
                                                        file.link = img;
                                                        this.doc.frente= file;
                                                        this.setState({});
                                                    })  
                                                break;
                                                case '.pdf': 
                                                    this.doc.frente= file;
                                                    this.setState({}); 
                                                break;
                                                default:
                                                    this.atencao = <BoxWindow titulo = {'Falha no envio'} mensagem={'O formato '+file.tipo+' não é aceito pela plataforma, por favor, converta para PNG, JPG ou PDF!'} onClose={()=>{
                                                        this.atencao = null;
                                                        this.setState({});
                                                    }} />
                                                    this.setState({});    
                                                break;
                                            } 
                                            this.setState({});
                                        }) 
                                    }}/>
                                    <img src={  "./assets/img/frente.png"} className="animate__animated animate__pulse" alt="frente_doc"  style={{opacity:'0.5'}} />
                                </div> 
                            </Flex> 
                    </GradientBorder> 
                </> 
            )
        }else if(this.doc.confF== null){
            dados.titulo = 'Confirmar se o frente está legível';
            dados.conteudo = ( 
                <>
                    <Text fontSize='sm' lineHeight='30px' color='#fff' fontWeight='bold' m={'10px 0'}>
                        Confirmar frente do Documento
                    </Text>
                    <GradientBorder 
                        w='100%'
                        borderRadius='10px'>
                            <Flex  
                                pos={'relative'}
                                border='transparent'
                                borderRadius='10px'
                                align='center' 
                                h={'250px'}
                                p='0'
                                w='100%'
                                maxH={'100%'}
                                overflow={'hidden'}
                                overflowY={'auto'}
                                direction={'column'}
                                bg='rgba(58, 255, 202,0.5)'
                                >
                                    <embed src={this.doc.frente.link} className="animate__animated animate__pulse" style={{ width:'100%',height:(this.doc.frente.tipo=='.pdf'?'100%':'auto'), position:'absolute',left:'0px',top:'0px',transform: 'translate(-50% -50%)'}} />
                                    
                            </Flex>

                            
                    </GradientBorder>   
                    <Flex
                        position={'relative'}
                        background='transparent'  
                        w='100%'  
                        justifyContent={'center'}
                        mx={{ base: "0px" }}
                        pb={'10px'}
                         >   
                        <Button
                            variant='brand'
                            fontSize='14px'
                            type='submit' 
                            h='40px'
                            mt='10px'
                            mb='10px'
                            alignItems={'center'}
                            justifyContent={'center'}
                            colorScheme="green"
                            bg={'green.500'}
                            color={'tema1.300'}
                            onClick={() => { 
                                this.doc.confF = true;
                                this.setState({});
                            }}
                            >
                            Confirmar Frente
                        </Button>
                        <Button
                            variant='outline'
                            fontSize='14px'
                            type='submit' 
                            h='40px'
                            ml="20px"
                            mt='10px'
                            mb='10px'
                            alignItems={'center'}
                            justifyContent={'center'} 
                            colorScheme="red"

                            onClick={() => { 
                                this.doc.frente = null 
                                this.setState({});
                            }}
                            >
                            Tentar Novamente
                        </Button>  
                    </Flex>
                </>
            ) 
        }else if(this.doc.verso == null && this.doc.confV == null){
            dados.titulo = 'Selecione ou Arraste o verso do seu documento de identificação';
            dados.conteudo = (
                <>
                    <Text fontSize='sm' lineHeight='30px' color='#fff' fontWeight='bold' m={'10px 0'}>
                        Verso do Documento
                    </Text> 
                    <GradientBorder 
                        w='100%'
                        borderRadius='10px' 
                        >
                            
                            <Flex  
                                border='transparent'
                                borderRadius='10px'
                                align='center' 
                                h={'300px'}
                                p='0'
                                w='100%'>
                                <div className={"boxSendUpload "+this.hoverFile} onDragOver={()=>{this.hoverFile="boxSendUploadDrag";this.setState({})}} onDragLeave={()=>{this.hoverFile="";this.setState({})}} onDrop={()=>{this.hoverFile="";this.setState({})}} >
                                    <input className="sendFile"  type="file"  accept='image/*'  onChange={async (e)=>{ 
                                        this.gerarFile(e,(file)=>{   
                                            switch(file.tipo){
                                                case '.png':case '.jpg':case '.jpeg':
                                                    this.resizeImg(file.link,500,500,(img)=>{ 
                                                        file.link = img;
                                                        this.doc.verso= file;
                                                        this.setState({});
                                                    })  
                                                break;
                                                case '.pdf': 
                                                    this.doc.frente= file;
                                                    this.setState({}); 
                                                break; 
                                                default:
                                                    this.atencao = <BoxWindow titulo = {'Falha no envio'} mensagem={'O formato '+file.tipo+' não é aceito pela plataforma, por favor, converta para PNG, JPG ou PDF!'} onClose={()=>{
                                                        this.atencao = null;
                                                        this.setState({});
                                                    }} />
                                                    this.setState({});    
                                                break;
                                            } 
                                            this.setState({});
                                        }) 
                                    }}/>
                                    <img src={  "./assets/img/verso.png"} className="animate__animated animate__pulse" alt="frente_doc"  style={{opacity:'0.5'}} />
                                </div> 
                            </Flex> 
                    </GradientBorder> 
                    <Flex
                        position={'relative'}
                        background='transparent'  
                        w='100%'  
                        justifyContent={'center'}
                        mx={{ base: "0px" }}
                        pb={'10px'}
                         >    
                        <Button
                            variant='outline'
                            fontSize='14px'
                            type='submit' 
                            h='40px' 
                            mt='10px'
                            mb='10px'
                            alignItems={'center'}
                            justifyContent={'center'} 
                            colorScheme="red"

                            onClick={() => { 
                                this.doc.confV = false;
                                this.setState({});
                            }}
                            >
                            Não tem Verso
                        </Button>  
                    </Flex>
                </> 
            )
        }else if(this.doc.confV == null){
            dados.titulo = 'Confirmar se o verso está legível';
            dados.conteudo = ( 
                <>
                    <Text fontSize='sm' lineHeight='30px' color='#fff' fontWeight='bold' m={'10px 0'}>
                        Confirmar verso do Documento
                    </Text>
                    <GradientBorder 
                        w='100%'
                        borderRadius='10px'>
                        <Flex  
                            pos={'relative'}
                            border='transparent'
                            borderRadius='10px'
                            align='center' 
                            h={'250px'}
                            p='0'
                            w='100%'
                            maxH={'100%'}
                            overflow={'hidden'}
                            overflowY={'auto'}
                            direction={'column'}
                            bg='rgba(58, 255, 202,0.5)'
                            >
                                <embed src={this.doc.verso.link} className="animate__animated animate__pulse" style={{ width:'100%',height:(this.doc.frente.tipo=='.pdf'?'100%':'auto'), position:'absolute',left:'0px',top:'0px',transform: 'translate(-50% -50%)'}} />
                                
                        </Flex> 
                    </GradientBorder>   
                    <Flex
                        position={'relative'}
                        background='transparent'  
                        w='100%'  
                        justifyContent={'center'}
                        mx={{ base: "0px" }}
                        pb={'10px'}
                         >   
                        <Button
                            variant='brand'
                            fontSize='14px'
                            type='submit' 
                            h='40px'
                            mt='10px'
                            mb='10px'
                            alignItems={'center'}
                            justifyContent={'center'}
                            colorScheme="green"
                            bg={'green.500'}
                            color={'tema1.300'}
                            onClick={() => { 
                                this.doc.confV = true;
                                this.setState({});
                            }}
                            >
                            Confirmar Verso
                        </Button>
                        <Button
                            variant='outline'
                            fontSize='14px'
                            type='submit' 
                            h='40px'
                            ml="20px"
                            mt='10px'
                            mb='10px'
                            alignItems={'center'}
                            justifyContent={'center'} 
                            colorScheme="red"

                            onClick={() => { 
                                this.doc.verso = null 
                                this.setState({});
                            }}
                            >
                            Tentar Novamente
                        </Button>  
                    </Flex>
                </>
            ) 
        }else{
            dados.conteudo = ( 
                <>
                    <Flex direction={'row'}>
                        <Stack w={'100%'}>
                            <Text fontSize='sm' lineHeight='30px' color='#fff' fontWeight='bold' m={'10px 0'}>
                                Frente do Documento
                            </Text>
                            <GradientBorder 
                                w='100%'
                                borderRadius='10px'>
                                    <Flex  
                                        pos={'relative'}
                                        border='transparent'
                                        borderRadius='10px'
                                        align='center' 
                                        h={(this.doc.confV?'125px':'250px')}
                                        p='0'
                                        w='100%'
                                        maxH={'100%'}
                                        overflow={'hidden'}
                                        overflowY={'auto'}
                                        direction={'column'}
                                        bg='rgba(58, 255, 202,0.5)'
                                        >
                                            <embed src={this.doc.frente.link} className="animate__animated animate__pulse" style={{ width:'100%',height:(this.doc.frente.tipo=='.pdf'?'100%':'auto'), position:'absolute',left:'0px',top:'0px',transform: 'translate(-50% -50%)'}} />
                                            
                                    </Flex> 
                            </GradientBorder>   
                        </Stack>
                        {(!this.doc.confV?
                            <></>:
                            (
                                <Stack w={'100%'}>
                            <Text fontSize='sm' lineHeight='30px' color='#fff' fontWeight='bold' m={'10px 0'}>
                                Verso do Documento
                            </Text>
                            <GradientBorder 
                                w='100%'
                                borderRadius='10px'>
                                    <Flex  
                                        pos={'relative'}
                                        border='transparent'
                                        borderRadius='10px'
                                        align='center' 
                                        h={'125px'}
                                        p='0'
                                        w='100%'
                                        maxH={'100%'}
                                        overflow={'hidden'}
                                        overflowY={'auto'}
                                        direction={'column'}
                                        bg='rgba(58, 255, 202,0.5)'
                                        >
                                            <embed src={this.doc.verso.link} className="animate__animated animate__pulse" style={{ width:'100%',height:(this.doc.verso.tipo=='.pdf'?'100%':'auto'), position:'absolute',left:'0px',top:'0px',transform: 'translate(-50% -50%)'}} />
                                            
                                    </Flex> 
                            </GradientBorder> 
                        </Stack>
                            )
                        )}
                        
                        
                    </Flex>
                    <Flex
                        position={'relative'}
                        background='transparent'  
                        w='100%'  
                        justifyContent={'center'}
                        mx={{ base: "0px" }}
                        pb={'10px'}
                         >   
                        <Button
                            variant='brand'
                            fontSize='14px'
                            type='submit' 
                            h='40px'
                            mt='10px'
                            mb='10px'
                            alignItems={'center'}
                            justifyContent={'center'}
                            colorScheme="green"
                            bg={'green.500'}
                            color={'tema1.300'}
                            onClick={() => { 
                                var send = {};
                                if(this.doc.confF){
                                    send.frente =this.doc.frente; 
                                }
                                if(this.doc.confV){
                                    send.verso =this.doc.verso; 
                                }   
                                G.socket.removeAllListeners('formDados').on('formDados',(response)=>{     
                                    if(response.cmd == "kycDocumento"){
                                        this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                                            if(!response.result.erro){
                                                this.props.onClose()
                                            }
                                            this.atencao = null;
                                            this.setState({});
                                        
                                        }}/> 
                                        this.setState({});
                                    }  
                                  
                                }); 
                                G.socket.emit("formDados", {
                                    modulo  :   'usuario',
                                    cmd     :   'kycDocumento',
                                    form    :   send
                                });    
                                this.atencao = <BoxWindow load={true} /> 
                                this.setState({});
                            }}
                            >
                            Enviar Documento
                        </Button>
                        <Button
                            variant='outline'
                            fontSize='14px'
                            type='submit' 
                            h='40px'
                            ml="20px"
                            mt='10px'
                            mb='10px'
                            alignItems={'center'}
                            justifyContent={'center'} 
                            colorScheme="red"

                            onClick={() => { 
                                this.doc.frente     =   null 
                                this.doc.confF      =   null 
                                this.doc.verso      =   null 
                                this.doc.confV      =   null 
                                this.setState({});
                            }}
                            >
                            Tentar Novamente
                        </Button>  
                    </Flex>
                </>
                
            ) 
        }
        return ({
            titulo      :   dados.titulo   ,
            conteudo    :   dados.conteudo
        });
    }
    BoxSelfie(){
        var dados ={
            titulo      :   'Selecione ou Arraste a imagem do seu comprovante de residência',
            conteudo    :    null
        } 
        if(this.doc.selfie== null){
            dados.titulo = 'Selecione ou Arraste a imagem de sua selfie com documento na mão';
            dados.conteudo = (
                <GradientBorder 
                    w='100%'
                    borderRadius='10px'>
                        <Flex  
                            border='transparent'
                            borderRadius='10px'
                            align='center' 
                            h={'450px'}
                            p='0'
                            w='100%'>
                            <div className={"boxSendUpload "+this.hoverFile} onDragOver={()=>{this.hoverFile="boxSendUploadDrag";this.setState({})}} onDragLeave={()=>{this.hoverFile="";this.setState({})}} onDrop={()=>{this.hoverFile="";this.setState({})}} >
                                <input className="sendFile"  type="file"  accept='image/*'  onChange={async (e)=>{ 
                                    this.gerarFile(e,(file)=>{   
                                        switch(file.tipo){
                                            case '.png':case '.jpg':case '.jpeg': 
                                                this.resizeImg(file.link,500,500,(img)=>{ 
                                                    file.link = img;
                                                    this.doc.selfie= file;
                                                    this.setState({});
                                                })  
                                                
                                            break;
                                            case '.pdf': 
                                                this.doc.frente= file;
                                                this.setState({}); 
                                            break; 
                                            default:
                                                this.atencao = <BoxWindow titulo = {'Falha no envio'} mensagem={'O formato '+file.tipo+' não é aceito pela plataforma, por favor, converta para PNG, JPG ou PDF!'} onClose={()=>{
                                                    this.atencao = null;
                                                    this.setState({});
                                                }} />
                                                this.setState({});    
                                            break;
                                        } 
                                        this.setState({});
                                    }) 
                                }}/>
                                <img src={  "./assets/img/selfie.png"} className="animate__animated animate__pulse" alt="comprovante_doc"  style={{opacity:'0.5'}} />
                            </div> 
                        </Flex>
                </GradientBorder> 
            )
        }else{
            dados.titulo = 'Confirmar se o comprovante está legível';
            dados.conteudo = ( 
                <>
                    <GradientBorder 
                        w='100%'
                        borderRadius='10px'>
                            <Flex  
                                pos={'relative'}
                                border='transparent'
                                borderRadius='10px'
                                align='center' 
                                h={'450px'}
                                p='0'
                                w='100%'
                                maxH={'100%'}
                                overflow={'hidden'}
                                overflowY={'auto'}
                                direction={'column'}
                                bg='rgba(58, 255, 202,0.5)'
                                >
                                    <embed src={this.doc.selfie.link} className="animate__animated animate__pulse" style={{ width:'100%',height:(this.doc.selfie.tipo=='.pdf'?'100%':'auto'), position:'absolute',left:'0px',top:'0px',transform: 'translate(-50% -50%)'}} />
                                    
                            </Flex>

                            
                    </GradientBorder>   
                    <Flex
                        position={'relative'}
                        background='transparent'  
                        w='100%'  
                        justifyContent={'center'}
                        mx={{ base: "0px" }}
                        pb={'10px'}
                        bg={{
                        base: "#110d1e",
                        }}>   
                        <Button
                            variant='brand'
                            fontSize='14px'
                            type='submit' 
                            h='40px'
                            mt='10px'
                            mb='10px'
                            alignItems={'center'}
                            justifyContent={'center'}
                            colorScheme="green"
                            bg={'green.500'}
                            color={'tema1.300'}
                            onClick={() => { 
                                G.socket.removeAllListeners('formDados').on('formDados',(response)=>{    
                                    if(response.cmd === "kycSelfie"){
                                        this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                                            if(!response.result.erro){
                                                this.props.onClose()
                                            }
                                            this.atencao = null;
                                            this.setState({});
                                        
                                        }}/> 
                                    }  
                                    this.setState({}); 
                                }); 
                                var send = {};   
                                send.selfie        =   this.doc.selfie; 
                                G.socket.emit("formDados", {
                                    modulo  :   'usuario',
                                    cmd     :   'kycSelfie',
                                    form    :   send
                                }); 
                                this.atencao = <BoxWindow load={true} />
                                this.setState({}); 
                            }}
                            >
                            Enviar Selfie
                        </Button>
                        <Button
                            variant='outline'
                            fontSize='14px'
                            type='submit' 
                            h='40px'
                            ml="20px"
                            mt='10px'
                            mb='10px'
                            alignItems={'center'}
                            justifyContent={'center'} 
                            colorScheme="red"

                            onClick={() => { 
                                this.doc.comprovante = null 
                                this.setState({});
                            }}
                            >
                            Tentar Novamente
                        </Button>  
                    </Flex>
                </>
            )

            


        }
        return ({
            titulo      :   dados.titulo   ,
            conteudo    :   dados.conteudo
        });
    }
    BoxComprovante(){
        var dados ={
            titulo      :   'Selecione ou Arraste a imagem do seu comprovante de residência',
            conteudo    :    null
        } 
        if(this.doc.comprovante== null){
            dados.titulo = 'Selecione ou Arraste a imagem do seu comprovante de residência';
            dados.conteudo = (
                <GradientBorder 
                    w='100%'
                    borderRadius='10px'>
                        <Flex  
                            border='transparent'
                            borderRadius='10px'
                            align='center' 
                            h={'450px'}
                            p='0'
                            w='100%'>
                            <div className={"boxSendUpload "+this.hoverFile} onDragOver={()=>{this.hoverFile="boxSendUploadDrag";this.setState({})}} onDragLeave={()=>{this.hoverFile="";this.setState({})}} onDrop={()=>{this.hoverFile="";this.setState({})}} >
                                <input className="sendFile"  type="file"  accept='image/*'  onChange={async (e)=>{ 
                                    this.gerarFile(e,(file)=>{   
                                        switch(file.tipo){
                                            case '.png':case '.jpg':case '.jpeg':case '.pdf':
                                                this.resizeImg(file.link,500,500,(img)=>{ 
                                                    file.link = img;
                                                    this.doc.comprovante= file;
                                                    this.setState({});
                                                })  
                                            break;
                                            default:
                                                this.atencao = <BoxWindow titulo = {'Falha no envio'} mensagem={'O formato '+file.tipo+' não é aceito pela plataforma, por favor, converta para PNG, JPG ou PDF!'} onClose={()=>{
                                                    this.atencao = null;
                                                    this.setState({});
                                                }} />
                                                this.setState({});    
                                            break;
                                        } 
                                        this.setState({});
                                    }) 
                                }}/>
                                <img src={  "./assets/img/comprovante.png"} className="animate__animated animate__pulse" alt="comprovante_doc"  style={{opacity:'0.5'}} />
                            </div> 
                        </Flex>
                </GradientBorder> 
            )
        }else{
            dados.titulo = 'Confirmar se o comprovante está legível';
            dados.conteudo = ( 
                <>
                    <GradientBorder 
                        w='100%'
                        borderRadius='10px'>
                            <Flex  
                                pos={'relative'}
                                border='transparent'
                                borderRadius='10px'
                                align='center' 
                                h={'450px'}
                                p='0'
                                w='100%'
                                maxH={'100%'}
                                overflow={'hidden'}
                                overflowY={'auto'}
                                direction={'column'}
                                bg='rgba(58, 255, 202,0.5)'
                                >
                                    <embed src={this.doc.comprovante.link} className="animate__animated animate__pulse" style={{ width:'100%',height:(this.doc.comprovante.tipo=='.pdf'?'100%':'auto'), position:'absolute',left:'0px',top:'0px',transform: 'translate(-50% -50%)'}} />
                                    
                            </Flex>

                            
                    </GradientBorder>  

                    <Text fontSize='sm' lineHeight='30px' color='#fff' fontWeight='bold' m={'10px 0'}>
                        Digite o endereço:
                    </Text>
                    <Textarea onChange={(el)=>{
                        this.endereco = el.target.value;
                    }} mb='10px' id='inopay' p='10px' lineHeight={'20px'} borderRadius='10px' outline={'none'} resize={'none'} color='#fff' minH={'60px'} fontSize='sm'>
                                                        
                    </Textarea>
                    <Flex
                        position={'relative'}
                        background='transparent'  
                        w='100%'  
                        justifyContent={'center'}
                        mx={{ base: "0px" }}
                        pb={'10px'}
                        bg={{
                        base: "#110d1e",
                        }}>   
                        <Button
                            variant='brand'
                            fontSize='14px'
                            type='submit' 
                            h='40px'
                            mt='10px'
                            mb='10px'
                            alignItems={'center'}
                            justifyContent={'center'}
                            colorScheme="green"
                            bg={'green.500'}
                            color={'tema1.300'}
                            onClick={() => { 
                                G.socket.removeAllListeners('formDados').on('formDados',(response)=>{    
                                    if(response.cmd === "kycComprovante"){
                                        this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                                            if(!response.result.erro){
                                                this.props.onClose()
                                            }
                                            this.atencao = null;
                                            this.setState({});
                                        
                                        }}/> 
                                    }  
                                    this.setState({}); 
                                }); 
                                var send = {};  
                                send.endereco           =   this.endereco;
                                send.comprovante        =   this.doc.comprovante; 
                                G.socket.emit("formDados", {
                                    modulo  :   'usuario',
                                    cmd     :   'kycComprovante',
                                    form    :   send
                                }); 
                                this.atencao = <BoxWindow load={true} />
                                this.setState({});
                                // this.clickOK(this.props.comando)
                            }}
                            >
                            Confirmar Arquivo
                        </Button>
                        <Button
                            variant='outline'
                            fontSize='14px'
                            type='submit' 
                            h='40px'
                            ml="20px"
                            mt='10px'
                            mb='10px'
                            alignItems={'center'}
                            justifyContent={'center'} 
                            colorScheme="red"

                            onClick={() => { 
                                this.doc.comprovante = null 
                                this.setState({});
                            }}
                            >
                            Tentar Novamente
                        </Button>  
                    </Flex>
                </>
            )

            


        }
        return ({
            titulo      :   dados.titulo   ,
            conteudo    :   dados.conteudo
        });
    }
    BoxComando(){
        switch (this.props.cmd) {
            case 'documento':
                return this.BoxDocumento();
            break;
            case 'selfie':
                return this.BoxSelfie();
            break;
            case 'comprovante':
                return this.BoxComprovante();
            break;
        }
    }
    componentDidMount(){
        // this.buscarJogos();
        // this.tempInter = setInterval(()=>{
        //     this.buscarJogos();
        // },200) 
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    render(){  
        var usu = G.painel; 
        var bg = 'transparent linear-gradient(0deg, rgba(46, 52, 68,0) 20%, rgba(46, 52, 68,1) 100%) 0% 0% no-repeat padding-box;' 
        var dados = this.BoxComando();
        return (
            <Modal isCentered isOpen={true}  size={{base:'sm',lg:'lg' }} onClose={this.props.onClose}>
                <ModalOverlay
                    bg='rgba(58, 255, 202,0.5)' 
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent bg={'tema1.200'}>
                    <ModalCloseButton  color={'tema1.100'}/> 
                    <ModalHeader color={'green.500'}>{dados.titulo}</ModalHeader> 
                    <ModalBody  color={'#ffffff'}>{dados.conteudo}</ModalBody> 
                    <ModalFooter/> 
                </ModalContent> 
                  
                    {this.atencao}
            </Modal>
        )
              
       
    }
}
export default BoxDocumentoKYC;