import * as React from "react"  
import { Box, Button, FormControl, FormLabel, Grid, Heading, Icon, Image, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text } from "@chakra-ui/react";
 
import PhoneInput from 'react-phone-number-input'
import  InputMask  from 'react-input-mask';   
import BoxWindow from "../BoxWindow/BoxWindow";
import G            from '../WebSocket';
import GradientBorder from "../VisionUI/GradientBorder";
import { FaCalendarDay, FaGlobeAmericas, FaIdCard, FaMailBulk, FaWallet } from "react-icons/fa"; 
class BoxWallet extends React.Component { 
    open = null;
    show1 = false;
    show2 = false;
    atencao =   null;
    render(){ 
        var show = false;
        // console.log(G.painel);
        return (
            <>
                <Modal isCentered isOpen={true}  size={{base:'md' }} onClose={()=>{
                    this.props.cmdFechar();
                }}> 
                    <ModalOverlay
                        bg='rgba(58, 255, 202,0.3)' 
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                    />
                    <ModalContent bg={'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'}>
                        <ModalHeader color={'tema1.100'}>Alterar Wallet</ModalHeader> 
                        <ModalCloseButton  color={'tema1.100'}/> 
                        <ModalBody>
                            <Stack 
                                rounded={'xl'}
                                px={{ base: 2, sm: 4, md: 6 }} 
                                pb={{ base: 2, sm: 4, md: 6 }} 
                                pt='0'
                             > 
                            <Box as={'form'}  onSubmit={(e)=>{
                                e.preventDefault();  
                                const formdata = new FormData(e.target);
                                let json = {};  
                                formdata.forEach(function(value, prop){ 
                                    json[prop] = value;
                                });   
                                G.socket.removeAllListeners('formDados').on('formDados',(response)=>{     
                                    if(response.cmd === "alterarCarteira"){  
                                        if(response.result.dfa){
                                            // this.code = <DoisFatores cmdFechar = {()=>{
                                            //     this.code = null; 
                                            //     this.setState({});
                                            // }}
                                            // cmdSend = {(codigo)=>{
                                            //     json.codigo = codigo;
                                            //     this.sendFormAlterarSenha(json);
                                            //     this.atencao = <BoxWindow   config    =   { {tamanho:"Alerta"} } titulo = "Aguarde" conteudo = "Enviando dados ao servidor..." comando = { {tipo:"ok"} }/>;
                                            //     this.setState({});
                                            // }}
                                            // /> 
                                            this.atencao = null;
                                            this.setState({});
                                        }else {
                                            this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                                                if(!response.result.erro){ 
                                                    this.props.cmdFechar();
                                                }
                                                this.atencao = null;
                                                this.setState({});
                                            }}/> 
                                            this.setState({}); 
                                        } 
                                    } 
                                }); 
                                G.socket.emit("formDados", {
                                    modulo  :   'usuario',
                                    cmd     :   'alterarCarteira',
                                    form    :   json
                                });  
                                this.atencao = <BoxWindow load = {true}/>
                                this.setState({});
                            }} >
                                <Stack spacing={0}> 
                                    <Stack bg={'linear-gradient(127.09deg, rgba(250, 0, 0, 0.94) 19.41%, rgba(250, 0, 0, 0.49) 76.65%)'} p={'20px'} borderRadius={'20px'}  mb={'20px'} >
                                        <Text fontWeight={'bold'} color={'tema1.100'}>ATENÇÃO!</Text>
                                        <Text fontWeight={'bold'} color={'tema1.100'}>Informe apenas uma carteira da REDE BEP-20, caso contrário você poderá perder seus ativos, não nos responsabilizamos caso isso aconteça!</Text>
                                        
                                    </Stack>
                                    <Stack  direction={{base:'column',sm :'row'}} gap={{ base: "0px", sm: "20px"}}> 
                                        <FormControl> 
                                            <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.100'>
                                            Sua Carteira BEP-20
                                            </FormLabel>
                                            <GradientBorder mb={{base:'0px',sm:'10px'}} w={{ base: "100%"}} borderRadius='20px'>
                                                <InputGroup size='lg'>
                                                    <InputLeftElement  width='3rem'>
                                                        <Icon as={FaWallet} boxSize={5}  color="green.500"/> 
                                                    </InputLeftElement>
                                                    <Input
                                                        pl='3rem'
                                                        as={InputMask}
                                                        mask={'####################################################################################################'}  
                                                        formatChars= {{'#': '[A-Za-z0-9]'}}  
                                                        maskChar= ''
                                                        alwaysShowMask= {true} 
                                                        placeholder="Carteira BEP-20" 
                                                        bg={'tema1.200'}
                                                        border={0}
                                                        defaultValue={G.painel.wallet}
                                                        fontSize={{base:'small',sm:'sm'}}
                                                        name="carteira"
                                                        color={'tema1.100'}
                                                        _placeholder={{
                                                            color: 'gray.300',
                                                        }}
                                                    /> 
                                                </InputGroup>
                                            </GradientBorder>
                                        </FormControl>
                                    </Stack>
                                     
                                </Stack>
                                
                                <Stack align='center' justify={'center'} flexDir={'row'} pt='5' spacing={1}>
                                    <Button
                                        type="submit"
                                        fontFamily={'heading'} 
                                        w={'full'}
                                        bgGradient="linear(to-r, tem1.100,tem1.100)"
                                        color={'white'}
                                        _hover={{
                                            bgGradient: 'linear(to-r,tema1.100,tema1.100)',
                                            boxShadow: 'xl',
                                            color:'tema1.200'
                                        }}>
                                        Salvar Carteira
                                    </Button> 
                                    <Button
                                        fontFamily={'heading'} 
                                        w={'160px'}
                                        bg="transparent"
                                        color={'red'}
                                        _hover={{
                                            bgGradient: 'linear(to-r,tema1.100,tema1.100)',
                                            boxShadow: 'xl',
                                            color:'tema1.200'
                                        }}
                                        cursor={'pointer'} onClick={()=>{
                                            this.props.cmdFechar();
                                        }}
                                        >
                                        Cancelar
                                    </Button>  
                                </Stack>
                            </Box> 
                            </Stack>
                        </ModalBody> 
                        {this.atencao}
                    </ModalContent> 
                </Modal>
            </>
        )
    }
}
export default BoxWallet;