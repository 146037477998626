import * as React from "react"  
import {Avatar, AvatarGroup, Button,  Flex, Grid, Icon, Menu, Spacer, Stack, Text, Textarea } from "@chakra-ui/react"; 
import { FcSelfie } from "react-icons/fc";
import {Helmet} from "react-helmet"; 
import G from '../../components/WebSocket';
import { FaMoneyBillTransfer } from "react-icons/fa6"; 
import { GiReceiveMoney } from "react-icons/gi";
import { FaChartBar, FaPaste, FaDollarSign, FaMoneyBill, FaPencilAlt, FaRegCalendarAlt, FaCopy, FaArrowDown, FaArrowUp, FaFileUpload, FaIndent, FaCheck, FaRegCheckSquare, FaCheckSquare, FaCheckCircle, FaClock, FaChrome, FaHome, FaRocket, FaQrcode } from "react-icons/fa";
 
import Card from "../../components/VisionUI/Card/Card";
import CardBody from "../../components/VisionUI/Card/CardBody";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
import GradientBorder from "../../components/VisionUI/GradientBorder";
import { RiAlarmWarningFill, RiFileWarningFill, RiMastercardFill } from "react-icons/ri"; 
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import { SiTether } from "react-icons/si";
import { HSeparator } from "../../components/VisionUI/Separator/Separator"; 
import { HiIdentification } from "react-icons/hi2";
import TransactionRow from "../../components/VisionUI/Tables/TransactionRow";
import TransactionRowDeposito from "../../components/VisionUI/Tables/TransactionRowDeposito";
// import BoxDocumentoKYC from "./BoxDocumentoKYC";
// import BoxSaques    from './BoxSaque'

class PageDepositos extends React.Component { 
    jogos = 0;
    robos = 0;
    db={
        saldos:{},
        status:{},
        lancamentos:[]
    } 
    pacote = {};
    gerarStatusGerais(){ 
        var total = (G.geral.saldos.rendimento+G.geral.saldos.indicacao+G.geral.saldos.indicacaoI+G.geral.saldos.binario+G.geral.saldos.residual);
        this.db.status = { 
            rendimento:{
                p:(G.geral.saldos.rendimento*100/total).toFixed(2),
                v:G.geral.saldos.rendimento
            },
            indicacao:{
                p:(G.geral.saldos.indicacao*100/total).toFixed(2),
                v:G.geral.saldos.indicacao
            },
            indicacaoI:{
                p:(G.geral.saldos.indicacaoI*100/total).toFixed(2),
                v:G.geral.saldos.indicacaoI
            },
            binario:{
                p:(G.geral.saldos.binario*100/total).toFixed(2),
                v:G.geral.saldos.binario
            },
            residual:{
                p:(G.geral.saldos.residual*100/total).toFixed(2),
                v:G.geral.saldos.residual
            }
        }  
    }
    buscarJogos(){
        var reload = false; 
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.saldos!=='undefined' && this.tempSaldos != JSON.stringify(G.geral.saldos)){
            this.tempSaldos = JSON.stringify(G.geral.saldos); 
            this.db.saldos = G.geral.saldos; 
            
            this.load = true;
            this.gerarStatusGerais();
            reload = true;
        }     
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.pedidos!=='undefined' && this.tempLance != JSON.stringify(G.geral.pedidos)){
            // console.log(G.pedidos);
            this.tempLance = JSON.stringify(G.geral.pedidos);   
            this.db.lancamentos = []; 
            var max = 0;
            this.pacote = {};
            G.geral.pedidos.sort((a, b)=>{  
                return a.criacao > b.criacao ? -1 : a.criacao < b.criacao ? 1 : 0; 
             }).forEach((lan,i)=>{   
                
                if(lan.situa == 'P'){
                    this.pacote = lan;
                }
                if(lan.situa == 'P' || lan.situa == 'N'){
                    // console.log(lan);
                    this.db.lancamentos.push({
                        name    :   'Depósito',
                        situa   :   lan.situa,
                        date    :   new Date(lan.criacao).toISOString().split('T')[0],
                        price   :   G.parseValor(lan.valor+lan.valor_taxa,5).f+' USDT',
                        pago   :   G.parseValor((lan.valor_pago== null)?0:lan.valor_pago,5).f+' USDT',
                        logo    :   ((lan.situa=='P')?FaClock:FaCheck),
                    }) 
                }
                
            }) 
           
            this.load = true;
            this.gerarStatusGerais();
            reload = true;
        }  
        if(typeof G.painel !=='undefined' && this.tempUsu != JSON.stringify(G.painel)){
            this.tempUsu = JSON.stringify(G.painel)
            reload = true;
        }
        if(reload){
            this.setState({}); 
        }
    } 
    componentDidMount(){
        this.buscarJogos();
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    render(){  
        var usu = G.painel; 
        var bg = 'transparent linear-gradient(0deg, rgba(46, 52, 68,0) 20%, rgba(46, 52, 68,1) 100%) 0% 0% no-repeat padding-box;'
     
        if(this.load){
            var doc =   G.painel.doc;   
            
            return (
                <>
                    <Helmet> 
                        <title>Inovatyon - Withdraw</title>
                        <meta name="title" content="Inovatyon" />
                        <meta name="description" content="" />
    
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content="https://inovatyon.com" />
                        <meta property="og:title" content="Inovatyon Tach labs" />
                        <meta property="og:description" content="" />
                        <meta property="og:image" content="https://inovatyon.com/assets/img/logobk.png" />
    
                        <meta property="twitter:card" content="summary_large_image" />
                        <meta property="twitter:url" content="https://inovatyon.com" />
                        <meta property="twitter:title" content="Inovatyon Tach labs" />
                        <meta property="twitter:description" content="" />
                        <meta property="twitter:image" content="https://inovatyon.com/assets/img/logobk.png" />
                    </Helmet>  
                    <Flex direction={{base:'column',lg:'row'}}  w={{base:'100%'}} spacing={0} pt={{base:'10px',sm:'20px'}} px={{base:'10px',sm:'20px'}}>  
                        <Stack direction='column'  w={{base:'100%',lg:'30%'}} spacing={{base:'0px',lg:'20px'}} p={{base:'0px 0px',lg:'0px 20px 0px 0px'}} pb={{base:'20px',lg:'20px'}}> 
                            <Grid templateColumns={{ sm: "1fr",  md: "1fr 1fr" }}  gap={{base:'10px',sm:'20px'}}>
                                 <Card    >
                                    <Flex direction='column'>
                                        <Flex
                                        justify='space-between'
                                        p='22px'
                                        mb='18px'
                                        bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
                                        borderRadius='18px'>
                                            <Flex direction='column'>
                                                <Text color='tema1.100'fontWeight='bold' fontSize='12px'>
                                                    Depósitos Total
                                                </Text>
                                                <Text color='green.500' fontWeight='bold' fontSize='34px'>
                                                    {G.parseValor(G.geral.saldos.depositos,5).p},<Text as='span'   fontSize='16px'>{G.parseValor(G.geral.saldos.depositos,5).d}</Text>
                                                </Text>
                                                <Stack direction={'row'} w={'100%'} align={'center'} justify={'center'}>
                                                    <Spacer/>
                                                    <Text   color='#fff' fontWeight='bold' fontSize='10px'   >
                                                        Pendente
                                                    </Text>
                                                    <Text  color={G.geral.saldos.saqueP>0?'orange.300':'tema1.100'} fontWeight='bold'  textAlign={'right'} fontSize='14px'> 
                                                        {G.parseValor(G.geral.saldos.saqueP,5).p},<Text as='span'   fontSize='10px'>{G.parseValor(G.geral.saldos.saqueP,5).d}</Text>
                                                    </Text>
                                                </Stack>
                                                
                                            </Flex>
                                            <Flex direction='column'> 
                                                <Button
                                                    bg='transparent'
                                                    _hover={{}}
                                                    _active={{}}
                                                    alignSelf='flex-end'
                                                    cursor='default'
                                                    p='0px'>
                                                    <Icon
                                                        as={SiTether}
                                                        color='green.500'
                                                        w='24px'
                                                        h='24px'
                                                        justifySelf='flex-start'
                                                        alignSelf='flex-start'
                                                    />
                                                </Button> 
                                            </Flex>
                                        </Flex> 
                                        
                                        <Flex justify='center' align='center'>
                                            {typeof this.pacote.id == 'undefined'?
                                                <Button
                                                    colorScheme='blue'
                                                    fontSize='14px'
                                                    type='submit'
                                                    bg={'green.500'}
                                                    leftIcon={<FaRocket size={'18px'}   />}
                                                    w='fit-content'
                                                    size='lg'
                                                    mt='0px'
                                                    h='34px'
                                                    p='0 10px'
                                                    textIndent={'5px'}
                                                    _hover={{
                                                        bg: 'tema1.300',
                                                        boxShadow: 'xl',
                                                        color:'green.500'
                                                    }} 
                                                    onClick={()=>{
                                                        G.openPlano();
                                                        // this.atencao = <BoxSaques  onClose={()=>{
                                                        //     this.atencao = null;
                                                        //     this.setState({});
                                                        // }}/> 
                                                        // this.setState({});
                                                    }}
                                                    mb='0px'>
                                                    Assinar Pacote
                                                </Button>:
                                                <Button
                                                    colorScheme='blue'
                                                    fontSize='14px'
                                                    type='submit'
                                                    bg={'green.500'}
                                                    leftIcon={<FaQrcode size={'18px'}   />}
                                                    w='fit-content'
                                                    size='lg'
                                                    mt='0px'
                                                    h='34px'
                                                    p='0 10px'
                                                    textIndent={'5px'}
                                                    _hover={{
                                                        bg: 'tema1.300',
                                                        boxShadow: 'xl',
                                                        color:'green.500'
                                                    }} 
                                                    onClick={()=>{
                                                        G.openPlano(); 
                                                    }}
                                                    mb='0px'>

                                                        
                                                    Pagar Pacote
                                                </Button>  
                                            } 
                                        </Flex>
                                    </Flex>
                                </Card>  
                            </Grid>
                            {/* <Card h='100%' alignSelf='flex-start'>
                                <CardHeader mb='0px'>
                                    <Flex justify='space-between' w='100%'> 
                                        <Flex direction='column'>
                                            <Text fontSize='lg' color='#fff' fontWeight='bold'>
                                                Pacotes
                                            </Text> 
                                        </Flex> 
                                        <Button
                                            bg='transparent'
                                            _hover={{}}
                                            _active={{}}
                                            alignSelf='flex-end'
                                            cursor='default'
                                            p='0px'>
                                            <Icon
                                                as={SiTether}
                                                color='green.500'
                                                w='24px'
                                                h='24px'
                                                justifySelf='flex-start'
                                                alignSelf='flex-start'
                                            />
                                        </Button> 
                                    </Flex>
                                </CardHeader>
                                <CardBody>
                                    <Flex  w ='100%' direction='column'> 
                                        <HSeparator mb='20px' />
                                        <Flex justify='space-between' w='100%'>
                                            <Flex
                                            justify='space-between'
                                            p='22px'
                                            mb='18px'
                                            bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
                                            borderRadius='18px'>
                                              
                                                <Flex direction='column' align={'center'} justify='center' >
                                                    <Text color='tema1.100'fontWeight='bold' fontSize='12px'>
                                                        Documento de identificação
                                                    </Text> 
                                                    <Text color='orange.400' fontWeight='bold' pt={'20px'} fontSize='12px'> 
                                                        <Icon
                                                            as={HiIdentification}
                                                            color='green.500'
                                                            w='40px'
                                                            h='40px'
                                                            justifySelf='flex-start'
                                                            alignSelf='flex-start'
                                                        /> 
                                                    </Text>
                                                    {
                                                    (doc.frente == 'OK')?   
                                                    <>
                                                        <Icon as={FaCheck} fontSize={'24px'} color='green.400' my='5px'/>
                                                        <Text color='green.400' fontWeight='bold' pb={'10px'} fontSize='12px'> 
                                                            CONFIRMADO
                                                        </Text>
                                                    </>:
                                                    (doc.frente == null)?
                                                        <>
                                                            <Text color='green.500' fontWeight='bold' pb={'20px'} fontSize='12px'> 
                                                                 Envie o Arquivo
                                                            </Text>
                                                            <Button   onClick={()=>{
                                                                    // this.atencao = <BoxDocumentoKYC cmd='documento' onClose={()=>{
                                                                    //     this.atencao = null;
                                                                    //     this.setState({});
                                                                    // }}  />
                                                                    this.setState({});
                                                                }} p={'0px 10px'} h={'30px'} leftIcon={<FaFileUpload fontSize={'18px'}/>} fontSize='12px' bg={'green.500'} color={'tema1.200'} variant='solid'>
                                                                Selecionar Arquivo
                                                            </Button>
                                                        </>:
                                                    ((doc.frente == 'PENDENTE')?
                                                        <>
                                                            <Icon as={FaClock} fontSize={'24px'} color='orange.400' my='5px'/>
                                                            <Text color='orange.400' fontWeight='bold' pb={'10px'} fontSize='12px'> 
                                                                EM ANÁLISE... 
                                                            </Text>
                                                        </>
                                                    :
                                                        <>
                                                            <Icon as={RiAlarmWarningFill} fontSize={'24px'} color='red.400' my='5px'/>
                                                            <Text color='red.400' fontWeight='bold' pb={'20px'} fontSize='12px'> 
                                                                {doc.frente} 
                                                            </Text> 
                                                            <Button   onClick={()=>{
                                                                // this.atencao = <BoxDocumentoKYC cmd='documento' onClose={()=>{
                                                                //     this.atencao = null;
                                                                //     this.setState({});
                                                                // }}  />
                                                                this.setState({});
                                                            }} p={'0px 10px'} h={'30px'} leftIcon={<FaFileUpload fontSize={'18px'}/>} fontSize='12px' bg={'green.500'} color={'tema1.200'} variant='solid'>
                                                                Enviar Arquivo
                                                            </Button>
                                                        </>
                                                    )
                                                        
                                                    }
                                                    
                                                </Flex> 
                                            </Flex>
                                            <Flex
                                            justify='space-between'
                                            p='22px'
                                            mb='18px'
                                            bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
                                            borderRadius='18px'>
                                                <Flex direction='column' align={'center'} justify='center' >
                                                    <Text color='tema1.100'fontWeight='bold' fontSize='12px'>
                                                        Selfie com Documento
                                                    </Text> 
                                                    <Text color='orange.400' fontWeight='bold' pt={'20px'} fontSize='12px'> 
                                                        <Icon
                                                            as={FcSelfie}
                                                            color='green.500'
                                                            w='40px'
                                                            h='40px'
                                                            justifySelf='flex-start'
                                                            alignSelf='flex-start'
                                                        /> 
                                                    </Text>
                                                    {
                                                    (doc.selfie == 'OK')?   
                                                    <>
                                                        <Icon as={FaCheck} fontSize={'24px'} color='green.400' my='5px'/>
                                                        <Text color='green.400' fontWeight='bold' pb={'10px'} fontSize='12px'> 
                                                            CONFIRMADO
                                                        </Text>
                                                    </>:
                                                    (doc.selfie == null)?
                                                        <>
                                                            <Text color='green.500' fontWeight='bold' pb={'20px'} fontSize='12px'> 
                                                                 Envie o Arquivo
                                                            </Text>
                                                            <Button   onClick={()=>{
                                                                    // this.atencao = <BoxDocumentoKYC cmd='selfie' onClose={()=>{
                                                                    //     this.atencao = null;
                                                                    //     this.setState({});
                                                                    // }}  />
                                                                    this.setState({});
                                                                }} p={'0px 10px'} h={'30px'} leftIcon={<FaFileUpload fontSize={'18px'}/>} fontSize='12px' bg={'green.500'} color={'tema1.200'} variant='solid'>
                                                                Selecionar Arquivo
                                                            </Button>
                                                        </>:
                                                    ((doc.selfie == 'PENDENTE')?
                                                        <>
                                                            <Icon as={FaClock} fontSize={'24px'} color='orange.400' my='5px'/>
                                                            <Text color='orange.400' fontWeight='bold' pb={'10px'} fontSize='12px'> 
                                                                EM ANÁLISE... 
                                                            </Text>
                                                        </>
                                                    :
                                                        <>
                                                            <Icon as={RiAlarmWarningFill} fontSize={'24px'} color='red.400' my='5px'/>
                                                            <Text color='red.400' fontWeight='bold' pb={'20px'} fontSize='12px'> 
                                                                {doc.selfie} 
                                                            </Text> 
                                                            <Button   onClick={()=>{
                                                                // this.atencao = <BoxDocumentoKYC cmd='selfie' onClose={()=>{
                                                                //     this.atencao = null;
                                                                //     this.setState({});
                                                                // }}  />
                                                                this.setState({});
                                                            }} p={'0px 10px'} h={'30px'} leftIcon={<FaFileUpload fontSize={'18px'}/>} fontSize='12px' bg={'green.500'} color={'tema1.200'} variant='solid'>
                                                                Enviar Arquivo
                                                            </Button>
                                                        </>
                                                    )
                                                        
                                                    }
                                                    
                                                </Flex> 
                                            </Flex>
                                            <Flex
                                            justify='space-between'
                                            p='22px'
                                            mb='18px'
                                            bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
                                            borderRadius='18px'>
                                                <Flex direction='column' align={'center'} justify='center' >
                                                    <Text color='tema1.100'fontWeight='bold' fontSize='12px'>
                                                        Comprovante Residência
                                                    </Text> 
                                                    <Text color='orange.400' fontWeight='bold' pt={'20px'} fontSize='12px'> 
                                                        <Icon
                                                            as={FaHome}
                                                            color='green.500'
                                                            w='40px'
                                                            h='40px'
                                                            justifySelf='flex-start'
                                                            alignSelf='flex-start'
                                                        /> 
                                                    </Text>
                                                    {
                                                        (doc.endereco == 'OK')?   
                                                        <>
                                                            <Icon as={FaCheck} fontSize={'24px'} color='green.400' my='5px'/>
                                                            <Text color='green.400' fontWeight='bold' pb={'10px'} fontSize='12px'> 
                                                                CONFIRMADO
                                                            </Text>
                                                        </>:
                                                        (doc.endereco == null)?
                                                            <>
                                                                <Text color='green.500' fontWeight='bold' pb={'20px'} fontSize='12px'> 
                                                                    Envie o Arquivo
                                                                </Text>
                                                                <Button   onClick={()=>{
                                                                        // this.atencao = <BoxDocumentoKYC cmd='comprovante' onClose={()=>{
                                                                        //     this.atencao = null;
                                                                        //     this.setState({});
                                                                        // }}  />
                                                                        this.setState({});
                                                                    }} p={'0px 10px'} h={'30px'} leftIcon={<FaFileUpload fontSize={'18px'}/>} fontSize='12px' bg={'green.500'} color={'tema1.200'} variant='solid'>
                                                                    Selecionar Arquivo
                                                                </Button>
                                                            </>:
                                                        ((doc.endereco == 'PENDENTE')?
                                                            <>
                                                                <Icon as={FaClock} fontSize={'24px'} color='orange.400' my='5px'/>
                                                                <Text color='orange.400' fontWeight='bold' pb={'10px'} fontSize='12px'> 
                                                                    EM ANÁLISE... 
                                                                </Text>
                                                            </>
                                                        :
                                                            <>
                                                                <Icon as={RiAlarmWarningFill} fontSize={'24px'} color='red.400' my='5px'/>
                                                                <Text color='red.400' fontWeight='bold' pb={'20px'} fontSize='12px'> 
                                                                    {doc.endereco} 
                                                                </Text> 
                                                                <Button   onClick={()=>{
                                                                    // this.atencao = <BoxDocumentoKYC cmd='comprovante' onClose={()=>{
                                                                    //     this.atencao = null;
                                                                    //     this.setState({});
                                                                    // }}  />
                                                                    this.setState({});
                                                                }} p={'0px 10px'} h={'30px'} leftIcon={<FaFileUpload fontSize={'18px'}/>} fontSize='12px' bg={'green.500'} color={'tema1.200'} variant='solid'>
                                                                    Enviar Arquivo
                                                                </Button>
                                                            </>
                                                        )   
                                                    }
                                                </Flex> 
                                            </Flex> 
                                        </Flex>
                                    </Flex>
                                </CardBody>
                            </Card>  */}
                        </Stack>
                        <Stack direction='column'    w={{base:'100%',lg:'70%'}} spacing={{base:'0'}} pb='20px'   > 
                            <Card  h={'100%'}   >
                                <CardHeader  minHeight={'max-content'} >
                                    <Flex direction='column' w='100%' minH={'max-content'}>
                                    <Flex
                                        direction={{ sm: "column", lg: "row" }}
                                        justify={{ sm: "center", lg: "space-between" }}
                                        align={{ sm: "center" }}
                                        w='100%'
                                        
                                        >
                                        <Text
                                        color='#fff'
                                        fontSize={{ sm: "lg", md: "xl", lg: "lg" }}
                                        fontWeight='bold'>
                                        Últimos Depósitos
                                        </Text> 
                                    </Flex>
                                    </Flex>
                                </CardHeader>
                                <CardBody mt='15px'>
                                    <Flex direction='column' w='100%'> 
                                        {
                                            (this.db.lancamentos.length>0)?
                                            this.db.lancamentos.map((row,pos) => {
                                                return ( 
                                                    <Flex direction={'column'} key={'ord'+pos} w='100%'>
                                                        <TransactionRowDeposito 
                                                            name={row.name}
                                                            logo={row.logo}
                                                            date={row.date}
                                                            situa={row.situa}
                                                            price={row.price}
                                                            pago={row.pago}
                                                        />
                                                    </Flex>
                                                    
                                                );
                                            }):<Text color='green.500'>Nenhum saque encontrado</Text>
                                        }  
                                    </Flex>
                                </CardBody>
                            </Card>
                        </Stack>
                    </Flex> 
                    
                    {this.atencao}
                </>
            )
        }else{
            return <BoxWindow load ={true}/>;
        }
       
    }
}
export default PageDepositos;