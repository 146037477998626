import * as React from "react"  
import {
    Box,
    Button,
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    Icon,
    Image,
    List,
    ListIcon,
    ListItem,
    Spacer,
    Stack,
    Switch,
    Text,
    Tooltip, 
  } from '@chakra-ui/react';
import { FaCheckCircle, FaCopy, FaHistory, FaPeace, FaPen, FaPenAlt, FaPlusSquare, FaPowerOff, FaRegStar, FaTimes, FaTrashAlt, FaTrophy } from 'react-icons/fa';
import { RiFolderHistoryFill } from 'react-icons/ri'; 

import G  from '../../components/WebSocket'
import BoxWindow from "../../components/BoxWindow/BoxWindow"; 
class ListaFinanceira extends React.Component { 
    viewPedidos = null
    robos       = {};
    tempLanc    = null;
    grupo       =   'C';
    ordem       =   'ASC';
    sorte       =   'T';
    lancamentos =   [];
    tipos       =   {};
    dados  =   [];
    db= {
        saldos:{}
    }
    load        = false;
    buscarJogos(){
        var relaod = false; 
        
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.saldos!=='undefined' && this.tempSaldos != JSON.stringify(G.geral.saldos)){
            this.tempSaldos = JSON.stringify(G.geral.saldos); 
            this.db.saldos = G.geral.saldos; 
            this.load = true; 
            relaod = true;
        } 

        if(G.geral!= null && (typeof G.geral.lancamentos != 'undefined' && this.tempLanc != JSON.stringify(G.geral.lancamentos))){ 
            this.tempLanc       =   JSON.stringify(G.geral.lancamentos); 
          
            this.lancamentos    = [];
            Object.values(G.geral.lancamentos).forEach((lanc)=>{
                this.lancamentos.push(lanc);
            }); 

            this.tipos = {};
            G.geral.lancamentosTipo.forEach((lt)=>{
                this.tipos[lt.id]=lt;
            }) 
            this.load =true;
            relaod = true;
        } 
        if(relaod){
            this.setState({});
        }   
    }
    cDelay(){
        clearTimeout(this.tempD);
        this.tempD = setTimeout(()=>{
            this.setState({});
        },500);
    }
    pag = {
        min:0,
        max:10,
        n:10,
        aba:0,
        p:0
    }
    statusB={};
    // processarEstatiscas(){
    //     var status = {};  
    //     this.historico.forEach((his)=>{  
    //         if(typeof this.robos[his.botId] !== 'undefined'){
    //             if(typeof status[his.botId] == 'undefined'){
    //                 status[his.botId] = {
    //                     bedRun      :0 , 
    //                     goodRun     :0, 
    //                     green       :0, 
    //                     oddMedia    :0,
    //                     historico   :0,
    //                     lucroT      :0,
    //                     red         :0,
    //                     total       :0,
    //                     unid        :0,
    //                     winRate     :0,
    //                     seqG        :0,
    //                     seqR        :0
    //                 }
    //             }
    //             var valor = 0;
    //             var lucro = 0;
    //             if(his.side == 'BACK'){ 
    //                 if(status[his.botId].oddMedia>0){
    //                     status[his.botId].oddMedia = (status[his.botId].oddMedia+parseFloat(his.oddLiv.p.a[0].price))/2
    //                 }else{
    //                     status[his.botId].oddMedia = (parseFloat(his.oddLiv.p.a[0].price))/2
    //                 }
                    
    //                 valor = parseFloat(((his.valor*his.oddLiv.p.a[0].price-his.valor)*0.935).toFixed(2));
    //             }else{
    //                 // = his.oddLiv.p.c[0].price
    //                 valor = parseFloat((his.valor*0.935).toFixed(2));
    //                 if(status[his.botId].oddMedia>0){
    //                     status[his.botId].oddMedia = (status[his.botId].oddMedia+parseFloat(his.oddLiv.p.c[0].price))/2
    //                 }else{
    //                     status[his.botId].oddMedia = (parseFloat(his.oddLiv.p.c[0].price))/2
    //                 }
    //             }
               
                 
    //             if((his.resulado=='WINNER' && his.side=='BACK')||(his.resulado=='LOSER' && his.side=='LAY')){
    //                 status[his.botId].green++;
    //                 status[his.botId].seqG++;
    //                 if(status[his.botId].seqR>status[his.botId].bedRun){
    //                     status[his.botId].bedRun =status[his.botId].seqR
    //                 }
    //                 status[his.botId].lucroT+= valor
    //                 status[his.botId].seqR=0;
    //             }else if((his.resulado=='LOSER' && his.side=='BACK')|| (his.resulado=='WINNER' && his.side=='LAY')){
    //                 if( his.side =='LAY'){
    //                     status[his.botId].lucroT-= parseFloat(((his.valor*his.oddLiv.p.c[0].price-his.valor)*0.935).toFixed(2));;
    //                 }else{
    //                     status[his.botId].lucroT-= his.valor;
    //                 }
                    
    //                 if(status[his.botId].seqG>status[his.botId].goodRun){
    //                     status[his.botId].goodRun =status[his.botId].seqG;
    //                 }
    //                 status[his.botId].seqG=0;
    //                 status[his.botId].seqR++;
    //                 status[his.botId].red++;
    //             }  
    //         }
           
            
    //     })
    //     Object.keys(status).forEach((idBot)=>{
    //         status[idBot].winRate   =   parseFloat((status[idBot].green*100/(status[idBot].green+status[idBot].red)).toFixed(2));
    //     })  
        
    //     this.statusB = status;

    // }
    processarExtrato(){
        var bgT = 'linear-gradient(180deg,rgba(25, 31, 50,0.6) 17.33%,rgba(25, 31, 50,0))'; 
        bgT = 'transparent'; 
        var view =  <Text align={'center'}  py='10px'  px={{base:'0px',md:'20px'}}  justify='center'  color={'tema1.400'}  opacity={'0.5'} fontSize={'xs'}   textAlign={{base:'center'}}>  Nenhum registro no momento  </Text>
 
        if(this.lancamentos.length>0){
            view = []
            var dados     = this.lancamentos; 
            this.pag.p    = parseInt((dados.length/this.pag.n).toString().split('.')[0]);
            if((dados.length%this.pag.n)){
              this.pag.p++;
            }
            var viewPag   =  [];
            var add = true;
            for(var n=0;n<this.pag.p;n++){ 
              if(this.pag.p>5){
                add = false;
                if(n==0 || n == (this.pag.p-1)){
                  add=true;
                }else if(this.pag.aba==0 || this.pag.aba==1 || this.pag.aba==2){
                  if(n == 1 || n == 2|| n == 3){
                    add=true; 
                  }
                }else if( this.pag.aba == (this.pag.p-3) || this.pag.aba == (this.pag.p-2)|| this.pag.aba == (this.pag.p-1)){ 
                    if(n == (this.pag.p-4) || n == (this.pag.p-3)|| n == (this.pag.p-2)){
                      add=true; 
                    }   
                }else if(n==this.pag.aba-1|| n==this.pag.aba || n==this.pag.aba+1){
                  add=true;   
                } 
              } 
              if(add){
                const pos = n; 
                viewPag.push(<Button onClick={()=>{ 
                  this.pag.aba = pos;
                  this.setState({});
                }} key={'pag'+pos}  colorScheme={this.pag.aba==pos?'green':'gray'} fontSize={'small'} p={0} h='24px' minW='24px'>{pos+1}</Button>)
              } 
            } 
            this.pag.min=(this.pag.n*this.pag.aba);
            this.pag.max=(this.pag.n*this.pag.aba)+this.pag.n; 
            var bg  = 'transparent linear-gradient(-90deg, rgba(46, 200, 68,0) 20%, rgba(46, 200, 68,0.3) 100%) 0% 0% no-repeat padding-box;' 
            var bgR = 'transparent linear-gradient(-90deg, rgba(250, 0, 0,0) 20%, rgba(250, 0, 0,0.4) 100%) 0% 0% no-repeat padding-box;'
          
            this.lancamentos.sort((a, b)=>{  
                return a.criacao > b.criacao ? -1 : a.criacao < b.criacao ? 1 : 0; 
             }).forEach((robo,i)=>{  
                if(i>=this.pag.min && i<this.pag.max){ 
                    view.push(
                       <Flex key={'bot'+robo.id} w={'100%'}>  
                            <Grid  display={{base:'none',sm:'grid'}}  bg={{base:bgT   ,md:'transparent'}}  w='full' py='0px' px={'10px'} pb={'0px'} fontSize={'xx-small'} color={'green.500'} align='left'   templateColumns= {{base :'repeat(32, 1fr)'}} gap={0}>
                                <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}    w={'full'} p='5px 10px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{robo.id}</Text> 
                                </GridItem>  
                                <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={6} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{this.tipos[robo.codtip].nome}</Text> 
                                </GridItem>  
                                <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={12} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{this.tipos[robo.codtip].nome}</Text> 
                                </GridItem> 
                                <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{G.parseValor(robo.valor,5).f} USDT</Text> 
                                </GridItem> 
                                <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{new Date(robo.criacao).toISOString().split('T')[0]}</Text> 
                                </GridItem> 
                                <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'}    w={'full'} p='5px 10px' colSpan={2} h='30px'    bg='transparent'>  
                                </GridItem>   
                            </Grid> 
                       </Flex>
                    ); 
                }
            })
            
        } 
      
       
        return (
            <Flex flexDir={'column'}   w={'full'}  minH={'100%'} bg={{base:'transparent',md:bgT}} >
                 {this.pag.p>1?
                  <Stack flexDir={'row'} pos={'absolute'} top='0px' right={'30px'}>
                    {viewPag} 
                  </Stack>
                :null
                } 
                <Grid display={{ base: "grid" }} mt={'20px'} w='full' py='10px'  px={'10px'} fontSize={'x-small'}  color={'tema1.400'} align='left' textAlign='left' templateColumns= {{base :'repeat(32, 1fr)'}} gap={0}>
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}    w={'full'} p='5px 10px' colSpan={4} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>HASH</Text> 
                    </GridItem>  
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={6} h='30px'    bg='transparent'> 
                        <Text as={'b'}   w={'full'}   noOfLines={1}>Tipo</Text> 
                    </GridItem>  
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={12} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>Descrição</Text> 
                    </GridItem> 
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>Valor</Text> 
                    </GridItem> 
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>Registro</Text> 
                    </GridItem> 
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'}    w={'full'} p='5px 10px' colSpan={2} h='30px'    bg='transparent'>  
                    </GridItem> 
                </Grid>
                {view}
            </Flex>  
        );
    }
    componentDidMount(){
        this.buscarJogos();
        this.setState({});
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    render(){  
        var viewLista   =   null;  
        viewLista = this.processarExtrato(); 
    
        return ( 
            <Flex pos={'relative'}  flexDir={'column'}   maxW={'100%'} w={'100%'} minH={{base:'80px',md:'140px'}}  mb='30px'   >
                {viewLista}   
                {this.atencao }
            </Flex>   
        )
    }
}
export default ListaFinanceira;