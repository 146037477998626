import * as React from "react"  
import { Avatar, Box, Button,  Flex, Grid, Icon, LightMode, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Progress, SimpleGrid, Spacer, Stack, Stat, StatHelpText, StatLabel, StatNumber, Text } from "@chakra-ui/react"; 
import {Helmet} from "react-helmet"; 
import G from '../../components/WebSocket';
import { FaChartBar, FaChartLine, FaChartPie, FaCheck, FaCoins, FaDollarSign, FaExchangeAlt, FaFilter, FaLock, FaPencilAlt, FaPlus, FaRegCalendarAlt, FaRobot, FaRocket, FaStackExchange } from "react-icons/fa";
import IconBox from "../../components/VisionUI/Icons/IconBox";
import Card from "../../components/VisionUI/Card/Card";
import CardBody from "../../components/VisionUI/Card/CardBody";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
import GradientBorder from "../../components/VisionUI/GradientBorder";
import { RiMastercardFill, RiNumber1 } from "react-icons/ri";
import TransactionRow from "../../components/VisionUI/Tables/TransactionRow";
import { TbNumber1, TbNumber2 , TbNumber3 , TbNumber4 , TbNumber5 , TbNumber6, TbNumber7 } from "react-icons/tb";

import ListaRede from "./ListaRede";
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import { GiPayMoney } from "react-icons/gi";
import { Link } from "react-router-dom";
class PageRede extends React.Component { 
    jogos = 0;
    robos = 0;
    load  = false;
    db = {
        saldos:{},
        graduacao : {}
    }
    buscarJogos(){
        var reload = false;
          
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.saldos!=='undefined' && this.tempSaldos != JSON.stringify(G.geral.saldos)){
            this.tempSaldos = JSON.stringify(G.geral.saldos); 
            this.db.saldos = G.geral.saldos; 
          
            reload = true;
        } 
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.graduacao!=='undefined' && this.tempGrad != JSON.stringify(G.geral.graduacao)){
            this.tempGrad = JSON.stringify(G.geral.graduacao);  
            this.db.graduacao = G.geral.graduacao;  
            reload = true;
            this.load = true; 
        } 
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.rede!=='undefined' && this.tempStat != JSON.stringify(G.geral.rede)){
            this.tempStat = JSON.stringify(G.geral.rede);  
            reload = true;
            
        }  
        if(reload){
            this.setState({}); 
        }
    } 
    componentDidMount(){
        this.buscarJogos();
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    } 
    render(){  
        var usu = G.painel; 
        var bg = 'transparent linear-gradient(0deg, rgba(46, 52, 68,0) 20%, rgba(46, 52, 68,1) 100%) 0% 0% no-repeat padding-box;'
        
        var nivel = [
            {nome:'Nível 1',id:0,icon: <TbNumber1  />,ativo:true,depU:0,minU:0,pacU:0},
            {nome:'Nível 2',id:1,icon: this.db.graduacao.nivel>=2?<TbNumber2  />:<FaLock/>,ativo: this.db.graduacao.nivel>=2?true:false,depU:500,minU:2,pacU:200},
            {nome:'Nível 3',id:1,icon: this.db.graduacao.nivel>=3?<TbNumber3  />:<FaLock/>,ativo: this.db.graduacao.nivel>=3?true:false,depU:1000,minU:2,pacU:500},
            {nome:'Nível 4',id:3,icon: this.db.graduacao.nivel>=4?<TbNumber4  />:<FaLock/>,ativo: this.db.graduacao.nivel>=4?true:false,depU:2000,minU:4,pacU:1000},
            {nome:'Nível 5',id:4,icon: this.db.graduacao.nivel>=5?<TbNumber5  />:<FaLock/>,ativo: this.db.graduacao.nivel>=5?true:false,depU:5000,minU:6,pacU:1000},
            {nome:'Nível 6',id:5,icon: this.db.graduacao.nivel>=6?<TbNumber6  />:<FaLock/>,ativo: this.db.graduacao.nivel>=6?true:false,depU:10000,minU:10,pacU:2000},
            {nome:'Nível 7',id:6,icon: this.db.graduacao.nivel>=7?<TbNumber7  />:<FaLock/>,ativo: this.db.graduacao.nivel>=7?true:false,depU:10000,minU:10,pacU:2000},
        ]

        if(this.load){
            return (
                <>
                    <Helmet> 
                        <title>Inovatyon - Dashboard</title>
                        <meta name="title" content="Inovatyon" />
                        <meta name="description" content="" />
    
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content="https://inovatyon.com" />
                        <meta property="og:title" content="Inovatyon Tach labs" />
                        <meta property="og:description" content="" />
                        <meta property="og:image" content="https://inovatyon.com/assets/img/logobk.png" />
    
                        <meta property="twitter:card" content="summary_large_image" />
                        <meta property="twitter:url" content="https://inovatyon.com" />
                        <meta property="twitter:title" content="Inovatyon Tach labs" />
                        <meta property="twitter:description" content="" />
                        <meta property="twitter:image" content="https://inovatyon.com/assets/img/logobk.png" />
                    </Helmet>  
                    <SimpleGrid columns={{ base: "2" , sm: "4"}} spacing={{base:'10px',sm:'20px'}}   p={{base:'10px 10px',sm:'10px 20px'}} pt={{base:'10px',sm:'30px'}}>
                        <Card minH={{base:'60px',md:'83px'}} p={{base:'12px',md:'15px',md:'20px'}}>
                            <CardBody >
                                <Flex flexDirection='row' align='center' justify='center' textAlign={'left'}  w='100%'>
                                    <Stat me='auto' >
                                        <StatLabel fontSize={{base:'xs',md:'xs'}} color='gray.200' pb='2px'>
                                            Saldo Disponível
                                        </StatLabel>
                                        <Flex>
                                            <Stack gap={0}>
                                                <StatNumber fontSize={{base:'sm',lg:'lg'}} color='#fff'>
                                                    {G.parseValor(this.db.saldos.saldo,4).p},<Text as='span' fontSize={'10px'}>{G.parseValor(this.db.saldos.saldo,4).d}</Text>
                                                </StatNumber> 
                                                <StatHelpText
                                                    textAlign={'left'}
                                                    alignSelf='center'
                                                    justifySelf='flex-start'
                                                    m='0px'
                                                    w='100%'
                                                    color='green.500'
                                                    fontWeight='bold' 
                                                    fontSize={{base:'xs',lg:'xs'}} >
                                                    USDT
                                                </StatHelpText> 
                                            </Stack>
                                            
                                        </Flex> 
                                    </Stat>
                                    <IconBox as='box' h={{base:'30px',lg:'45px'}} w={{base:'30px',lg:'45px'}} bg='green.500' >
                                        <Icon as={FaCheck} color={'tema1.300'} fontSize={{base:'16px',lg:'22px'}}></Icon>
                                    </IconBox>
                                </Flex>
                            </CardBody> 
                        </Card>
                        <Card minH={{base:'60px',md:'83px'}} p={{base:'12px',md:'15px',md:'20px'}}>
                            <CardBody >
                                <Flex flexDirection='row' align='center' justify='center' textAlign={'left'}  w='100%'>
                                    <Stat me='auto' >
                                        <StatLabel fontSize={{base:'xs',md:'xs'}} color='gray.200' pb='2px'>
                                            Saque Total
                                        </StatLabel>
                                        <Flex>
                                            <Stack gap={0}>
                                                <StatNumber fontSize={{base:'sm',lg:'lg'}} color='#fff'>
                                                    {G.parseValor(this.db.saldos.saques,4).p},<Text as='span' fontSize={'10px'}>{G.parseValor(this.db.saldos.saques,4).d}</Text>
                                                </StatNumber> 
                                                <StatHelpText
                                                    textAlign={'left'}
                                                    alignSelf='center'
                                                    justifySelf='flex-start'
                                                    m='0px'
                                                    w='100%'
                                                    color='green.500'
                                                    fontWeight='bold' 
                                                    fontSize={{base:'xs',lg:'xs'}} >
                                                    USDT
                                                </StatHelpText> 
                                            </Stack>
                                            
                                        </Flex> 
                                    </Stat>
                                    <IconBox as='box' h={{base:'30px',lg:'45px'}} w={{base:'30px',lg:'45px'}} bg='green.500' >
                                        <Icon as={FaCoins} color={'tema1.300'} fontSize={{base:'16px',lg:'22px'}}></Icon>
                                    </IconBox>
                                </Flex>
                            </CardBody> 
                        </Card>
                        <Card minH={{base:'60px',md:'83px'}} p={{base:'12px',md:'15px',md:'20px'}}>
                            <CardBody >
                                <Flex flexDirection='row' align='center' justify='center' textAlign={'left'}  w='100%'>
                                    <Stat me='auto' >
                                        <StatLabel fontSize={{base:'xs',md:'xs'}} color='gray.200' pb='2px'>
                                            Ganhos Totais
                                        </StatLabel>
                                        <Flex>
                                            <Stack gap={0}>
                                                <StatNumber fontSize={{base:'sm',lg:'lg'}} color='#fff'>
                                                    {G.parseValor(this.db.saldos.ganhos,4).p},<Text as='span' fontSize={'10px'}>{G.parseValor(this.db.saldos.ganhos,4).d}</Text>
                                                </StatNumber> 
                                                <StatHelpText
                                                    textAlign={'left'}
                                                    alignSelf='center'
                                                    justifySelf='flex-start'
                                                    m='0px'
                                                    w='100%'
                                                    color='green.500'
                                                    fontWeight='bold' 
                                                    fontSize={{base:'xs',lg:'xs'}} >
                                                    USDT
                                                </StatHelpText> 
                                            </Stack>
                                            
                                        </Flex> 
                                    </Stat>
                                    <IconBox as='box' h={{base:'30px',lg:'45px'}} w={{base:'30px',lg:'45px'}} bg='green.500' >
                                        <Icon as={FaChartLine} color={'tema1.300'} fontSize={{base:'16px',lg:'22px'}}></Icon>
                                    </IconBox>
                                </Flex>
                            </CardBody> 
                        </Card>
                        <Card minH={{base:'60px',md:'83px'}} p={{base:'12px',md:'15px',md:'20px'}}>
                            <CardBody >
                                <Flex flexDirection='row' align='center' justify='center' textAlign={'left'}  w='100%'>
                                    <Stat me='auto' >
                                        <StatLabel fontSize={{base:'xs',md:'xs'}} color='gray.200' pb='2px'>
                                            Pacotes Total
                                        </StatLabel>
                                        <Flex>
                                            <Stack gap={0}>
                                                <StatNumber fontSize={{base:'sm',lg:'lg'}} color='#fff'>
                                                    {G.parseValor(this.db.saldos.depositos,4).p},<Text as='span' fontSize={'10px'}>{G.parseValor(this.db.saldos.depositos,4).d}</Text>
                                                </StatNumber> 
                                                <StatHelpText
                                                    textAlign={'left'}
                                                    alignSelf='center'
                                                    justifySelf='flex-start'
                                                    m='0px'
                                                    w='100%'
                                                    color='green.500'
                                                    fontWeight='bold' 
                                                    fontSize={{base:'xs',lg:'xs'}} >
                                                    USDT
                                                </StatHelpText> 
                                            </Stack>
                                            
                                        </Flex> 
                                    </Stat>
                                    <IconBox as='box' h={{base:'30px',lg:'45px'}} w={{base:'30px',lg:'45px'}} bg='green.500' >
                                        <Icon as={FaRocket} color={'tema1.300'} fontSize={{base:'16px',lg:'22px'}}></Icon>
                                    </IconBox>
                                </Flex>
                            </CardBody> 
                        </Card> 
                    </SimpleGrid>
                    
                    <Flex  position={'relative'} direction={{base:'column'}} w={{base:'100%'}} spacing={0} pt={{base:'0px',sm:'0px'}} px={{base:'10px',sm:'20px'}} >  
                        <Card zIndex={12312312} px='30px'>
                            <CardBody >
                                <Grid
                                     
                                      justify='space-between'
                                      align='center' 
                                      w='100%' 
                                    templateColumns={{ base: "repeat(4, 1fr)", lg: "repeat(7, 1fr)" }}
                                     >
                                    {nivel.map((v,id)=>{
                                        return (
                                            <>
                                                {id>0?
                                                    <Popover  key={'nivel'+id} >
                                                        <PopoverTrigger>
                                                            <Flex key={'nivel'+id} opacity={(typeof G.geral.rede[id+1] !=='undefined' && G.geral.rede[id+1].length>0 && v.ativo)?'1':'0.3'} onClick={()=>{
                                                                if(typeof G.geral.rede[id+1] !=='undefined'  && G.geral.rede[id+1].length>0){
                                                                    G.nivel =id;
                                                                } 
                                                                this.setState({})
                ;                                            }} direction='column' mx='auto' align='center' cursor={'pointer'}>
                                                                
                                                                <Stack 
                                                                    border='1px solid'
                                                                    borderColor='green.500'
                                                                    borderRadius='50%'
                                                                    mb='6px'
                                                                    p='4px'>
                                                                    <Avatar color={(G.nivel == id)?"tema1.200":"green.500"}  icon={v.icon} size='md' bg={(G.nivel == id)?"green.500":"transparent"} />
                                                                </Stack>
                                                                <Text fontSize='xs'   color={(G.nivel == id)?"green.500":"gray.400"}>
                                                                    {v.nome}
                                                                </Text>
                                                            </Flex>
                                                        </PopoverTrigger>
                                                        <PopoverContent bg={'tema1.200'}  w={'250px'}>
                                                            <PopoverArrow  bg={'tema1.200'}/>
                                                            <PopoverCloseButton />
                                                            <PopoverHeader ><Text py={'10px'} color={'green.500'}>{v.ativo?'Desbloqueado':'Bloqueado'}</Text></PopoverHeader>
                                                            <PopoverBody  p={'20px'}>
                                                            <Flex direction='column'>
                                                                    <Flex justify='space-between' mb='8px'> 
                                                                        <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                                            Depósitos
                                                                        </Text>
                                                                        <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                                            {this.db.graduacao.pacotes}/{v.depU}
                                                                        </Text>
                                                                        
                                                                    </Flex>
                                                                    <LightMode>
                                                                        <Progress
                                                                        bg='#2D2E5F'
                                                                        colorScheme='green'
                                                                        size='xs'
                                                                        value={0}
                                                                        borderRadius='15px'></Progress>
                                                                    </LightMode>
                                                                </Flex>
                                                                <Flex direction='column' pb={'10px'}>
                                                                    <Flex justify='space-between' mb='8px'>
                                                                        <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                                            {v.minU} diretos com {v.pacU} USDT
                                                                        </Text>
                                                                        <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                                            {this.db.graduacao['num'+v.pacU]}/{v.minU}
                                                                        </Text>
                                                                    </Flex>
                                                                    <LightMode>
                                                                        <Progress
                                                                        bg='#2D2E5F'
                                                                        colorScheme='green'
                                                                        size='xs'
                                                                        value={0}
                                                                        borderRadius='15px'></Progress>
                                                                    </LightMode>
                                                                </Flex>
                                                                
                                                            </PopoverBody>
                                                        </PopoverContent>
                                                    </Popover>:
                                                    <Flex key={'nivel'+id} opacity={(typeof G.geral.rede[id+1] !=='undefined' && G.geral.rede[id+1].length>0 && v.ativo)?'1':'0.3'} onClick={()=>{
                                                        if(typeof G.geral.rede[id+1] !=='undefined'  && G.geral.rede[id+1].length>0){
                                                            G.nivel =id;
                                                        } 
                                                        this.setState({})
        ;                                            }} direction='column' mx='auto' align='center' cursor={'pointer'}>
                                                        
                                                        <Stack 
                                                            border='1px solid'
                                                            borderColor='green.500'
                                                            borderRadius='50%'
                                                            mb='6px'
                                                            p='4px'>
                                                            <Avatar color={(G.nivel == id)?"tema1.200":"green.500"}  icon={v.icon} size='md' bg={(G.nivel == id)?"green.500":"transparent"} />
                                                        </Stack>
                                                        <Text fontSize='xs'   color={(G.nivel == id)?"green.500":"gray.400"}>
                                                            {v.nome}
                                                        </Text>
                                                    </Flex>
                                                }
                                                 
                                            </>
                                            
                                        )
                                    })}
                                     
                                </Grid>
                            </CardBody>
                        </Card> 
                        <Stack direction='column'  w={{base:'100%',lg:'100%'}} spacing={{base:'0px',lg:'20px'}} p={{base:'0px 0px',sm:'10px 0px'}}  > 
                             <Card p='0 16px' mt={{base:'0px',lg:'0'}} >
                                <CardHeader>
                                    <Flex
                                        justify='space-between'
                                        align='center'
                                        minHeight='60px'
                                        w='100%'>
                                        <Text fontSize='lg' color='#fff' fontWeight='bold'>
                                        Indicados Nível {G.nivel+1}
                                        </Text>
                                        <Button leftIcon={<FaFilter/>} maxW='135px' fontSize='10px' bg={'green.500'} color={'tema1.200'} variant='solid'>
                                            Filtro
                                        </Button>
                                    </Flex>
                                </CardHeader>
                                <CardBody  >
                                    <ListaRede  openEdit={(dados)=>{
                                            // this.boxView =<BoxBot dados={dados} 
                                            //     cmdFechar={()=>{
                                            //     this.boxView = null;
                                            //     this.setState({})
                                            //     }
                                            // }/> 
                                    }} />
                                </CardBody>
                            </Card> 
                        </Stack>
                        
                    </Flex>
                      
                </>
            )
        }else{
            return <BoxWindow load ={true} />
        }
        
    }
}
export default PageRede;