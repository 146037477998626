import * as React from "react"  
import { Button, Center, Flex, Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text } from "@chakra-ui/react"; 

class BoxWindow extends React.Component { 
    
    render(){ 
        if(this.props.load){
            return (
                <> 
                    <Modal isCentered isOpen={true}  size={{base:'sm',sm:(typeof this.props.size=='undefined'?'md':this.props.size)}}  >
                        <ModalOverlay
                            bg='rgba(58, 255, 202,0.5)' 
                            backdropFilter='blur(10px) hue-rotate(90deg)'
                        />
                        <ModalContent bg={'tema1.200'}>
                            <ModalHeader color={'green.500'} w='auto'>Enviando dados ao servidor</ModalHeader> 
                                <ModalBody  color={'#ffffff'}>
                                    <Flex flexDirection='row' align='center' justify='center'>
                                        <Spinner color='tema1.100' /> <Text ml='15px' >Aguarde um momento...</Text>
                                    </Flex> 
                                </ModalBody> 
                            <ModalFooter/>
                        </ModalContent>
                    </Modal>
                </>
            )
        }else{
            
        }
        var isOpen =true;
        var onClose = ()=>{

        }
        var mensagem = [];
        if(typeof this.props.mensagem != 'undefined'){
            if(typeof this.props.mensagem =='string'){
                mensagem = <Text   dangerouslySetInnerHTML={{__html: this.props.mensagem}}/>
            }else if(Array.isArray(this.props.mensagem)){
                this.props.mensagem.forEach((m,i)=>{
                    mensagem.push(<Text key={'m'+i} dangerouslySetInnerHTML={{__html: m.mensagem}}></Text>)
                })
            }else{
                mensagem = this.props.mensagem;
            }
        }
        return (
            <> 
                <Modal isCentered isOpen={true} size={typeof this.props.size=='undefined'?'md':this.props.size} onClose={()=>{
                    this.props.onClose();
                }}>
                    <ModalOverlay
                        bg='rgba(58, 255, 202,0.5)' 
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                    />
                    <ModalContent bg={'tema1.200'}>
                        <ModalHeader color={'green.500'}>{this.props.titulo}</ModalHeader>
                        <ModalCloseButton  color={'tema1.100'}/>
                        <ModalBody color={'tema1.400'}>
                            {mensagem} 
                        </ModalBody>
                        <ModalFooter align='center' justify='center'>
                            <Center w={'full'}>
                                {(typeof this.props.botao =='undefined'? 
                                    <Button onClick={()=>{
                                        this.props.onClose();
                                    }}>OK</Button>
                                    :
                                    this.props.botao
                                )}
                                
                            </Center> 
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        )
    }
}
export default BoxWindow;