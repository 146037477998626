import * as React from "react"  
import {
    Box,
    Button,
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    Icon,
    Image,
    List,
    ListIcon,
    ListItem,
    Spacer,
    Stack,
    Switch,
    Text,
    Tooltip, 
  } from '@chakra-ui/react';
import { FaCheckCircle, FaCopy, FaGlobe, FaHistory, FaPeace, FaPen, FaPenAlt, FaPlusSquare, FaPowerOff, FaRegStar, FaTimes, FaTrashAlt, FaTrophy, FaWallet } from 'react-icons/fa';
import { RiFolderHistoryFill } from 'react-icons/ri'; 

import G  from '../../components/ADMSocket'
import BoxWindow from "../../components/BoxWindow/BoxWindow"; 
import { Link } from "react-router-dom";
class ListaLancamentos extends React.Component { 
    viewPedidos = null
    robos       = {};
    tempLanc    = null;
    grupo       =   'C';
    ordem       =   'ASC';
    sorte       =   'T';
    lancamentos =   [];
    tipos       =   {};
    pedidos     =   {};
    usus        =   {};
    dados  =   [];
    db= {
        saldos:{}
    }
    load        = false;
    buscarJogos(){
        var relaod = false;  
        
        if(G.geral!= null && (typeof G.geral.pedidos != 'undefined' && this.tempPed != JSON.stringify(G.geral.pedidos))){  
            this.tempPed       =   JSON.stringify(G.geral.pedidos);  

            this.pedidos = {} 
            G.geral.pedidos.forEach((lt)=>{
                this.pedidos[lt.id]=lt;
            })   
            relaod = true;
        } 
        if(G.geral!= null && (typeof G.geral.lancamentosTipo != 'undefined' && this.tempLtip != JSON.stringify(G.geral.lancamentosTipo))){  
            this.tempLtip      =   JSON.stringify(G.geral.lancamentosTipo);  

            this.tipos = {} 
            G.geral.lancamentosTipo.forEach((lt)=>{
                this.tipos[lt.id]=lt;
            })   
            relaod = true;
        } 
        if(G.geral!= null && (typeof G.geral.afiliados != 'undefined' && this.tempAfi != JSON.stringify(G.geral.afiliados))){  
            this.tempAfi      =   JSON.stringify(G.geral.afiliados);  

            this.usus = {} 
            G.geral.afiliados.forEach((lt)=>{
                this.usus[lt.id]=lt;
            })   
            relaod = true;
        } 
        if(G.geral!= null && (typeof G.geral.lancamentos != 'undefined' && this.tempLanc != JSON.stringify(G.geral.lancamentos))){ 
            
            this.tempLanc       =   JSON.stringify(G.geral.lancamentos);  
            this.lancamentos    = [];
            Object.values(G.geral.lancamentos).forEach((lanc)=>{
                if(lanc.codtip =='4' && lanc.voucher=='S'){
                    this.lancamentos.push(lanc);
                } 
            });  
            this.load =true;
            relaod = true;
        } 
        if(relaod){
            this.setState({});
        }   
    }
    cDelay(){
        clearTimeout(this.tempD);
        this.tempD = setTimeout(()=>{
            this.setState({});
        },500);
    }
    pag = {
        min:0,
        max:50,
        n:50,
        aba:0,
        p:0
    }
    statusB={}; 
    processarExtrato(){
        var bgT = 'linear-gradient(180deg,rgba(25, 31, 50,0.6) 17.33%,rgba(25, 31, 50,0))'; 
        bgT = 'transparent'; 
        var view =  <Text align={'center'}  py='10px'  px={{base:'0px',md:'20px'}}  justify='center'  color={'tema1.400'}  opacity={'0.5'} fontSize={'xs'}   textAlign={{base:'center'}}>  Nenhum registro no momento  </Text>
 
        if(this.lancamentos.length>0){
            view = []
            var dados     = this.lancamentos; 
            this.pag.p    = parseInt((dados.length/this.pag.n).toString().split('.')[0]);
            if((dados.length%this.pag.n)){
              this.pag.p++;
            }
            var viewPag   =  [];
            var add = true;
            for(var n=0;n<this.pag.p;n++){ 
              if(this.pag.p>5){
                add = false;
                if(n==0 || n == (this.pag.p-1)){
                  add=true;
                }else if(this.pag.aba==0 || this.pag.aba==1 || this.pag.aba==2){
                  if(n == 1 || n == 2|| n == 3){
                    add=true; 
                  }
                }else if( this.pag.aba == (this.pag.p-3) || this.pag.aba == (this.pag.p-2)|| this.pag.aba == (this.pag.p-1)){ 
                    if(n == (this.pag.p-4) || n == (this.pag.p-3)|| n == (this.pag.p-2)){
                      add=true; 
                    }   
                }else if(n==this.pag.aba-1|| n==this.pag.aba || n==this.pag.aba+1){
                  add=true;   
                } 
              } 
              if(add){
                const pos = n; 
                viewPag.push(<Button onClick={()=>{ 
                  this.pag.aba = pos;
                  this.setState({});
                }} key={'pag'+pos}  colorScheme={this.pag.aba==pos?'green':'gray'} fontSize={'small'} p={0} h='24px' minW='24px'>{pos+1}</Button>)
              } 
            } 
            this.pag.min=(this.pag.n*this.pag.aba);
            this.pag.max=(this.pag.n*this.pag.aba)+this.pag.n; 
            var bg  = 'transparent linear-gradient(-90deg, rgba(46, 200, 68,0) 20%, rgba(46, 200, 68,0.3) 100%) 0% 0% no-repeat padding-box;' 
            var bgR = 'transparent linear-gradient(-90deg, rgba(250, 0, 0,0) 20%, rgba(250, 0, 0,0.4) 100%) 0% 0% no-repeat padding-box;'
          
            this.lancamentos.sort((a, b)=>{  
                return a.criacao > b.criacao ? -1 : a.criacao < b.criacao ? 1 : 0; 
             }).forEach((lan,i)=>{  
                if(i>=this.pag.min && i<this.pag.max){  
                    view.push(
                       <Flex key={'bot'+lan.id} w={'100%'}>  
                            <Grid  display={{base:'none',sm:'grid'}}  bg={{base:bgT   ,md:'transparent'}}  w='full' py='0px' px={'10px'} pb={'0px'} fontSize={'xx-small'} color={'green.500'} align='left'   templateColumns= {{base :'repeat(32, 1fr)'}} gap={0}>
                                <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}    w={'full'} p='5px 10px' colSpan={3} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{lan.id}/{lan.variacao}</Text> 
                                </GridItem>  
                                <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{this.tipos[lan.codtip].nome} {lan.rendimento}%</Text> 
                                </GridItem>  
                                <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={7} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{lan.codafi}-{this.usus[lan.codafi].usuario}</Text> 
                                </GridItem> 
                                <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{G.parseValor(lan.valor,5).f} USDT</Text> 
                                </GridItem> 
                                <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{G.parseValor(this.pedidos[lan.variacao].valor,5).f}/{G.parseValor((this.pedidos[lan.variacao].valor_pago== null)?0:this.pedidos[lan.variacao].valor_pago,5).f} USDT</Text> 
                                </GridItem> 
                                <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{new Date(lan.criacao).toISOString().split('T')[0]}</Text> 
                                </GridItem> 
                                <GridItem borderBottom={'1px solid'}  textAlign={'center'}  borderColor={'tema1.500'}    w={'full'} p='5px 10px' colSpan={4} h='30px'    bg='transparent'>  
                                    {(this.pedidos[lan.variacao].hash== null)?
                                    <a href={'https://bscscan.com/address/'+this.pedidos[lan.variacao].carteira} target="new">
                                        <Icon as={FaWallet} fontSize={'14px'}/>
                                    </a>:
                                    
                                    <a href={'https://bscscan.com/tx/'+this.pedidos[lan.variacao].hash} target="new">
                                        <Icon as={FaGlobe} fontSize={'14px'}/>
                                    </a>}
                                    
                                </GridItem>   
                            </Grid> 
                       </Flex>
                    ); 
                }
            })
            
        } 
      
       
        return (
            <Flex flexDir={'column'}   w={'full'}  minH={'100%'} bg={{base:'transparent',md:bgT}} >
                 {this.pag.p>1?
                  <Stack flexDir={'row'} pos={'absolute'} top='0px' right={'30px'}>
                    {viewPag} 
                  </Stack>
                :null
                } 
                <Grid display={{ base: "grid" }} mt={'20px'} w='full' py='10px'  px={'10px'} fontSize={'x-small'}  color={'tema1.400'} align='left' textAlign='left' templateColumns= {{base :'repeat(32, 1fr)'}} gap={0}>
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}    w={'full'} p='5px 10px' colSpan={3} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>HASH</Text> 
                    </GridItem>  
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'> 
                        <Text as={'b'}   w={'full'}   noOfLines={1}>TIPO</Text> 
                    </GridItem>  
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={7} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>DESCRIÇÂO</Text>  
                    </GridItem> 
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>VALOR</Text> 
                    </GridItem> 
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>PAGO</Text> 
                    </GridItem> 
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'} textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>REGISTRO</Text> 
                    </GridItem> 
                    <GridItem borderBottom={'1px solid'}  borderColor={'tema1.500'}    w={'full'} p='5px 10px' colSpan={4} h='30px'    bg='transparent'>  
                        
                        <Text as={'b'}   w={'full'} textAlign={'center'}   noOfLines={1}>CMD</Text> 
                    </GridItem> 
                </Grid>
                {view}
            </Flex>  
        );
    }
    componentDidMount(){
        this.buscarJogos(); 
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    render(){  
        var viewLista   =   null;  
        console.log('render');
        viewLista = this.processarExtrato(); 
    
        return ( 
            <Flex pos={'relative'}  flexDir={'column'}   maxW={'100%'} w={'100%'} minH={{base:'80px',md:'140px'}}  mb='30px'   >
                {viewLista}   
                {this.atencao }
            </Flex>   
        )
    }
}
export default ListaLancamentos;