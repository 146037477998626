import * as React from "react"  
import { Box, Button, Center, Flex, Grid, Heading, Image, Input, InputGroup, InputRightElement, Modal, ModalCloseButton, ModalContent, ModalOverlay, Spacer, Stack, Text, Textarea } from "@chakra-ui/react";
 
import  InputMask  from 'react-input-mask';   
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import G            from '../../components/ADMSocket';
import QRCode from "react-qr-code";
import Card from "../../components/VisionUI/Card/Card";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
import { FaCopy } from "react-icons/fa";
import GradientBorder from "../../components/VisionUI/GradientBorder";

class BoxPagamento extends React.Component { 
    open = null;
    show1 = false;
    show2 = false;
    show3 = false;
    atencao =   null;
    render(){ 
        var lan =this.props.lan; 
        var show = false; 
        return (
            <>
                <Modal isCentered isOpen={true}  size={{base:'2xl' }} onClose={this.props.cmdFechar}> 
                    <ModalOverlay
                        bg='rgba(0,200,0,0.5)' 
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                    />
                    <ModalContent bg={'tema1.200'}>
                        <ModalCloseButton  color={'tema1.100'}/> 
                        <Stack   rounded={'xl'}  p={{ base: '20px' }} spacing={{ base: 0}}  align={'center'} justify={'center'}>
                            <Flex w={'100%'}  direction={{ base: 'column', md: 'row' }}  textAlign="center" gap={'30px'}>
                                <Flex  direction={{ base: 'column', md: 'column' }}  textAlign="center">
                                    <Text as={'b'} mt={'15px'} mb={'5px'} fontSize="lg" color={'tema1.400'} >
                                        Trust Wallet
                                    </Text> 
                                    <Box bg='tema1.400' w={{base:'180px'}} h={{base:'180px'}} borderRadius={'lg'}> 
                                        <Center p='10px' >
                                            <QRCode value={'https://link.trustwallet.com/send?amount='+G.parseValor(lan.valor*0.95,5).p+'.'+G.parseValor(lan.valor*0.95,5).d+'&asset=c20000714_t0x55d398326f99059fF775485246999027B3197955&value='+(G.parseValor(lan.valor*0.95,5).fv * 1000000000000000000)+'&address='+lan.carteira}  bgColor="transparent" size={160}  /> 
                                        </Center> 
                                        <Button
                                            type="submit"
                                            fontFamily={'heading'} 
                                            mt={'20px'}
                                            w={'full'}
                                            bgGradient="linear(to-r, tem1.100,tem1.100)"
                                            color={'white'}
                                            onClick={()=>{
                                                G.socket.removeAllListeners('formDados').on('formDados',(response)=>{    
                                                    if(response.cmd === "pagarSaque"){   
                                                        
                                                        this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                                                            if(!response.result.erro){
                                                                
                                                                lan.situa = 'N';
                                                                this.props.cmdFechar(); 
                                                            }
                                                            this.atencao = null;
                                                            this.setState({});
                                                        }}/> 
                                                        this.setState({});  
                                                    } 
                                                }); 
                                                G.socket.emit("formDados", {
                                                    modulo  :   'financeiro',
                                                    cmd     :   'pagarSaque',
                                                    form    :   {
                                                        id:lan.id.toString(), 
                                                        situa   :'N'
                                                    }
                                                });  
                                                this.atencao = <BoxWindow load = {true}/>
                                                this.setState({});
                                            }}
                                            _hover={{
                                                bgGradient: 'linear(to-r,green.500,green.500)',
                                                boxShadow: 'xl',
                                                color:'tema1.200'
                                            }}>
                                            Confirmar Pagamento
                                        </Button> 
                                    </Box>
                                </Flex>
                                
                                <Flex  w={'100%'} direction={{ base: 'column', md: 'column' }}  textAlign="center" spacing={0} > 
                                    <Stack  w={'100%'} textAlign="center" justify="center"  spacing={0} > 
                                        <Text as={'b'} mt={'15px'} mb={'5px'} fontSize="lg" color={'tema1.400'} >
                                            Saque de <Text as={'span'}>{this.props.usu.usuario}</Text>
                                        </Text> 
                                        <Box textTransform={'uppercase'} p={'10px'} w={'300px'} m='0 auto' shadow="base" borderWidth="1px" alignSelf={{ base: 'center', lg: 'flex-start' }}  borderColor={'tema1.500'} borderRadius={'xl'}> 
                                            <Stack  direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                                <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                                    Valor do Saque
                                                </Text>
                                                <Spacer/>
                                                <Text as={'b'} fontSize="small" color={'gray.500'} px={'0px'}>
                                                    {G.parseValor((lan.valor),5).f} USDT
                                                </Text>
                                            </Stack>
                                            <Stack  direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                                <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                                    Taxa de Saque 
                                                </Text>
                                                <Spacer/>
                                                <Text as={'b'} fontSize="small" color={'gray.500'} px={'0px'}> 
                                                {G.parseValor(lan.valor*0.05).f} USDT
                                                </Text>
                                            </Stack> 
                                            <Stack mt='10px' direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                                <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                                    Total a Pagar
                                                    
                                                </Text>
                                                <Spacer/>
                                                <Text as={'b'} fontSize="lg" color={'green.500'} px={'0px'}> 
                                                {G.parseValor(lan.valor*0.95).f} USDT
                                                </Text>
                                            </Stack> 
                                        </Box>  
                                    </Stack> 
                                    <Card mt={'20px'} w={'100%'} >
                                        <CardHeader w={'100%'}  > 
                                            <Flex
                                                justify='space-between'
                                                align='center'
                                                pb='10px' 
                                                w={'100%'}
                                                >  
                                                <Text fontSize='sm' lineHeight='30px' color='#fff' fontWeight='bold'>
                                                    Carteira BEP-20
                                                </Text>
                                                <Button  onClick={async (e)=>{ 
                                                    e.preventDefault();   
                                                    var Url = document.getElementById("inopay");
                                                    Url.select() 
                                                    document.execCommand("copy");
                                                    this.atencaoB = <BoxWindow titulo = {'Cópia realizada com sucesso!'} mensagem={'Você copiou a carteira BEP-20 na memória, agora só transferir o valor exato para essa carteira, e assim  finalizar o pagamento'} onClose={()=>{
                                                        this.atencaoB = null;
                                                        this.setState({});
                                                    }}/>
                                                    this.setState({});
                                                }} p={'0px 10px'} h={'30px'} leftIcon={<FaCopy fontSize={'18px'}/>} fontSize='12px' bg={'green.500'} color={'tema1.200'} variant='solid'>
                                                    Copiar
                                                </Button>
                                            </Flex>
                                        </CardHeader>
                                        <Flex
                                            direction={{ sm: "column", md: "row" }}
                                            align='center' 
                                            justify='center'
                                        >
                                            <GradientBorder 
                                            w='100%'
                                            borderRadius='10px'>
                                                <Flex
                                                    p='0'
                                                    bg='tema1.200'
                                                    border='transparent'
                                                    borderRadius='10px'
                                                    align='center'
                                                    justify={'center'}
                                                    w='100%'>
                                                        
                                                    <Textarea id="inopay" textAlign={'center'}  overflow='hidden' p='10px 10px'  lineHeight={'20px'}  borderRadius='10px' outline={'none'} color='#fff' minH={'40px'} fontSize='xs' isReadOnly placeholder='Here is a sample placeholder'  resize='none' onClick={(e)=>{ 
                                                        e.preventDefault();   
                                                        var Url = document.getElementById("copyText");
                                                        Url.select() 
                                                        document.execCommand("copy");
                                                        this.atencaoB = <BoxWindow titulo = {'Cópia realizada com sucesso!'} mensagem={'Você copiou a carteira BEP-20 na memória, agora só transferir o valor exato para essa carteira, e assim  finalizar o pagamento'} onClose={()=>{
                                                            this.atencaoB = null;
                                                            this.setState({});
                                                        }} />
                                                        this.setState({});
                                                    }}
                                                    defaultValue={lan.carteira}
                                                    /> 
                                                </Flex>
                                            </GradientBorder> 
                                        </Flex> 
                                    </Card>  
                                </Flex>
                            </Flex>
                            
                            
                         
                        
                        </Stack>
                        {this.atencao}
                        {this.atencaoB}
                        </ModalContent>
                    {/* 
                        <ModalHeader color={'tema1.100'}>Enviando dados ao servidor</ModalHeader> 
                            <ModalBody  color={'#ffffff'}>
                                <Flex flexDirection='row' align='center' justify='center'>
                                    <Spinner color='tema1.100' /> <Text ml='15px' >Aguarde um momento...</Text>
                                </Flex> 
                            </ModalBody> 
                        <ModalFooter/>
                    </ModalContent> */}
                </Modal>
            </>
        )
    }
}
export default BoxPagamento;