import * as React from "react"  
import {   Button,  Flex, Grid, Icon, LightMode, Progress, SimpleGrid, Spacer, Stack, Stat, StatHelpText, StatLabel, StatNumber, Text, Textarea } from "@chakra-ui/react"; 
import {Helmet} from "react-helmet"; 
import { SiTether } from "react-icons/si";

import G from '../../components/ADMSocket';
import { FaArrowDown, FaArrowUp, FaChartBar, FaChartPie, FaCheck, FaClock, FaCopy, FaDollarSign, FaExchangeAlt, FaFilter, FaPencilAlt, FaRegCalendarAlt, FaRobot, FaStackExchange, FaUser } from "react-icons/fa";
import IconBox from "../../components/VisionUI/Icons/IconBox";
import Card from "../../components/VisionUI/Card/Card";
import CardBody from "../../components/VisionUI/Card/CardBody";
import CardHeader from "../../components/VisionUI/Card/CardHeader";  
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import TransactionRow from "../../components/VisionUI/Tables/TransactionRowDeposito";
import ListaLancamentos from "./ListaAfiliados";
import { FaTicket } from "react-icons/fa6";
import InputAutocomplete from "../../components/Padrao/InputAutocomplete";
const BoxComandoVoucher = function(props){ 
    const  {usu=[],onChange} =   props; 
    return <>
        <Stack justify={'space-between'} direction={'row'} gap={'20px'} align={'center'} > 
            <InputAutocomplete name='codusu' onChange={(v)=>{
                try {
                    onChange(v);
                } catch (error) {
                    
                }
            }}  placeholder='Busca rápida...'  leftIcon={FaUser} lista={Object.values(usu).map((u)=>{
                    return {valor:u.id,nome:u.usuario+' - '+u.nome}
                })}/> 
                <Spacer/>
            <Button
                bg='tema1.200' 
                leftIcon={<FaFilter size={'24px'}/>}
                color='green.500'
            >
                 
                <Text>
                    Filtro
                </Text>
            </Button>
        </Stack>
    </>
    // render(){  
       
    
    //     return (
    //         <>
                    
    //             <Flex direction={{base:'column',lg:'row'}}  w={{base:'100%'}} spacing={0} pt={{base:'10px',sm:'20px'}} px={{base:'10px',sm:'20px'}}>  
    //                 <Stack direction='column'  w={{base:'100%',lg:'60%'}} spacing={{base:'0px',lg:'20px'}} p={{base:'0px 0px',lg:'0px 20px 0px 0px'}} pb={{base:'20px',lg:'20px'}}> 
    //                     <Grid templateColumns={{ sm: "1fr",  md: "1fr 1fr" }}  gap={{base:'10px',sm:'20px'}}>
    //                                 {/* Credit Balance */}
    //                         <Card>
    //                             <Flex direction='column'>
    //                                 <Flex
    //                                 justify='space-between'
    //                                 p='22px'
    //                                 mb='18px'
    //                                 bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
    //                                 borderRadius='18px'>
    //                                     <Flex direction='column'>
    //                                         <Text color='#E9EDF7' fontSize='12px'>
    //                                             Voucher Totais
    //                                         </Text>
    //                                         <Text color='green.500' fontWeight='bold' fontSize='34px'>
    //                                             {G.parseValor(this.db.saldos.voucher,5).p},<Text as='span'   fontSize='16px'>{G.parseValor(this.db.saldos.voucher,5).d}</Text>
    //                                         </Text>
    //                                     </Flex>
    //                                     <Flex direction='column'>
    //                                         <Button
    //                                         bg='transparent'
    //                                         _hover={{}}
    //                                         _active={{}}
    //                                         alignSelf='flex-end'
    //                                         cursor='default'
    //                                         p='0px'>
    //                                         <Icon
    //                                             as={SiTether}
    //                                             color='green.500'
    //                                             w='24px'
    //                                             h='24px'
    //                                             justifySelf='flex-start'
    //                                             alignSelf='flex-start'
    //                                         />
    //                                         </Button>
    //                                         {/* <GraphIcon w='60px' h='18px' /> */}
    //                                     </Flex>
    //                                 </Flex>
    //                             </Flex>
    //                         </Card>
    //                         <Card>
    //                             <Flex direction='column'>
    //                                 <Flex
    //                                 justify='space-between'
    //                                 p='22px'
    //                                 mb='18px'
    //                                 bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
    //                                 borderRadius='18px'>
    //                                 <Flex direction='column'>
    //                                     <Text color='#E9EDF7' fontSize='12px'>
    //                                         Voucher 0%
    //                                     </Text>
    //                                     <Text color='green.500' fontWeight='bold' fontSize='34px'>
    //                                         {G.parseValor(this.db.saldos.voucher0,5).p},<Text as='span'   fontSize='16px'>{G.parseValor(this.db.saldos.voucher0,5).d}</Text>
    //                                     </Text>
    //                                 </Flex>
    //                                 <Flex direction='column'>
    //                                     <Button
    //                                     bg='transparent'
    //                                     _hover={{}}
    //                                     _active={{}}
    //                                     alignSelf='flex-end'
    //                                     cursor='default'
    //                                     p='0px'>
    //                                     <Icon
    //                                         as={SiTether}
    //                                         color='green.500'
    //                                         w='24px'
    //                                         h='24px'
    //                                         justifySelf='flex-start'
    //                                         alignSelf='flex-start'
    //                                     />
    //                                     </Button>
    //                                     {/* <GraphIcon w='60px' h='18px' /> */}
    //                                 </Flex>
    //                                 </Flex> 
    //                             </Flex>
    //                         </Card>  
    //                         <Card>
    //                             <Flex direction='column'>
    //                                 <Flex
    //                                 justify='space-between'
    //                                 p='22px'
    //                                 mb='18px'
    //                                 bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
    //                                 borderRadius='18px'>
    //                                 <Flex direction='column'>
    //                                     <Text color='#E9EDF7' fontSize='12px'>
    //                                         Voucher 30%
    //                                     </Text>
    //                                     <Text color='green.500' fontWeight='bold' fontSize='34px'>
    //                                         {G.parseValor(this.db.saldos.voucher30,5).p},<Text as='span'   fontSize='16px'>{G.parseValor(this.db.saldos.voucher30,5).d}</Text>
    //                                     </Text>
    //                                 </Flex>
    //                                 <Flex direction='column'>
    //                                     <Button
    //                                     bg='transparent'
    //                                     _hover={{}}
    //                                     _active={{}}
    //                                     alignSelf='flex-end'
    //                                     cursor='default'
    //                                     p='0px'>
    //                                     <Icon
    //                                         as={SiTether}
    //                                         color='green.500'
    //                                         w='24px'
    //                                         h='24px'
    //                                         justifySelf='flex-start'
    //                                         alignSelf='flex-start'
    //                                     />
    //                                     </Button>
    //                                     {/* <GraphIcon w='60px' h='18px' /> */}
    //                                 </Flex>
    //                                 </Flex> 
    //                             </Flex>
    //                         </Card> 
    //                         <Card>
    //                             <Flex direction='column'>
    //                                 <Flex
    //                                 justify='space-between'
    //                                 p='22px'
    //                                 mb='18px'
    //                                 bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
    //                                 borderRadius='18px'>
    //                                 <Flex direction='column'>
    //                                     <Text color='#E9EDF7' fontSize='12px'>
    //                                         Voucher 100%
    //                                     </Text>
    //                                     <Text color='green.500' fontWeight='bold' fontSize='34px'>
    //                                         {G.parseValor(this.db.saldos.voucher100,5).p},<Text as='span'   fontSize='16px'>{G.parseValor(this.db.saldos.voucher100,5).d}</Text>
    //                                     </Text>
    //                                 </Flex>
    //                                 <Flex direction='column'>
    //                                     <Button
    //                                     bg='transparent'
    //                                     _hover={{}}
    //                                     _active={{}}
    //                                     alignSelf='flex-end'
    //                                     cursor='default'
    //                                     p='0px'>
    //                                     <Icon
    //                                         as={SiTether}
    //                                         color='green.500'
    //                                         w='24px'
    //                                         h='24px'
    //                                         justifySelf='flex-start'
    //                                         alignSelf='flex-start'
    //                                     />
    //                                     </Button>
    //                                     {/* <GraphIcon w='60px' h='18px' /> */}
    //                                 </Flex>
    //                                 </Flex> 
    //                             </Flex>
    //                         </Card> 
    //                     </Grid> 
    //                 </Stack>
                    
    //                 <Stack direction='column'    w={{base:'100%',lg:'40%'}} spacing={{base:'0'}} pb='20px'   > 
    //                     <Card  h={'100%'}   >
    //                         <CardHeader  minHeight={'max-content'} >
    //                             <Flex direction='column' w='100%' minH={'max-content'}>
    //                             <Flex
    //                                 direction={{ sm: "column", lg: "row" }}
    //                                 justify={{ sm: "center", lg: "space-between" }}
    //                                 align={{ sm: "center" }}
    //                                 w='100%'
                                    
    //                                 >
    //                                 <Text
    //                                 color='#fff'
    //                                 fontSize={{ sm: "lg", md: "xl", lg: "lg" }}
    //                                 fontWeight='bold'>
    //                                 Últimas Transações
    //                                 </Text> 
    //                             </Flex>
    //                             </Flex>
    //                         </CardHeader>
    //                         <CardBody mt='15px' >
    //                             <Flex direction='column' w='100%'> 
    //                                 {
    //                                     (this.db.lancamentos.length>0)?
    //                                     this.db.lancamentos.map((row,key) => {
    //                                         return (
                                                    
    //                                                 <TransactionRow
    //                                                     key={'key'+key} 
    //                                                     name={row.name}
    //                                                     logo={row.logo}
    //                                                     date={row.date}
    //                                                     price={row.price}
    //                                                     pago={row.pago}
    //                                                 /> 
                                                
    //                                         );
    //                                     }):<Text color='green.500'>Nenhum Lançamento encontrado</Text>
    //                                 }  
    //                             </Flex>
    //                         </CardBody>
    //                     </Card>
    //                 </Stack>
    //             </Flex>
    //             <Stack direction='column'    w={{base:'100%',lg:'100%'}} spacing={{base:'0'}} p={{base:'0px 10px 0px 10px',sm:'0px 20px 0px 20px',lg:'0px 20px 20px 20px' }}  > 
    //                 <Card  h={'100%'}   >
    //                     <CardHeader  minHeight={'max-content'} >
    //                         <Flex direction='column' w='100%' minH={'max-content'}>
    //                         <Flex
    //                             direction={{ sm: "column", lg: "row" }}
    //                             justify={{ sm: "center", lg: "space-between" }}
    //                             align={{ sm: "center" }}
    //                             w='100%'
                                
    //                             >
    //                             <Text
    //                             color='#fff'
    //                             fontSize={{ sm: "lg", md: "xl", lg: "lg" }}
    //                             fontWeight='bold'>
    //                             Extrato Detalhado
    //                             </Text>
    //                             <Flex align='center'>
    //                             <Icon
    //                                 as={FaChartBar} 
    //                                 w='15px'
    //                                 h='15px'
    //                                 color='#fff'
    //                                 me='6px'
    //                             />
                                
    //                             </Flex>
    //                         </Flex>
    //                         </Flex>
    //                     </CardHeader>
    //                     <CardBody>
    //                         <Flex direction='column' w='100%'>
    //                             <ListaLancamentos/>
    //                         </Flex>
    //                     </CardBody>
    //                 </Card>
                    
    //             </Stack>
    //             {this.atencaoB}
    //         </>
    //     )
    // }
}
export default BoxComandoVoucher;