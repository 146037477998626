import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom"
import {
  ChakraProvider, 
} from "@chakra-ui/react" 
import TemaSite       from  "./themes/site/TemaSite"
import TemaLogin      from  "./themes/site/TemaLogin"

import TemaADMLogin      from  "./themes/admin/TemaADMLogin"
import TemaAdmin      from  "./themes/admin/TemaAdmin"


import 'animate.css'; 
import { extendTheme } from "@chakra-ui/react"
// nao logados
import PageLogin   from "./pages/Login/PageLogin";
import PageLost   from "./pages/Lost/PageLost";
import PageRegistro   from "./pages/Registro/PageRegistro";
// logados
import PageHome         from "./pages/Home/PageHome";
import PageFinanceiro   from "./pages/Financeiro/PageFinanceiro";
import PageSaque        from "./pages/Saque/PageSaque";
import PageDeposito     from "./pages/Depositos/PageDepositos";
import PageRede         from "./pages/Rede/PageRede";
import PagePacotes         from "./pages/Pacotes/PagePacotes";
import PageBinario         from "./pages/Binario/PageBinario";

// nao logados ADM 
import ADMLogin   from "./admin/Login/ADMLogin"; 
// logados
import ADMDashboard         from "./admin/Dashboard/ADMDashboard";
import ADMDepositos         from "./admin/Depositos/ADMDepositos"; 
import ADMAfiliados         from "./admin/Afiliados/ADMAfiliados"; 


import ADMSaques            from "./admin/Saques/ADMSaques"; 
import ADMVoucher           from "./admin/Voucher/ADMVoucher"; 
import ADMKyc           from "./admin/KYC/ADMkyc"; 

import G              from './components/WebSocket'; 
import GA              from './components/ADMSocket'; 

const theme = extendTheme({ 
  components: {
    Card:{ 
      baseStyle: {
        p: "22px",
        display: "flex",
        flexDirection: "column",
        backdropFilter: "blur(120px)",
        width: "100%",
        borderRadius: "20px",
        bg:
          "linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)",
        backgroundClip: "border-box"
      }
    },
    CardBody:{
      baseStyle: {
        display: "flex",
        width: "100%",
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          bg: "tema1.200",
            borderColor:'tema1.100',
          _checked: {
            bg: "tema1.100",
            borderColor:'tema1.200',
          }
        }
      }
    }
  },
  colors: {
    green:{
      500:'#3AFFCA'
    },
    tema1: {
      100: "#ffffff", 
      200: "#000000",
      300: '#042359',
      400: '#ffffff',
      500: '#6E737E',
      600: '#E8F344',
      700: '#0D1427'
    } 
  },
});
export const App = () => {
  useEffect(() => { 
      return ()=>{
        if(G.conectado){
          G.desconectarNode();
          GA.desconectarNode() 
        } 
      }
  }, [])
  

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter> 
        <Routes>

          <Route path="/login" element={<TemaLogin><PageLogin/></TemaLogin>} />
          <Route path="/lost/*" element={<TemaLogin><PageLost/></TemaLogin>} />
          <Route path="/register/*" element={<TemaLogin><PageRegistro/></TemaLogin>} /> 

          <Route path="/" element={<TemaSite><PageHome/></TemaSite>} />
          <Route path="/financial" element={<TemaSite><PageFinanceiro/></TemaSite>} /> 
          <Route path="/withdraw" element={<TemaSite><PageSaque/></TemaSite>} /> 
          <Route path="/deposits" element={<TemaSite><PageDeposito/></TemaSite>} /> 
          <Route path="/network" element={<TemaSite><PageRede/></TemaSite>} /> 
          <Route path="/mypackages" element={<TemaSite><PagePacotes/></TemaSite>} />  
          <Route path="/binary" element={<TemaSite><PageBinario/></TemaSite>} />   
          {/* admin */}

          
          <Route path="/skyadm/login" element={<TemaADMLogin><ADMLogin/></TemaADMLogin>} />   
          <Route path="/skyadm" element={<TemaAdmin><ADMDashboard/></TemaAdmin>} />
          <Route path="/skyadm/depositos" element={<TemaAdmin><ADMDepositos/></TemaAdmin>} />
          <Route path="/skyadm/saques" element={<TemaAdmin><ADMSaques/></TemaAdmin>} />

          <Route path="/skyadm/afiliados" element={<TemaAdmin><ADMAfiliados/></TemaAdmin>} />
          <Route path="/skyadm/voucher" element={<TemaAdmin><ADMVoucher/></TemaAdmin>} />
          <Route path="/skyadm/kyc" element={<TemaAdmin><ADMKyc/></TemaAdmin>} />


        </Routes>
      </BrowserRouter> 
  </ChakraProvider>
  )
} 
