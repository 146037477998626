import { io }   from "socket.io-client"; 
import {Buffer} from 'buffer'; 
import zlib from 'react-zlib-js'; 
import { json } from "react-router-dom";
import axios from 'axios';

var ADDH={}; 
let     ADM       =   {};  
ADM.empresa       =   "inovatyon";  
ADM.focusG        =   {};
ADM.sessao        =   "inovatyonadmin";
ADM.server        =   "inovatyon.com:5017";   
ADM.geral         =   null;
ADM.painel        =   null; 
ADM.taxa          =   0.935; 

ADM.meses         =   ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];
ADM.siglaMeses    =   ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'];

ADM.viewPlanos = null;

ADM.calcPadrao    =   {};
ADM.openBanca     =   ()=>{};
ADM.audioAtivo = false;
ADM.paypalP = 'ASlBiswi1fHZ9H10dySvLHKMzWeSEoXNV2SxpXkgvvwFRiHrno_dBZHg4spNLqNrfNm-HSTUpfaL1ogD';
ADM.paypalS = 'EPqqph4BSrKRZ4SnXfn9m4i5SODnvyq1QjQS95SYYW01rbbhGh4DragfKR6E1XQhn66Kq0Xvy67g6OKh';
ADM.audios ={}

ADM.laodAudios =()=>{
    ADM.audios.maisede2     =   new Audio('/assets/audio/maisde2.mp3');
    ADM.audios.maisede5     =   new Audio('/assets/audio/maisde5.mp3');
    ADM.audios.maisede10    =   new Audio('/assets/audio/maisde10.mp3');
    ADM.audios.maisede15    =   new Audio('/assets/audio/maisde30.mp3');
    ADM.audios.maisede30    =   new Audio('/assets/audio/maisde15.mp3'); 
    ADM.audios.Heal4        =   new Audio('/assets/audio/Heal4.mp3'); 
    ADM.audios.Confirm      =   new Audio('/assets/audio/Confirm.mp3'); 
    ADM.audios.Cancel1      =   new Audio('/assets/audio/Cancel1.mp3'); 
    ADM.audios.Save         =   new Audio('/assets/audio/Save.mp3'); 
}
ADM.parseValor=( num,decimal=2)=>{
    var numN = {
        p:0,
        d:0,
        f:0, 
        fv:0
    } 
    if(num>=0){  
        var valor = parseFloat(num).toFixed((decimal+2)).toString(); 
        valor     =   valor.substring(0, valor.length - 2);  
        var pos   =   parseFloat(valor).toString().split('.'); 
        numN.p    = pos[0].replace(/(.)(?=(\d{3})+$)/g,'$1.'); 
        numN.d    = (typeof pos[1] =='undefined'?0:pos[1]);
        numN.f    =  numN.p+','+numN.d 
        return numN;
    }else{
      return numN;
    }  
}
ADM.filtrarJogos=(jogos)=>{
    var send = [];
    var add = true;  
    var f = ADM.painel.fSite;
    //filtro casa  
    try {
        if(ADM.painel.ativoSite == 'S' ){ 
            jogos.forEach((jogo)=>{
                add = true; 
                if(jogo.odds[0].site !== 'BLOCK'){ 
                    add =false;
                    if(f[jogo.odds[0].site].casas[jogo.odds[1].site] && f[jogo.odds[1].site].casas[jogo.odds[0].site]){
                    // if((f[jogo.odds[0].site].ativa || f[jogo.odds[0].site].casas[jogo.odds[].site]) || (f[jogo.odds[1].site].ativa || f[jogo.odds[1].site].casas[jogo.odds[0].site]) ){
                        add =true;
                    }  
                    if(add){
                        if(f[jogo.odds[0].site].esportes[jogo.esporte.toLowerCase()] && f[jogo.odds[1].site].esportes[jogo.esporte.toLowerCase()]){
                            // if((f[jogo.odds[0].site].ativa || f[jogo.odds[0].site].casas[jogo.odds[].site]) || (f[jogo.odds[1].site].ativa || f[jogo.odds[1].site].casas[jogo.odds[0].site]) ){
                                add =true;
                        }else{
                            add =false;
                        } 
                    }
                    //filtro lucro
                    if(add){
                        //VERIFICAR FILTRO MENOR E MAOIR
                        var pMin =f[jogo.odds[0].site].pMin;
                        if(f[jogo.odds[1].site].pMin<pMin){
                            pMin = f[jogo.odds[1].site].pMin;
                        }
                        var pMax =f[jogo.odds[0].site].pMax;
                        if(f[jogo.odds[1].site].pMax>pMax){
                            pMax = f[jogo.odds[1].site].pMax;
                        } 
                        if(pMax == 100){
                            add = false;
                            if(jogo.lucroP>=pMin){
                                add = true
                            } 
                        }else{
                            add = false;
                            if(jogo.lucroP>=pMin && jogo.lucroP<pMax){
                                add = true
                            } 
                        }  
                    }
                    if(add && jogo.data!='BLOCK'){
                        //VERIFICAR FILTRO MENOR E MAOIR tempo
                        var tMin =f[jogo.odds[0].site].tMin; 
                        if(f[jogo.odds[1].site].tMin<tMin){
                            tMin = f[jogo.odds[1].site].tMin;
                        }
                        
                        var tMax =f[jogo.odds[0].site].tMax;
                        if(f[jogo.odds[1].site].tMax>tMax){
                            tMax = f[jogo.odds[1].site].tMax;
                        }
                        tMin =new Date().getTime()+(tMin*60*60000)
                        if(tMax!=120){
                            tMax =new Date().getTime()+(tMax*60*60000)
                            add = false;
                            if(jogo.data>=tMin && jogo.data<tMax){
                                add = true
                            } 
                        }else{ 
                            add = false;
                            if(jogo.data>=tMin ){
                                add = true
                            } 
                        }
                        
                    } 
                } 
                if(add){
                    send.push(jogo)
                }
            })
        }else{
            send = jogos;
        } 
    } catch (error) {
        send = jogos;
    }
    
    return send;
}
ADM.tempAlerta = {};
ADM.gerarAlertas =()=>{
    var jogos = [];
    if(ADM.geral!=null && typeof ADM.painel.usuario != 'undefined' ){
        if(typeof ADM.geral.jogos !=='undefined'){
            jogos = ADM.filtrarJogos(ADM.geral.jogos); 
        }
    } 

    var alerta={
        lucro   :   0,
        sair    :   '',
        time    :   new Date().getTime()
    } 
    if(ADM.audioAtivo){  
        jogos.forEach((jogo)=>{
            if(typeof ADM.tempAlerta[jogo.id] == "undefined"){
                ADM.tempAlerta[jogo.id]   =   {}; 
                if(jogo.lucroP>alerta.lucro){
                    alerta.lucro=jogo.lucroP;
                }
            }else{
                if(ADM.tempAlerta[jogo.id].lucroP< jogo.lucroP){ 
                    ADM.audios.Heal4.play(); 
                }else if(ADM.tempAlerta[jogo.id].lucroP> jogo.lucroP){ 
                    ADM.audios.Confirm.play(); 
                } 
            }
            ADM.tempAlerta[jogo.id].lucroP    =   jogo.lucroP;
            ADM.tempAlerta[jogo.id].up        =   alerta.time;
        }) 
        if(alerta.lucro>30){
            ADM.audios.maisede30.play(); 
        }else if(alerta.lucro>15){
            ADM.audios.maisede15.play(); 
        }else if(alerta.lucro>10){
            ADM.audios.maisede10.play(); 
        }else if(alerta.lucro>5){
            ADM.audios.maisede5.play();
        }else if(alerta.lucro>0.5){
            ADM.audios.maisede2.play();
        }   
        //verifica se saiu jogo
        Object.keys(ADM.tempAlerta).forEach((id)=>{
            if(ADM.tempAlerta[id].up!== alerta.time){ 
                delete ADM.tempAlerta[id];
                ADM.audios.Cancel1.play(); 
            }
        })   
    }else{
        // console.log("focar tela");
    } 
} 
ADM.TtempAlerta = null;
ADM.processarAlertas =()=>{
    document.querySelector("html").addEventListener('click', () => {
        
        ADM.audioAtivo = true;
    });
  
    ADM.gerarAlertas();
    clearTimeout(ADM.TtempAlerta);
    ADM.TtempAlerta = setInterval(()=>{
        ADM.gerarAlertas();
    },1000)
}

ADM.idioma        =   {
    id : 'ptBR'
} 
ADM.situa = {
    'C':'Cancelado',
    'P':'Pendente',
    'N':'Completo',
    'F':'Finalizado'
}
ADM.nivel = 0; 
ADM.socket = null;
ADM.conectarNode =(callback)=>{
    if(ADM.socket == null){
        ADM.socket    =   new io("wss://"+ADM.server,{withCredentials: true});  
        ADM.socket.on("connect", () => {  
            ADM.conectado=true;  
            ADM.autologin(callback);
        
        }); 
        ADM.socket.on("disconnect", () => {  
            ADM.conectado =false;
        });
    }
     
}
ADM.desconectarNode =()=>{
    if(!ADM.socket == null){
        ADM.socket =null;
        ADM.conectado = false;
        clearInterval(ADM.loopGeral);
    }
}
ADM.sendForm=(sendForm,callback)=>{ 
    sendForm.form.idioma = ADM.idioma.id; 
    ADM.socket.removeAllListeners("formDados").on("formDados",(dados)=>{    
        if(dados.modulo == sendForm.modulo && dados.cmd == sendForm.cmd){
            callback(dados.result)
        }   
    });  
    ADM.socket.emit("formDados",sendForm); 
};  
ADM.getDados=()=>{  
    ADM.socket.removeAllListeners("dadosGeral").on("dadosGeral",(dados)=>{    
        if(ADM.geral == null){
            ADM.geral = {};
        }   
        // var bufferR = new Buffer.from(dados, 'base64');
        // zlib.unzip(bufferR, function(err, bufferD) {
        //     if (!err) {
        //         try {
        //             dados = JSON.parse(bufferD.toString())  
                    Object.keys(dados).forEach((campo)=>{
                        switch (campo) {
                            case 'usuario': 
                                ADM.painel        = dados[campo]; 
                                ADM.geral[campo]  = dados[campo];
                            break;  
                            default: 
                                ADM.geral[campo]  = dados[campo];
                            break;
                        }  
                    }); 
        //         } catch (error) {
                    
        //         } 
        //     }
        // });   
    }).emit("dadosGeral"); 
    // ADM.socket.removeAllListeners("dadosHistorico").on("dadosHistorico",(dados)=>{  
    //     if(ADM.geral == null){
    //         ADM.geral = {};
    //     } 
    //     if(typeof ADM.geral.historico == 'undefined'){
    //         ADM.geral.historico = [];
    //     }
    //     var bufferR = new Buffer.from(dados.d, 'base64');
    //     zlib.unzip(bufferR, function(err, bufferD) {
    //         if (!err) {
    //             try { 
    //                 var sendD = JSON.parse(bufferD.toString())  
    //                 if(sendD.length>0){ 
    //                     sendD.forEach((jg)=>{
    //                         ADDH[jg.id] = jg;   
    //                     })
    //                     ADM.geral.historico = Object.values(ADDH);
    //                     ADM.geral.historico = ADM.geral.historico.sort((a,b)=> { 
    //                         return a.dataI > b.dataI ? -1 : a.dataI < b.dataI ? 1 : 0; 
    //                     })
    //                 } 
    //             } catch (error) { 
    //             } 
    //         }
    //     });   
    // }) 
};
ADM.deletarHistorico=(bot)=>{
    var temp =[]; 
    ADM.geral.historico.forEach((jg)=>{

        if(bot!=jg.botId){
            temp.push(jg);
        }else{
            // console.log(bot,jg.botId)
        }
    });
    ADM.geral.historico = temp;
}; 
ADM.historico={}; 
ADM.autologin=(callback)=>{ 
    if (typeof localStorage !== 'undefined') { 
        ADM.token     =   localStorage.getItem('skt'+ADM.sessao);  
      
        // if(window.location.hostname == 'localhost'){
        //     ADM.token     =  'da2ce7aa115edde327a0a30c165746c4';
        // }
        
        // ADM.token     = 'e42399cd51c807445cc4a82daf5eef7a';
        
    }    

    if(ADM.token == 'null' || ADM.token == null ||  typeof ADM.token =='undefined'){
        ADM.painel    =   {};  
        callback();
    }else{   
        ADM.socket.removeAllListeners('autologin').on('autologin',(dados)=>{   
            if(typeof window !== "undefined"){ 
                ADM.token = (typeof dados.hash!=="undefined")?dados.hash:null;   
                ADM.painel = (typeof dados.usu!=="undefined")?dados.usu:{}; 
            }    
            callback();
        }).emit("autologin", ADM.token); 
        // ADM.socket.removeAllListeners('infoHistorico').on('infoHistorico',(dados)=>{     
        //     var bufferR = new Buffer.from(dados, 'base64');
        //     zlib.unzip(bufferR, function(err, bufferD) {
        //         if (!err) {
        //             try { 
        //                 var sendD = JSON.parse(bufferD.toString());
        //                 if(Object.keys(sendD).length>0){ 
        //                     Object.keys(sendD).forEach((key)=>{
        //                         ADM.historico[key] = sendD[key]; 
        //                     }) 
        //                 } 
        //             } catch (error) { 
        //             } 
        //         }
        //     });    
        //     // ADM.historico[dados.id] = dados.info; 
        // })
    }   

    if(ADM.loopGeral == null){
        clearInterval(ADM.loopGeral);
        ADM.loopGeral = setInterval(()=>{
            ADM.socket.emit("dadosGeral");   
        },1000);
        // getHistorico();
        // ADM.socket.emit("dadosHistorico"); 
       
    } 
    ADM.getDados()
}
ADM.getDadosDocumentos= (afi)=> {  
    var info ={
        id          :   afi.id,
        dados       :   afi,
        autologin   :   afi.autologin,
        nome        :   afi.nome,
        usuario     :   afi.usuario,
        email       :   afi.email,
        telefone    :   afi.telefone, 
        ativo       :   false,
        pend        :   false,
        enviado     :   false,
        up          :   0,
        frenteT      :   (afi.kyc_frente_time== null)?0:parseInt(afi.kyc_frente_time), 
        selfieT      :   (afi.kyc_selfie_time== null)?0:parseInt(afi.kyc_selfie_time), 
        residT      :   (afi.kyc_residencia_time== null)?0:parseInt(afi.kyc_residencia_time),
        kyc_frente          :   '',
        kyc_verso    :   '',
        kyc_selfie          :   '',
        kyc_residencia      :   ''
    }
    if(info.up<=info.frenteT ){
        info.up = info.frenteT
    } 
    if(info.up<=info.selfieT ){
        info.up = info.selfieT
    }  
    if(info.up<=info.residT ){
        info.up = info.residT
    }  
    if(afi.kyc_frente !=null || afi.kyc_selfie !=null || afi.kyc_residencia ){
        info.enviado = true
    }
    var situa = {
        'P':'Pendente',
        'D':'Não tem',
        'R':'Rejeitado',
        'N':'Aprovado' 
    }
    var cont =0,pend=0,revis=0;
    [
        {campo:'kyc_frente_obs',valor:'docF'},
        {campo:'kyc_verso_obs',valor:'docV'},
        {campo:'kyc_selfie_obs',valor:'self'},
        {campo:'kyc_residencia_obs',valor:'resid'}
    ].forEach((v)=>{
        switch (afi[v.campo]) {
            case null:case 'PENDENTE':
                info[v.valor] = 'P'; 
                pend++;
            break;
            case 'NO':
                info[v.valor] = 'D'; 
                cont++; 
            break;
            case 'OK':
                info[v.valor] = 'N'; 
                cont++;
            break; 
            default:
                console.log(afi[v.campo]);
                revis++;
                info[v.valor] = 'R'
            break 
        } 
        info[v.valor+"Nome"] = situa[info[v.valor]]; 
        if(cont==4){
            info.ativo =true;
        }else if(pend>0 && info.enviado){
            info.pend =true;
            
        }else if(revis>0){
            info.revisao =true;
        }  
        
    })
    try { info.kyc_frente = afi.kyc_frente.replace('./../../www/midia','https://webdoc.inovatyon.com');  } catch (error) {}
    try { info.kyc_verso = afi.kyc_verso.replace('./../../www/midia','https://webdoc.inovatyon.com');  } catch (error) {}
    try { info.kyc_selfie = afi.kyc_selfie.replace('./../../www/midia','https://webdoc.inovatyon.com');  } catch (error) {}
    try { info.kyc_residencia = afi.kyc_residencia.replace('./../../www/midia','https://webdoc.inovatyon.com');  } catch (error) {}
    info.kyc_verso_obs  =   afi.kyc_verso_obs;
    info.endereco           =   afi.endereco;
    
    
    // if(afi.kyc_frente)kyc_frente_obs kyc_frente_time

    // kyc_verso kyc_verso_obs
    // kyc_selfie kyc_selfie_obs kyc_selfie_time 
    //kyc_residencia kyc_residencia_obs kyc_residencia_time

    return info
}
ADM.getSaldosConta= (LANs)=> {  
    var time = new Date().getTime() - 3*60*60*1000; 
    var saldo = {
        saldo           :   0, //saldo em Salus dolar 
        saldoP          :   0,

        depositos       :   0,
        depositosP      :   0,
        depositosF      :   0,

        voucher         :   0,
        voucher0        :   0,
        voucher30       :   0,
        voucher100      :   0,



        reinvestimento  :   0,
        inopay          :   0,  

        saque           :   0,
        saqueP          :   0,
        taxaSaque       :   0,
        indicacao       :   0, 
        indicacaoI       :   0,
        binario         :   0,
        binarioP        :   0,  
        rendimento      :   0, 
        rendimentoP     :   0,
        residual        :   0,
        ganhos          :   0,
        
        salExtra        :   0,
        salExtraP       :   0,
        debExtra        :   0,
        creExtra        :   0, 
        taxaReembolso   :   0
    }; 
    var up = 0;
    LANs.forEach((lan)=>{
        if(up<new Date(lan.dataUP).getTime()){
           up=new Date(lan.dataUP).getTime();
        }
        switch (lan.codtip) {
            case 1: //credito
                if(lan.situa == 'N'){
                    saldo.saldo         +=  lan.valor;
                }
            break;
            case 3: //saque
                if(lan.situa =='N' ){
                    saldo.saque         +=  lan.valor;
                    saldo.saldo         -=  lan.valor;
                }
                if(lan.situa =='P' ){ 
                    saldo.saqueP        +=  lan.valor;
                    saldo.saldo         -=  lan.valor;
                }  
            break;
            case 4: //deposito
                if(lan.situa == 'N' && lan.voucher == 'N'){
                    saldo.depositos     +=  lan.valor;
                }
                if(lan.situa == 'P' && lan.voucher == 'N'){
                    saldo.depositosP     +=  lan.valor;
                }
                if(lan.situa == 'F'  && lan.voucher == 'N'){
                    saldo.depositosF     +=  lan.valor;
                }
                if(lan.situa == 'F'  && lan.reinvestimento == 'S'){
                    saldo.reinvestimento     +=  lan.valor;
                }
                if(lan.situa == 'F'  && lan.inopay == 'S'){
                    saldo.inopay     +=  lan.valor;
                }
                if(lan.situa == 'N' && lan.voucher == 'S'){
                    saldo.voucher     +=  lan.valor;
                    
                    saldo['voucher'+lan.rendimento]  +=  lan.valor;
                }

            break;
            case 5://indicacao direta
                if(lan.situa == 'N'){
                    saldo.saldo         +=  lan.valor;
                    saldo.indicacao     +=  lan.valor;
                    saldo.ganhos        +=  lan.valor;
                }
            break;
            case 33://indicacao indireta
                if(lan.situa == 'N'){
                    saldo.saldo         +=  lan.valor;
                    saldo.indicacaoI    +=  lan.valor;
                    saldo.ganhos        +=  lan.valor;
                }
            break;
            
            case 6://binario
                if(lan.situa == 'N'){  
                    saldo.saldo         +=  lan.valor;
                    saldo.binario       +=  lan.valor; 
                    saldo.ganhos        +=  lan.valor;
                }
            break;  
            case 8://taxa saque
                if(lan.situa == 'N'){ 
                    saldo.taxaSaque     +=  lan.valor;
                    saldo.saldo         -=  lan.valor;
                }
            break; 
            case 9://rendimento
                if(lan.situa == 'N'){ 
                    if(time<parseInt(lan.disponivel)){
                        saldo.saldoP         +=  lan.valor;
                        saldo.rendimentoP     +=  lan.valor;
                    }else{
                        saldo.saldo         +=  lan.valor;
                        saldo.rendimento     +=  lan.valor;
                    }  
                    saldo.ganhos        +=  lan.valor;
                }
            break;
            case 34://residual
                if(lan.situa == 'N'){
                    saldo.saldo         +=  lan.valor;
                    saldo.residual      +=  lan.valor;
                    saldo.ganhos        +=  lan.valor;
                } 
            break;
            case 7://credito extra
                if(lan.situa == 'N'){ 
                    saldo.salExtra       +=  lan.valor;
                    saldo.creExtra       +=  lan.valor;
                }
            break; 
            case 31://debito extra
                if(lan.situa == 'N'){ 
                    saldo.salExtra       -=  lan.valor;
                    saldo.debExtra       +=  lan.valor;
                }
                if(lan.situa == 'P'){ 
                    saldo.salExtra       -=  lan.valor;
                    saldo.debExtra       +=  lan.valor;
                    saldo.salExtraP      +=  lan.valor;
                }
            break; 
            case 32://taxa reembolso
                if(lan.situa == 'N'){ 
                    saldo.salExtra       -=  lan.valor;
                    saldo.taxaReembolso  +=  lan.valor;
                }
            break; 
        }
    });
    return saldo;
}
ADM.dataTempo=function(ms){ 
    var send = {
        n:'',
        d:0,
        h:0,
        m:0,
        s:0
    };
    send.s = ms;
    send.d = send.s/(24*60*60*1000);
    send.s = send.s%(24*60*60*1000);
    
    send.h = send.s/(60*60*1000);
    send.s = send.s%(60*60*1000);

    send.m = send.s/(60*1000);
    send.s = send.s%(60*1000);

    send.n = (send.d>=1)? (send.d).toFixed(0)+((send.d.toFixed(0)>=1)? 'dia ':'dias '):'';
    send.n += (send.h>=1)? send.h.toFixed(0)+'h ':'';
    send.n += (send.m>=1)? send.m.toFixed(0)+'min ':''; 
    send.n += (send.d>1 || send.h>1)? '' : (send.s*0.001).toFixed(0)+'s';  
    return send;
}

ADM.valorView=function(valor,max =6){
    var send = '0,00';
    if(valor<0.001){
        send = valor.toFixed((max>=6)?6:max).toString().replace('.',',')
    }else if(valor<0){
        send = valor.toFixed((max>=4)?4:max).toString().replace('.',',')
    }else if(valor<1000){
        send = valor.toFixed((max>=2)?2:max).toString().replace('.',',')
    }else if(valor<10000){
        send = valor.toFixed((max>=1)?1:max).toString().replace('.',',')
    }else{
        send = valor.toFixed((max>=0)?0:max).toString().replace('.',',')
    }
    return send;
}   
export default ADM;