import { io }   from "socket.io-client"; 
import {Buffer} from 'buffer'; 
import zlib from 'react-zlib-js'; 
import { json } from "react-router-dom";
import axios from 'axios';

var ADDH={};
// function getHistorico(){ 
//     axios.get('https://inovatyon.app:8000/history')
//         .then(function (response) {
//             // handle success
//             if(DADOS.geral == null){
//                 DADOS.geral = {};
//             } 
//             if(typeof DADOS.geral.historico == 'undefined'){
//                 DADOS.geral.historico = [];
//             } 
//             var bufferR = new Buffer.from(response.data, 'base64');
//             zlib.unzip(bufferR, function(err, bufferD) {
//                 if (!err) {
//                     try { 
                     
//                         var sendD = JSON.parse(bufferD.toString())  
//                         if(sendD.length>0){ 
//                             sendD.forEach((jg)=>{
//                                 ADDH[jg.id] = jg;   
//                             })
//                             DADOS.geral.historico = Object.values(ADDH);
//                             DADOS.geral.historico = DADOS.geral.historico.sort((a,b)=> { 
//                                 return a.dataI > b.dataI ? -1 : a.dataI < b.dataI ? 1 : 0; 
//                             })
//                         } 
//                     } catch (error) { 
//                     } 
//                 }
//             }); 
//             console.log(response);
//         })
//         .catch(function (error) {
//             // handle error
//             console.log(error);
//         })
//         .finally(function ( ) { 
//             // always executed
//         });
// }
// getHistorico();
let     DADOS       =   {};  
DADOS.empresa       =   "inovatyon";  
DADOS.focusG        =   {};
DADOS.sessao        =   "inovatyonpainel";
DADOS.server        =   "inovatyon.com:5080";   
DADOS.geral         =   null;
DADOS.painel        =   null; 
DADOS.taxa          =   0.935; 

DADOS.meses         =   ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];
DADOS.siglaMeses    =   ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'];

DADOS.viewPlanos = null;

DADOS.calcPadrao    =   {};
DADOS.openBanca     =   ()=>{};
DADOS.audioAtivo = false;
DADOS.paypalP = 'ASlBiswi1fHZ9H10dySvLHKMzWeSEoXNV2SxpXkgvvwFRiHrno_dBZHg4spNLqNrfNm-HSTUpfaL1ogD';
DADOS.paypalS = 'EPqqph4BSrKRZ4SnXfn9m4i5SODnvyq1QjQS95SYYW01rbbhGh4DragfKR6E1XQhn66Kq0Xvy67g6OKh';
DADOS.audios ={}

DADOS.laodAudios =()=>{
    DADOS.audios.maisede2     =   new Audio('/assets/audio/maisde2.mp3');
    DADOS.audios.maisede5     =   new Audio('/assets/audio/maisde5.mp3');
    DADOS.audios.maisede10    =   new Audio('/assets/audio/maisde10.mp3');
    DADOS.audios.maisede15    =   new Audio('/assets/audio/maisde30.mp3');
    DADOS.audios.maisede30    =   new Audio('/assets/audio/maisde15.mp3'); 
    DADOS.audios.Heal4        =   new Audio('/assets/audio/Heal4.mp3'); 
    DADOS.audios.Confirm      =   new Audio('/assets/audio/Confirm.mp3'); 
    DADOS.audios.Cancel1      =   new Audio('/assets/audio/Cancel1.mp3'); 
    DADOS.audios.Save         =   new Audio('/assets/audio/Save.mp3'); 
}
DADOS.validarN= (valor)=>{
    if(isNaN(valor)){
        return 0;
    }else{
        return valor;
    }
}
DADOS.parseValor=( num,decimal=2)=>{
    var numN = {
        p:0,
        d:0,
        f:0, 
        fv:0
    } 
    if(num>=0){  
        var valor = parseFloat(num).toFixed((decimal+2)).toString(); 
        valor     =   valor.substring(0, valor.length - 2);  
        var pos   =   parseFloat(valor).toString().split('.'); 
        numN.p    = pos[0].replace(/(.)(?=(\d{3})+$)/g,'$1.'); 
        numN.d    = (typeof pos[1] =='undefined'?0:pos[1]);
        numN.f    =  numN.p+','+numN.d 
        numN.fv    =  numN.p+'.'+numN.d 
        return numN;
    }else{
      return numN;
    }  
}
DADOS.filtrarJogos=(jogos)=>{
    var send = [];
    var add = true;  
    var f = DADOS.painel.fSite;
    //filtro casa  
    try {
        if(DADOS.painel.ativoSite == 'S' ){ 
            jogos.forEach((jogo)=>{
                add = true; 
                if(jogo.odds[0].site !== 'BLOCK'){ 
                    add =false;
                    if(f[jogo.odds[0].site].casas[jogo.odds[1].site] && f[jogo.odds[1].site].casas[jogo.odds[0].site]){
                    // if((f[jogo.odds[0].site].ativa || f[jogo.odds[0].site].casas[jogo.odds[].site]) || (f[jogo.odds[1].site].ativa || f[jogo.odds[1].site].casas[jogo.odds[0].site]) ){
                        add =true;
                    }  
                    if(add){
                        if(f[jogo.odds[0].site].esportes[jogo.esporte.toLowerCase()] && f[jogo.odds[1].site].esportes[jogo.esporte.toLowerCase()]){
                            // if((f[jogo.odds[0].site].ativa || f[jogo.odds[0].site].casas[jogo.odds[].site]) || (f[jogo.odds[1].site].ativa || f[jogo.odds[1].site].casas[jogo.odds[0].site]) ){
                                add =true;
                        }else{
                            add =false;
                        } 
                    }
                    //filtro lucro
                    if(add){
                        //VERIFICAR FILTRO MENOR E MAOIR
                        var pMin =f[jogo.odds[0].site].pMin;
                        if(f[jogo.odds[1].site].pMin<pMin){
                            pMin = f[jogo.odds[1].site].pMin;
                        }
                        var pMax =f[jogo.odds[0].site].pMax;
                        if(f[jogo.odds[1].site].pMax>pMax){
                            pMax = f[jogo.odds[1].site].pMax;
                        } 
                        if(pMax == 100){
                            add = false;
                            if(jogo.lucroP>=pMin){
                                add = true
                            } 
                        }else{
                            add = false;
                            if(jogo.lucroP>=pMin && jogo.lucroP<pMax){
                                add = true
                            } 
                        }  
                    }
                    if(add && jogo.data!='BLOCK'){
                        //VERIFICAR FILTRO MENOR E MAOIR tempo
                        var tMin =f[jogo.odds[0].site].tMin; 
                        if(f[jogo.odds[1].site].tMin<tMin){
                            tMin = f[jogo.odds[1].site].tMin;
                        }
                        
                        var tMax =f[jogo.odds[0].site].tMax;
                        if(f[jogo.odds[1].site].tMax>tMax){
                            tMax = f[jogo.odds[1].site].tMax;
                        }
                        tMin =new Date().getTime()+(tMin*60*60000)
                        if(tMax!=120){
                            tMax =new Date().getTime()+(tMax*60*60000)
                            add = false;
                            if(jogo.data>=tMin && jogo.data<tMax){
                                add = true
                            } 
                        }else{ 
                            add = false;
                            if(jogo.data>=tMin ){
                                add = true
                            } 
                        }
                        
                    } 
                } 
                if(add){
                    send.push(jogo)
                }
            })
        }else{
            send = jogos;
        } 
    } catch (error) {
        send = jogos;
    }
    
    return send;
}
DADOS.tempAlerta = {};
DADOS.gerarAlertas =()=>{
    var jogos = [];
    if(DADOS.geral!=null && typeof DADOS.painel.usuario != 'undefined' ){
        if(typeof DADOS.geral.jogos !=='undefined'){
            jogos = DADOS.filtrarJogos(DADOS.geral.jogos); 
        }
    } 

    var alerta={
        lucro   :   0,
        sair    :   '',
        time    :   new Date().getTime()
    } 
    if(DADOS.audioAtivo){  
        jogos.forEach((jogo)=>{
            if(typeof DADOS.tempAlerta[jogo.id] == "undefined"){
                DADOS.tempAlerta[jogo.id]   =   {}; 
                if(jogo.lucroP>alerta.lucro){
                    alerta.lucro=jogo.lucroP;
                }
            }else{
                if(DADOS.tempAlerta[jogo.id].lucroP< jogo.lucroP){ 
                    DADOS.audios.Heal4.play(); 
                }else if(DADOS.tempAlerta[jogo.id].lucroP> jogo.lucroP){ 
                    DADOS.audios.Confirm.play(); 
                } 
            }
            DADOS.tempAlerta[jogo.id].lucroP    =   jogo.lucroP;
            DADOS.tempAlerta[jogo.id].up        =   alerta.time;
        }) 
        if(alerta.lucro>30){
            DADOS.audios.maisede30.play(); 
        }else if(alerta.lucro>15){
            DADOS.audios.maisede15.play(); 
        }else if(alerta.lucro>10){
            DADOS.audios.maisede10.play(); 
        }else if(alerta.lucro>5){
            DADOS.audios.maisede5.play();
        }else if(alerta.lucro>0.5){
            DADOS.audios.maisede2.play();
        }   
        //verifica se saiu jogo
        Object.keys(DADOS.tempAlerta).forEach((id)=>{
            if(DADOS.tempAlerta[id].up!== alerta.time){ 
                delete DADOS.tempAlerta[id];
                DADOS.audios.Cancel1.play(); 
            }
        })   
    }else{
        // console.log("focar tela");
    } 
} 
DADOS.TtempAlerta = null;
DADOS.processarAlertas =()=>{
    document.querySelector("html").addEventListener('click', () => {
        
        DADOS.audioAtivo = true;
    });
  
    DADOS.gerarAlertas();
    clearTimeout(DADOS.TtempAlerta);
    DADOS.TtempAlerta = setInterval(()=>{
        DADOS.gerarAlertas();
    },1000)
}

DADOS.idioma        =   {
    id : 'ptBR'
} 
DADOS.nivel = 0; 
DADOS.socket = null;
DADOS.conectarNode =(callback)=>{
    if(DADOS.socket == null){
        DADOS.socket    =   new io("wss://"+DADOS.server,{withCredentials: true});  
        DADOS.socket.on("connect", () => {  
            DADOS.conectado=true;  
          
            DADOS.autologin(callback); 
            // console.log('conectado')  
        }); 
        DADOS.socket.on("disconnect", () => {
            // console.log('desconectado')  
            DADOS.conectado =false;
        });
    }
     
}
DADOS.desconectarNode =()=>{ 
    if(DADOS.socket != null){ 
        DADOS.socket.close();
        DADOS.socket =null;
        DADOS.conectado = false;
        clearInterval(DADOS.loopGeral);
    }
     
}
DADOS.sendForm=(sendForm,callback)=>{ 
    sendForm.form.idioma = DADOS.idioma.id; 
    DADOS.socket.removeAllListeners("formDados").on("formDados",(dados)=>{    
        if(dados.modulo == sendForm.modulo && dados.cmd == sendForm.cmd){
            callback(dados.result)
        }   
    });  
    DADOS.socket.emit("formDados",sendForm); 
};  
DADOS.getDados=()=>{  
    DADOS.socket.removeAllListeners("dadosGeral").on("dadosGeral",(dados)=>{    
        if(DADOS.geral == null){
            DADOS.geral = {};
        }   
        // var bufferR = new Buffer.from(dados, 'base64');
        // zlib.unzip(bufferR, function(err, bufferD) {
        //     if (!err) {
        //         try {
        //             dados = JSON.parse(bufferD.toString())  
                    Object.keys(dados).forEach((campo)=>{
                        switch (campo) {
                            case 'usuario': 
                                DADOS.painel        = dados[campo]; 
                                DADOS.geral[campo]  = dados[campo];
                            break;  
                            default: 
                                DADOS.geral[campo]  = dados[campo];
                            break;
                        }  
                    }); 
        //         } catch (error) {
                    
        //         } 
        //     }
        // });   
    }).emit("dadosGeral"); 
    // DADOS.socket.removeAllListeners("dadosHistorico").on("dadosHistorico",(dados)=>{  
    //     if(DADOS.geral == null){
    //         DADOS.geral = {};
    //     } 
    //     if(typeof DADOS.geral.historico == 'undefined'){
    //         DADOS.geral.historico = [];
    //     }
    //     var bufferR = new Buffer.from(dados.d, 'base64');
    //     zlib.unzip(bufferR, function(err, bufferD) {
    //         if (!err) {
    //             try { 
    //                 var sendD = JSON.parse(bufferD.toString())  
    //                 if(sendD.length>0){ 
    //                     sendD.forEach((jg)=>{
    //                         ADDH[jg.id] = jg;   
    //                     })
    //                     DADOS.geral.historico = Object.values(ADDH);
    //                     DADOS.geral.historico = DADOS.geral.historico.sort((a,b)=> { 
    //                         return a.dataI > b.dataI ? -1 : a.dataI < b.dataI ? 1 : 0; 
    //                     })
    //                 } 
    //             } catch (error) { 
    //             } 
    //         }
    //     });   
    // }) 
};
DADOS.deletarHistorico=(bot)=>{
    var temp =[]; 
    DADOS.geral.historico.forEach((jg)=>{

        if(bot!=jg.botId){
            temp.push(jg);
        }else{
            // console.log(bot,jg.botId)
        }
    });
    DADOS.geral.historico = temp;
}; 
DADOS.historico={}; 
DADOS.autologin=(callback)=>{  
    if (typeof localStorage !== 'undefined') { 
        DADOS.token     =   localStorage.getItem('skt'+DADOS.sessao);  
        if(window.location.hostname == 'localhost'){
            // DADOS.token     =  '33d2fcb694fade871e7f778395756923';
        }
        
        // DADOS.token     = 'e42399cd51c807445cc4a82daf5eef7a';
        
    }      
    if(DADOS.token == 'null' || DADOS.token == null ||  typeof DADOS.token =='undefined'){
        DADOS.painel    =   {};  
        callback();
    }else{   
        DADOS.socket.removeAllListeners('autologin').on('autologin',(dados)=>{   
            if(typeof window !== "undefined"){ 
                DADOS.token = (typeof dados.hash!=="undefined")?dados.hash:null;   
                DADOS.painel = (typeof dados.usu!=="undefined")?dados.usu:{}; 
            }    
            callback();
        }).emit("autologin", DADOS.token); 
        // DADOS.socket.removeAllListeners('infoHistorico').on('infoHistorico',(dados)=>{     
        //     var bufferR = new Buffer.from(dados, 'base64');
        //     zlib.unzip(bufferR, function(err, bufferD) {
        //         if (!err) {
        //             try { 
        //                 var sendD = JSON.parse(bufferD.toString());
        //                 if(Object.keys(sendD).length>0){ 
        //                     Object.keys(sendD).forEach((key)=>{
        //                         DADOS.historico[key] = sendD[key]; 
        //                     }) 
        //                 } 
        //             } catch (error) { 
        //             } 
        //         }
        //     });    
        //     // DADOS.historico[dados.id] = dados.info; 
        // })
    }   

    if(DADOS.loopGeral == null){
        clearInterval(DADOS.loopGeral);
        DADOS.loopGeral = setInterval(()=>{
            DADOS.socket.emit("dadosGeral");  
            // DADOS.socket.emit("dadosHistorico"); 
            // DADOS.autologin() 
            // getHistorico();
        },3000);
        // getHistorico();
        // DADOS.socket.emit("dadosHistorico"); 
       
    } 
    DADOS.getDados()
}
DADOS.dataTempo=function(ms){ 
    var send = {
        n:'',
        d:0,
        h:0,
        m:0,
        s:0
    };
    send.s = ms;
    send.d = send.s/(24*60*60*1000);
    send.s = send.s%(24*60*60*1000);
    
    send.h = send.s/(60*60*1000);
    send.s = send.s%(60*60*1000);

    send.m = send.s/(60*1000);
    send.s = send.s%(60*1000);

    send.n = (send.d>=1)? (send.d).toFixed(0)+((send.d.toFixed(0)>=1)? 'dia ':'dias '):'';
    send.n += (send.h>=1)? send.h.toFixed(0)+'h ':'';
    send.n += (send.m>=1)? send.m.toFixed(0)+'min ':''; 
    send.n += (send.d>1 || send.h>1)? '' : (send.s*0.001).toFixed(0)+'s';  
    return send;
}

DADOS.valorView=function(valor,max =6){
    var send = '0,00';
    if(valor<0.001){
        send = valor.toFixed((max>=6)?6:max).toString().replace('.',',')
    }else if(valor<0){
        send = valor.toFixed((max>=4)?4:max).toString().replace('.',',')
    }else if(valor<1000){
        send = valor.toFixed((max>=2)?2:max).toString().replace('.',',')
    }else if(valor<10000){
        send = valor.toFixed((max>=1)?1:max).toString().replace('.',',')
    }else{
        send = valor.toFixed((max>=0)?0:max).toString().replace('.',',')
    }
    return send;
}   
export default DADOS;