import * as React from "react" 
import { Center, Icon,  InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react"; 
import GradientBorder from "../../components/VisionUI/GradientBorder";
import {
    Box,
    Button, 
    Flex,
    FormControl,
    FormLabel, 
    Image,
    Input, 
    Text,
} from "@chakra-ui/react";
import  { FaCcPaypal, FaExchangeAlt, FaEye, FaEyeSlash, FaKey, FaKeybase, FaRegEye, FaRegEyeSlash, FaSignInAlt, FaStreetView, FaUser, FaUserAlt } from "react-icons/fa";
import  { NavLink, Navigate, Router } from "react-router-dom";
import  G from '../../components/WebSocket';
import  InputMask  from 'react-input-mask';  
import  BoxWindow from "../../components/BoxWindow/BoxWindow";

class PageLogin extends React.Component {
    open = null;
    viewLogin = null
    render() { 
        const titleColor = "white";
        const textColor = "tema1.500";
        const illustrationBackground = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
        return (
            <Flex position='relative'>
                <Flex
                    minH='100vh'
                    h={{ base: "100vh", lg: "100vh" }}
                    w='full' 
                    align={'center'} 
                    justify={'center'}  
                    flexDirection='column'  >
                        <Center>
                            <GradientBorder  
                                borderRadius={'20px'}
                                boxShadow={'lg'}
                                bg='radial-gradient(69.43% 69.43% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.1) 100%),
                                    radial-gradient(60% 51.57% at 50% 50%, #FFFFFF 0%, rgba(88, 44, 255, 0.05) 100%),
                                    radial-gradient(54.8% 53% at 50% 50%, #FFFFFF 0%, rgba(21, 21, 21, 0) 100%)'
                                p='2px' me={{ base: "none", lg: "0px", xl: "none" }}>
                                <Box pos={'relative'} bg='rgba(255,255,255,0.3)' borderRadius={'20px'} as={'form'}  onSubmit={(e)=>{
                                    e.preventDefault();  
                                    const formdata = new FormData(e.target);
                                    const json = {};  
                                    formdata.forEach(function(value, prop){ 
                                        json[prop] = value;
                                    });    
                                        G.socket.removeAllListeners('login').on('login',(response)=>{  
                                            // console.log(response);
                                            if(response.dfa){
                                                alert('abrir 2fa')
                                            //   this.code = <DoisFatores cmdFechar = {()=>{
                                            //       this.code = null; 
                                            //       this.setState({});
                                            //     }}
                                            //     cmdSend = {(codigo)=>{
                                            //       json.codigo = codigo;
                                            //       this.sendForm(json);
                                            //       this.atencao = <BoxWindow   config    =   { {tamanho:"Alerta"} } titulo = "Aguarde" conteudo = "Enviando dados ao servidor..." comando = { {tipo:"ok"} }/>;
                                            //       this.setState({});
                                            //     }}
                                            //   /> 
                                            //   this.atencao = null;
                                            //   this.setState({});
                                            }else if(response.erro==0){  
                                                this.atencao = null;
                                                G.token = response.hash; 
                                                G.painel = response.usu;  
                                                localStorage.setItem('skt'+G.sessao,G.token); 
                                                G.getDados();
                                           
                                            }else{   
                                                
                                            //       this.code = <BoxCode idioma={G.idioma.id} form={json} time = {response.hash.time} titulo={G.idioma.bxDigite} 
                                            //         sendForm = {(codigo)=>{  
                                            //           if(codigo!==''){
                                            //             var send =  {
                                            //               chave   : '',
                                            //               email   : 'S',
                                            //               resend  : 'S'
                                            //             };
                                            //             Object.keys(json).forEach((campo)=>{
                                            //               send[campo] = json[campo];
                                            //             })
                                            //             if(typeof codigo!== "undefined"){
                                            //               send.chave= codigo;
                                            //             } 
                                            //             this.code=null;
                                            //             this.setState({});
                                            //             this.sendForm(send);
                                            //           }else{
                                            //             this.atencao = <BoxWindow   config    =   {{tamanho:"Alerta"}} titulo = {((typeof response.titulo =="undefined")?'Alert':response.titulo)} conteudo = "Digite a chave de ativação" comando = { {tipo:"ok"} }/>;
                                            //             this.setState({});
                                            //           }
                                                      
                                            //         }} 
                                            //         fecharCMD={()=>{
                                            //             this.code=null;
                                            //             this.setState({});
                                            //         }} 
                                            //       />
                                            //     }
                                            //     if(response.erro){ 
                                            //       this.atencao = <BoxWindow   config    =   {{tamanho:"Alerta"}} titulo = {((typeof response.titulo =="undefined")?'Alert':response.titulo)} conteudo = {response.mensagem} comando = { {tipo:"ok"} }/>;
                                            //     }
                                            //     this.setState({});
                                                if(response.hash!== null && response.hash.cmd ==='email'){
                                                    alert('resend email');
                                                    this.atencao = null;
                                                }else{
                                                    this.atencao = <BoxWindow titulo = {response.titulo} mensagem={response.mensagem} onClose={()=>{
                                                        this.atencao = null;
                                                        this.setState({});
                                                    }}/>
                                                } 
                                            } 
                                            this.setState({}); 
                                          })
                                          G.socket.emit("login", json);
                                    // G.sendForm({modulo:'usuario',cmd:'login',form:json},(result)=>{
                                    //     if(!result.erro){
                                           
                                    //     } 
                                       
                                        
                                    // })
                                    this.atencao = <BoxWindow load = {true}/>
                                    this.setState({});
                                }} >
                                
                                        <Flex   zIndex={'2'}   h={'100%'}  style={{ userSelect: "none" }}  mx={{ base: "auto", lg: "unset" }}  w={{ base: "340px", sm: "400px", lg: "450px" }}  p={{ base: "20px", sm: "40px", lg: "50px" }}>
                                            
                                            <Flex direction='column' w='100%' >

                                                <Center>
                                                    <Image w={{ lg: '300px', xl: '370px', '2xl': '440px' }} objectFit='fill' src='/assets/img/logo.png' alt='Logo inovatyon' />
                                                </Center>  
                                                <FormControl>
                                                    <FormLabel ms='4px' fontSize='sm' fontWeight='500' color='tema1.200'>
                                                        E-mail
                                                    </FormLabel>
                                                    <GradientBorder  mb='10px' w={{ base: "100%"}} borderRadius='20px'>
                                                        <InputGroup size='lg'>
                                                            <InputLeftElement width='3rem'>
                                                                <Icon as={FaUser} boxSize={5}  color="green.500"/> 
                                                            </InputLeftElement>
                                                            <Input
                                                                pl='3rem'
                                                                as={InputMask}
                                                                mask={'####################################################################################################'}  
                                                                formatChars= {{'#': '[A-Za-z0-9@._-]'}}  
                                                                maskChar= ''
                                                                alwaysShowMask= {true}

                                                                placeholder="Seu e-mail ou login"
                                                                bg={'tema1.200'}
                                                                border={0}
                                                                name="login"
                                                                color={'tema1.100'}
                                                                _placeholder={{
                                                                    color: 'gray.300',
                                                                }}
                                                            /> 
                                                        </InputGroup>
                                                    </GradientBorder>
                                                </FormControl>
                                                <FormControl >
                                                    <FormLabel ms='4px' fontSize='sm' fontWeight='500' color='tema1.200'>
                                                        Senha
                                                    </FormLabel>
                                                    <GradientBorder  mb='20px' w={{ base: "100%"}} borderRadius='20px'>
                                                        <InputGroup size='lg'>
                                                            <InputLeftElement width='3rem'>
                                                                <Icon    as={FaKey} boxSize={5}    color={'green.500'}/> 
                                                            </InputLeftElement> 
                                                            <Input
                                                                pl='3rem'
                                                                pr='3rem'
                                                                type={this.show1 ? 'text' : 'password'}
                                                                placeholder="Sua senha"
                                                                bg={'tema1.200'}
                                                                border={0}
                                                                name="senha"
                                                                color={'tema1.100'}
                                                                _placeholder={{
                                                                    color: 'gray.300',
                                                                }}
                                                            />
                                                            <InputRightElement width='3rem'>
                                                                <Button h='1.75rem' size='md' onClick={()=>{
                                                                    if(this.show1){
                                                                        this.show1 =false;    
                                                                    }else{
                                                                        this.show1 =true
                                                                    } 
                                                                    this.setState({})
                                                                }}>
                                                                {this.show1 ?  <Icon  fontSize={'22px'}  as={FaEyeSlash}/> : <Icon fontSize={'22px'}  as={FaEye}/> }
                                                                </Button>
                                                            </InputRightElement>
                                                        </InputGroup> 
                                                    </GradientBorder>
                                                </FormControl>
                                                <Flex
                                                    flexDirection='column'
                                                    justifyContent='center'
                                                    alignItems='center'
                                                    maxW='100%'
                                                    mb='20px'>
                                                    <Text color={textColor} fontWeight='medium'> 
                                                        <NavLink to={'/lost'} >
                                                            <Text as='span' color={'tema1.200'} ms='5px' fontWeight='bold'>
                                                            Esqueci minha senha!
                                                            </Text>
                                                        </NavLink>
                                                    </Text>
                                                </Flex>
                                                <Flex
                                                    flexDirection='column'
                                                    justifyContent='center'
                                                    alignItems='center'
                                                    maxW='100%'
                                                    mb='20px'>
                                                        <Button
                                                        colorScheme='blue'
                                                        fontSize='14px'
                                                        type='submit'
                                                        bg={'green.500'}
                                                        leftIcon={<FaSignInAlt />}
                                                        w='fit-content'
                                                        size='lg'
                                                        mt='0px'
                                                        _hover={{
                                                            bg: 'tema1.300',
                                                            boxShadow: 'xl',
                                                            color:'green.500'
                                                        }} 
                                                        mb='0px'>
                                                        Fazer Login
                                                    </Button> 
                                                </Flex>
                                               
                                                <Flex
                                                    flexDirection='column'
                                                    justifyContent='center'
                                                    alignItems='center'
                                                    maxW='100%'
                                                    mb='30px'>
                                                    <Text color={textColor} fontWeight='medium'>
                                                        Não tem uma conta?
                                                        <NavLink to={'/register'} >
                                                            <Text as='span' color={'tema1.200'} ms='5px' fontWeight='bold'>
                                                            Cadastre-se
                                                            </Text>
                                                        </NavLink>
                                                    </Text>
                                                </Flex>
                                                {/* <Center>
                                                    <Image w={{ lg: '120px', xl: '120px', '2xl': '144px' }} opacity={0.4} objectFit='fill' src='/assets/img/logobf.png' alt='Logo inovatyon' />
                                                </Center> */}
                                            </Flex>   
                                        </Flex>  
                                
                                </Box> 
                            </GradientBorder>
                        
                        </Center>    
                        <Box pos={'relative'}  p='10px 0'     >
                            <Text as='span' color={'tema1.200'} ms='5px'  fontSize={{base:'10px',sm:'12px'}} >
                                © 2023 - 2024 Inovatyon Tech Labs. Todos os Direitos Reservados.
                            </Text>
                        </Box>
                           
                </Flex>
                {this.atencao}
            </Flex>
        );
    }
}
export default PageLogin;