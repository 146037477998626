import * as React from "react"  
import {
    Avatar,
    Box,
    Button,
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    Icon,
    Image,
    List,
    ListIcon,
    ListItem,
    Spacer,
    Stack,
    Switch,
    Text,
    Tooltip, 
  } from '@chakra-ui/react';
import { FaCheckCircle, FaCopy, FaHistory, FaPeace, FaPen, FaPenAlt, FaPlusSquare, FaPowerOff, FaRegStar, FaTimes, FaTrashAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { RiFolderHistoryFill } from 'react-icons/ri'; 

import G  from '../../components/WebSocket'
import BoxWindow from "../../components/BoxWindow/BoxWindow"; 
import { FaUsb } from "react-icons/fa6";
class ListaFinanceira extends React.Component { 
    viewPedidos = null
    robos       = {};
    tempLanc    = null;
    grupo       =   'C';
    ordem       =   'ASC';
    sorte       =   'T';
    lancamentos =   [];
    rede        =   [];
    tipos       =   {};
    dados  =   [];
    db= {
        saldos:{}
    }
    load        = false;
    buscarJogos(){
        var relaod = false; 
        
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.saldos!=='undefined' && this.tempSaldos != JSON.stringify(G.geral.saldos)){
     
            this.tempSaldos = JSON.stringify(G.geral.saldos); 
            this.db.saldos = G.geral.saldos; 
         
            relaod = true;
        } 
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.graduacao!=='undefined' && this.tempGrad != JSON.stringify(G.geral.graduacao)){
            // console.log(G.geral.graduacao);
            this.tempGrad = JSON.stringify(G.geral.graduacao);   
            relaod = true;
        } 
        if(G.geral!= null && (typeof G.geral.rede != 'undefined' && this.tempLanc != JSON.stringify(G.geral.rede))){ 
            this.tempLanc       =   JSON.stringify(G.geral.rede); 
            this.rede =  G.geral.rede; 
            this.load = true; 
 
            this.load =true;
            relaod = true;
        } 
        if(relaod){
            this.setState({});
        }   
    }
    cDelay(){
        clearTimeout(this.tempD);
        this.tempD = setTimeout(()=>{
            this.setState({});
        },500);
    }
    pag = {
        min:0,
        max:10,
        n:10,
        aba:0,
        p:0
    }
    statusB={}; 
    processarExtrato(){
        var bgT = 'linear-gradient(180deg,rgba(25, 31, 50,0.6) 17.33%,rgba(25, 31, 50,0))'; 
        bgT = 'transparent'; 
        var view =  <Text align={'center'}  py='10px'  px={{base:'0px',md:'20px'}}  justify='center'  color={'tema1.400'}  opacity={'0.5'} fontSize={'xs'}   textAlign={{base:'center'}}>  Nenhum registro no momento  </Text>
        this.lancamentos    = []; 
        if(typeof this.rede[(G.nivel+1)] != 'undefined'){  
            Object.values(this.rede[(G.nivel+1)]).forEach((lanc)=>{
                this.lancamentos.push(lanc);
            }); 
            
        } 
        if(this.lancamentos.length>0){
            view = []
            var dados     = this.lancamentos; 
            this.pag.p    = parseInt((dados.length/this.pag.n).toString().split('.')[0]);
            if((dados.length%this.pag.n)){
              this.pag.p++;
            }
            var viewPag   =  [];
            var add = true;
            for(var n=0;n<this.pag.p;n++){ 
              if(this.pag.p>5){
                add = false;
                if(n==0 || n == (this.pag.p-1)){
                  add=true;
                }else if(this.pag.aba==0 || this.pag.aba==1 || this.pag.aba==2){
                  if(n == 1 || n == 2|| n == 3){
                    add=true; 
                  }
                }else if( this.pag.aba == (this.pag.p-3) || this.pag.aba == (this.pag.p-2)|| this.pag.aba == (this.pag.p-1)){ 
                    if(n == (this.pag.p-4) || n == (this.pag.p-3)|| n == (this.pag.p-2)){
                      add=true; 
                    }   
                }else if(n==this.pag.aba-1|| n==this.pag.aba || n==this.pag.aba+1){
                  add=true;   
                } 
              } 
              if(add){
                const pos = n; 
                viewPag.push(<Button onClick={()=>{ 
                  this.pag.aba = pos;
                  this.setState({});
                }} key={'pag'+pos}  colorScheme={this.pag.aba==pos?'green':'gray'} fontSize={'small'} p={0} h='24px' minW='24px'>{pos+1}</Button>)
              } 
            } 
            this.pag.min=(this.pag.n*this.pag.aba);
            this.pag.max=(this.pag.n*this.pag.aba)+this.pag.n; 
          
            
            this.lancamentos.sort((a, b)=>{  
                return a.criacao > b.criacao ? -1 : a.criacao < b.criacao ? 1 : 0; 
            }).forEach((dado,i)=>{  
                if(i>=this.pag.min && i<this.pag.max){ 
                    view.push(
                       <Flex key={'bot'+dado.id} w={'100%'}>  
                            <Grid  display={{base:'none',sm:'grid'}} borderBottom={'1px solid'}    borderColor={'tema1.500'}   bg={{base:bgT   ,md:'transparent'}}  w='full' py='0px' px={'10px'} pb={'0px'} fontSize={'sm'} color={'green.500'} align='left'   templateColumns= {{base :'repeat(32, 1fr)'}} gap={0}>
                         
                                <GridItem  direction={'row'}  w={'full'} py='0px' colSpan={22} h='auto'    bg='transparent'>
                                    <Flex direction={'row'} gap={'20px'}>
                                        <Stack direction={'column'}  align={'center'} justify={'center'}  w={'auto'} > 
                                            <Avatar color={"tema1.300"} my={'0px'} overflow={'hidden'} pt='2px' icon={<FaUser size={'30px'} />} size='sm' bg={"green.500"} /> 
                                        </Stack>
                                        <Stack direction={'column'}  align={'center'} justify={'center'}  w={'100%'} pb='10px'> 
                                            <Text as={'b'}   w={'full'}   noOfLines={1} color={'tema1.100'}>{dado.nome}</Text> 
                                            <Stack direction={'row'}  align={'center'} justify={'left'}  w={'100%'}  > 
                                                <Text as={'b'}     noOfLines={1} color={'tema1.100'}>{dado.usuario}</Text>
                                                <Text as={'b'}     noOfLines={1}>{dado.email}</Text> 
                                            </Stack>
                                        </Stack>
                                    </Flex>
                                    
                                    
                                </GridItem> 
                                <GridItem b  textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{G.parseValor(dado.saldos.depositos,1).f} USDT</Text> 
                                </GridItem> 
                                <GridItem  textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                                    <Text as={'b'}   w={'full'}   noOfLines={1}>{new Date(dado.criacao).toISOString().split('T')[0]}</Text> 
                                </GridItem> 
                                <GridItem      w={'full'} p='5px 10px' colSpan={2} h='30px'    bg='transparent'>  
                                </GridItem>   
                            </Grid> 
                       </Flex>
                    ); 
                }
            })
            
        } 
      
       
        return (
            <Flex flexDir={'column'}   w={'full'}  minH={'100%'} bg={{base:'transparent',md:bgT}} >
                 {this.pag.p>1?
                  <Stack flexDir={'row'} pos={'absolute'} top='0px' right={'30px'}>
                    {viewPag} 
                  </Stack>
                :null
                } 
                <Grid display={{ base: "grid" }}  borderBottom={'1px solid'}  borderColor={'tema1.500'} mt={'20px'} w='full' py='0px'  px={'10px'} fontSize={'sm'}  color={'tema1.400'} align='left' textAlign='left' templateColumns= {{base :'repeat(32, 1fr)'}} gap={0}>
                    <GridItem   textAlign={'left'}   w={'full'} py='5px' colSpan={22} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>Dados Usuário</Text> 
                    </GridItem> 
                    <GridItem  textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>Depósitos</Text> 
                    </GridItem> 
                    <GridItem  textAlign={'center'}   w={'full'} py='5px' colSpan={4} h='30px'    bg='transparent'>
                        <Text as={'b'}   w={'full'}   noOfLines={1}>Registro</Text> 
                    </GridItem> 
                    <GridItem     w={'full'} p='5px 10px' colSpan={2} h='30px'    bg='transparent'>  
                    </GridItem> 
                </Grid>
                {view}
            </Flex>  
        );
    }
    componentDidMount(){
        this.buscarJogos();
        this.setState({});
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    render(){  
        var viewLista   =   null;  
        viewLista = this.processarExtrato(); 
    
        return ( 
            <Flex pos={'relative'}  flexDir={'column'}   maxW={'100%'} w={'100%'} minH={{base:'80px',md:'140px'}}  mb='30px'   >
                {viewLista}   
                {this.atencao }
            </Flex>   
        )
    }
}
export default ListaFinanceira;