 
import { Center, Grid, Icon, IconButton, InputGroup, InputLeftElement, InputRightElement, Stack } from "@chakra-ui/react"; 
import GradientBorder from "../../components/VisionUI/GradientBorder";
import {
    Box,
    Button, 
    Flex,
    FormControl,
    FormLabel, 
    Image,
    Input, 
    Text,
} from "@chakra-ui/react";
import  {  FaCheck, FaEye, FaEyeSlash, FaKey, FaUser, FaUserLock } from "react-icons/fa";
import  { NavLink, Navigate, useLocation } from "react-router-dom";
import  G from '../../components/WebSocket';
import  InputMask  from 'react-input-mask';  
import  BoxWindow from "../../components/BoxWindow/BoxWindow";
import { useEffect, useState } from "react";
 
const PageLost = function(){
    const url = useLocation(); 
    const [chave,setChave]  =  useState(url.pathname.replace('/lost','').replace(/\//g,'')); 
    const [load,setLoad]    =  useState(false); 
    const [atencao,setAtencao]    =  useState(null); 
    const [valida,setValida]    =  useState(false); 
    const [show,setShow]    =  useState(false); 
    function consultarChave(){
        if(!load){
            G.socket.removeAllListeners('lostChaveConsulta').on('lostChaveConsulta',(response)=>{    
                G.socket.removeAllListeners('lostChaveConsulta');
                setLoad(true);
                if(response.erro){
                    setValida(false);
                }else{
                    setValida(true);
                }
                console.log(response);  
            })
            G.socket.emit("lostChaveConsulta", {chave:chave});
        } 
    }
    function formLost(){
        return (
            <Flex
                    minH='100vh'
                    h={{ base: "100vh", lg: "100vh" }}
                    w='full' 
                    align={'center'} 
                    justify={'center'}  
                    flexDirection='column'  >
                        <Center  >
                            <GradientBorder  
                            borderRadius={'20px'}
                            boxShadow={'lg'}
                            bg='radial-gradient(69.43% 69.43% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.1) 100%),
                                radial-gradient(60% 51.57% at 50% 50%, #FFFFFF 0%, rgba(88, 44, 255, 0.05) 100%),
                                radial-gradient(54.8% 53% at 50% 50%, #FFFFFF 0%, rgba(21, 21, 21, 0) 100%)'
                                    p='2px' me={{ base: "none", lg: "0px", xl: "none" }}>
                                <Box pos={'relative'} bg='rgba(255,255,255,0.3)'   borderRadius={'20px'} as={'form'}  onSubmit={(e)=>{
                                    e.preventDefault();  
                                    const formdata = new FormData(e.target);
                                    const json = {};  
                                    formdata.forEach(function(value, prop){ 
                                        json[prop] = value;
                                    });   
                                    G.socket.removeAllListeners('lost').on('lost',(response)=>{   
                                        G.socket.removeAllListeners('lost');
                                        console.log(response);
                                        setAtencao(<BoxWindow titulo = {response.titulo} mensagem={response.mensagem} onClose={()=>{
                                            if(response.erro){
                                                setAtencao(null);
                                            }else{
                                                setAtencao(<Navigate to={'/login'} />); 
                                            }  
                                        }}/>); 
                                    })
                                    G.socket.emit("lost", json); 
                                    setAtencao(<BoxWindow load = {true}/>); 
                                }} >
                                
                                        <Flex   zIndex={'2'}   h={'100%'}style={{ userSelect: "none" }}  mx={{ base: "auto", lg: "unset" }}  w={{ base: "340px", sm: "400px", lg: "450px" }}  p={{ base: "20px", sm: "40px", lg: "50px" }}>
                                            
                                            <Flex direction='column' w='100%' >
    
                                                <Center>
                                                    <Image w={{ lg: '300px', xl: '370px', '2xl': '440px' }} objectFit='fill' src='/assets/img/logo.png' alt='Logo inovatyon' />
                                                </Center>  
                                                <Text as='span' color={'tema1.300'} p='20px 0 10px 0' ms='0px' fontSize={'sm'} fontWeight={'500'} >
                                                    Informe o e-mail da sua conta para que você possa criar uma nova senha!
                                                </Text>
                                                <FormControl> 
                                                    <GradientBorder  mb='20px' w={{ base: "100%"}} borderRadius='20px'>
                                                        <InputGroup size='lg'>
                                                            <InputLeftElement width='3rem'>
                                                                <Icon as={FaUser} boxSize={5}  color="green.500"/> 
                                                            </InputLeftElement>
                                                            <Input
                                                                pl='3rem'
                                                                as={InputMask}
                                                                mask={'####################################################################################################'}  
                                                                formatChars= {{'#': '[A-Za-z0-9@._-]'}}  
                                                                maskChar= ''
                                                                alwaysShowMask= {true} 
                                                                placeholder="E-mail de cadastro"
                                                                bg={'tema1.200'}
                                                                border={0}
                                                                name="email"
                                                                color={'tema1.100'}
                                                                _placeholder={{
                                                                    color: 'gray.300',
                                                                }}
                                                            /> 
                                                        </InputGroup>
                                                    </GradientBorder>
                                                </FormControl>  
                                                <Flex
                                                    flexDirection='column'
                                                    justifyContent='center'
                                                    alignItems='center'
                                                    maxW='100%'
                                                    mb='20px'>
                                                        <Button
                                                        colorScheme='blue'
                                                        fontSize='14px'
                                                        type='submit'
                                                        bg={'green.500'}
                                                        leftIcon={<FaUserLock />}
                                                        w='fit-content'
                                                        size='lg'
                                                        mt='0px'
                                                        _hover={{
                                                            bg: 'tema1.300',
                                                            boxShadow: 'xl',
                                                            color:'green.500'
                                                        }} 
                                                        mb='0px'>
                                                        Recuperar senha
                                                    </Button> 
                                                </Flex> 
                                                <Flex
                                                    flexDirection='column'
                                                    justifyContent='center'
                                                    alignItems='center'
                                                    maxW='100%'
                                                    mb='30px'>
                                                    <Text color={'tema1.500'} fontWeight='medium'>
                                                        Lembrou a senha?
                                                        <NavLink to={'/login'} >
                                                            <Text as='span' color={'tema1.200'} ms='5px' fontWeight='bold'>
                                                            Fazer Login
                                                            </Text>
                                                        </NavLink>
                                                    </Text>
                                                </Flex>
                                            </Flex>   
                                        </Flex>  
                                
                                </Box> 
                            </GradientBorder>
                        
                        </Center>    
                        <Box pos={'relative'}  p='10px 0'     >
                            <Text as='span' color={'tema1.200'} ms='5px'  fontSize={{base:'10px',sm:'12px'}} >
                                © 2023 - 2024 Inovatyon Tech Labs. Todos os Direitos Reservados.
                            </Text>
                        </Box> 
            </Flex>
        );
    };
    function formLostPassword(){
        if(valida){
            return (
                <Flex
                        minH='100vh'
                        h={{ base: "100vh", lg: "100vh" }}
                        w='full' 
                        align={'center'} 
                        justify={'center'}  
                        flexDirection='column'  >
                            <Center  >
                                <GradientBorder  
                                borderRadius={'20px'}
                                boxShadow={'lg'}
                                bg='radial-gradient(69.43% 69.43% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.1) 100%),
                                    radial-gradient(60% 51.57% at 50% 50%, #FFFFFF 0%, rgba(88, 44, 255, 0.05) 100%),
                                    radial-gradient(54.8% 53% at 50% 50%, #FFFFFF 0%, rgba(21, 21, 21, 0) 100%)'
                                        p='2px' me={{ base: "none", lg: "0px", xl: "none" }}>
                                    <Box pos={'relative'} bg='rgba(255,255,255,0.3)'   borderRadius={'20px'} as={'form'}  onSubmit={(e)=>{
                                        e.preventDefault();  
                                        const formdata = new FormData(e.target);
                                        const json = {};  
                                        formdata.forEach(function(value, prop){ 
                                            json[prop] = value;
                                        });   
                                        json.chave = chave;
                                        G.socket.removeAllListeners('lostChave').on('lostChave',(response)=>{  
                                            G.socket.removeAllListeners('lostChave');  
                                            console.log(response);
                                            setAtencao(<BoxWindow titulo = {response.titulo} mensagem={response.mensagem} onClose={()=>{
                                                if(response.erro){
                                                    setAtencao(null);
                                                }else{
                                                    setAtencao(<Navigate to={'/login'} />); 
                                                }  
                                            }}/>); 
                                        }) 
                                        G.socket.emit("lostChave", json); 
                                        setAtencao(<BoxWindow load = {true}/>); 
                                    }} >
                                    
                                            <Flex   zIndex={'2'}   h={'100%'}style={{ userSelect: "none" }}  mx={{ base: "auto", lg: "unset" }}  w={{ base: "340px", sm: "400px", lg: "450px" }}  p={{ base: "20px", sm: "40px", lg: "50px" }}>
                                                
                                                <Flex direction='column' w='100%' >
        
                                                    <Center>
                                                        <Image w={{ lg: '300px', xl: '370px', '2xl': '440px' }} objectFit='fill' src='/assets/img/logo.png' alt='Logo inovatyon' />
                                                    </Center>  
                                                    <Text as='span' color={'tema1.300'} p='20px 0 10px 0' ms='0px' fontSize={'sm'} fontWeight={'500'} >
                                                        Informe uma nova senha para sua conta!
                                                    </Text>
                                                    <Stack  direction={{base:'column'}} gap={{ base: "0px"}}  >
                                                        <FormControl >
                                                            <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.200'>
                                                                Nova senha
                                                            </FormLabel>
                                                            <GradientBorder mb={{base:'0px',sm:'10px'}}  w={{ base: "100%"}} borderRadius='20px'>
                                                                <InputGroup size='lg'>
                                                                    <InputLeftElement width='3rem'>
                                                                        <Icon    as={FaKey} boxSize={5}    color={'green.500'}/> 
                                                                    </InputLeftElement> 
                                                                    <Input
                                                                        pl='3rem'
                                                                        pr='3rem'
                                                                        type={show ? 'text' : 'password'}
                                                                        placeholder="Nova senha"
                                                                        bg={'tema1.200'}
                                                                        fontSize={{base:'small',sm:'sm'}}
                                                                        border={0}
                                                                        name="senha1"
                                                                        color={'tema1.100'}
                                                                        _placeholder={{
                                                                            color: 'gray.300',
                                                                        }}
                                                                    />
                                                                    <InputRightElement width='3rem'>
                                                                        <Button h='1.75rem' size='md' onClick={()=>{
                                                                            if(show){
                                                                                setShow(false)    
                                                                            }else{
                                                                                setShow(true) 
                                                                            }  
                                                                        }}>
                                                                        {show ?  <Icon  fontSize={'22px'}  as={FaEyeSlash}/> : <Icon fontSize={'22px'}  as={FaEye}/> }
                                                                        </Button>
                                                                    </InputRightElement>
                                                                </InputGroup> 
                                                            </GradientBorder>
                                                        </FormControl>
                                                        <FormControl >
                                                            <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.200'>
                                                                Confirmar senha
                                                            </FormLabel>
                                                            <GradientBorder mb={{base:'0px',sm:'10px'}}  w={{ base: "100%"}} borderRadius='20px'>
                                                                <InputGroup size='lg'>
                                                                    <InputLeftElement width='3rem'>
                                                                        <Icon    as={FaCheck} boxSize={5}    color={'green.500'}/> 
                                                                    </InputLeftElement> 
                                                                    <Input
                                                                        pl='3rem'
                                                                        pr='3rem'
                                                                        type={show ? 'text' : 'password'}
                                                                        placeholder="Sua senha"
                                                                        bg={'tema1.200'}
                                                                        fontSize={{base:'small',sm:'sm'}}
                                                                        border={0}
                                                                        name="senha2"
                                                                        color={'tema1.100'}
                                                                        _placeholder={{
                                                                            color: 'gray.300',
                                                                        }}
                                                                    />
                                                                    <InputRightElement width='3rem'>
                                                                        <Button h='1.75rem' size='md' onClick={()=>{
                                                                            if(show){
                                                                                setShow(false)    
                                                                            }else{
                                                                                setShow(true) 
                                                                            }  
                                                                        }}>
                                                                        {show ?  <Icon  fontSize={'22px'}  as={FaEyeSlash}/> : <Icon fontSize={'22px'}  as={FaEye}/> }
                                                                        </Button>
                                                                    </InputRightElement>
                                                                </InputGroup> 
                                                            </GradientBorder>
                                                        </FormControl>
                                                    </Stack> 
                                                   
                                                    <Flex
                                                        flexDirection='column'
                                                        justifyContent='center'
                                                        alignItems='center'
                                                        maxW='100%'
                                                        mb='15px'>
                                                        <Text color={'tema1.500'} fontWeight='medium'>
                                                            Lembrou a senha?
                                                            <NavLink to={'/login'} >
                                                                <Text as='span' color={'tema1.200'} ms='5px' fontWeight='bold'>
                                                                Fazer Login
                                                                </Text>
                                                            </NavLink>
                                                        </Text>
                                                    </Flex>
                                                    <Flex
                                                            flexDirection='column'
                                                            justifyContent='center'
                                                            alignItems='center'
                                                            maxW='100%'
                                                            mb='20px'>
                                                                <Button
                                                                colorScheme='blue'
                                                                fontSize='14px'
                                                                type='submit'
                                                                bg={'green.500'}
                                                                leftIcon={<FaKey />}
                                                                w='fit-content'
                                                                size='lg'
                                                                mt='0px'
                                                                _hover={{
                                                                    bg: 'tema1.300',
                                                                    boxShadow: 'xl',
                                                                    color:'green.500'
                                                                }} 
                                                                mb='0px'>
                                                                Alterar Senha
                                                            </Button> 
                                                    </Flex> 
                                                </Flex>   
                                            </Flex>  
                                    
                                    </Box> 
                                </GradientBorder>
                            
                            </Center>    
                            <Box pos={'relative'}  p='10px 0'     >
                                <Text as='span' color={'tema1.200'} ms='5px'  fontSize={{base:'10px',sm:'12px'}} >
                                    © 2023 - 2024 Inovatyon Tech Labs. Todos os Direitos Reservados.
                                </Text>
                            </Box> 
                </Flex>
            );
        }else{
            return <BoxWindow titulo = {'Falha na chave!'} mensagem={'Link de alteração de senha inválido ou expirado, tente novamente!'} onClose={()=>{
                setChave('');
                setAtencao(<Navigate to='/lost'/>);
                setTimeout(()=>{
                    setAtencao(null);
                })
                
            }} />
        } 
    };
    useEffect(()=>{
        if(chave!=''){
            consultarChave();
        }  
    },[atencao,show])
    return  <Flex position='relative'>
        {chave==''?formLost():
        load?formLostPassword(): 
            <BoxWindow load={true}/>
        }
        {atencao}
    </Flex>
};
export default PageLost;
// class PageLogin extends React.Component {
//     open = null;
//     viewLogin = null
    
//     render() {  
//         const tema1.500 = "tema1.500";
//         const illustrationBackground = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
//         return (
//             <Flex position='relative'>
                
//                 {this.atencao}
//             </Flex>
//         );
//     }
// }
// export default PageLogin;