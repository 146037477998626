import * as React from "react"  
import {   Button,  Flex, Grid, Icon, LightMode, Progress, SimpleGrid, Spacer, Stack, Stat, StatHelpText, StatLabel, StatNumber, Text, Textarea } from "@chakra-ui/react"; 
import {Helmet} from "react-helmet"; 
import { SiTether } from "react-icons/si";

import G from '../../components/ADMSocket';
import { FaArrowDown, FaArrowUp, FaChartBar, FaChartPie, FaCheck, FaClock, FaCopy, FaDollarSign, FaExchangeAlt, FaPencilAlt, FaRegCalendarAlt, FaRobot, FaStackExchange, FaUser, FaUserCheck, FaUserClock, FaUsers } from "react-icons/fa";
import IconBox from "../../components/VisionUI/Icons/IconBox";
import Card from "../../components/VisionUI/Card/Card";
import CardBody from "../../components/VisionUI/Card/CardBody";
import CardHeader from "../../components/VisionUI/Card/CardHeader";  
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import TransactionRow from "../../components/VisionUI/Tables/TransactionRowDeposito";
import ListaLancamentos from "./ListaKyc";
import BoxComandoKyc from "./BoxComandoKyc"; 
import { WarningIcon } from "@chakra-ui/icons";
import { FaUserPen } from "react-icons/fa6";
class PageHome extends React.Component { 
    jogos = 0;
    tempSaldos = null;
    tempLance  = null;
    load=false;
    db={
        saldos:{},
        status:{},
        lancamentos:[]
    } 
    usus = {};
    getStatus(dados){
        var status = {
            ativos      :   0,
            pendente    :   0,
            revisao     :   0,      
            enviado     :   0,
            enviadoP    :   0
        }
        dados.forEach((afi)=>{
            if(afi.ativo){
                status.ativos++
            }
            if(afi.pend){
                status.pendente++
            }
            if(afi.revisao){
                status.revisao++
            }
            if(afi.enviado){
                status.enviado++
            }else{
                status.enviadoP++
            }  

        })
        return status;
    }
    buscarJogos(){
        var reload = false;
        // if(G.geral!= null && (typeof G.geral.afiliados != 'undefined' && this.tempAfi!= JSON.stringify(G.geral.afiliados))){  
        //     this.tempAfi       =   JSON.stringify(G.geral.afiliados);   
        //     this.afiliados = {} 
        //     G.geral.afiliados.forEach((lt)=>{
        //         this.afiliados[lt.id]=lt;
        //     })   
        //     reload = true;
        // } 

        
        // if(G.geral!= null && (typeof G.geral.pedidos != 'undefined' && this.tempPed != JSON.stringify(G.geral.pedidos))){  
        //     this.tempPed       =   JSON.stringify(G.geral.pedidos);  

        //     this.pedidos = {} 
        //     G.geral.pedidos.forEach((lt)=>{
        //         this.pedidos[lt.id]=lt;
        //     })   
        //     reload = true;
        // } 
        // if(G.geral!= null && (typeof G.geral.planos != 'undefined' && this.tempPla != JSON.stringify(G.geral.planos))){  
        //     this.tempPla       =   JSON.stringify(G.geral.planos);  

        //     this.planos = {} 
        //     G.geral.planos.forEach((lt)=>{
        //         this.planos[lt.id]=lt;
        //     })   
        //     console.log(this.planos);
        //     reload = true;
        // } 
        // if(G.geral!= null && (typeof G.geral.afiliados != 'undefined' && this.tempAfi != JSON.stringify(G.geral.afiliados))){  
        //     this.tempAfi      =   JSON.stringify(G.geral.afiliados);  

        //     this.usus = {} 
        //     G.geral.afiliados.forEach((lt)=>{
        //         this.usus[lt.id]=lt;
        //     })   
        //     reload = true;
        // } 
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.afiliados!=='undefined' && this.tempAfi != JSON.stringify(G.geral.afiliados)){
            this.tempAfi = JSON.stringify(G.geral.afiliados);  
       
            this.db.lancamentos = [];  
            var nl =0 
            var temp = [];
            G.geral.afiliados.forEach((a)=>{  
                var kyc = G.getDadosDocumentos(a);
                // if(kyc.enviado){
                    temp.push(kyc)
                // } 
            })
            this.db.status = this.getStatus(temp);
            temp.sort((a, b)=>{  
                return a.up > b.up ? -1 : a.up < b.up ? 1 : 0; 
            }).forEach((lan,i)=>{
                if(nl<4 && ((lan.enviado && lan.pend) )){    
                    this.db.lancamentos.push({
                        name    :   lan.nome,
                        situa   :   lan.situa,
                        date    :   new Date(lan.up).toISOString().split('T')[0],
                        price   :   ' USDT',
                        pago    :   ' USDT',
                        logo    :   ((lan.situa=='P')?FaClock:FaCheck),
                    }) 
                    nl++
                } 
            }) 
            this.load = true; 
            reload = true;
        }   
        if(reload){
            this.setState({}); 
        }
    } 
    componentDidMount(){
        this.buscarJogos();
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    render(){  
        var usu = G.painel; 
        var bg = 'transparent linear-gradient(0deg, rgba(46, 52, 68,0) 20%, rgba(46, 52, 68,1) 100%) 0% 0% no-repeat padding-box;'
        if(this.load){
            return (
                <>
                    <Helmet> 
                        <title>Inovatyon - Voucher</title>
                        <meta name="title" content="Inovatyon" />
                        <meta name="description" content="" /> 
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content="https://inovatyon.com" />
                        <meta property="og:title" content="Inovatyon Tach labs" />
                        <meta property="og:description" content="" />
                        <meta property="og:image" content="https://inovatyon.com/assets/img/logobk.png" />
    
                        <meta property="twitter:card" content="summary_large_image" />
                        <meta property="twitter:url" content="https://inovatyon.com" />
                        <meta property="twitter:title" content="Inovatyon Tach labs" />
                        <meta property="twitter:description" content="" />
                        <meta property="twitter:image" content="https://inovatyon.com/assets/img/logobk.png" />
                    </Helmet>  
                    <Flex direction={{base:'column',lg:'row'}}  w={{base:'100%'}} spacing={0} pt={{base:'10px',sm:'20px'}} px={{base:'10px',sm:'20px'}}>  
                        <Stack direction='column'  w={{base:'100%',lg:'60%'}} spacing={{base:'0px',lg:'20px'}} p={{base:'0px 0px',lg:'0px 20px 0px 0px'}} pb={{base:'20px',lg:'20px'}}> 
                            <Grid templateColumns={{ sm: "1fr",  md: "1fr 1fr" }}  gap={{base:'10px',sm:'20px'}}>
                                
                                <Card>
                                    <Flex direction='column'>
                                        <Flex
                                        justify='space-between'
                                        p='22px'
                                        mb='18px'
                                        bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
                                        borderRadius='18px'>
                                            <Flex direction='column'>
                                                <Text color='#E9EDF7' fontSize='12px'>
                                                    KYC Totais
                                                </Text>
                                                <Text color='green.500' fontWeight='bold' fontSize='34px'>
                                                    {this.db.status.enviado}/{this.db.status.enviadoP}
                                                </Text>
                                            </Flex>
                                            <Flex direction='column'>
                                                <Button
                                                bg='transparent'
                                                _hover={{}}
                                                _active={{}}
                                                alignSelf='flex-end'
                                                cursor='default'
                                                p='0px'>
                                                <Icon
                                                    as={FaUsers}
                                                    color='green.500'
                                                    w='24px'
                                                    h='24px'
                                                    justifySelf='flex-start'
                                                    alignSelf='flex-start'
                                                />
                                                </Button> 
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Card>
                                <Card>
                                    <Flex direction='column'>
                                        <Flex
                                        justify='space-between'
                                        p='22px'
                                        mb='18px'
                                        bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
                                        borderRadius='18px'>
                                        <Flex direction='column'>
                                            <Text color='#E9EDF7' fontSize='12px'>
                                                KYC Aprovados
                                            </Text>
                                            <Text color='green.500' fontWeight='bold' fontSize='34px'>
                                                {this.db.status.ativos}/{this.db.status.enviado}
                                            </Text>
                                        </Flex>
                                        <Flex direction='column'>
                                            <Button
                                            bg='transparent'
                                            _hover={{}}
                                            _active={{}}
                                            alignSelf='flex-end'
                                            cursor='default'
                                            p='0px'>
                                            <Icon
                                                as={FaUserCheck}
                                                color='green.500'
                                                w='24px'
                                                h='24px'
                                                justifySelf='flex-start'
                                                alignSelf='flex-start'
                                            />
                                            </Button> 
                                        </Flex>
                                        </Flex> 
                                    </Flex>
                                </Card>  
                                <Card>
                                    <Flex direction='column'>
                                        <Flex
                                        justify='space-between'
                                        p='22px'
                                        mb='18px'
                                        bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
                                        borderRadius='18px'>
                                        <Flex direction='column'>
                                            <Text color='#E9EDF7' fontSize='12px'>
                                                KYC Pendente
                                            </Text>
                                            <Text color='green.500' fontWeight='bold' fontSize='34px'>
                                                {this.db.status.pendente}
                                            </Text>
                                        </Flex>
                                        <Flex direction='column'>
                                            <Button
                                            bg='transparent'
                                            _hover={{}}
                                            _active={{}}
                                            alignSelf='flex-end'
                                            cursor='default'
                                            p='0px'>
                                            <Icon
                                                as={FaUserClock}
                                                color='green.500'
                                                w='24px'
                                                h='24px'
                                                justifySelf='flex-start'
                                                alignSelf='flex-start'
                                            />
                                            </Button> 
                                        </Flex>
                                        </Flex> 
                                    </Flex>
                                </Card> 
                                <Card>
                                    <Flex direction='column'>
                                        <Flex
                                        justify='space-between'
                                        p='22px'
                                        mb='18px'
                                        bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
                                        borderRadius='18px'>
                                        <Flex direction='column'>
                                            <Text color='#E9EDF7' fontSize='12px'>
                                                KYC Revisão
                                            </Text>
                                            <Text color='green.500' fontWeight='bold' fontSize='34px'>
                                                {this.db.status.revisao}
                                            </Text>
                                        </Flex>
                                        <Flex direction='column'>
                                            <Button
                                            bg='transparent'
                                            _hover={{}}
                                            _active={{}}
                                            alignSelf='flex-end'
                                            cursor='default'
                                            p='0px'>
                                            <Icon
                                                as={FaUserPen}
                                                color='green.500'
                                                w='24px'
                                                h='24px'
                                                justifySelf='flex-start'
                                                alignSelf='flex-start'
                                            />
                                            </Button> 
                                        </Flex>
                                        </Flex> 
                                    </Flex>
                                </Card> 
                            </Grid> 
                        </Stack> 
                        <Stack direction='column'    w={{base:'100%',lg:'40%'}} spacing={{base:'0'}} pb='20px'   > 
                            <Card  h={'100%'}   >
                                <CardHeader  minHeight={'max-content'} >
                                    <Flex direction='column' w='100%' minH={'max-content'}>
                                    <Flex
                                        direction={{ sm: "column", lg: "row" }}
                                        justify={{ sm: "center", lg: "space-between" }}
                                        align={{ sm: "center" }}
                                        w='100%'
                                        
                                        >
                                        <Text
                                        color='#fff'
                                        fontSize={{ sm: "lg", md: "xl", lg: "lg" }}
                                        fontWeight='bold'>
                                            Últimos Envios
                                        </Text> 
                                    </Flex>
                                    </Flex>
                                </CardHeader>
                                <CardBody mt='15px' >
                                    <Flex direction='column' w='100%'> 
                                        {
                                            (this.db.lancamentos.length>0)?
                                            this.db.lancamentos.map((row,key) => {
                                                return ( 
                                                    <TransactionRow
                                                        key={'key'+key} 
                                                        name={row.name}
                                                        logo={row.logo}
                                                        date={row.date}
                                                        price={row.price}
                                                        pago={row.pago}
                                                    /> 
                                                    
                                                );
                                            }):<Text color='green.500'>Nenhum Lançamento encontrado</Text>
                                        }  
                                    </Flex>
                                </CardBody>
                            </Card>
                        </Stack>
                    </Flex>
                    <Stack direction='column'    w={{base:'100%',lg:'100%'}} spacing={{base:'0'}} p={{base:'0px 10px 0px 10px',sm:'0px 20px 0px 20px',lg:'0px 20px 20px 20px' }}  > 
                        <Card  h={'100%'}   > 
                            <CardBody>
                                <Flex direction='column' w='100%'>
                                    <BoxComandoKyc
                                        cmdNew={()=>{
                                            // this.atencaoB = <BoxNovoVoucher  usu={this.usus} pla={this.planos}  cmdFechar={()=>{
                                            //     this.atencaoB = null;
                                            //     this.setState({});
                                            // }} /> 
                                            // this.setState({});
                                        }}
                                        
                                    />
                                </Flex>
                            </CardBody>
                        </Card> 
                    </Stack>
                    <Stack direction='column'    w={{base:'100%',lg:'100%'}} spacing={{base:'0'}} p={{base:'0px 10px 0px 10px',sm:'0px 20px 0px 20px',lg:'0px 20px 20px 20px' }}  > 
                        <Card  h={'100%'}   >
                            <CardHeader  minHeight={'max-content'} >
                                <Flex direction='column' w='100%' minH={'max-content'}>
                                <Flex
                                    direction={{ sm: "column", lg: "row" }}
                                    justify={{ sm: "center", lg: "space-between" }}
                                    align={{ sm: "center" }}
                                    w='100%'
                                    
                                    >
                                    <Text
                                    color='#fff'
                                    fontSize={{ sm: "lg", md: "xl", lg: "lg" }}
                                    fontWeight='bold'>
                                    Extrato Detalhado
                                    </Text>
                                    <Flex align='center'>
                                    <Icon
                                        as={FaChartBar} 
                                        w='15px'
                                        h='15px'
                                        color='#fff'
                                        me='6px'
                                    />
                                    
                                    </Flex>
                                </Flex>
                                </Flex>
                            </CardHeader>
                           
                            <CardBody>
                                <Flex direction='column' w='100%'>
                                    <ListaLancamentos/>
                                </Flex>
                            </CardBody>
                        </Card>
                        
                    </Stack>
                    {this.atencaoB}
                </>
            )
        }else{
           return <BoxWindow load={true} />
        }
       
    }
}
export default PageHome;