import * as React from "react"  
import { Box, Button, FormControl, FormLabel, Grid, Heading, Icon, Image, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text } from "@chakra-ui/react";
 
import PhoneInput from 'react-phone-number-input'
import  InputMask  from 'react-input-mask';   
import BoxWindow from "../BoxWindow/BoxWindow";
import G            from '../WebSocket';
import GradientBorder from "../VisionUI/GradientBorder";
import { FaCalendarDay, FaCheck, FaEyeSlash, FaGlobeAmericas, FaIdCard, FaKey, FaMailBulk, FaUser } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";

class BoxSenha extends React.Component { 
    open = null;
    show1 = false;
    show2 = false;
    atencao =   null;
    render(){ 
        var show = false;
        // console.log(G.painel);
        return (
            <>
                <Modal isCentered isOpen={true}  size={{base:'md' }} onClose={()=>{
                    this.props.cmdFechar();
                }}> 
                    <ModalOverlay
                        bg='rgba(58, 255, 202,0.3)' 
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                    />
                    <ModalContent bg={'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'}>
                        <ModalHeader color={'tema1.100'}>Alterar Senha</ModalHeader> 
                        <ModalCloseButton  color={'tema1.100'}/> 
                        <ModalBody>
                            <Stack 
                                rounded={'xl'}
                                px={{ base: 2, sm: 4, md: 6 }} 
                                pb={{ base: 2, sm: 4, md: 6 }} 
                                pt='0'
                             > 
                            <Box as={'form'}  onSubmit={(e)=>{
                                e.preventDefault();  
                                const formdata = new FormData(e.target);
                                let json = {};  
                                formdata.forEach(function(value, prop){ 
                                    json[prop] = value;
                                });   
                                G.socket.removeAllListeners('formDados').on('formDados',(response)=>{     
                                    if(response.cmd === "alterarSenha"){  
                                        if(response.result.dfa){
                                            // this.code = <DoisFatores cmdFechar = {()=>{
                                            //     this.code = null; 
                                            //     this.setState({});
                                            // }}
                                            // cmdSend = {(codigo)=>{
                                            //     json.codigo = codigo;
                                            //     this.sendFormAlterarSenha(json);
                                            //     this.atencao = <BoxWindow   config    =   { {tamanho:"Alerta"} } titulo = "Aguarde" conteudo = "Enviando dados ao servidor..." comando = { {tipo:"ok"} }/>;
                                            //     this.setState({});
                                            // }}
                                            // /> 
                                            this.atencao = null;
                                            this.setState({});
                                        }else {
                                            this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                                                if(!response.result.erro){ 
                                                    this.props.cmdFechar();
                                                }
                                                this.atencao = null;
                                                this.setState({});
                                            }}/> 
                                            this.setState({}); 
                                        } 
                                    } 
                                }); 
                                G.socket.emit("formDados", {
                                    modulo  :   'usuario',
                                    cmd     :   'alterarSenha',
                                    form    :   json
                                });  
                                this.atencao = <BoxWindow load = {true}/>
                                this.setState({});
                            }} >
                                <Stack spacing={0}>  
                                    <Stack  direction={{base:'column',md :'column'}} gap={{ base: "0px", md: "20px"}}> 
                                       
                                        <Stack direction={{base:'column',sm :'column'}} gap={{ base: "0px", sm: "20px"}}  >
                                            <FormControl >
                                                <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.100'>
                                                    Senha Atual
                                                </FormLabel>
                                                <GradientBorder mb={{base:'0px',sm:'10px'}}  w={{ base: "100%"}} borderRadius='20px'>
                                                    <InputGroup size='lg'>
                                                        <InputLeftElement width='3rem'>
                                                            <Icon    as={FaKey} boxSize={5}    color={'green.500'}/> 
                                                        </InputLeftElement> 
                                                        <Input
                                                            pl='3rem'
                                                            pr='3rem'
                                                            type={this.show1 ? 'text' : 'password'}
                                                            placeholder="Sua senha atual"
                                                            bg={'tema1.200'}
                                                            fontSize={{base:'small',sm:'sm'}}
                                                            border={0}
                                                            name="senha"
                                                            color={'tema1.100'}
                                                            _placeholder={{
                                                                color: 'gray.300',
                                                            }}
                                                        />
                                                        <InputRightElement width='3rem'>
                                                            <Button h='1.75rem' size='md' onClick={()=>{
                                                                if(this.show1){
                                                                    this.show1 =false;    
                                                                }else{
                                                                    this.show1 =true
                                                                } 
                                                                this.setState({})
                                                            }}>
                                                            {this.show1 ?  <Icon  fontSize={'22px'}  as={FaEyeSlash}/> : <Icon fontSize={'22px'}  as={FaEye}/> }
                                                            </Button>
                                                        </InputRightElement>
                                                    </InputGroup> 
                                                </GradientBorder>
                                            </FormControl>
                                            <FormControl >
                                                <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.100'>
                                                    Nova Senha
                                                </FormLabel>
                                                <GradientBorder mb={{base:'0px',sm:'10px'}}  w={{ base: "100%"}} borderRadius='20px'>
                                                    <InputGroup size='lg'>
                                                        <InputLeftElement width='3rem'>
                                                            <Icon    as={FaKey} boxSize={5}    color={'green.500'}/> 
                                                        </InputLeftElement> 
                                                        <Input
                                                            pl='3rem'
                                                            pr='3rem'
                                                            type={this.show1 ? 'text' : 'password'}
                                                            placeholder="Nova Senha"
                                                            bg={'tema1.200'}
                                                            fontSize={{base:'small',sm:'sm'}}
                                                            border={0}
                                                            name="senha1"
                                                            color={'tema1.100'}
                                                            _placeholder={{
                                                                color: 'gray.300',
                                                            }}
                                                        />
                                                        <InputRightElement width='3rem'>
                                                            <Button h='1.75rem' size='md' onClick={()=>{
                                                                if(this.show1){
                                                                    this.show1 =false;    
                                                                }else{
                                                                    this.show1 =true
                                                                } 
                                                                this.setState({})
                                                            }}>
                                                            {this.show1 ?  <Icon  fontSize={'22px'}  as={FaEyeSlash}/> : <Icon fontSize={'22px'}  as={FaEye}/> }
                                                            </Button>
                                                        </InputRightElement>
                                                    </InputGroup> 
                                                </GradientBorder>
                                            </FormControl>
                                            <FormControl >
                                                <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.100'>
                                                    Confirmar Nova Senha
                                                </FormLabel>
                                                <GradientBorder  mb='20px' w={{ base: "100%"}} borderRadius='20px'>
                                                    <InputGroup size='lg'>
                                                        <InputLeftElement width='3rem'>
                                                            <Icon    as={FaCheck} boxSize={5}    color={'green.500'}/> 
                                                        </InputLeftElement> 
                                                        <Input
                                                            pl='3rem'
                                                            pr='3rem'
                                                            type={this.show1 ? 'text' : 'password'}
                                                            placeholder="Confirmar nova senha"
                                                            fontSize={{base:'small',sm:'sm'}}
                                                            bg={'tema1.200'}
                                                            border={0}
                                                            name="senha2"
                                                            color={'tema1.100'}
                                                            _placeholder={{
                                                                color: 'gray.300',
                                                            }}
                                                        />
                                                        <InputRightElement width='3rem'>
                                                            <Button h='1.75rem' size='md' onClick={()=>{
                                                                if(this.show1){
                                                                    this.show1 =false;    
                                                                }else{
                                                                    this.show1 =true
                                                                } 
                                                                this.setState({})
                                                            }}>
                                                            {this.show1 ?  <Icon  fontSize={'22px'}  as={FaEyeSlash}/> : <Icon fontSize={'22px'}  as={FaEye}/> }
                                                            </Button>
                                                        </InputRightElement>
                                                    </InputGroup> 
                                                </GradientBorder>
                                            </FormControl>
                                        </Stack>
                                    </Stack> 
                                </Stack>
                                
                                <Stack align='center' justify={'center'} flexDir={'row'} pt='5' spacing={1}>
                                    <Button
                                        type="submit"
                                        fontFamily={'heading'} 
                                        w={'full'}
                                        bgGradient="linear(to-r, tem1.100,tem1.100)"
                                        color={'white'}
                                        _hover={{
                                            bgGradient: 'linear(to-r,tema1.100,tema1.100)',
                                            boxShadow: 'xl',
                                            color:'tema1.200'
                                        }}>
                                        Salvar Alteração
                                    </Button> 
                                    <Button
                                        fontFamily={'heading'} 
                                        w={'160px'}
                                        bg="transparent"
                                        color={'red'}
                                        _hover={{
                                            bgGradient: 'linear(to-r,tema1.100,tema1.100)',
                                            boxShadow: 'xl',
                                            color:'tema1.200'
                                        }}
                                        cursor={'pointer'} onClick={()=>{
                                            this.props.cmdFechar();
                                        }}
                                        >
                                        Cancelar
                                    </Button>  
                                </Stack>
                            </Box> 
                            </Stack>
                        </ModalBody> 
                        {this.atencao}
                    </ModalContent>
                    {/* 
                        
                            <ModalBody  color={'#ffffff'}>
                                <Flex flexDirection='row' align='center' justify='center'>
                                    <Spinner color='tema1.100' /> <Text ml='15px' >Aguarde um momento...</Text>
                                </Flex> 
                            </ModalBody> 
                        <ModalFooter/>
                    </ModalContent> */}
                </Modal>
            </>
        )
    }
}
export default BoxSenha;