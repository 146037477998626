import * as React from "react"  
import { Box, Button, Center, Flex, FormControl, FormLabel, Grid, Heading, Icon, Image, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalCloseButton, ModalContent, ModalOverlay, Spacer, Stack, Text, Textarea } from "@chakra-ui/react";
 
import  InputMask  from 'react-input-mask';   
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import G            from '../../components/ADMSocket';
import QRCode from "react-qr-code";
import Card from "../../components/VisionUI/Card/Card";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
import { FaCopy, FaInfo } from "react-icons/fa";
import GradientBorder from "../../components/VisionUI/GradientBorder";

class BoxCancelar extends React.Component { 
    open = null;
    show1 = false;
    show2 = false;
    show3 = false;
    atencao =   null;
    render(){ 
        var lan =this.props.lan; 
        var show = false; 
        return (
            <>
                <Modal isCentered isOpen={true}  size={{base:'md' }} onClose={this.props.cmdFechar}> 
                    <ModalOverlay
                        bg='rgba(0,200,0,0.5)' 
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                    />
                    <ModalContent bg={'tema1.200'}>
                        <ModalCloseButton  color={'tema1.100'}/> 
                        <Stack   rounded={'xl'}  p={{ base: '20px' }} spacing={{ base: 0}}  align={'center'} justify={'center'}>
                            <Flex w={'100%'}  direction={{ base: 'column', md: 'column' }}  textAlign="center" gap={'30px'}>
                               
                                <Flex  w={'100%'} direction={{ base: 'column', md: 'column' }}  textAlign="center" spacing={0} > 
                                    <Stack  w={'100%'} textAlign="center" justify="center"  spacing={0} > 
                                        <Text as={'b'} mt={'15px'} mb={'5px'} fontSize="lg" color={'tema1.400'} >
                                            Saque de <Text as={'span'}>{this.props.usu.usuario}</Text>
                                        </Text> 
                                        <Box textTransform={'uppercase'} p={'10px'} w={'300px'} m='0 auto' shadow="base" borderWidth="1px" alignSelf={{ base: 'center', lg: 'flex-start' }}  borderColor={'tema1.500'} borderRadius={'xl'}> 
                                            <Stack  direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                                <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                                    Valor do Saque
                                                </Text>
                                                <Spacer/>
                                                <Text as={'b'} fontSize="small" color={'gray.500'} px={'0px'}>
                                                    {G.parseValor((lan.valor),5).f} USDT
                                                </Text>
                                            </Stack>
                                            <Stack  direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                                <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                                    Taxa de Saque 
                                                </Text>
                                                <Spacer/>
                                                <Text as={'b'} fontSize="small" color={'gray.500'} px={'0px'}> 
                                                {G.parseValor(lan.valor*0.05).f} USDT
                                                </Text>
                                            </Stack> 
                                            <Stack mt='10px' direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                                <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                                    Total a Pagar
                                                    
                                                </Text>
                                                <Spacer/>
                                                <Text as={'b'} fontSize="lg" color={'green.500'} px={'0px'}> 
                                                {G.parseValor(lan.valor*0.95).f} USDT
                                                </Text>
                                            </Stack> 
                                        </Box>  
                                    </Stack>  
                                </Flex>
                                <Flex  direction={{ base: 'column', md: 'column' }}  textAlign="center"> 
                                    <Box bg='transparent' w={{base:'100%'}} h={{base:'180px'}} borderRadius={'lg'}> 
                                        <FormControl>
                                            <FormLabel ms='4px' fontSize='sm' fontWeight='500' color='tema1.200'>
                                                Obs.:
                                            </FormLabel>
                                            <GradientBorder  mb='10px' w={{ base: "100%"}} borderRadius='20px'>
                                                <InputGroup size='lg'>
                                                    <InputLeftElement width='3rem'>
                                                        <Icon as={FaInfo} boxSize={5}  color="green.500"/> 
                                                    </InputLeftElement>
                                                    <Input
                                                        pl='3rem' 
                                                        placeholder="Observação"
                                                        bg={'tema1.200'}
                                                        border={0}
                                                        name="login"
                                                        color={'tema1.100'}
                                                        onChange={(e)=>{
                                                            this.obs =  e.target.value;
                                                        }}
                                                        _placeholder={{
                                                            color: 'gray.300',
                                                        }}
                                                    /> 
                                                </InputGroup>
                                            </GradientBorder>
                                        </FormControl>
                                        <Button
                                            type="submit"
                                            fontFamily={'heading'} 
                                            mt={'20px'}
                                            w={'full'}
                                            bgGradient="linear(to-r, tem1.100,tem1.100)"
                                            color={'white'}
                                            onClick={()=>{
                                                G.socket.removeAllListeners('formDados').on('formDados',(response)=>{  
                                                    if(response.cmd === "pagarSaque"){   
                                                        
                                                        this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                                                            if(!response.result.erro){
                                                                
                                                                lan.situa='C';
                                                                this.props.cmdFechar(); 
                                                            }
                                                            this.atencao = null;
                                                            this.setState({});
                                                        }}/> 
                                                        this.setState({});  
                                                    } 
                                                }); 
                                                G.socket.emit("formDados", {
                                                    modulo  :   'financeiro',
                                                    cmd     :   'pagarSaque',
                                                    form    :   {
                                                        id      :  lan.id.toString(), 
                                                        situa   : 'C',
                                                        obs     :  this.obs
                                                    }
                                                });  
                                                this.atencao = <BoxWindow load = {true}/>
                                                this.setState({});
                                            }}
                                            _hover={{
                                                bgGradient: 'linear(to-r,green.500,green.500)',
                                                boxShadow: 'xl',
                                                color:'tema1.200'
                                            }}>
                                            Cancelar Pagamento
                                        </Button> 
                                    </Box> 
                                </Flex> 
                                 
                            </Flex>
                            
                            
                         
                        
                        </Stack>
                        {this.atencao}
                        {this.atencaoB}
                        </ModalContent>
                    {/* 
                        <ModalHeader color={'tema1.100'}>Enviando dados ao servidor</ModalHeader> 
                            <ModalBody  color={'#ffffff'}>
                                <Flex flexDirection='row' align='center' justify='center'>
                                    <Spinner color='tema1.100' /> <Text ml='15px' >Aguarde um momento...</Text>
                                </Flex> 
                            </ModalBody> 
                        <ModalFooter/>
                    </ModalContent> */}
                </Modal>
            </>
        )
    }
}
export default BoxCancelar;