import { Box, Icon, Input, InputGroup, InputLeftElement, useStyleConfig } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaCalendarDay } from "react-icons/fa";
const  InputDate = function(props) {
  const { variant, children, ...rest } = props;  
  return (
    <InputGroup   size='lg'> 
        <InputLeftElement width='3rem' display={{base:'flex'}}>
            <Icon as={FaCalendarDay} boxSize={5}  color="green.500"/> 
            {/* <Icon as={FaIdCard} boxSize={5}  color="green.500"/>  */}
        </InputLeftElement>
        <Input 
            type={'date'}
            bg={'tema1.200'}
            border={0}  
            lineHeight={'48px'}
            fontSize={{base:'small',sm:'sm'}}
            color={'tema1.100'}  
            placeholder={'dd/mm/aaaa'} 
            textAlign='right' 
            _placeholder={{
                color: 'gray.300',
            }}
            {...rest}
            // onfocus="(this.type='date')"
            // onblur="(this.type='text')"
            
        /> 
    </InputGroup> 
  );
};
export default InputDate;