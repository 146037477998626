import { useEffect,useContext, useState, useRef } from "react"; 
import {   Box, Button,  Collapse,  Fade,  Flex, FormControl, FormLabel, Grid, Icon, Input, InputGroup, InputLeftElement, LightMode, Menu, MenuButton, MenuItem, MenuList, Progress, SimpleGrid, Slide, Spacer, Stack, Stat, StatHelpText, StatLabel, StatNumber, Text, Textarea, VisuallyHidden } from "@chakra-ui/react"; 
import {Helmet} from "react-helmet"; 
import { SiTether } from "react-icons/si";

import G from '../../components/ADMSocket';

import GradientBorder from "../VisionUI/GradientBorder";
import { FaBox, FaCheck, FaCircle, FaInfo } from "react-icons/fa";
import { FaCircleCheck, FaRadio } from "react-icons/fa6";
import { MdCircle, MdOutlineCircle } from "react-icons/md";
const InputAutocomplete = function(props){ 
        const { label, leftIcon, lista, value='',name ='',placeholder,onChange,minLn=0} = props; 
       
        const [focus, setFocus] = useState(false);
        const [focusIP, setFocusIP] = useState(false);
        const [filtro, setFiltro] = useState('');
        const [selecionado, setSelecionado] = useState(''); 
        const [focusS, setFocusS] = useState(0);
        useEffect(() => { 
            if(typeof lista[value]!=='undefined'){
                setSelecionado(value); 
            }  
         }, []);
        // if(selecionado==null){
        //     setSelecionado(value)
        //     console.log('reset selecionado')
        // }
        const  ipFiltro   = useRef();
        const  ipLista    = useRef(); 
       
        let listaF = [];
        if(lista.length>0 && filtro.length>=minLn){
            lista.forEach((li)=>{
                if(filtro.length>=minLn){ 
                    if((li.nome).includes(filtro)){
                        listaF.push(li);
                    } 
                }else{
                    listaF.push(li);
                } 
            })
            
        }
        useEffect(() => { 
            // var texto = document.body.querySelectorAll('label') ;
            // var idioma = {};
            // idioma['Afiliado'] = 'Afiliado2';
            // console.log(texto);
            // texto.forEach((el)=>{
            //     if(typeof idioma[el.innerHTML] !== 'undefined'){
            //         console.log(el);
            //         el.innerHTML = idioma[el.outerText];  
            //     }
            //     console.log()
            // })
            try {
                onChange(selecionado,lista[selecionado]);
            } catch (error) {
                
            } 
         }, [selecionado]);

        useEffect(() => {  
         }, [filtro]);

        let  view = false;
        if(focusIP){
            view =true;
        } 
        return  <Stack direction={'column'} w={{ base: "100%" }} gap={'0px'}  >
                    <Input type="hidden" name={name}  value={selecionado}   />
                    <FormControl>
                        <FormLabel ms='4px' fontSize='sm' fontWeight='500' color='tema1.100'>
                            {label}
                        </FormLabel>
                        <GradientBorder zIndex='1' mb='10px' w={{ base: "100%" }} borderRadius='20px' >
                            <InputGroup size='lg'  borderRadius='20px'>
                                {typeof leftIcon == 'undefined'?null:<InputLeftElement  w='3rem' ><Icon as={leftIcon} boxSize={5} color="green.500" /></InputLeftElement>} 
                                <Input
                                    type="text"
                                    ref={ipFiltro}
                                    pl={typeof leftIcon == 'undefined'?'0':'3rem'}
                                    placeholder={placeholder}
                                    bg={'tema1.200'}
                                    border={0} 
                                    color={'tema1.100'}
                                    borderRadius='20px'
                                    value={filtro} 
                                    onFocus={()=>{ 
                                        setTimeout(()=>{
                                            setFocusIP(true);
                                        },300) 
                                    }}
                                    onBlur={()=>{
                                        setTimeout(()=>{
                                            setFocusIP(false);
                                        },300) 
                                    }} 
                                    onKeyDown={(el)=>{
                                        const matches = ipLista.current.querySelectorAll("button.selecao"); 
                                        switch (el.code) {
                                            case 'Enter':
                                                try { 
                                                    if(typeof matches[focusS] == 'undefined'){
                                                        matches[0].click();  
                                                    }else{
                                                        matches[focusS].click();  
                                                    }
                                                    
                                                } catch (error) {
                                                    
                                                } 
                                            break; 
                                            case 'ArrowUp':
                                                if(focusS>0){ 
                                                    try {
                                                        ipLista.current.scrollTop= matches[focusS-1].offsetTop; 
                                                    } catch (error) {
                                                        
                                                    }
                                                    setFocusS(focusS-1);
                                                }
                                            break; 
                                            case 'ArrowDown':
                                                if(focusS<matches.length){
                                                    try {
                                                        if(matches[focusS+1].offsetTop>=ipLista.current.clientHeight){
                                                            ipLista.current.scrollTop= matches[focusS+1].offsetTop- matches[focusS+1].clientHeight;
                                                        } 
                                                    } catch (error) {
                                                        
                                                    } 
                                                    setFocusS(focusS+1); 
                                                }
                                            break; 
                                        } 
                                    }}
                                    onChange={(e) => {  
                                        setFiltro(e.target.value);  
                                        setFocusIP(true);
                                    } }
                                    _placeholder={{
                                        color: 'gray.300',
                                    }} />
                                     
                            </InputGroup>
                        </GradientBorder>   
                        <Stack zIndex='2' display={(view)?'flex':'none'} className="animate__animated animate__fadeIn" position={'absolute'} mt='-12px' ml={'20px'}  w={'calc(100% - 40px)'} bg={'red'}   >
                            <Stack ref={ipLista} bg={'tema1.200'} direction={'column'} w={{ base: "100%" }} align={'left'} justify={'left'} overflowY={'auto'} border={'2px solid'} borderTop={'0px'}  maxH={'230px'}  gap={0}>
                                {listaF.length>0? 
                                    listaF.map((v,i)=>{
                                        return  <Button key={'bt'+i} bg={focusS==i?'rgba(255,255,255,0.1)':'tema1.200'} className="selecao" leftIcon={selecionado==v.valor?<FaCheck color="green.500" />:<MdOutlineCircle size='16px' p='20px' />} textAlign='left' color={selecionado==v.valor?'green.500': 'tema1.100'}  borderRadius={0} zIndex={2}  py={'10px'} w={'100%'} onClick={(e)=>{ 
                                            e.preventDefault();   
                                            if(v.valor==selecionado){
                                                setFiltro(''); 
                                                setSelecionado(''); 
                                                setFocusS(0);
                                                ipFiltro.current.focus();
                                            }else{
                                                setFiltro(v.nome); 
                                                setSelecionado(v.valor); 
                                                setFocusIP(false); 
                                            }
                                            
                                        }}><Text p='15px' color={'green.500'} w={'100%'} noOfLines={0} title={v.nome} >{v.nome}</Text></Button>
                                    })  
                                :
                                    <Text p='15px'  color={'green.500'} textAlign={'left'}>{filtro.length>0?'Nenhum resultado encontrado':'Digite letra para buscar...'}</Text>
                                } 
                            </Stack> 
                        </Stack>  
                              
                    </FormControl> 
                </Stack> 
}
export default InputAutocomplete;