import * as React from "react"  
import { Box, Button, Center, Flex, FormControl, FormLabel, Grid, Heading, Icon, Image, Img, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverTrigger, Select, Spacer, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea } from "@chakra-ui/react";
 
import  InputMask  from 'react-input-mask';   
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import G            from '../../components/ADMSocket';
import QRCode from "react-qr-code";
import Card from "../../components/VisionUI/Card/Card";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
import { FaCalendarDay, FaChartLine, FaCheck, FaClock, FaCopy, FaEye, FaEyeSlash, FaGlobeAmericas, FaIdCard, FaInfo, FaKey, FaMailBulk, FaRegSave, FaRocket, FaSave, FaSignInAlt, FaTimes, FaUser } from "react-icons/fa";
import GradientBorder from "../../components/VisionUI/GradientBorder";
import InputAutocomplete from "../../components/Padrao/InputAutocomplete";
import { MdWarning } from "react-icons/md";
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input'
import InputDate from "../../components/Padrao/InputData";
import md5 from "md5"
class BoxConfigAfiliado extends React.Component { 
    open = null;
    show1 = false;
    show2 = false;
    show3 = false;
    atencao =   null; 
    aba = 0;
    openBox = false;
    cmdFormView(cmd,campo){
        var onOpen = ()=>{};
        var onClose = ()=>{
           this.openBox =false;
           this.setState({})
        }; 
       var lan =this.props.lan; 
        return <Stack  direction='row'  w={'100%'} textAlign="center" justify="space-between" pb='15px' > 
            {lan[campo]=='P' || lan[campo]=='R'?
            <Button  onClick={async (e)=>{ 
                e.preventDefault();    
                G.socket.removeAllListeners('formDados').on('formDados',(response)=>{   
                    // console.log(response);
                    if(response.cmd === "cmdDocumento"){    
                        this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                            if(!response.result.erro){
                                
                                lan[campo]='N';
                                // this.props.cmdFechar(); 
                            }
                            this.atencao = null;
                            this.setState({});
                        }}/> 
                        this.setState({});  
                    } 
                }); 
                G.socket.emit("formDados", {
                    modulo  :   'afiliado',
                    cmd     :   'cmdDocumento',
                    form    :   {
                        id       :  lan.id,
                        cmd      :  cmd,  
                        obs      :  'OK'
                    }
                });  
                this.atencao = <BoxWindow load = {true}/>
                this.setState({});
                }} p={'0px 10px'} h={'30px'} leftIcon={<FaCheck fontSize={'18px'}/>} fontSize='12px' bg={'green.500'} color={'tema1.200'} variant='solid'>
                    Aprovar Documento
                </Button>
            :null} 
            <Spacer/>

            <Popover
                isOpen={this.openBox}
                // initialFocusRef={firstFieldRef}
                onOpen={()=>{
                    this.obs = '';
                }}
                onClose={onClose}
                placement='bottom'
                closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button  onClick={async (e)=>{ 
                        e.preventDefault();     
                        this.openBox=true;
                        this.setState({}); 
                    }} p={'0px 10px'} h={'30px'} leftIcon={<MdWarning fontSize={'18px'}/>} fontSize='12px' bg={'red.500'} color={'tema1.200'} variant='solid'>
                        Revisar Documento
                    </Button>
                </PopoverTrigger>
                <PopoverContent p={5} bg={'tema1.200'}  borderColor={'green.500'}>
                    <PopoverArrow bg={'green.500'} />
                    <PopoverCloseButton />
                    <FormControl>
                        <FormLabel ms='4px' fontSize='sm' fontWeight='500' color='tema1.100'>
                            Obs.:
                        </FormLabel>
                        <GradientBorder  mb='10px' w={{ base: "100%"}} borderRadius='20px'>
                            <InputGroup size='lg'>
                                <InputLeftElement width='3rem'>
                                    <Icon as={FaInfo} boxSize={5}  color="green.500"/> 
                                </InputLeftElement>
                                <Input 
                                    pl='3rem' 
                                    placeholder="Observação"
                                    bg={'tema1.200'}
                                    border={0}
                                    name="login"
                                    color={'tema1.100'}
                                    onChange={(e)=>{
                                        this.obs =  e.target.value;
                                    }}
                                    _placeholder={{
                                        color: 'gray.300',
                                    }}
                                /> 
                            </InputGroup>
                        </GradientBorder>
                    </FormControl>
                    <Button
                        type="submit"
                        fontFamily={'heading'} 
                        mt={'20px'}
                        w={'full'}
                        bgGradient="linear(to-r, tem1.100,tem1.100)"
                        color={'white'}
                        onClick={()=>{
                            G.socket.removeAllListeners('formDados').on('formDados',(response)=>{   
                                // console.log(response);
                                if(response.cmd === "cmdDocumento"){    
                                    this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                                        if(!response.result.erro){
                                            
                                            lan[campo]='R';
                                            // this.props.cmdFechar(); 
                                        }
                                        this.atencao = null;
                                        this.setState({});
                                    }}/> 
                                    this.setState({});  
                                } 
                            }); 
                            G.socket.emit("formDados", {
                                modulo  :   'afiliado',
                                cmd     :   'cmdDocumento',
                                form    :   {
                                    id       :  lan.id,
                                    cmd      :  cmd,  
                                    obs      :  this.obs
                                }
                            });  
                            this.atencao = <BoxWindow load = {true}/>
                            this.setState({});
                        }}
                        _hover={{
                            bgGradient: 'linear(to-r,green.500,green.500)',
                            boxShadow: 'xl',
                            color:'tema1.200'
                        }}>
                        Revisar Documento
                    </Button>  
                </PopoverContent>
            </Popover>
        </Stack>
    }
    enviarSenhaNova(senha){
        G.socket.removeAllListeners('formDados').on('formDados',(result)=>{   
            if(result.cmd =='cmdSenha'){ 
                this.atencao = <BoxWindow titulo = {result.result.titulo} mensagem={result.result.mensagem} onClose={()=>{
                        this.atencao = null;
                        this.setState({});
                }}/>
                this.setState({});    
            }
           
        }); 
        G.socket.emit("formDados", {modulo:'afiliado',cmd:'cmdSenha',form:{senha:senha}}); 
        this.atencao = <BoxWindow load = {true}/>
        this.setState({}); 
    }
    gerarSenha= '';
    novaSenha= '';
    formSenha(lan){
        return(
            <Box pos={'relative'}   borderRadius={'20px'}   > 
                    <Flex   zIndex={'2'}   h={'100%'}  style={{ userSelect: "none" }}  mx={{ base: "auto", lg: "unset" }}  w={{ base: "100%" }}  p={{ base: "0px" }}>
                        
                        <Flex direction='column' w='100%' >  
                            <Stack  w={'100%'} textAlign="center" justify="center"  >  
                                <Box textTransform={'uppercase'} p={4} w={'100%'} m='0 auto' shadow="base" borderWidth="1px" alignSelf={{ base: 'center', lg: 'flex-start' }}  borderColor={'tema1.500'} borderRadius={'xl'}> 
                                    <Stack  direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                        <Text fontSize="small" color={'tema1.400'} py={'10px'}>
                                            Gerar Senha Aleatória
                                        </Text>
                                        <Spacer/> 
                                        <Button h='40px' size='md' borderRadius={'lg'} bg={'green.500'} colorScheme="green" onClick={()=>{
                                            this.gerarSenha = md5("INoVatyon"+new Date().getTime()+lan.id);
                                            this.enviarSenhaNova(this.gerarSenha);
                                        }}>Gerar Senha</Button>
                                    </Stack>
                                    <Stack  direction={{ base: 'row', md: 'row' }} pt={'20px'}  w={'100%'} textAlign="center" justify="center"  align={'center'}> 
                                        <Text fontSize="small" color={'tema1.400'} pb={'10px'}>
                                            Nova Senha
                                        </Text>
                                        <Spacer/>
                                        <GradientBorder  mb={{base:'0px',sm:'10px'}}   w={'300px'} borderRadius='20px'>
                                            <InputGroup size='lg' alignItems={'center'} justifyItems={'center'}>
                                                <InputLeftElement width='3rem'>
                                                    <Icon    as={FaKey} boxSize={5}    color={'green.500'}/> 
                                                </InputLeftElement> 
                                                <Input color={'green.500'}  value={this.gerarSenha} p={'0px'}  pl='3rem'  lineHeight={'16px'}    align={'center'} fontSize={'14px'}  placeholder="Click em gerar senha"  borderRadius='20px 0 0 20px' overflow='hidden' bg='tema1.200'    border='0px solid' borderColor='tema1.500' isReadOnly  w={{base:'100%'}} minH='48px' py={'16px'} maxH={'48px'} resize='none'  defaultValue={this.gerarSenha}
                                                /> 
                                            </InputGroup> 
                                             
                                            {/* <Button
                                            colorScheme='blue'
                                            fontSize='14px'
                                            type='submit'
                                            bg={'green.500'}
                                            leftIcon={<FaCopy />}
                                            w='fit-content'
                                            size='lg'
                                            borderRadius={'0 20px 20px 0'}
                                            ml='2px'
                                            _hover={{
                                                bg: 'tema1.300',
                                                boxShadow: 'xl',
                                                color:'green.500'
                                            }} 
                                            onClick={(e)=>{ 
                                                e.preventDefault();   
                                                var Url = document.getElementById("copySenha");
                                                Url.select()  
                                                document.execCommand("copy"); 
                                                this.atencaoB = <BoxWindow titulo = {'Senha copiada com sucesso!'} mensagem={'Você copiou a senha '+this.gerarSenha+' na memória, agora é só enviar para o afiliado!'} onClose={()=>{
                                                    this.atencaoB = null;
                                                    this.setState({});
                                                }} />
                                                this.setState({});
                                            }}
                                            mb='0px'>
                                            Copy
                                        </Button> */}
                                        </GradientBorder>
                                            
                                            
                                    </Stack> 
                                </Box>  
                            </Stack>
                            <Text fontSize="small" color={'tema1.400'} py={'15px'}>
                                OU
                            </Text>
                            <Stack direction={{base:'column',sm :'row'}} gap={{ base: "0px", sm: "20px"}}  >
                                <FormControl >
                                    <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='green.500'>
                                        Nova senha
                                    </FormLabel>
                                    <GradientBorder mb={{base:'0px',sm:'10px'}}  w={{ base: "100%"}} borderRadius='20px'>
                                        <InputGroup size='lg'>
                                            <InputLeftElement width='3rem'>
                                                <Icon    as={FaKey} boxSize={5}    color={'green.500'}/> 
                                            </InputLeftElement> 
                                            <Input
                                                pl='3rem'
                                                pr='3rem'
                                                type={this.show1 ? 'text' : 'password'}
                                                placeholder="Nova senha"
                                                bg={'tema1.200'}
                                                fontSize={{base:'small',sm:'sm'}}
                                                border={0}
                                                borderRadius={'15px 0 0 15px'}
                                                name="senha"
                                                value={this.novaSenha}
                                                onChange={(el)=>{
                                                    this.novaSenha = el.target.value;
                                                    this.setState({})
                                                }}
                                                color={'tema1.100'}
                                                _placeholder={{
                                                    color: 'gray.300',
                                                }}
                                            />
                                            <InputRightElement width='3rem'>
                                                <Button h='100%' size='md' borderRadius={0} onClick={()=>{
                                                    if(this.show1){
                                                        this.show1 =false;    
                                                    }else{
                                                        this.show1 =true
                                                    } 
                                                    this.setState({})
                                                }}>
                                                {this.show1 ?  <Icon  fontSize={'22px'}  as={FaEyeSlash}/> : <Icon fontSize={'22px'}  as={FaEye}/> }
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup> 
                                        <Button
                                            colorScheme='blue'
                                            fontSize='14px'
                                            type='submit'
                                            bg={'green.500'}
                                            leftIcon={<FaSave />}
                                            w='fit-content'
                                            size='lg'
                                            borderRadius={'0 20px 20px 0'}
                                            ml='2px'
                                            _hover={{
                                                bg: 'tema1.300',
                                                boxShadow: 'xl',
                                                color:'green.500'
                                            }} 
                                            onClick={()=>{ 
                                                this.enviarSenhaNova(this.novaSenha);
                                            }}
                                            mb='0px'>
                                            Salvar
                                        </Button> 
                                    </GradientBorder>
                                </FormControl>
                                  
                            </Stack> 
                            
                            
                        </Flex>   
                    </Flex>   
            </Box> 
        )
       
    } 
    formEmail(lan){
        return(
            <Box pos={'relative'}   borderRadius={'20px'} as={'form'}  onSubmit={(e)=>{
                e.preventDefault();    
                const formdata = new FormData(e.target);
                const json = {};  
                formdata.forEach(function(value, prop){ 
                    json[prop] = value;
                });   
                G.socket.removeAllListeners('formDados').on('formDados',(result)=>{   
                    if(result.cmd =='cmdEmail'){
                        console.log(result);
                        this.atencao = <BoxWindow titulo = {result.result.titulo} mensagem={result.result.mensagem} onClose={()=>{
                                this.atencao = null;
                                this.setState({});
                        }}/>
                        this.setState({});   
                        
                    }
                   
                }); 
                G.socket.emit("formDados", {modulo:'afiliado',cmd:'cmdEmail',form:json}); 
                this.atencao = <BoxWindow load = {true}/>
                this.setState({});
            }} >
            
                    <Flex   zIndex={'2'}   h={'100%'}  style={{ userSelect: "none" }}  mx={{ base: "auto", lg: "unset" }}  w={{ base: "100%" }}  p={{ base: "0px" }}>
                        
                        <Flex direction='column' w='100%' > 
                            <Stack  direction={{base:'column',sm :'row'}} gap={{ base: "0px", sm: "20px"}}>
                                 
                                <FormControl> 
                                    <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='green.500'>
                                    Seu e-mail
                                    </FormLabel>
                                    <GradientBorder mb={{base:'0px',sm:'10px'}} w={{ base: "100%"}} borderRadius='20px'>
                                        <InputGroup size='lg'>
                                            <InputLeftElement  width='3rem'>
                                                <Icon as={FaMailBulk} boxSize={5}  color="green.500"/> 
                                            </InputLeftElement>
                                            <Input
                                                pl='3rem'
                                                as={InputMask}
                                                mask={'####################################################################################################'}  
                                                formatChars= {{'#': '[A-Za-z0-9@._-]'}}  
                                                maskChar= ''
                                                alwaysShowMask= {true} 
                                                placeholder="E-mail válido" 
                                                bg={'tema1.200'}
                                                border={0}
                                                borderRadius='20px 0 0 20px'
                                                fontSize={{base:'small',sm:'sm'}}
                                                name="email"
                                                defaultValue={lan.dados.email}  
                                                color={'tema1.100'}
                                                _placeholder={{
                                                    color: 'gray.300',
                                                }}
                                            /> 
                                        </InputGroup>
                                        <Button
                                            colorScheme='blue'
                                            fontSize='14px'
                                            type='submit'
                                            bg={'green.500'}
                                            leftIcon={<FaSignInAlt />}
                                            w='fit-content'
                                            size='lg'
                                            borderRadius={'0 20px 20px 0'}
                                            ml='2px'
                                            _hover={{
                                                bg: 'tema1.300',
                                                boxShadow: 'xl',
                                                color:'green.500'
                                            }} 
                                            mb='0px'>
                                            Salvar E-mail
                                        </Button> 
                                    </GradientBorder> 
                                </FormControl>
                            </Stack>  
                            <Flex
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='center'
                                maxW='100%'
                                mb='20px'> 
                            </Flex> 
                        </Flex>   
                    </Flex>   
            </Box> 
        )
       
    }
    formDados(lan){ 
        var nascimento = new Date(lan.dados.nascimento).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit'})
        
        return(
            <Box pos={'relative'}   borderRadius={'20px'} as={'form'}  onSubmit={(e)=>{
                e.preventDefault();  
                const formdata = new FormData(e.target);
                const json = {};  
                formdata.forEach(function(value, prop){ 
                    json[prop] = value;
                });   
                G.socket.removeAllListeners('formDados').on('formDados',(result)=>{   
                    if(result.cmd =='cmdDados'){
                        console.log(result);
                        this.atencao = <BoxWindow titulo = {result.result.titulo} mensagem={result.result.mensagem} onClose={()=>{
                                this.atencao = null;
                                this.setState({});
                        }}/>
                        this.setState({});   
                        
                    }
                   
                }); 
                G.socket.emit("formDados", {modulo:'afiliado',cmd:'cmdDados',form:json}); 
                this.atencao = <BoxWindow load = {true}/>
                this.setState({});
            }} >
            
                    <Flex   zIndex={'2'}   h={'100%'}  style={{ userSelect: "none" }}  mx={{ base: "auto", lg: "unset" }}  w={{ base: "100%" }}  p={{ base: "0px" }}>
                        
                        <Flex direction='column' w='100%' > 
                            <Stack  direction={{base:'column',sm :'row'}} gap={{ base: "0px", sm: "20px"}}>
                                <FormControl> 
                                    <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='green.500'>
                                        Seu nome
                                    </FormLabel>
                                    <GradientBorder  mb={{base:'0px',sm:'10px'}} w={{ base: "100%"}} borderRadius='20px'>
                                        <InputGroup size='lg'>
                                            <InputLeftElement width='3rem'>
                                                <Icon as={FaIdCard} boxSize={5}  color="green.500"/> 
                                            </InputLeftElement>
                                            <Input
                                                pl='3rem'
                                                as={InputMask} 
                                                 
                                                maskChar= ''
                                                alwaysShowMask= {true}
                                                fontSize={{base:'small',sm:'sm'}} 
                                                placeholder="Nome Completo"
                                                bg={'tema1.200'}
                                                border={0}
                                                name="nome" 
                                                defaultValue={lan.dados.nome}
                                                color={'tema1.100'}
                                                _placeholder={{
                                                    color: 'gray.300',
                                                }}
                                            /> 
                                        </InputGroup>
                                    </GradientBorder>
                                </FormControl>  
                            </Stack>
                            <Stack  direction={{base:'column',md :'row'}} gap={{ base: "0px", md: "20px"}}>
                                <Stack direction={{base:'column',sm :'row'}} gap={{ base: "0px", sm: "20px"}} pr={{ base: "0px", sm: "20px", md: "2px"}}>
                                    <FormControl minW={{base:'100%',sm:'50%',md:'200px'}}>
                                        <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='green.500'> 
                                        Telefone
                                        </FormLabel>
                                        <GradientBorder  mb={{base:'0px',sm:'10px'}} w={{ base: "100%"}} borderRadius='20px'>
                                            <InputGroup size='lg'> 
                                                <Input  
                                                    id='telefone'  
                                                    border={0} 
                                                    placeholder='Telefone' 
                                                    bg={'tema1.200'} 
                                                    name="telefone"
                                                    as={PhoneInput} 
                                                    country={'BR'}
                                                    fontSize={{base:'small',sm:'sm',md:'lg'}} 
                                                    value={'+'+getCountryCallingCode(lan.dados.telefoneCountry)+''+lan.dados.telefone.replace('+'+getCountryCallingCode(lan.dados.telefoneCountry),'')}  
                                                    color={'tema1.100'}
                                                    _placeholder={{
                                                        color: 'gray.300',
                                                    }}
                                                    onChange={(e)=>{
                                                        
                                                    }}
                                                /> 
                                            </InputGroup> 
                                        </GradientBorder>
                                    </FormControl>
                                    <FormControl minW={{base:'100%',sm:'50%',md:'150px'}}>
                                        <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='green.500'>
                                            Nascimento
                                        </FormLabel>
                                        <GradientBorder  mb={{base:'0px',sm:'10px'}} w={{ base: "100%"}} borderRadius='20px'>
                                            <InputDate name='nascimento' defaultValue={nascimento}  />
                                            {/* <InputGroup size='lg'> 
                                                <InputLeftElement width='3rem' display={{base:'flex',sm:'none'}}>
                                                    <Icon as={FaCalendarDay} boxSize={5}  color="green.500"/> 
                                                </InputLeftElement>
                                                <Input 
                                                    type="text"  
                                                    bg={'tema1.200'}
                                                    border={0} 
                                                    name="nascimento"
                                                    
                                                    defaultValue={lan.dados.nascimento}  
                                                    textAlign={'left'}
                                                    fontSize={{base:'small',sm:'sm'}}
                                                    color={'tema1.100'}  
                                                    placeholder={'dd/mm/aaaa'} 
                                                    onFocus={(el)=>{
                                                        el.type='date'; 
                                                    }}
                                                    // onfocus="(this.type='date')"
                                                    // onblur="(this.type='text')"
                                                    _placeholder={{
                                                        color: 'gray.300',
                                                    }}
                                                /> 
                                            </InputGroup>  */}
                                        </GradientBorder>
                                    </FormControl>  
                                </Stack>                
                                <Stack  direction={{base:'column',sm :'row'}} gap={{ base: "0px", sm: "20px"}}>
                                    <FormControl   > 
                                        <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='green.500'>
                                        Seu país
                                        </FormLabel>
                                        <GradientBorder  mb={{base:'20px',sm:'10px'}} w={{ base: "100%"}} borderRadius='20px'>
                                            <InputGroup size='lg'> 
                                                <InputLeftElement width='3rem' >
                                                    <Icon as={FaGlobeAmericas} boxSize={5}  color="green.500"/> 
                                                </InputLeftElement>
                                                <Input 
                                                    as={InputMask}
                                                    fontSize={{base:'small',sm:'sm'}}
                                                    alwaysShowMask= {true} 
                                                    placeholder="Nacionalidade" 
                                                    defaultValue={lan.dados.pais}  
                                                    bg={'tema1.200'}
                                                    border={0}
                                                    name="pais"
                                                    color={'tema1.100'}
                                                    _placeholder={{
                                                        color: 'gray.300',
                                                    }}
                                                /> 
                                            </InputGroup>
                                        </GradientBorder>
                                    </FormControl> 
                                </Stack>
                                
                                
                            </Stack>  
                            
                            <Flex
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='center'
                                maxW='100%'
                                mb='20px'>
                                    <Button
                                    colorScheme='blue'
                                    fontSize='14px'
                                    type='submit'
                                    bg={'green.500'}
                                    leftIcon={<FaSignInAlt />}
                                    w='fit-content'
                                    size='lg'
                                    mt='0px'
                                    _hover={{
                                        bg: 'tema1.300',
                                        boxShadow: 'xl',
                                        color:'green.500'
                                    }} 
                                    mb='0px'>
                                    Salvar Dados
                                </Button> 
                            </Flex> 
                        </Flex>   
                    </Flex>   
            </Box> 
        )
       
    }
    render(){ 
          
       var lan =this.props.lan; 
        var show = false;  
        return (
            <>
                <Modal  isOpen={true}  size={{base:'2xl' }} onClose={this.props.cmdFechar}> 
                    <ModalOverlay
                        bg='rgba(0,200,0,0.5)' 
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                    />
                    <ModalContent bg={'tema1.200'}>
                        <ModalHeader color={'green.500'} w='auto'>Configurar {lan.usuario}</ModalHeader> 
                        <ModalCloseButton  color={'tema1.100'}/> 
                        <Stack   rounded={'xl'}  p={{ base: '20px' }}  pt ='0' spacing={{ base: 0}}  align={'center'} justify={'center'}>
                        
                        <Tabs defaultIndex={2} onChange={(i)=>{
                            this.aba= i;
                        }} w={'100%'}>
                            <TabList>
                                <Tab color={'tema1.100'} _selected={{color:'green.500',borderColor:'green.500'}}>
                                    <Text>Dados</Text>
                                </Tab> 
                                <Tab  color={'tema1.100'} _selected={{color:'green.500',borderColor:'green.500'}}>
                                    <Text>E-mail</Text>
                                </Tab> 
                                <Tab color={'tema1.100'} _selected={{color:'green.500',borderColor:'green.500'}}>
                                    <Text>Senha</Text>
                                </Tab> 
                            </TabList>
                            <TabPanels >
                                <TabPanel>
                                    {this.formDados(lan)}
                                </TabPanel>
                                <TabPanel>
                                    {this.formEmail(lan)}
                                </TabPanel> 
                                <TabPanel>
                                    {this.formSenha(lan)}
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                         
                        
                            
                            {/* <Flex  w={{base:'100%'}}  direction={{ base: 'column', md: 'column' }}  textAlign="center"> 
                                <Box   as={'form'}  onSubmit={(e)=>{
                                    e.preventDefault();   
                                    const formdata = new FormData(e.target);
                                    const json = {};  

                                    formdata.forEach(function(value, prop){ 
                                        json[prop] = value;
                                    });   
                                    G.socket.removeAllListeners('formDados').on('formDados',(response)=>{     
                                        if(response.cmd === "cadastroVoucher"){    
                                            this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                                                if(!response.result.erro){ 
                                                    this.props.cmdFechar(); 
                                                }
                                                this.atencao = null;
                                                this.setState({});
                                            }}/> 
                                            this.setState({});  
                                        } 
                                    }); 
                                    G.socket.emit("formDados", {
                                        modulo  :   'financeiro',
                                        cmd     :   'cadastroVoucher',
                                        form    :   json
                                    });   
                                
                                    this.atencao = <BoxWindow load = {true}/>
                                    this.setState({});
                                }}   bg='transparent' w={{base:'100%'}}  borderRadius={'lg'}> 
                                    <InputAutocomplete label={'Afiliado'} name='codusu'  placeholder='Selecione um afiliado...'  leftIcon={FaUser} lista={Object.values(usu).map((u)=>{
                                        return {valor:u.id,nome:u.usuario+' - '+u.nome}
                                    })}/> 
                                    <Stack direction={{base:'column',md:'row'}} gap={{base:'0px',md:'30px'}}>
                                        <InputAutocomplete label={'Pacote'} name='id' placeholder='Selecione um plano...'   leftIcon={FaRocket} lista={Object.values(pla).map((u)=>{
                                            return {valor:u.id,nome:u.nome}
                                        })}/> 
                                        <InputAutocomplete  label={'Rendimento'} name='rendimento' placeholder='Selecione um rendimento...'  leftIcon={FaChartLine} lista={
                                            [{valor:0,nome: 'Sem rendimento'},{valor:30,nome:'30% rendimento'},{valor:100,nome:'100% rendimento'}]
                                        }/> 
                                    </Stack> 
                                    <Button
                                        type="submit"
                                        fontFamily={'heading'} 
                                        mt={'20px'}
                                        w={'full'}
                                        bgGradient="linear(to-r, tem1.100,tem1.100)"
                                        color={'white'}
                                         >
                                        Adicionar Voucher
                                    </Button> 
                                </Box> 
                            </Flex>  */}
                            
                            
                         
                        
                        </Stack>
                        {this.atencao}
                        {this.atencaoB}
                    </ModalContent> 
                </Modal>
            </>
        )
    }
}
export default BoxConfigAfiliado;