import * as React from "react"  
import { Avatar, Box, Button,  Flex, Grid, HStack, Icon, SimpleGrid, Spacer, Stack, Stat, StatHelpText, StatLabel, StatNumber, Text, VStack } from "@chakra-ui/react"; 
import {Helmet} from "react-helmet"; 
import G from '../../components/WebSocket';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaArrowCircleRight, FaChartBar, FaChartLine, FaChartPie, FaCheck, FaCoins, FaDollarSign, FaExchangeAlt, FaFilter, FaPencilAlt, FaPlus, FaProjectDiagram, FaRegCalendarAlt, FaRobot, FaRocket, FaStackExchange, FaUsers } from "react-icons/fa";
import IconBox from "../../components/VisionUI/Icons/IconBox";
import Card from "../../components/VisionUI/Card/Card";
import CardBody from "../../components/VisionUI/Card/CardBody";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
import GradientBorder from "../../components/VisionUI/GradientBorder";
import { RiMastercardFill, RiNumber1 } from "react-icons/ri";
import TransactionRow from "../../components/VisionUI/Tables/TransactionRow";
import { TbNumber1, TbNumber2 , TbNumber3 , TbNumber4 , TbNumber5 , TbNumber6 } from "react-icons/tb";

import ListaRede from "./ListaBinario";
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import { GiPayMoney } from "react-icons/gi";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown, MdPayment } from "react-icons/md";
class PageBinario extends React.Component { 
    jogos = 0;
    robos = 0;
    load  = false;
    rede  =  [];
    db = {
        saldos:{},
        pontos:{}
    }
    buscarJogos(){
        var reload = false;
        
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.saldos!=='undefined' && this.tempSaldos != JSON.stringify(G.geral.saldos)){
            this.tempSaldos = JSON.stringify(G.geral.saldos); 
            this.db.saldos = G.geral.saldos; 
          
            reload = true;
        } 
        if(typeof G.geral!=='undefined' && G.geral!= null  && typeof G.geral.pontos!=='undefined' && this.tempPont != JSON.stringify(G.geral.pontos)){
            this.tempPont = JSON.stringify(G.geral.pontos);  
            this.db.pontos = G.geral.pontos; 
            reload = true; 
        } 
        if(typeof G.geral!=='undefined' && G.geral!= null  && typeof G.geral.rede!=='undefined' && this.tempStat != JSON.stringify(G.geral.rede)){
            this.tempStat = JSON.stringify(G.geral.rede);  
            reload = true;
            this.load = true; 
        }  
        if(reload){
            this.setState({}); 
        }
    } 
    componentDidMount(){
        this.buscarJogos();
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    } 
    render(){   
        if(this.load){ 
            var usu = G.painel;

            return (
                <>
                    <Helmet> 
                        <title>Inovatyon - Binário</title>
                        <meta name="title" content="Inovatyon" />
                        <meta name="description" content="" />
    
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content="https://inovatyon.com" />
                        <meta property="og:title" content="Inovatyon Tach labs" />
                        <meta property="og:description" content="" />
                        <meta property="og:image" content="https://inovatyon.com/assets/img/logobk.png" />
    
                        <meta property="twitter:card" content="summary_large_image" />
                        <meta property="twitter:url" content="https://inovatyon.com" />
                        <meta property="twitter:title" content="Inovatyon Tach labs" />
                        <meta property="twitter:description" content="" />
                        <meta property="twitter:image" content="https://inovatyon.com/assets/img/logobk.png" />
                    </Helmet>  
                    <SimpleGrid columns={{ base: "2" , sm: "4"}} spacing={{base:'10px',sm:'20px'}}   p={{base:'10px 10px',sm:'10px 20px'}} pt={{base:'10px',sm:'30px'}}>
                        <Card minH={{base:'60px',md:'83px'}} p={{base:'12px',md:'15px',md:'20px'}}>
                            <CardBody >
                                <Flex flexDirection='row' align='center' justify='center' textAlign={'left'}  w='100%'>
                                    <Stat me='auto' >
                                        <StatLabel fontSize={{base:'xs',md:'xs'}} color='gray.200' pb='2px'>
                                            Ganho Total
                                        </StatLabel>
                                        <Flex>
                                            <Stack gap={0}>
                                                <StatNumber fontSize={{base:'sm',lg:'lg'}} color='#fff'>
                                                {G.parseValor((this.db.pontos.pago*0.1),4).p},<Text as='span' fontSize={'10px'}>{G.parseValor((this.db.pontos.pago*0.1),4).d}</Text>
                                                </StatNumber> 
                                                <StatHelpText
                                                    textAlign={'left'}
                                                    alignSelf='center'
                                                    justifySelf='flex-start'
                                                    m='0px'
                                                    w='100%'
                                                    color='green.500'
                                                    fontWeight='bold' 
                                                    fontSize={{base:'xs',lg:'xs'}} >
                                                    USDT
                                                </StatHelpText> 
                                            </Stack>
                                            
                                        </Flex> 
                                    </Stat>
                                    <IconBox as='box' h={{base:'30px',lg:'45px'}} w={{base:'30px',lg:'45px'}} bg='green.500' >
                                        <Icon as={FaCoins} color={'tema1.300'} fontSize={{base:'16px',lg:'22px'}}></Icon>
                                    </IconBox>
                                </Flex>
                            </CardBody> 
                        </Card>
                        <Card minH={{base:'60px',md:'83px'}} p={{base:'12px',md:'15px',md:'20px'}}>
                            <CardBody >
                                <Flex flexDirection='row' align='center' justify='center' textAlign={'left'}  w='100%'>
                                    <Stat me='auto' >
                                        <StatLabel fontSize={{base:'xs',md:'xs'}} color='gray.200' pb='2px'>
                                            Pontos Pago
                                        </StatLabel>
                                        <Flex>
                                            <Stack gap={0}>
                                                <StatNumber fontSize={{base:'sm',lg:'lg'}} color='#fff'>
                                                {G.parseValor((this.db.pontos.pago),4).p}
                                                </StatNumber> 
                                                <StatHelpText
                                                    textAlign={'left'}
                                                    alignSelf='center'
                                                    justifySelf='flex-start'
                                                    m='0px'
                                                    w='100%'
                                                    color='green.500'
                                                    fontWeight='bold' 
                                                    fontSize={{base:'xs',lg:'xs'}} >
                                                    PONTOS
                                                </StatHelpText> 
                                            </Stack>
                                            
                                        </Flex> 
                                    </Stat>
                                    <IconBox as='box' h={{base:'30px',lg:'45px'}} w={{base:'30px',lg:'45px'}} bg='green.500' >
                                        <Icon as={MdPayment} color={'tema1.300'} fontSize={{base:'16px',lg:'22px'}}></Icon>
                                    </IconBox>
                                </Flex>
                            </CardBody> 
                        </Card>
                        <Card minH={{base:'60px',md:'83px'}} p={{base:'12px',md:'15px',md:'20px'}}>
                            <CardBody >
                                <Flex flexDirection='row' align='center' justify='center' textAlign={'left'}  w='100%'>
                                    <Stat me='auto' >
                                        <StatLabel fontSize={{base:'xs',md:'xs'}} color='gray.200' pb='2px'>
                                            Pontos Esquerda
                                        </StatLabel>
                                        <Flex>
                                            <Stack gap={0}>
                                                <StatNumber fontSize={{base:'sm',lg:'lg'}} color='#fff'>
                                                {G.parseValor((this.db.pontos.pontoE),4).p}
                                                </StatNumber> 
                                                <StatHelpText
                                                    textAlign={'left'}
                                                    alignSelf='center'
                                                    justifySelf='flex-start'
                                                    m='0px'
                                                    w='100%'
                                                    color='green.500'
                                                    fontWeight='bold' 
                                                    fontSize={{base:'xs',lg:'xs'}} >
                                                    PONTOS
                                                </StatHelpText> 
                                            </Stack>
                                            
                                        </Flex> 
                                    </Stat>
                                    <IconBox as='box' h={{base:'30px',lg:'45px'}} w={{base:'30px',lg:'45px'}} bg='green.500' >
                                        <Icon as={FaArrowAltCircleLeft} color={'tema1.300'} fontSize={{base:'16px',lg:'22px'}}></Icon>
                                    </IconBox>
                                </Flex>
                            </CardBody> 
                        </Card>
                        <Card minH={{base:'60px',md:'83px'}} p={{base:'12px',md:'15px',md:'20px'}}>
                            <CardBody >
                                <Flex flexDirection='row' align='center' justify='center' textAlign={'left'}  w='100%'>
                                    <Stat me='auto' >
                                        <StatLabel fontSize={{base:'xs',md:'xs'}} color='gray.200' pb='2px'>
                                            Pontos Direita
                                        </StatLabel>
                                        <Flex>
                                            <Stack gap={0}>
                                                <StatNumber fontSize={{base:'sm',lg:'lg'}} color='#fff'>
                                                    {G.parseValor(this.db.pontos.pontoD,4).p}
                                                </StatNumber> 
                                                <StatHelpText
                                                    textAlign={'left'}
                                                    alignSelf='center'
                                                    justifySelf='flex-start'
                                                    m='0px'
                                                    w='100%'
                                                    color='green.500'
                                                    fontWeight='bold' 
                                                    fontSize={{base:'xs',lg:'xs'}} >
                                                    PONTOS
                                                </StatHelpText> 
                                            </Stack>
                                            
                                        </Flex> 
                                    </Stat>
                                    <IconBox as='box' h={{base:'30px',lg:'45px'}} w={{base:'30px',lg:'45px'}} bg='green.500' >
                                        <Icon as={FaArrowAltCircleRight} color={'tema1.300'} fontSize={{base:'16px',lg:'22px'}}></Icon>
                                    </IconBox>
                                </Flex>
                            </CardBody> 
                        </Card> 
                    </SimpleGrid>
                    
                    <Flex position={'relative'} direction={{base:'column'}}  w={{base:'100%'}} spacing={0} pt={{base:'0px',sm:'0px'}} px={{base:'10px',sm:'20px'}}>  
                        
                       
                        <Stack position={'relative'} direction='column' overflow={'auto'}  w={{base:'100%',lg:'100%'}} spacing={{base:'0px',lg:'20px'}} p={{base:'0px 0px',sm:'10px 0px'}}   > 
                            <ListaRede  openEdit={(dados)=>{ }} />
                            <Flex position={'relative'} minW={'min-content'} p={'0 20px'} maxW={'max-content'} >
                                
                            </Flex>   
                            
                             {/* <Card p='0 16px' mt={{base:'0px',lg:'0'}} bg='transparent' maxW={'100%'}   >
                                <CardHeader maxW={'100%'} >
                                    <Flex
                                        justify='center'
                                        align='center'
                                        minHeight='60px'
                                        w='100%'>
                                        <Text fontSize='lg' color='#fff' fontWeight='bold'>
                                        Rede Binária
                                        </Text> 
                                    </Flex> 
                                </CardHeader>
                                <CardBody  maxW={'100%'} >
                                    
                                    
                                </CardBody>
                            </Card>  */}
                        </Stack> 
                    </Flex>
                     
                    {/* 
                         <Stack   align='center' color={'tema1.400'} justify='center' spacing={'0'} py={'10px'}>
                             <Text w={'100%'} noOfLines={1} textAlign='left'  fontSize="small"  > {(usu.email!==null?usu.email:'Olá, '+usu.nome.split(' ')[0])}</Text>
                             <Text as={'b'} w={'100%'}   textAlign='left'  fontSize="x-small"  >Apostas em andamento <Text  as={'span'}    color="green.500"> 0</Text></Text>
                             <Text  as={'b'} w={'100%'} noOfLines={1} textAlign='left'  fontSize="lg" color="green.500"> 0,00 {G.painel.moeda}</Text>
                         </Stack>   
                         <Spacer/>
                         <Stack  align='center' justify='center'>
                             <Button colorScheme={'green'} fontSize={'xx-small'} leftIcon={<FaRobot />} bg={'green.500'} lineHeight={'26px'} maxH={'26px'}  color={'tema1.400'} _hover={{color:'tema1.200'}}>CRIAR UM NOVO BOT</Button> 
                         </Stack>
                    </Flex> 
                    <Stack   display={{base:'flex',sm:'none'}} flexDir={'row'} w={{base:'100%'}} px='0px' align='left' spacing='10px'> 
                        <Stack bg={bg} borderRadius={'lg'}  w={'50%'}  align='center' color={'tema1.400'} justify='center'  p={'10px'} >
                            <Stack  flexDir={'row'}   w={'100%'} align='left' color={'tema1.400'} justify='left' py='0px' spacing='5px'>
                                <Text as={'b'}    textAlign='left'  fontSize="x-small"  >Jogos ao vivo  </Text><Text  as={'p'}    textAlign='left'  fontSize="x-small"   color="yellow.500"> BETFAIR</Text> 
                            </Stack> 
                            <Text as={'b'} w={'100%'}    textAlign='left'  fontSize="xl" p='0px'  >{this.jogos}</Text>
                        </Stack>  
                        <Stack bg={bg} borderRadius={'lg'}  w={'50%'}  align='center' color={'tema1.400'} justify='center'  p={'10px'} >
                            <Stack  flexDir={'row'}   w={'100%'} align='left' color={'tema1.400'} justify='left' py='0px' spacing='5px'>
                                <Text as={'b'}    textAlign='left'  fontSize="x-small"  >Bots Ativos</Text> 
                            </Stack> 
                            <Text as={'b'} w={'100%'}    textAlign='left'  fontSize="xl" p='0px'  >{this.robos}</Text>
                        </Stack>  
                     </Stack> 
                   
                    <Flex  w={'full'} flex={1} flexDir={{base:'column',md:'row'}}  maxH={{base:'auto',sm:'100%'}}  px={{ base: 0,  sm: '10px' }} overflow={{base:'auto',sm:'hidden'}} > 
                        <Stack  display={{base:'none',sm:'flex'}} w={{base:'100%',md:'50%'}}  overflow={{base:'initial',sm:'hidden'}} overflowY={{base:'initial',sm:'auto'}}   >
                            <ListaCategorias/> 
                        </Stack>
                        <Stack pl={{base:'0',md:'0px'}} w={{base:'100%',md:'50%'}} flexDir={'column'}   minH={{base:'auto',sm:'100%'}} >
                            <ListaApostas/>
                            <ListaApostas cmd = 'historico' />
                        </Stack>
                    </Flex>   */}
                </>
            )
        }else{
            return <BoxWindow load ={true} />
        }
        
    }
}
export default PageBinario;