import * as React from "react"  
import { Box, Button, Center, Flex, FormControl, FormLabel, Grid, Heading, Icon, Image, Img, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverTrigger, Select, Spacer, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea } from "@chakra-ui/react";
 
import  InputMask  from 'react-input-mask';   
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import G            from '../../components/ADMSocket';
import QRCode from "react-qr-code";
import Card from "../../components/VisionUI/Card/Card";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
import { FaChartLine, FaCheck, FaClock, FaCopy, FaInfo, FaRocket, FaTimes, FaUser } from "react-icons/fa";
import GradientBorder from "../../components/VisionUI/GradientBorder";
import InputAutocomplete from "../../components/Padrao/InputAutocomplete";
import { MdWarning } from "react-icons/md";

class BoxAprovarDocumento extends React.Component { 
    open = null;
    show1 = false;
    show2 = false;
    show3 = false;
    atencao =   null; 
    aba = 0;
    openBox = false;
    cmdFormView(cmd,campo){
        var onOpen = ()=>{};
        var onClose = ()=>{
           this.openBox =false;
           this.setState({})
        }; 
       var lan =this.props.lan; 
        return <Stack  direction='row'  w={'100%'} textAlign="center" justify="space-between" pb='15px' > 
            {lan[campo]=='P' || lan[campo]=='R'?
            <Button  onClick={async (e)=>{ 
                e.preventDefault();    
                G.socket.removeAllListeners('formDados').on('formDados',(response)=>{   
                    // console.log(response);
                    if(response.cmd === "cmdDocumento"){    
                        this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                            if(!response.result.erro){
                                
                                lan[campo]='N';
                                // this.props.cmdFechar(); 
                            }
                            this.atencao = null;
                            this.setState({});
                        }}/> 
                        this.setState({});  
                    } 
                }); 
                G.socket.emit("formDados", {
                    modulo  :   'afiliado',
                    cmd     :   'cmdDocumento',
                    form    :   {
                        id       :  lan.id,
                        cmd      :  cmd,  
                        obs      :  'OK'
                    }
                });  
                this.atencao = <BoxWindow load = {true}/>
                this.setState({});
                }} p={'0px 10px'} h={'30px'} leftIcon={<FaCheck fontSize={'18px'}/>} fontSize='12px' bg={'green.500'} color={'tema1.200'} variant='solid'>
                    Aprovar Documento
                </Button>
            :null} 
            <Spacer/>

            <Popover
                isOpen={this.openBox}
                // initialFocusRef={firstFieldRef}
                onOpen={()=>{
                    this.obs = '';
                }}
                onClose={onClose}
                placement='bottom'
                closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button  onClick={async (e)=>{ 
                        e.preventDefault();     
                        this.openBox=true;
                        this.setState({}); 
                    }} p={'0px 10px'} h={'30px'} leftIcon={<MdWarning fontSize={'18px'}/>} fontSize='12px' bg={'red.500'} color={'tema1.200'} variant='solid'>
                        Revisar Documento
                    </Button>
                </PopoverTrigger>
                <PopoverContent p={5} bg={'tema1.200'}  borderColor={'green.500'}>
                    <PopoverArrow bg={'green.500'} />
                    <PopoverCloseButton />
                    <FormControl>
                        <FormLabel ms='4px' fontSize='sm' fontWeight='500' color='tema1.100'>
                            Obs.:
                        </FormLabel>
                        <GradientBorder  mb='10px' w={{ base: "100%"}} borderRadius='20px'>
                            <InputGroup size='lg'>
                                <InputLeftElement width='3rem'>
                                    <Icon as={FaInfo} boxSize={5}  color="green.500"/> 
                                </InputLeftElement>
                                <Input 
                                    pl='3rem' 
                                    placeholder="Observação"
                                    bg={'tema1.200'}
                                    border={0}
                                    name="login"
                                    color={'tema1.100'}
                                    onChange={(e)=>{
                                        this.obs =  e.target.value;
                                    }}
                                    _placeholder={{
                                        color: 'gray.300',
                                    }}
                                /> 
                            </InputGroup>
                        </GradientBorder>
                    </FormControl>
                    <Button
                        type="submit"
                        fontFamily={'heading'} 
                        mt={'20px'}
                        w={'full'}
                        bgGradient="linear(to-r, tem1.100,tem1.100)"
                        color={'white'}
                        onClick={()=>{
                            G.socket.removeAllListeners('formDados').on('formDados',(response)=>{   
                                // console.log(response);
                                if(response.cmd === "cmdDocumento"){    
                                    this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                                        if(!response.result.erro){
                                            
                                            lan[campo]='R';
                                            // this.props.cmdFechar(); 
                                        }
                                        this.atencao = null;
                                        this.setState({});
                                    }}/> 
                                    this.setState({});  
                                } 
                            }); 
                            G.socket.emit("formDados", {
                                modulo  :   'afiliado',
                                cmd     :   'cmdDocumento',
                                form    :   {
                                    id       :  lan.id,
                                    cmd      :  cmd,  
                                    obs      :  this.obs
                                }
                            });  
                            this.atencao = <BoxWindow load = {true}/>
                            this.setState({});
                        }}
                        _hover={{
                            bgGradient: 'linear(to-r,green.500,green.500)',
                            boxShadow: 'xl',
                            color:'tema1.200'
                        }}>
                        Revisar Documento
                    </Button>  
                </PopoverContent>
            </Popover>
        </Stack>
    }
    render(){ 
          
       var lan =this.props.lan; 
        var show = false;  
        return (
            <>
                <Modal  isOpen={true}  size={{base:'2xl' }} onClose={this.props.cmdFechar}> 
                    <ModalOverlay
                        bg='rgba(0,200,0,0.5)' 
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                    />
                    <ModalContent bg={'tema1.200'}>
                        <ModalHeader color={'green.500'} w='auto'>Aprovar Documento</ModalHeader> 
                        <ModalCloseButton  color={'tema1.100'}/> 
                        <Stack   rounded={'xl'}  p={{ base: '20px' }}  pt ='0' spacing={{ base: 0}}  align={'center'} justify={'center'}>
                        <Flex direction={{ base: 'row', md: 'row' }}  textAlign="center" w={'100%'}> 
                            <Stack  w={'100%'} textAlign="center" justify="center"  > 
                                 
                                <Box textTransform={'uppercase'} p={4} w={'100%'} m='0 auto' shadow="base" borderWidth="1px" alignSelf={{ base: 'center', lg: 'flex-start' }}  borderColor={'tema1.500'} borderRadius={'xl'}> 
                                    <Stack  direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                        <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                            Nome
                                        </Text>
                                        <Spacer/>
                                        <Text as={'b'} fontSize="small" color={'gray.500'} px={'0px'}>
                                            {lan.nome}
                                        </Text> 
                                    </Stack>
                                    <Stack  direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                        <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                            Endereço
                                        </Text>
                                        <Spacer/>
                                        <Text as={'b'} fontSize="small" color={'gray.500'} px={'0px'}>
                                            {lan.endereco}
                                        </Text> 
                                    </Stack> 
                                </Box>  
                            </Stack>
                        </Flex>
                        <Tabs onChange={(i)=>{
                            this.aba= i;
                        }} w={'100%'}>
                            <TabList>
                                <Tab color={lan.docF=='R'?'red.500': lan.docF=='N'?'green.400':'orange.500'} _selected={{borderColor:'green.500'}}>
                                    {lan.docF=='R'?<Icon as={MdWarning} mr={'10px'} />:lan.docF=='N'?<Icon as={FaCheck} mr={'10px'} />:<Icon as={FaClock} mr={'10px'} />} <Text>Doc. Frente</Text>
                                </Tab>
                                <Tab color={lan.kyc_verso_obs=='NO'?'blue.500':lan.docV=='R'?'red.500': lan.docV=='N'?'green.600':'orange.500'} _selected={{borderColor:'green.500'}}>
                                    {lan.kyc_verso_obs=='NO'?<Icon as={FaTimes} mr={'10px'} />:lan.docV=='R'?<Icon as={MdWarning} mr={'10px'} />:lan.docV=='N'?<Icon as={FaCheck} mr={'10px'} />:<Icon as={FaClock} mr={'10px'} />} <Text>Doc. Verso</Text>
                                </Tab>
                                <Tab color={lan.self=='R'?'red.500': lan.self=='N'?'green.400':'orange.500'}_selected={{borderColor:'green.500'}}>
                                    {lan.self=='R'?<Icon as={MdWarning} mr={'10px'} />:lan.self=='N'?<Icon as={FaCheck} mr={'10px'} />:<Icon as={FaClock} mr={'10px'} />} <Text>Selfie</Text>
                                </Tab>
                                <Tab color={lan.resid=='R'?'red.500': lan.resid=='N'?'green.400':'orange.500'} _selected={{borderColor:'green.500'}}>
                                    {lan.resid=='R'?<Icon as={MdWarning} mr={'10px'} />:lan.resid=='N'?<Icon as={FaCheck} mr={'10px'} />:<Icon as={FaClock} mr={'10px'} />} <Text>Endereço</Text>
                                </Tab> 
                            </TabList>
                            <TabPanels >
                                <TabPanel>
                                    {this.cmdFormView('frente','docF')}
                                    {lan.kyc_frente.split('.')[lan.kyc_frente.split('.').length-1].toUpperCase()=='PDF'?<embed src={lan.kyc_frente} width="100%" height="375"  type="application/pdf" />:<Img src={lan.kyc_frente} w={'100%'}  m='0px 0px'  borderRadius='sm' />}
                                </TabPanel>
                                <TabPanel>
                                    {this.cmdFormView('verso','docV')}
                                    {lan.kyc_verso_obs=='NO'?<Text color={'green.500'}>Não tem Verso</Text>:lan.kyc_verso.split('.')[lan.kyc_verso.split('.').length-1].toUpperCase()=='PDF'?<embed src={lan.kyc_verso} width="100%" height="375"  type="application/pdf" />:<Img src={lan.kyc_verso} w={'100%'}  m='0px 0px'  borderRadius='sm' />}
                                </TabPanel>
                                <TabPanel>
                                    {this.cmdFormView('selfie','self')}
                                    {lan.kyc_selfie.split('.')[lan.kyc_selfie.split('.').length-1].toUpperCase()=='PDF'?<embed src={lan.kyc_selfie} width="100%" height="375"  type="application/pdf" />:<Img src={lan.kyc_selfie} w={'100%'}  m='0px 0px'  borderRadius='sm' />}
                                </TabPanel>
                                <TabPanel>
                                    {this.cmdFormView('comprovante','resid')}
                                    {lan.kyc_residencia.split('.')[lan.kyc_residencia.split('.').length-1].toUpperCase()=='PDF'?<embed src={lan.kyc_residencia} width="100%" height="375"  type="application/pdf" />:<Img src={lan.kyc_residencia} w={'100%'}  m='0px 0px'  borderRadius='sm' />}
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                         
                        
                            
                            {/* <Flex  w={{base:'100%'}}  direction={{ base: 'column', md: 'column' }}  textAlign="center"> 
                                <Box   as={'form'}  onSubmit={(e)=>{
                                    e.preventDefault();   
                                    const formdata = new FormData(e.target);
                                    const json = {};  

                                    formdata.forEach(function(value, prop){ 
                                        json[prop] = value;
                                    });   
                                    G.socket.removeAllListeners('formDados').on('formDados',(response)=>{     
                                        if(response.cmd === "cadastroVoucher"){    
                                            this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                                                if(!response.result.erro){ 
                                                    this.props.cmdFechar(); 
                                                }
                                                this.atencao = null;
                                                this.setState({});
                                            }}/> 
                                            this.setState({});  
                                        } 
                                    }); 
                                    G.socket.emit("formDados", {
                                        modulo  :   'financeiro',
                                        cmd     :   'cadastroVoucher',
                                        form    :   json
                                    });   
                                
                                    this.atencao = <BoxWindow load = {true}/>
                                    this.setState({});
                                }}   bg='transparent' w={{base:'100%'}}  borderRadius={'lg'}> 
                                    <InputAutocomplete label={'Afiliado'} name='codusu'  placeholder='Selecione um afiliado...'  leftIcon={FaUser} lista={Object.values(usu).map((u)=>{
                                        return {valor:u.id,nome:u.usuario+' - '+u.nome}
                                    })}/> 
                                    <Stack direction={{base:'column',md:'row'}} gap={{base:'0px',md:'30px'}}>
                                        <InputAutocomplete label={'Pacote'} name='id' placeholder='Selecione um plano...'   leftIcon={FaRocket} lista={Object.values(pla).map((u)=>{
                                            return {valor:u.id,nome:u.nome}
                                        })}/> 
                                        <InputAutocomplete  label={'Rendimento'} name='rendimento' placeholder='Selecione um rendimento...'  leftIcon={FaChartLine} lista={
                                            [{valor:0,nome: 'Sem rendimento'},{valor:30,nome:'30% rendimento'},{valor:100,nome:'100% rendimento'}]
                                        }/> 
                                    </Stack> 
                                    <Button
                                        type="submit"
                                        fontFamily={'heading'} 
                                        mt={'20px'}
                                        w={'full'}
                                        bgGradient="linear(to-r, tem1.100,tem1.100)"
                                        color={'white'}
                                         >
                                        Adicionar Voucher
                                    </Button> 
                                </Box> 
                            </Flex>  */}
                            
                            
                         
                        
                        </Stack>
                        {this.atencao}
                        {this.atencaoB}
                    </ModalContent> 
                </Modal>
            </>
        )
    }
}
export default BoxAprovarDocumento;