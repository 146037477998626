import * as React from "react"  
import {   Box, Button,  Flex, Grid, Icon, LightMode, Progress, SimpleGrid, Spacer, Stack, Stat, StatHelpText, StatLabel, StatNumber, Text, Textarea } from "@chakra-ui/react"; 
import {Helmet} from "react-helmet"; 
import { SiTether } from "react-icons/si";

import G from '../../components/WebSocket';
import { FaArrowDown, FaArrowUp, FaChartBar, FaChartPie, FaClock, FaCopy, FaDollarSign, FaExchangeAlt, FaPencilAlt, FaRegCalendarAlt, FaRobot, FaStackExchange } from "react-icons/fa";
import IconBox from "../../components/VisionUI/Icons/IconBox";
import Card from "../../components/VisionUI/Card/Card";
import CardBody from "../../components/VisionUI/Card/CardBody";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
import GradientBorder from "../../components/VisionUI/GradientBorder"; 
import TransactionRow from "../../components/VisionUI/Tables/TransactionRow";
import BoxWindow from "../../components/BoxWindow/BoxWindow";
class PageHome extends React.Component { 
    jogos = 0;
    tempSaldos = null;
    tempLance  = null;
    load=false;
    db={
        saldos:{},
        status:{},
        lancamentos:[],
        performance:[]
    }
    gerarStatusGerais(){ 
        var total =(G.geral.saldos.rendimento+G.geral.saldos.indicacao+G.geral.saldos.indicacaoI+G.geral.saldos.binario+G.geral.saldos.residual);
        this.db.status = { 
            rendimento:{
                p:G.validarN((G.geral.saldos.rendimento*100/total).toFixed(2)),
                v:G.validarN(G.geral.saldos.rendimento)
            },
            indicacao:{
                p:G.validarN((G.geral.saldos.indicacao*100/total).toFixed(2)),
                v:G.validarN(G.geral.saldos.indicacao)
            },
            indicacaoI:{
                p:G.validarN((G.geral.saldos.indicacaoI*100/total).toFixed(2)),
                v:G.validarN(G.geral.saldos.indicacaoI)
            },
            binario:{
                p:G.validarN((G.geral.saldos.binario*100/total).toFixed(2)),
                v:G.validarN(G.geral.saldos.binario)
            },
            residual:{
                p:G.validarN((G.geral.saldos.residual*100/total).toFixed(2)),
                v:G.validarN(G.geral.saldos.residual)
            }
        }  
    }
    select = 0;
    buscarJogos(){
        var reload = false;
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.performance!=='undefined' && this.tempPerfom != JSON.stringify(G.geral.performance)){
            this.tempPerfom = JSON.stringify(G.geral.performance); 
            this.db.performance = G.geral.performance;   
            reload = true;
        }  
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.lancamentos!=='undefined' && this.tempLance != JSON.stringify(G.geral.lancamentos)){
            this.tempLance = JSON.stringify(G.geral.lancamentos); 
            var tipos = {};
            G.geral.lancamentosTipo.forEach((lt)=>{
                tipos[lt.id]=lt;
            }) 
            this.db.lancamentos = [];
            G.geral.lancamentos.sort((a, b)=>{  
                return a.criacao > b.criacao ? -1 : a.criacao < b.criacao ? 1 : 0; 
             }).forEach((lan,i)=>{
                if(i<5){  
                    this.db.lancamentos.push({
                        name: tipos[lan.codtip].nome,
                        date: new Date(lan.criacao).toISOString().split('T')[0],
                        price: ((lan.codtip=='4' || lan.codtip=='8')?'-':'+')+""+G.parseValor(lan.valor,5).f,
                        logo:  ((lan.codtip=='4' || lan.codtip=='8')?FaArrowDown:FaArrowUp),
                    })
                } 
            })
            // console.log(G.geral);
            // [
            //     {
            //       name: "Netflix",
            //       date: "27 March 2021, at 12:30 PM",
            //       price: "- $2,500",
            //       logo: FaArrowDown,
            //     },
            //     {
            //       name: "Apple",
            //       date: "27 March 2021, at 12:30 PM",
            //       price: "+ $2,500",
            //       logo: FaArrowUp,
            //     },
            //   ]
            this.load = true;
            this.gerarStatusGerais();
            reload = true;
        }   
        if(reload){
            this.setState({}); 
        }
    } 
    componentDidMount(){
        this.buscarJogos();
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    ms = ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'];
    render(){  
        var usu = G.painel; 
        var bg = 'linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
        if(this.load){
            return (
                <>
                    <Helmet> 
                        <title>Inovatyon - Dashboard</title>
                        <meta name="title" content="Inovatyon" />
                        <meta name="description" content="" /> 
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content="https://inovatyon.com" />
                        <meta property="og:title" content="Inovatyon Tach labs" />
                        <meta property="og:description" content="" />
                        <meta property="og:image" content="https://inovatyon.com/assets/img/logobk.png" />
    
                        <meta property="twitter:card" content="summary_large_image" />
                        <meta property="twitter:url" content="https://inovatyon.com" />
                        <meta property="twitter:title" content="Inovatyon Tach labs" />
                        <meta property="twitter:description" content="" />
                        <meta property="twitter:image" content="https://inovatyon.com/assets/img/logobk.png" />
                    </Helmet>  
                    <Flex direction={{base:'column',lg:'row'}}  w={{base:'100%'}} spacing={0} pt={{base:'10px',sm:'20px'}} px={{base:'10px',sm:'20px'}}>  
                        <Stack direction='column'  w={{base:'100%',lg:'60%'}} spacing={{base:'0px',lg:'20px'}} p={{base:'0px 0px',lg:'0px 20px 0px 0px'}} pb={{base:'20px',lg:'20px'}}> 
                            <Grid templateColumns={{ sm: "1fr",  md: "1fr 1fr" }}  gap={{base:'10px',sm:'20px'}}>
                                     {/* Credit Balance */}
                                <Card    >
                                    <Flex direction='column'>
                                        <Flex
                                        justify='space-between'
                                        p='22px'
                                        mb='18px'
                                        bg='linear-gradient(127.09deg, rgba(34, 41, 78, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'
                                        borderRadius='18px'>
                                        <Flex direction='column'>
                                            <Text color='#E9EDF7' fontSize='12px'>
                                                Saldo Disponível
                                            </Text>
                                            <Text color='green.500' fontWeight='bold' fontSize='34px'>
                                                {G.parseValor(G.geral.saldos.saldo,5).p},<Text as='span'   fontSize='16px'>{G.parseValor(G.geral.saldos.saldo,5).d}</Text>
                                            </Text>
                                        </Flex>
                                        <Flex direction='column'>
                                            <Button
                                            bg='transparent'
                                            _hover={{}}
                                            _active={{}}
                                            alignSelf='flex-end'
                                            cursor='default'
                                            p='0px'>
                                            <Icon
                                                as={SiTether}
                                                color='green.500'
                                                w='24px'
                                                h='24px'
                                                justifySelf='flex-start'
                                                alignSelf='flex-start'
                                            />
                                            </Button>
                                            {/* <GraphIcon w='60px' h='18px' /> */}
                                        </Flex>
                                        </Flex>
                                        <Text fontSize='10px' color='green.500' mb='8px'>
                                        PENDENTE
                                        </Text>
                                        <Flex  w='100%'justify='space-between' align='center'>
                                            <Flex   w='100%' align='center' justify='space-between'>
                                                {/* <IconBox
                                                bg='#22234B'
                                                borderRadius='30px'
                                                w='42px'
                                                h='42px'
                                                color='green.500'
                                                me='10px'>
                                                    <FaClock /> 
                                                </IconBox> */}
                                                <Flex direction='column' align={'left'} justify={'left'} textAlign={'left'}>
                                                    <Text color={this.db.saldos.saldoP>0?'orange.300':'tema1.100'} fontSize='sm' mb='2px'>
                                                        Rendimentos
                                                    </Text>
                                                    <Text color={this.db.saldos.saqueP>0?'orange.300':'tema1.100'} fontSize='sm' mb='2px'>
                                                        Saques
                                                    </Text>
                                                </Flex>
                                                <Spacer/>
                                                <Flex direction='column' align={'left'} justify={'left'} textAlign={'right'}>
                                                    <Text color={this.db.saldos.saldoP>0?'orange.300':'tema1.100'} fontSize='sm' fontWeight={'bold'} mb='2px'>
                                                        {G.parseValor(this.db.saldos.saldoP,3).f}
                                                    </Text>
                                                    <Text color={this.db.saldos.saqueP>0?'orange.300':'tema1.100'} fontSize='sm' mb='2px' fontWeight={'bold'}>
                                                        {G.parseValor(this.db.saldos.saqueP,3).f}
                                                    </Text>
                                                </Flex>
                                            </Flex> 
                                        </Flex>
                                    </Flex>
                                </Card>
                                {/* MasterCard */}
                                <Card
                                // backgroundImage={BackgroundCard1}
                                    backgroundRepeat='no-repeat'
                                    bgSize='cover'
                                    bgPosition='10%'
                                    p='16px' 
                                    >
                                    <Text
                                        color='#fff'
                                         w='100%'
                                         textAlign='left'
                                        fontSize={{ sm: "lg", md: "xl", lg: "lg" }}
                                        fontWeight='bold'
                                        pb='10px'
                                        >
                                        Balanço Geral
                                    </Text>
                                    <Flex direction='column' w='100%' h='100%'>
                                        <Stack direction='column' spacing='10px' w='100%' mb='auto'>
                                            <Flex direction='column'>
                                                <Flex justify='space-between' mb='8px'>
                                                    <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                        Rendimento
                                                    </Text>
                                                    <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                    {this.db.status.rendimento.p}%
                                                    </Text>
                                                </Flex>
                                                <LightMode>
                                                    <Progress
                                                    bg='#2D2E5F'
                                                    colorScheme='green'
                                                    size='xs'
                                                    value={this.db.status.rendimento.p}
                                                    borderRadius='15px'></Progress>
                                                </LightMode>
                                            </Flex>
                                            <Flex direction='column'>
                                                <Flex justify='space-between' mb='8px'>
                                                    <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                        Indicação Direta
                                                    </Text>
                                                    <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                        {this.db.status.indicacao.p}%
                                                    </Text>
                                                </Flex>
                                                <LightMode>
                                                    <Progress
                                                    bg='#2D2E5F'
                                                    colorScheme='green'
                                                    size='xs'
                                                    value={this.db.status.indicacao.p}
                                                    borderRadius='15px'/>
                                                </LightMode>
                                            </Flex>
                                            <Flex direction='column'>
                                                <Flex justify='space-between' mb='8px'>
                                                    <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                        Indicação Indireta
                                                    </Text>
                                                    <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                    {this.db.status.indicacaoI.p}%
                                                    </Text>
                                                </Flex>
                                                <LightMode>
                                                    <Progress
                                                    bg='#2D2E5F'
                                                    colorScheme='green'
                                                    size='xs'
                                                    value={this.db.status.indicacaoI.p}
                                                    borderRadius='15px'/>
                                                </LightMode>
                                            </Flex>
                                            <Flex direction='column'>
                                                <Flex justify='space-between' mb='8px'>
                                                    <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                        Binário
                                                    </Text>
                                                    <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                    {this.db.status.binario.p}%
                                                    </Text>
                                                </Flex>
                                                <LightMode>
                                                    <Progress
                                                    bg='#2D2E5F'
                                                    colorScheme='green'
                                                    size='xs'
                                                    value={this.db.status.binario.p}
                                                    borderRadius='15px'/>
                                                </LightMode>
                                            </Flex>
                                            <Flex direction='column'>
                                                <Flex justify='space-between' mb=' px'>
                                                    <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                        Residual
                                                    </Text>
                                                    <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                    {this.db.status.residual.p}%
                                                    </Text>
                                                </Flex>
                                                <LightMode>
                                                    <Progress
                                                    bg='#2D2E5F'
                                                    colorScheme='green'
                                                    size='xs'
                                                    value={this.db.status.residual.p}
                                                    borderRadius='15px'/>
                                                </LightMode>
                                            </Flex>
                                        </Stack> 
                                    </Flex> 
                                </Card>
                               
                            </Grid>
                            <Card p='0 16px' mt={{base:'10px', sm:'20px',lg:'0'}} >
                                <CardHeader>
                                    <Flex
                                        justify='space-between'
                                        align='center'
                                        minHeight='60px'
                                        w='100%'>
                                        <Text fontSize='lg' color='#fff' fontWeight='bold'>
                                        Link Indicação
                                        </Text>
                                        <Button p={'0px 10px'} h={'30px'} leftIcon={<FaCopy fontSize={'18px'}/>} fontSize='12px' bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                            e.preventDefault();   
                                            var Url = document.getElementById("copyText");
                                            Url.select()  
                                            document.execCommand("copy");
                                            this.atencaoB = <BoxWindow titulo = {'Link copiado com sucesso!'} mensagem={'Você copiou o link indicação na memória, agora é só enviar para seu afiliado!'} onClose={()=>{
                                                this.atencaoB = null;
                                                this.setState({});
                                            }} />
                                            this.setState({});
                                        }}>
                                            Copiar Link
                                        </Button>
                                    </Flex>
                                </CardHeader>
                                <CardBody>
                                    <Flex
                                        direction={{ sm: "column", md: "row" }}
                                        align='center'
                                        w='100%'
                                        justify='center'
                                        py='1rem'>
                                        <GradientBorder 
                                        w='100%'
                                        borderRadius='20px'>
                                            <Flex
                                                p='0'
                                                bg='tema1.200'
                                                border='transparent'
                                                borderRadius='20px'
                                                align='center'
                                                w='100%'>
                                               <Textarea id="copyText" p={'20px'} lineHeight={'20px'} minH={'60px'}  borderRadius='20px' overflow='hidden' bg='tema1.200' color={'green.500'}   border='0px solid' borderColor='tema1.500' isReadOnly textAlign={'center'}    w={{base:'100%'}} h='40px' resize='none' onClick={(e)=>{ 
                                                    e.preventDefault();   
                                                    var Url = document.getElementById("copyText");
                                                    Url.select()  
                                                    document.execCommand("copy");
                                                    this.atencaoB = <BoxWindow titulo = {'Cópia realizada com sucesso!'} mensagem={'Você copiou o link indicação na memória, agora é só enviar para seu afiliado!'} onClose={()=>{
                                                        this.atencaoB = null;
                                                        this.setState({});
                                                    }} />
                                                    this.setState({});
                                                }} defaultValue={'https://inovatyon.com/register/'+G.painel.usuario}
                                                />
                                            </Flex>
                                        </GradientBorder>
                                        
                                    </Flex>
                                </CardBody>
                            </Card> 
                        </Stack>
                        <Stack direction='column'    w={{base:'100%',lg:'40%'}} spacing={{base:'0'}} pb='20px'   > 
                            <Card  h={'100%'}   >
                                <CardHeader  minHeight={'max-content'} >
                                    <Flex direction='column' w='100%' minH={'max-content'}>
                                    <Flex
                                        direction={{ sm: "column", lg: "row" }}
                                        justify={{ sm: "center", lg: "space-between" }}
                                        align={{ sm: "center" }}
                                        w='100%'
                                        
                                        >
                                        <Text
                                        color='#fff'
                                        fontSize={{ sm: "lg", md: "xl", lg: "lg" }}
                                        fontWeight='bold'>
                                        Últimas Transações
                                        </Text> 
                                    </Flex>
                                    </Flex>
                                </CardHeader>
                                <CardBody mt='15px' >
                                    <Flex direction='column' w='100%'> 
                                        {
                                            (this.db.lancamentos.length>0)?
                                            this.db.lancamentos.map((row,key) => {
                                                return (
                                                     
                                                        <TransactionRow
                                                            key={'key'+key} 
                                                            name={row.name}
                                                            logo={row.logo}
                                                            date={row.date}
                                                            price={row.price}
                                                        /> 
                                                    
                                                );
                                            }):<Text color='green.500'>Nenhum Lançamento encontrado</Text>
                                        }  
                                    </Flex>
                                </CardBody>
                            </Card>
                        </Stack>
                    </Flex>
                    <Stack direction='column'    w={{base:'100%',lg:'100%'}} spacing={{base:'0'}} p={{base:'0px 10px 0px 10px',sm:'0px 20px 0px 20px',lg:'0px 20px 20px 20px' }}  > 
                        <Card  h={'100%'}   >
                            <CardHeader  minHeight={'max-content'} >
                                <Flex direction='column' w='100%' minH={'max-content'}>
                                <Flex
                                    direction={{ sm: "column", lg: "row" }}
                                    justify={{ sm: "center", lg: "space-between" }}
                                    align={{ sm: "center" }}
                                    w='100%'
                                    
                                    >
                                    <Text
                                    color='#fff'
                                    fontSize={{ sm: "lg", md: "xl", lg: "lg" }}
                                    fontWeight='bold' pb={'10px'}>
                                    Performance
                                    </Text>
                                    <Flex align='center'>
                                    <Icon
                                        as={FaChartBar} 
                                        w='15px'
                                        h='15px'
                                        color='#fff'
                                        me='6px'
                                    />
                                    
                                    </Flex>
                                </Flex>
                                </Flex>
                            </CardHeader>
                            <CardBody>
                                <Flex direction='row' w='100%' gap={'20px'} justifyItems={'flex-end'} alignItems={'flex-end'} overflowX={'auto'}>
                                    <Flex direction='row' w='fit-content' pb='10px'  minH={'90px'}  overflow={'hidden'} gap={'20px'} justifyItems={'flex-end'} alignItems={'flex-end'} overflowX={'auto'}>
                                        {this.db.performance.sort((a, b)=>{  
                                            return a.data > b.data ? -1 : a.data < b.data ? 1 : 0; 
                                        }).map((per,id)=>{
                                            if(this.select==id || id ==0){
                                                var div = 1.4   ;
                                            }else{
                                                var div = 1;
                                            } 
                                            return (
                                                <Stack onMouseMove={()=>{
                                                    this.select =id;
                                                    this.setState({});
                                                }} transition={'0.5s'} key={'map'+id} bg={bg} justify='top' align='top' borderRadius={parseInt(15*div)+'px'} minH={parseInt(80*div)+'px'} minW={parseInt(80*div)+'px'}> 
                                                    <Text  transition={'0.5s'} lineHeight={parseInt(10*div)+'px'} fontWeight={'bold'} fontSize={parseInt(9*div)+'px'} color={'green.500'} textTransform={'uppercase'} pt='10px'>{this.ms[new Date(per.data).getDay()]}</Text>
                                                    {(per.porcentagem>=1?
                                                        <Text  transition={'0.5s'}lineHeight={parseInt(16*div)+'px'} fontWeight='bold' fontSize={parseInt(18*div)+'px'} color={'green.500'}> {G.parseValor(per.porcentagem,2).p},<Text as='span'   fontSize={parseInt(7*div)+'px'}>{G.parseValor(per.porcentagem,2).d}%</Text></Text>:
                                                        <Text  transition={'0.5s'}lineHeight={parseInt(16*div)+'px'} fontWeight='bold' fontSize={parseInt(7*div)+'px'} color={'green.500'}> {(G.parseValor(per.porcentagem,2).p)},<Text as='span'   fontSize={parseInt(18*div)+'px'}>{G.parseValor(per.porcentagem,2).d}</Text>%</Text>
                                                    )}
                                                    <Box transition={'0.5s'} bg={'green.500'} m={'0 auto'} fontWeight={'bold'} fontSize={parseInt(9*div)+'px'} p={'4px 6px'} borderRadius={parseInt(7*div)+'px'}>
                                                        <Text lineHeight={parseInt(10*div)+'px'}>{new Date(per.data).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit'})}</Text>
                                                    </Box>
                                                </Stack>
                                            )
                                        })}
                                    </Flex>
                                    {/* <Stack bg={bg} borderRadius={'8px'} h={'40px'} w={'40px'} >

                                    </Stack> */}
                                    {/* <Stack bg={bg} borderRadius={'8px'} h={'40px'} w={'40px'} >

                                    </Stack> */}
                                </Flex>
                            </CardBody>
                        </Card>
                    </Stack>
                    {this.atencaoB}
                </>
            )
        }else{
           return <BoxWindow load={true} />
        }
       
    }
}
export default PageHome;