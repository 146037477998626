import * as React from "react" 
import { Card, CardHeader, Center, Divider, Grid, Icon, IconButton, InputGroup, InputLeftElement, InputRightElement, Stack } from "@chakra-ui/react";
import {Helmet} from "react-helmet"; 
import PhoneInput from 'react-phone-number-input'
import GradientBorder from "../../components/VisionUI/GradientBorder";
import {
    Box,
    Button,
    DarkMode,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Image,
    Input,
    Link,
    Switch,
    Text,
} from "@chakra-ui/react";
import  { FaCalendarDay, FaCcPaypal, FaCheck, FaCopy, FaExchangeAlt, FaEye, FaEyeSlash, FaGlobeAmericas, FaIdCard, FaKey, FaKeybase, FaKeyboard, FaKeycdn, FaMailBulk, FaPhone, FaPhoneAlt, FaPhoneSquare, FaRegEye, FaRegEyeSlash, FaRegKeyboard, FaRegUser, FaSignInAlt, FaStreetView, FaUser, FaUserAlt, FaUserCog, FaUserTag } from "react-icons/fa";
import  { NavLink, Navigate } from "react-router-dom";
import  G from '../../components/WebSocket';
import  InputMask  from 'react-input-mask';  
import  BoxWindow from "../../components/BoxWindow/BoxWindow";
import { FiSmartphone } from "react-icons/fi";
import { FcSmartphoneTablet } from "react-icons/fc";
import InputDate from "../../components/Padrao/InputData";

class PageRegistro extends React.Component {
    open = null;
    viewLogin = null
    indicacao = null;
    buscarIndicao(){  
        if(this.indicacao!=null){
          G.socket.removeAllListeners('consultor').on('consultor',(response)=>{ 
            if(!response){ 
              this.indicacao      = ''; 
              localStorage.setItem(G.sessao+'cadastro','');
              this.setState({});
            }  
            this.setState({});
          });
          G.socket.emit("consultor", this.indicacao);
        } 
    }
    componentDidMount(){  
     
        if(this.indicacao===null){
            var d =window.location.href.split('/register/');
            //console.log(d);
            if(typeof d[1]!=="undefined" && d[1].trim()!==''){
                var indicacao = d[1].replace(/\//g,'');
                localStorage.setItem(G.sessao+'cadastro',indicacao);
                this.indicacao = indicacao;
                this.buscarIndicao(); 
            }else{ 
                if(typeof localStorage!=="undefined"){
                    this.indicacao=localStorage.getItem(G.sessao+'cadastro'); 
                    this.buscarIndicao();
                } 
            } 
        }  
        // this.checarSessao();  
    
       
    }
    render() {

        const titleColor = "white";
        const textColor = "tema1.500";
        const illustrationBackground = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
        return (
            <>
                <Helmet> 
                        <title>Inovatyon - Registro</title>
                        <meta name="title" content="Inovatyon" />
                        <meta name="description" content="" /> 
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content="https://inovatyon.com" />
                        <meta property="og:title" content="Inovatyon Tach labs" />
                        <meta property="og:description" content="" />
                        <meta property="og:image" content="https://inovatyon.com/assets/img/logobk.png" />
    
                        <meta property="twitter:card" content="summary_large_image" />
                        <meta property="twitter:url" content="https://inovatyon.com" />
                        <meta property="twitter:title" content="Inovatyon Tach labs" />
                        <meta property="twitter:description" content="" />
                        <meta property="twitter:image" content="https://inovatyon.com/assets/img/logobk.png" />
                </Helmet> 
                    <Flex position='relative'>
                        <Flex
                            minH='100vh' 
                        
                            w='full' 
                            align={'center'} 
                            justify={'center'}  
                            flexDirection='column'
                            p={{base:'10px',sm:'20px'}}  >
                                <Center>
                                    <GradientBorder  
                                        borderRadius={'20px'}
                                        boxShadow={'lg'}
                                        bg='radial-gradient(69.43% 69.43% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.1) 100%),
                                        radial-gradient(60% 51.57% at 50% 50%, #FFFFFF 0%, rgba(88, 44, 255, 0.05) 100%),
                                        radial-gradient(54.8% 53% at 50% 50%, #FFFFFF 0%, rgba(21, 21, 21, 0) 100%)'
                                            p='2px' me={{ base: "none", lg: "0px", xl: "none" }}>
                                        <Box pos={'relative'} bg='rgba(255,255,255,0.3)' borderRadius={'20px'} as={'form'}  onSubmit={(e)=>{
                                            e.preventDefault();  
                                            const formdata = new FormData(e.target);
                                            const json = {};  
                                            formdata.forEach(function(value, prop){ 
                                                json[prop] = value;
                                            });   
                                            G.socket.removeAllListeners('cadastro').on('cadastro',(result)=>{  
                                                if(!result.erro){  
                                                    G.socket.removeAllListeners('login').on('login',(response)=>{  
                                                        // console.log(response);
                                                        if(response.erro==0){  
                                                            this.atencao = null;
                                                            G.token = response.hash; 
                                                            G.painel = response.usu;  
                                                            localStorage.setItem('skt'+G.sessao,G.token); 
                                                            G.getDados(); 
                                                        } 
                                                        this.setState({}); 
                                                    })
                                                    G.socket.emit("login", json); 
                                                }else{
                                                    this.atencao = <BoxWindow titulo = {result.titulo} mensagem={result.mensagem} onClose={()=>{
                                                            this.atencao = null;
                                                            this.setState({});
                                                    }}/>
                                                    this.setState({});  
                                                } 
                                                
                                            });
                                            json.indicacao  =   this.indicacao;  
                                            G.socket.emit("cadastro", {form:json});
                                             
                                            this.atencao = <BoxWindow load = {true}/>
                                            this.setState({});
                                        }} >
                                        
                                                <Flex   zIndex={'2'}   h={'100%'}  style={{ userSelect: "none" }}  mx={{ base: "auto", lg: "unset" }}  w={{ base: "100%", sm: "440px", md: "640px" }}  p={{ base: "20px" , lg: "30px" }}>
                                                    
                                                    <Flex direction='column' w='100%' >

                                                        <Center>
                                                            <Image w={{ lg: '300px', xl: '370px', '2xl': '440px' }} objectFit='fill' src='/assets/img/logo.png' alt='Logo inovatyon' mb='20px' />
                                                        </Center>  
                                                        {(this.indicacao!=null && this.indicacao!='')?
                                                          <Center>
                                                                <Card  w={'fit-content'} mb={'15px'}>
                                                                    <CardHeader   textAlign={'center'}> 
                                                                        <Flex
                                                                            justify='center'
                                                                            align='center'
                                                                            pb='0px' 
                                                                            >  
                                                                            <Text mb={'0px'} color={'tema1.100'} fontWeight={'bold'}> Indicação  de <Text as={'span'} color={'green.500'}>{this.indicacao}</Text></Text> 
                                                                
                                                                        </Flex>
                                                                    </CardHeader>
                                                                
                                                            </Card>
                                                          </Center>
                                                     
                                                        
                                                            
                                                        :null}

                                                        <Stack  direction={{base:'column',sm :'row'}} gap={{ base: "0px", sm: "20px"}}>
                                                            <FormControl> 
                                                                <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.200'>
                                                                    Seu nome
                                                                </FormLabel>
                                                                <GradientBorder  mb={{base:'0px',sm:'10px'}} w={{ base: "100%"}} borderRadius='20px'>
                                                                    <InputGroup size='lg'>
                                                                        <InputLeftElement width='3rem'>
                                                                            <Icon as={FaIdCard} boxSize={5}  color="green.500"/> 
                                                                        </InputLeftElement>
                                                                        <Input
                                                                            pl='3rem'
                                                                            as={InputMask} 
                                                                             
                                                                            maskChar= ''
                                                                            alwaysShowMask= {true}
                                                                            fontSize={{base:'small',sm:'sm'}} 
                                                                            placeholder="Nome Completo"
                                                                            bg={'tema1.200'}
                                                                            border={0}
                                                                            name="nome"
                                                                            color={'tema1.100'}
                                                                            _placeholder={{
                                                                                color: 'gray.300',
                                                                            }}
                                                                        /> 
                                                                    </InputGroup>
                                                                </GradientBorder>
                                                            </FormControl> 
                                                            <FormControl> 
                                                                <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.200'>
                                                                Seu e-mail
                                                                </FormLabel>
                                                                <GradientBorder mb={{base:'0px',sm:'10px'}} w={{ base: "100%"}} borderRadius='20px'>
                                                                    <InputGroup size='lg'>
                                                                        <InputLeftElement  width='3rem'>
                                                                            <Icon as={FaMailBulk} boxSize={5}  color="green.500"/> 
                                                                        </InputLeftElement>
                                                                        <Input
                                                                            pl='3rem'
                                                                            as={InputMask}
                                                                            mask={'####################################################################################################'}  
                                                                            formatChars= {{'#': '[A-Za-z0-9@._-]'}}  
                                                                            maskChar= ''
                                                                            alwaysShowMask= {true} 
                                                                            placeholder="E-mail válido" 
                                                                            bg={'tema1.200'}
                                                                            border={0}
                                                                            fontSize={{base:'small',sm:'sm'}}
                                                                            name="email"
                                                                            color={'tema1.100'}
                                                                            _placeholder={{
                                                                                color: 'gray.300',
                                                                            }}
                                                                        /> 
                                                                    </InputGroup>
                                                                </GradientBorder>
                                                            </FormControl>
                                                        </Stack>
                                                        <Stack  direction={{base:'column',md :'row'}} gap={{ base: "0px", md: "20px"}}>
                                                            <Stack direction={{base:'column',sm :'row'}} gap={{ base: "0px", sm: "20px"}} pr={{ base: "0px", sm: "20px", md: "2px"}}>
                                                                <FormControl minW={{base:'100%',sm:'50%',md:'200px'}}>
                                                                    <FormLabel ms='4px' display={{base:'none',sm:'flex'}} fontSize='sm' fontWeight='500' color='tema1.200'>
                                                                    Telefone
                                                                    </FormLabel>
                                                                    <GradientBorder  mb={{base:'0px',sm:'10px'}} w={{ base: "100%"}} borderRadius='20px'>
                                                                        <InputGroup size='lg'> 
                                                                            <Input    
                                                                                border={0} 
                                                                                placeholder='Telefone' 
                                                                                bg={'tema1.200'} 
                                                                                name="telefone"
                                                                                as={PhoneInput} 
                                                                                country="BR"
                                                                                fontSize={{base:'small',sm:'sm',md:'lg'}}
                                                                                value={'+55 '}
                                                                                color={'tema1.100'}
                                                                                _placeholder={{
                                                                                    color: 'gray.300',
                                                                                }}
                                                                                onChange={(e)=>{
                                                                                    
                                                                                }}
                                                                            /> 
                                                                        </InputGroup> 
                                                                    </GradientBorder>
                                                                </FormControl>
                                                                <FormControl minW={{base:'100%',sm:'50%',md:'150px'}}>
                                                                    <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.200'>
                                                                        Nascimento
                                                                    </FormLabel>
                                                                    <GradientBorder  mb={{base:'0px',sm:'10px'}} w={{ base: "100%"}} borderRadius='20px'>
                                                                        
                                                                        <InputGroup size='lg'> 
                                                                            <InputLeftElement width='3rem' display={{base:'flex',sm:'none'}}>
                                                                                <Icon as={FaCalendarDay} boxSize={5}  color="green.500"/> 
                                                                            </InputLeftElement>
                                                                            <InputDate name='nascimento'  />
                                                                            {/* <Input 
                                                                                type="text"  
                                                                                bg={'tema1.200'}
                                                                                border={0} 
                                                                                name="nascimento"
                                                                                
                                                                                textAlign={'left'}
                                                                                fontSize={{base:'small',sm:'sm'}}
                                                                                color={'tema1.100'}  
                                                                                placeholder={'dd/mm/aaaa'} 
                                                                                onFocus={(el)=>{
                                                                                    el.type='date'; 
                                                                                }}
                                                                                // onfocus="(this.type='date')"
                                                                                // onblur="(this.type='text')"
                                                                                _placeholder={{
                                                                                    color: 'gray.300',
                                                                                }}
                                                                            />  */}
                                                                        </InputGroup> 
                                                                    </GradientBorder>
                                                                </FormControl>  
                                                            </Stack>                
                                                            <Stack  direction={{base:'column',sm :'row'}} gap={{ base: "0px", sm: "20px"}}>
                                                                <FormControl   > 
                                                                    <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.200'>
                                                                    Seu país
                                                                    </FormLabel>
                                                                    <GradientBorder  mb={{base:'20px',sm:'10px'}} w={{ base: "100%"}} borderRadius='20px'>
                                                                        <InputGroup size='lg'> 
                                                                            <InputLeftElement width='3rem' >
                                                                                <Icon as={FaGlobeAmericas} boxSize={5}  color="green.500"/> 
                                                                            </InputLeftElement>
                                                                            <Input 
                                                                                as={InputMask}
                                                                                fontSize={{base:'small',sm:'sm'}}
                                                                                alwaysShowMask= {true} 
                                                                                placeholder="Nacionalidade" 
                                                                                bg={'tema1.200'}
                                                                                border={0}
                                                                                name="pais"
                                                                                color={'tema1.100'}
                                                                                _placeholder={{
                                                                                    color: 'gray.300',
                                                                                }}
                                                                            /> 
                                                                        </InputGroup>
                                                                    </GradientBorder>
                                                                </FormControl> 
                                                            </Stack>
                                                            
                                                            
                                                        </Stack> 
                                                        <Stack  direction={{base:'column',md :'row'}} gap={{ base: "0px", md: "20px"}}> 
                                                            <Stack direction={{base:'column',sm :'row'}} gap={{ base: "0px", sm: "20px"}}  >
                                                                <FormControl>
                                                                    <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.200'>
                                                                        Seu usuário
                                                                    </FormLabel>
                                                                    <GradientBorder mb={{base:'0px',sm:'10px'}}  w={{ base: "100%"}} borderRadius='20px'>
                                                                        <InputGroup size='lg'>
                                                                            <InputLeftElement width='3rem'>
                                                                                <Icon as={FaUser} boxSize={5}  color="green.500"/> 
                                                                            </InputLeftElement>
                                                                            <Input
                                                                                pl='3rem'
                                                                                as={InputMask}
                                                                                mask={'####################################################################################################'}  
                                                                                formatChars= {{'#': '[A-Za-z0-9._-]'}}  
                                                                                maskChar= ''
                                                                                alwaysShowMask= {true}
                                                                                fontSize={{base:'small',sm:'sm'}} 
                                                                                placeholder="Seu login"
                                                                                bg={'tema1.200'}
                                                                                border={0}
                                                                                name="login"
                                                                                color={'tema1.100'}
                                                                                _placeholder={{
                                                                                    color: 'gray.300',
                                                                                }}
                                                                            /> 
                                                                        </InputGroup>
                                                                    </GradientBorder>
                                                                </FormControl>
                                                            </Stack> 
                                                            <Stack direction={{base:'column',sm :'row'}} gap={{ base: "0px", sm: "20px"}}  >
                                                                <FormControl >
                                                                    <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.200'>
                                                                        Sua senha
                                                                    </FormLabel>
                                                                    <GradientBorder mb={{base:'0px',sm:'10px'}}  w={{ base: "100%"}} borderRadius='20px'>
                                                                        <InputGroup size='lg'>
                                                                            <InputLeftElement width='3rem'>
                                                                                <Icon    as={FaKey} boxSize={5}    color={'green.500'}/> 
                                                                            </InputLeftElement> 
                                                                            <Input
                                                                                pl='3rem'
                                                                                pr='3rem'
                                                                                type={this.show1 ? 'text' : 'password'}
                                                                                placeholder="Sua senha"
                                                                                bg={'tema1.200'}
                                                                                fontSize={{base:'small',sm:'sm'}}
                                                                                border={0}
                                                                                name="senha"
                                                                                color={'tema1.100'}
                                                                                _placeholder={{
                                                                                    color: 'gray.300',
                                                                                }}
                                                                            />
                                                                            <InputRightElement width='3rem'>
                                                                                <Button h='1.75rem' size='md' onClick={()=>{
                                                                                    if(this.show1){
                                                                                        this.show1 =false;    
                                                                                    }else{
                                                                                        this.show1 =true
                                                                                    } 
                                                                                    this.setState({})
                                                                                }}>
                                                                                {this.show1 ?  <Icon  fontSize={'22px'}  as={FaEyeSlash}/> : <Icon fontSize={'22px'}  as={FaEye}/> }
                                                                                </Button>
                                                                            </InputRightElement>
                                                                        </InputGroup> 
                                                                    </GradientBorder>
                                                                </FormControl>
                                                                <FormControl >
                                                                    <FormLabel display={{base:'none',sm:'flex'}} ms='4px' fontSize='sm' fontWeight='500' color='tema1.200'>
                                                                    Confirme sua senha
                                                                    </FormLabel>
                                                                    <GradientBorder  mb='20px' w={{ base: "100%"}} borderRadius='20px'>
                                                                        <InputGroup size='lg'>
                                                                            <InputLeftElement width='3rem'>
                                                                                <Icon    as={FaCheck} boxSize={5}    color={'green.500'}/> 
                                                                            </InputLeftElement> 
                                                                            <Input
                                                                                pl='3rem'
                                                                                pr='3rem'
                                                                                type={this.show1 ? 'text' : 'password'}
                                                                                placeholder="Sua Senha"
                                                                                fontSize={{base:'small',sm:'sm'}}
                                                                                bg={'tema1.200'}
                                                                                border={0}
                                                                                name="senha2"
                                                                                color={'tema1.100'}
                                                                                _placeholder={{
                                                                                    color: 'gray.300',
                                                                                }}
                                                                            />
                                                                            <InputRightElement width='3rem'>
                                                                                <Button h='1.75rem' size='md' onClick={()=>{
                                                                                    if(this.show1){
                                                                                        this.show1 =false;    
                                                                                    }else{
                                                                                        this.show1 =true
                                                                                    } 
                                                                                    this.setState({})
                                                                                }}>
                                                                                {this.show1 ?  <Icon  fontSize={'22px'}  as={FaEyeSlash}/> : <Icon fontSize={'22px'}  as={FaEye}/> }
                                                                                </Button>
                                                                            </InputRightElement>
                                                                        </InputGroup> 
                                                                    </GradientBorder>
                                                                </FormControl>
                                                            </Stack>
                                                        </Stack>       
                                                        
                                                        <Flex
                                                            flexDirection='column'
                                                            justifyContent='center'
                                                            alignItems='center'
                                                            maxW='100%'
                                                            mb='20px'>
                                                                <Button
                                                                colorScheme='blue'
                                                                fontSize='14px'
                                                                type='submit'
                                                                bg={'green.500'}
                                                                leftIcon={<FaSignInAlt />}
                                                                w='fit-content'
                                                                size='lg'
                                                                mt='0px'
                                                                _hover={{
                                                                    bg: 'tema1.300',
                                                                    boxShadow: 'xl',
                                                                    color:'green.500'
                                                                }} 
                                                                mb='0px'>
                                                                Registrar Conta
                                                            </Button> 
                                                        </Flex>
                                                    
                                                        <Flex
                                                            flexDirection='column'
                                                            justifyContent='center'
                                                            alignItems='center'
                                                            maxW='100%'
                                                            mb='30px'>
                                                            <Text color={textColor} fontWeight='medium'>
                                                                Já tem uma conta?
                                                                <NavLink to={'/login'} >
                                                                    <Text as='span' color={'tema1.200'} ms='5px' fontWeight='bold'>
                                                                    Fazer Login
                                                                    </Text>
                                                                </NavLink>
                                                            </Text>
                                                        </Flex>
                                                        {/* <Center>
                                                            <Image w={{ lg: '120px', xl: '120px', '2xl': '144px' }} opacity={0.4} objectFit='fill' src='/assets/img/logobf.png' alt='Logo inovatyon' />
                                                        </Center> */}
                                                    </Flex>   
                                                </Flex>  
                                        
                                        </Box> 
                                    </GradientBorder>
                                
                                </Center>    
                                <Box pos={'relative'}  p='20px 0 0px 0'     >
                                    <Text as='span' color={'tema1.200'} ms='5px'  fontSize={{base:'10px',sm:'12px'}} >
                                        © 2023 - 2024 Inovatyon Tech Labs. Todos os Direitos Reservados.
                                    </Text>
                                </Box>
                                
                        </Flex>
                        {this.atencao}
                    </Flex>
            </>
           
        );
    }
}
export default PageRegistro;