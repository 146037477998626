import * as React from "react"  
import {
    Avatar, 
    Box, 
    Button,   
    Flex, 
    Icon, 
    Stack, 
    Text 
  } from '@chakra-ui/react';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaArrowCircleRight, FaArrowRight, FaCheckCircle, FaCopy, FaExchangeAlt, FaFileAlt, FaHistory, FaHouseUser, FaInfo, FaPeace, FaPen, FaPenAlt, FaPlusSquare, FaPowerOff, FaProjectDiagram, FaRegStar, FaRocket, FaTimes, FaTrashAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { RiFolderHistoryFill } from 'react-icons/ri'; 

import G  from '../../components/WebSocket'
import BoxWindow from "../../components/BoxWindow/BoxWindow";  
import Card from "../../components/VisionUI/Card/Card";
import CardBody from "../../components/VisionUI/Card/CardBody";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
import { FiArrowDownLeft, FiArrowDownRight } from "react-icons/fi";
import { FaLeftLong } from "react-icons/fa6";
import { BsFileTextFill } from "react-icons/bs";
class ListaBinario extends React.Component { 
    viewPedidos = null
    robos       = {};
    tempLanc    = null;
    grupo       =   'C';
    ordem       =   'ASC';
    sorte       =   'T';
    binario     =   [];
    tipos       =   {};
    rede = [];
    dados  =   [];
    rUSU = {}
    
    load        = false;
    buscarJogos(){
        var relaod = false; 
        
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.binario!=='undefined' && this.tempBinario != JSON.stringify(G.geral.binario)){
            this.tempBinario = JSON.stringify(G.geral.binario); 
            this.binario = G.geral.binario;  
            this.rUSU = {};
            G.geral.binario.forEach((nivel)=>{
                nivel.forEach((usu)=>{
                    this.rUSU[usu.id] = usu;
                    this.rUSU[usu.id].pontos = 0;
                    Object.values(usu.pacotes).forEach((pac)=>{
                        if(pac.voucher=='N'){
                            this.rUSU[usu.id].pontos += pac.valor;
                        } 
                    })
                }) 
            })   
            relaod = true;  
            this.load =true;
        } 
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.rede!=='undefined' && this.tempRede != JSON.stringify(G.geral.rede)){
            this.tempRede = JSON.stringify(G.geral.rede); 
            this.rede = G.geral.rede;  
            
            relaod = true;
        } 
        if(relaod){
            this.setState({});
        }   
    } 
    componentDidMount(){
        this.buscarJogos();
        this.setState({});
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    paiID   =   null;
    nivel   =   0; 
    matriz  =   {};
    subPai  =   [];
    gerarDadosMatriz(){
        this.matriz['N0']= {};
        
        this.matriz['N1P1E']= {};
        this.matriz['N1P1D']= {};

        this.matriz['N2P1E']= {};
        this.matriz['N2P1D']= {};
        this.matriz['N2P2E']= {};
        this.matriz['N2P2D']= {};

        this.matriz['N3P1E']= {};
        this.matriz['N3P1D']= {};
        this.matriz['N3P2E']= {};
        this.matriz['N3P2D']= {};
        this.matriz['N3P3E']= {};
        this.matriz['N3P3D']= {};
        this.matriz['N3P4E']= {};
        this.matriz['N3P4D']= {};
        this.subPai = [];
        if(this.paiID == null) {
            this.paiID ={ 
                usuario :   G.painel.usuario, 
                pontos  :   0
            };
        } 
        //buscar nivel 0
        this.binario.forEach((np,nv)=>{
            np.forEach((nivel)=>{
                if(this.paiID.usuario == nivel.usuario){  
                    this.matriz['N0'] = {
                        id      : nivel.id,
                        usuario : nivel.usuario, 
                        pontos  :   nivel.pontos
                    } 
                }   
            })  
        });
        //buscar nivel 1
        this.binario.forEach((np,nv)=>{
            np.forEach((nivel)=>{
                if(this.matriz['N0'].id == nivel.cod_sub){    
                    this.matriz['N1P1'+nivel.cod_lado] = {
                        id      : nivel.id,
                        usuario : nivel.usuario, 
                        pontos  :   nivel.pontos
                    } 
                }  
            })
            
        }); 
        //buscar nivel 2
        var pos = [{p:'N1P1E',b:'N2P1'},{p:'N1P1D',b:'N2P2'}];
        this.binario.forEach((np,nv)=>{
            np.forEach((nivel)=>{
                pos.forEach((d)=>{
                    if(this.matriz[d.p].id == nivel.cod_sub){  
                        this.matriz[d.b+nivel.cod_lado] = {
                            id      : nivel.id,
                            usuario : nivel.usuario, 
                            pontos  :    nivel.pontos
                        } 
                    } 
                })  
            })
            
        });  
        if(typeof this.matriz['N2P1E'].id!=='undefined' ||typeof this.matriz['N2P1D'].id!=='undefined' ||typeof this.matriz['N2P2E'].id!=='undefined' ||typeof this.matriz['N2P2D'].id!=='undefined' ){
            this.niv2 =true;
            //buscar nivel 3
            var pos = [
                {p:'N2P1E',b:'N3P1'},
                {p:'N2P1D',b:'N3P2'},
                {p:'N2P2E',b:'N3P3'},
                {p:'N2P2D',b:'N3P4'}
            ]; 
            this.binario.forEach((np,nv)=>{
                np.forEach((nivel)=>{
                    pos.forEach((d)=>{
                        if(this.matriz[d.p].id == nivel.cod_sub){  
                            this.matriz[d.b+nivel.cod_lado] = {
                                id      : nivel.id,
                                usuario : nivel.usuario, 
                                pontos  :   0 
                            }
                            this.rede.forEach((nr,pos)=>{
                                nr.forEach((nrV)=>{
                                    if(nrV.id == nivel.id){
                                        // console.log(nrV.saldos)
                                        try { 
                                            // console.log(this.matriz[d.b+nivel.cod_lado].pontos,d.b,nivel.cod_lado,nrV.saldos.depositos)
                                            this.matriz[d.b+nivel.cod_lado].pontos = nrV.saldos.depositos;
                                        } catch (error) { 
                                            this.matriz[d.b+nivel.cod_lado].pontos = null;
                                        }
                                        // console.log('nrV',nrV)
                                    }
                                })
                            }) 
                        } 
                    })  
                });
            }); 
        }else{
            this.niv2 =false;
        }

        this.subPai=[];  
        if(typeof this.matriz['N0'].usuario !=='undefined' && this.matriz['N0'].usuario != G.painel.usuario){ 
            var sub =  this.rUSU[this.matriz['N0'].id].cod_sub;
            var fim =true;  
            var max = 0;
            
            while(fim){
                var pai = {} 
                if(max == 10){
                    fim =false;
                }
                if(typeof this.rUSU[sub]!= 'undefined'){  
                    this.subPai.push(this.rUSU[sub]); 
                    sub= this.rUSU[sub].cod_sub;
                } 
                max++;
            }
            this.subPai.reverse(); 
        }
        
    }
    addBoxPai(dado,dadoE = {},dadoD = {},nivel){  
            if(dado.usuario == G.painel.usuario){
                return  (
                    <Flex w={'180px'}  border='2px solid'  borderColor='tema1.200' p={'3px'} borderRadius= "24px" bg='rgba(0, 0, 0,0.3)' my='30px'>
                         <Card  h='100%' alignSelf='flex-start' bg='tema1.200'>
                             <CardHeader mb='0px' pt='25px'>
                                <Stack  pos={'absolute'} left={'50%'}   borderRadius={'20px 0 0 0'}    bg='green.500'  w={'2px'} ml={'-1px'} bottom={'-39px'} h={'35px'}></Stack>
                                
                                <Stack   pos={'absolute'}  top={'-35px'} left={'50%'} transform={'translateX(-50%)'} w={'fit-content'} border='2px solid' borderColor='green.500'  borderRadius='50%' p={'4px'}  bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"}>
                                    <Avatar color={"tema1.300"} h='60px' w={'60px'} icon={<FaHouseUser size='40px'/>}   bg={"green.500"} overflow={'hidden'} />
                                </Stack> 
                                <Flex justify='center' w='100%' pb={'8px'}> 
                                    <Text textTransform={'uppercase'} fontSize='sm' color='tema1.100' fontWeight={'bold'} noOfLines={1}  >
                                        Você
                                    </Text>   
                                </Flex>
                                {nivel==2 && typeof dadoE.id !== 'undefined' ?<Stack zIndex={2} cursor={'pointer'} onClick={()=>{
                                    this.paiID = { 
                                        usuario :   dadoE.usuario, 
                                        pontos  :   0
                                    }
                                    this.setState({});
                                }}   pos={'absolute'}  bottom={'-20px'} left={'0'} transform={'translateX(-50%)'} w={'fit-content'} border='2px solid' borderColor='green.500'  borderRadius='50%' p={'4px'}  bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"}>
                                    <Avatar color={"tema1.300"} h='30px' w={'30px'} icon={<FiArrowDownLeft size='26px'/>}  bg={"green.500"} overflow={'hidden'} />
                                </Stack>:null}
                                {nivel==2 && typeof dadoD.id !== 'undefined'?<Stack zIndex={2}  cursor={'pointer'} onClick={()=>{
                                    this.paiID = { 
                                        usuario :   dadoD.usuario, 
                                        pontos  :   0
                                    }
                                    this.setState({});
                                }}  pos={'absolute'}  bottom={'-20px'} right={'-40px'} transform={'translateX(-50%)'} w={'fit-content'} border='2px solid' borderColor='green.500'  borderRadius='50%' p={'4px'}  bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"}>
                                    <Avatar color={"tema1.300"} h='30px' w={'30px'} icon={<FiArrowDownRight size='26px'/>}  bg={"green.500"} overflow={'hidden'} />
                                </Stack>:null} 
                            </CardHeader>
                            <CardBody>
                                <Flex direction='column' w={'100%'} > 
                                    <Text textTransform={'uppercase'} fontSize='10px' pb={'5px'} color='tema1.100' fontWeight={'bold'} noOfLines={1}  >
                                      <Text as='span' color={'green.500'} >Chave:</Text>  {G.painel.chaveB=='A'?'Balanceado':G.painel.chaveB=='E'?"Esquerda":"Direita"}
                                    </Text>   
                                    <Flex justify='space-between' w='100%' gap={0}>
                                        <Button direction='column'  opacity={G.painel.chaveB == 'E'?'1':'0.3'} _hover={{opacity:'1'}}   fontSize='12px' h='30px' minW={'30px'} lineHeight={'30px'} p='0' bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                            e.preventDefault();      
                                            this.mudarChave('E');
                                             
                                        }}>
                                            <Icon as={FaArrowAltCircleLeft} h={'30px'}  fontSize={'20px'} /> 
                                        </Button>
                                        <Button direction='column'   opacity={G.painel.chaveB == 'A'?'1':'0.3'}  _hover={{opacity:'1'}}     fontSize='12px' h='30px' minW={'30px'} lineHeight={'30px'} bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                            e.preventDefault();    
                                            this.mudarChave('A');  
                                             
                                        }}>
                                            <Text fontSize='12px'    >AUTO</Text> 
                                        </Button>
                                        <Button direction='column'   opacity={G.painel.chaveB == 'D'?'1':'0.3'} _hover={{opacity:'1'}}     fontSize='12px' h='30px' minW={'30px'} lineHeight={'30px'} p='0' bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                            e.preventDefault();    
                                            this.mudarChave('D');
                                        }}>
                                            <Icon as={FaArrowAltCircleRight} h={'30px'}  fontSize={'20px'} /> 
                                        </Button>
                                        {/* <Flex direction='column' gap={0}>
                                            <Button direction='column'     fontSize='12px' h='39px' lineHeight={'39px'} bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                                e.preventDefault();    
                                                this.atencaoB = <BoxWindow titulo = {'Extrato Geral'} mensagem={'No momento este recurso está desativado, logo mais será implementado!'} onClose={()=>{
                                                    this.atencaoB = null;
                                                    this.setState({});
                                                }} />
                                                this.setState({});
                                            }}>
                                                <Stack direction='column' w={'16px'}  p={'0px'} minW={'20px'}   align={'center'} justify={'100%'} lineHeight={'15px'}  gap={'0'} >
                                                    <Icon as={FaInfo} h={'8px'} p={'0px'}  fontSize={'8px'} size={'18px'}/>
                                                    <Text fontSize='8px'  pt={'3px'} lineHeight={'10px'} >DADOS</Text> 
                                                </Stack> 
                                            </Button>
                                        </Flex>
                                        <Flex direction='column'>
                                            <Button direction='column'     fontSize='12px' h='39px' lineHeight={'39px'} bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                                e.preventDefault();    
                                                this.atencaoB = <BoxWindow titulo = {'Extrato Geral'} mensagem={'No momento este recurso está desativado, logo mais será implementado!'} onClose={()=>{
                                                    this.atencaoB = null;
                                                    this.setState({});
                                                }} />
                                                this.setState({});
                                            }}>
                                                <Stack direction='column' w={'16px'}  p={'0px'} minW={'20px'}   align={'center'} justify={'100%'} lineHeight={'15px'}  gap={'0'} >
                                                    <Icon as={FaInfo} h={'8px'} p={'0px'}  fontSize={'8px'} size={'18px'}/>
                                                    <Text fontSize='8px'  pt={'3px'} lineHeight={'10px'} >DADOS</Text> 
                                                </Stack> 
                                            </Button>
                                        </Flex> */}
                                    </Flex>
                                </Flex>
                            </CardBody> 
                        </Card>
                    </Flex>  
                );
            }else{
                var cor = (dado.cod_lado == "E")?{
                    cor1:'green.500',
                    cor2:'tema1.300',
                    gd:"rgba(58, 255, 202, 0.3)"
                }:{ 
                    cor1:'tema1.300',
                    cor2:'green.500',
                    gd:"rgba(6, 12, 40,0.3)"
                };
                return  (
                    <Flex position={'relative'} key={'pai'+dado.id} w={'180px'}  border='2px solid'  borderColor={cor.cor1} p={'3px'} borderRadius= "24px" bg={cor.gd} my='30px'>
                        <Card  h='100%' alignSelf='flex-start' >
                            <CardHeader mb='18px' pt='25px'>
                                <Stack  pos={'absolute'} left={'50%'}   borderRadius={'20px 0 0 0'}    bg={cor.cor1}  w={'2px'} ml={'-1px'} bottom={'-39px'} h={'35px'}></Stack>
                                
                                <Stack   pos={'absolute'}  top={'-35px'} left={'50%'} transform={'translateX(-50%)'} w={'fit-content'} border='2px solid' borderColor={cor.cor1}  borderRadius='50%' p={'4px'}  bg={cor.gd}>
                                    <Avatar color={cor.cor2} h='60px' w={'60px'} icon={<FaUser size='60px'/>} pt='5px' bg={cor.cor1} overflow={'hidden'} />
                                </Stack>
                                <Stack   pos={'absolute'}  top={'5px'} left={'50%'} transform={'translateX(-50%)'} w={'fit-content'}   p={'4px'} >
                                    
                                    <Button direction='column'  leftIcon={<FaProjectDiagram/>}    fontSize='10px' h='30px' lineHeight={'30px'} bg={'tema1.200'} color={'green.500'} variant='solid'  onClick={(e)=>{ 
                                        this.paiID = { 
                                            usuario :   dado.usuario, 
                                            pontos  :   0
                                        };
                                        this.setState({});
                                    }}>
                                        VER REDE
                                    </Button>    
                                </Stack>
                                {dado.cod_lado =='E'?
                                    <Stack   pos={'absolute'}  ml={'-60px'}  top={'-55px'} bg={cor.gd} left={'50%'} color={cor.cor1} border={'2px solid'} p='2px' borderRadius={'100%'} borderColor={cor.cor1} transform={'translateX(-50%)'} w={'fit-content'}   ><FaArrowAltCircleLeft size='30px'/></Stack>:
                                    <Stack   pos={'absolute'} ml={'60px'}  top={'-55px'} bg={'rgba(255,255,255,0.1)'} left={'50%'} color={cor.cor1} border={'2px solid'} p='2px' borderRadius={'100%'} borderColor={cor.cor1} transform={'translateX(-50%)'} w={'fit-content'}   ><FaArrowAltCircleRight size='30px'/></Stack>
                                
                                }
                                <Flex justify='center' w='100%'> 
                                    <Text textTransform={'uppercase'} fontSize='sm' color='tema1.100' fontWeight={'bold'} noOfLines={1}  >
                                        {dado.usuario}
                                    </Text>   
                                </Flex>
                                {nivel==2 && typeof dadoE.id !== 'undefined' ?<Stack zIndex={2} cursor={'pointer'} onClick={()=>{
                                    this.paiID = { 
                                        usuario :   dadoE.usuario, 
                                        pontos  :   0
                                    }
                                    this.setState({});
                                }}   pos={'absolute'}  bottom={'-20px'} left={'0'} transform={'translateX(-50%)'} w={'fit-content'} border='2px solid' borderColor='green.500'  borderRadius='50%' p={'4px'}  bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"}>
                                    <Avatar color={"tema1.300"} h='30px' w={'30px'} icon={<FiArrowDownLeft size='26px'/>}  bg={"green.500"} overflow={'hidden'} />
                                </Stack>:null}
                                {nivel==2 && typeof dadoD.id !== 'undefined'?<Stack zIndex={2}  cursor={'pointer'} onClick={()=>{
                                    this.paiID = { 
                                        usuario :   dadoD.usuario, 
                                        pontos  :   0
                                    }
                                    this.setState({});
                                }}  pos={'absolute'}  bottom={'-20px'} right={'-40px'} transform={'translateX(-50%)'} w={'fit-content'} border='2px solid' borderColor='green.500'  borderRadius='50%' p={'4px'}  bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"}>
                                    <Avatar color={"tema1.300"} h='30px' w={'30px'} icon={<FiArrowDownRight size='26px'/>}  bg={"green.500"} overflow={'hidden'} />
                                </Stack>:null} 
                            </CardHeader>
                            <CardBody>
                                <Flex direction='column' w={'100%'} > 
                                    <Flex justify='space-between' w='100%' gap={0}>
                                        <Flex direction='column' gap={0}>
                                            <Text fontSize='xs' color='#fff' >
                                                {dado.pontos}
                                            </Text>
                                            <Text color='gray.400' fontSize='sm'>
                                                PONTOS
                                            </Text>
                                        </Flex>
                                        <Flex direction='column'>
                                            <Button direction='column'     fontSize='12px' h='39px' lineHeight={'39px'} bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                                e.preventDefault();    
                                                this.atencaoB = <BoxWindow titulo = {'Extrato Geral'} mensagem={'No momento este recurso está desativado, logo mais será implementado!'} onClose={()=>{
                                                    this.atencaoB = null;
                                                    this.setState({});
                                                }} />
                                                this.setState({});
                                            }}>
                                                <Stack direction='column' w={'16px'}  p={'0px'} minW={'20px'}   align={'center'} justify={'100%'} lineHeight={'15px'}  gap={'0'} >
                                                    <Icon as={FaInfo} h={'8px'} p={'0px'}  fontSize={'8px'} size={'18px'}/>
                                                    <Text fontSize='8px'  pt={'3px'} lineHeight={'10px'} >DADOS</Text> 
                                                </Stack> 
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </CardBody>
                        </Card>
                    </Flex>  
                );   
            }
           
    }
    admBoxUser(dado,dadoE = {},dadoD = {},nivel){

    }
    mudarChave(chave){
        G.socket.removeAllListeners('formDados').on('formDados',(response)=>{   
            G.socket.removeAllListeners('formDados');
            // console.log('resposta',response)
            if(response.cmd === "mudarChaveB"){    
                G.painel.chaveB = chave;
                this.atencao = null; 
                this.setState({});   
            }
        }); 
        G.socket.emit("formDados", {
            modulo  :   'usuario',
            cmd     :   'mudarChaveB',
            form    :   {
                chave       :  chave
            }
        }); 
        this.atencao = <BoxWindow load={true}/>
        this.setState({}); 
    }
    addBoxUser(dado,dadoE = {},dadoD = {},nivel){  
        if(typeof dado.id == 'undefined'){ 
            return  (
                <Flex position={'relative'}  w={'180px'} opacity={0.3}  border='2px solid'  borderColor='green.500' p={'3px'} borderRadius= "24px" bg='rgba(58, 255, 202,0.3)'>
                    <Card  h='100%' alignSelf='flex-start' >
                        <CardHeader mb='18px' pt='25px'>
                            {nivel==0?<Stack  pos={'absolute'} left={'-175px'}   borderRadius={'20px 0 0 0'}  borderTop='2px solid' borderLeft='2px solid' borderColor='green.500'  w={'170px'} top={'calc(50% - 2px)'} h={'110px'}></Stack> :null}
                            {nivel==0?<Stack  pos={'absolute'} right={'-175px'}  borderRadius={'0 20px 0 0'}  borderTop='2px solid' borderRight='2px solid' borderColor='green.500'  w={'170px'} top={'calc(50% - 2px)'} h={'110px'}></Stack> :null}
                            
                            {nivel==1 && this.niv2?<Stack  pos={'absolute'} left={'-72px'}   borderRadius={'20px 0 0 0'}  borderTop='2px solid' borderLeft='2px solid' borderColor='green.500'  w={'66px'} top={'calc(50% - 2px)'} h={'110px'}></Stack> :null}
                            {nivel==1 && this.niv2?<Stack  pos={'absolute'} right={'-72px'}  borderRadius={'0 20px 0 0'}  borderTop='2px solid' borderRight='2px solid' borderColor='green.500'  w={'66px'} top={'calc(50% - 2px)'} h={'110px'}></Stack> :null}
                            
                            <Stack 
                                pos={'absolute'}
                                top={'-35px'}
                                left={'50%'}
                                transform={'translateX(-50%)'}
                                w={'fit-content'}
                                border='2px solid'
                                borderColor='green.500'
                                borderRadius='50%'
                                p={'4px'}
                                bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"}
                                >
                                <Avatar color={"tema1.300"} h='60px' w={'60px'} icon={<FaUser size='60px'/>} pt='5px' bg={"green.500"} overflow={'hidden'} />
                            </Stack>
                            <Flex justify='center' w='100%'> 
                                <Text fontSize='sm' color='tema1.100' fontWeight={'bold'}  >
                                    VAZIO
                                </Text>   
                            </Flex>
                        </CardHeader>
                        <CardBody>
                            <Flex direction='column' w={'100%'} > 
                                <Flex justify='space-between' w='100%' gap={0}>
                                    <Flex direction='column' gap={0}>
                                        <Text fontSize='xs' color='#fff' >
                                            0
                                        </Text>
                                        <Text color='gray.400' fontSize='sm'>
                                            PONTOS
                                        </Text>
                                    </Flex>
                                    <Flex direction='column'>
                                        <Button direction='column'     fontSize='12px' h='39px' lineHeight={'39px'} bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                            e.preventDefault();   
                                            
                                            this.atencaoB = <BoxWindow titulo = {'Extrato Geral'} mensagem={'No momento este recurso está desativado, logo mais será implementado!'} onClose={()=>{
                                                this.atencaoB = null;
                                                this.setState({});
                                            }} />
                                            this.setState({});
                                        }}>
                                            <Stack direction='column' w={'16px'}  p={'0px'} minW={'20px'}   align={'center'} justify={'100%'} lineHeight={'15px'}  gap={'0'} >
                                                <Icon as={FaInfo} h={'8px'} p={'0px'}  fontSize={'8px'} size={'18px'}/>
                                                <Text fontSize='8px'  pt={'3px'} lineHeight={'10px'} >DADOS</Text> 
                                            </Stack> 
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </CardBody>
                    </Card>
                </Flex>  
            );
        }else if(dado.usuario == G.painel.usuario){  
            return  (
                <Flex position={'relative'}  w={'180px'}  border='2px solid'  borderColor='tema1.200' p={'3px'} borderRadius= "24px" bg='rgba(0, 0, 0,0.3)' >
                    <Card  h='100%' alignSelf='flex-start' bg='tema1.200' >
                         <CardHeader mb='0px' pt='25px'>
                            {nivel==0?<Stack opacity={(typeof dadoE.id !== 'undefined'?'1':'0.3')} pos={'absolute'} left={'-175px'}   borderRadius={'20px 0 0 0'}  borderTop='2px solid' borderLeft='2px solid' borderColor='green.500'  w={'170px'} top={'calc(50% - 2px)'} h={'110px'}></Stack> :null}
                            {nivel==0?<Stack  opacity={(typeof dadoD.id !== 'undefined'?'1':'0.3')} pos={'absolute'} right={'-175px'}  borderRadius={'0 20px 0 0'}  borderTop='2px solid' borderRight='2px solid' borderColor='green.500'  w={'170px'} top={'calc(50% - 2px)'} h={'110px'}></Stack> :null}
                            
                            {nivel==1&& this.niv2?<Stack opacity={(typeof dadoE.id !== 'undefined'?'1':'0.3')} pos={'absolute'} left={'-72px'}   borderRadius={'20px 0 0 0'}  borderTop='2px solid' borderLeft='2px solid' borderColor='green.500'  w={'66px'} top={'calc(50% - 2px)'} h={'110px'}></Stack> :null}
                            {nivel==1&& this.niv2?<Stack opacity={(typeof dadoD.id !== 'undefined'?'1':'0.3')} pos={'absolute'} right={'-72px'}  borderRadius={'0 20px 0 0'}  borderTop='2px solid' borderRight='2px solid' borderColor='green.500'  w={'66px'} top={'calc(50% - 2px)'} h={'110px'}></Stack> :null}
                            
                            <Stack   pos={'absolute'}  top={'-35px'} left={'50%'} transform={'translateX(-50%)'} w={'fit-content'} border='2px solid' borderColor='green.500'  borderRadius='50%' p={'4px'}  bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"}>
                                <Avatar color={"tema1.300"} h='60px' w={'60px'} icon={<FaHouseUser size='40px'/>}   bg={"green.500"} overflow={'hidden'} />
                            </Stack> 
                            <Flex justify='center' w='100%' pb={'8px'}> 
                                <Text textTransform={'uppercase'} fontSize='sm' color='tema1.100' fontWeight={'bold'} noOfLines={1}  >
                                    Você
                                </Text>   
                            </Flex>
                            {nivel==2 && typeof dadoE.id !== 'undefined' ?<Stack zIndex={2} cursor={'pointer'} onClick={()=>{
                                this.paiID = { 
                                    usuario :   dadoE.usuario, 
                                    pontos  :   0
                                }
                                this.setState({});
                            }}   pos={'absolute'}  bottom={'-20px'} left={'0'} transform={'translateX(-50%)'} w={'fit-content'} border='2px solid' borderColor='green.500'  borderRadius='50%' p={'4px'}  bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"}>
                                <Avatar color={"tema1.300"} h='30px' w={'30px'} icon={<FiArrowDownLeft size='26px'/>}  bg={"green.500"} overflow={'hidden'} />
                            </Stack>:null}
                            {nivel==2 && typeof dadoD.id !== 'undefined'?<Stack zIndex={2}  cursor={'pointer'} onClick={()=>{
                                this.paiID = { 
                                    usuario :   dadoD.usuario, 
                                    pontos  :   0
                                }
                                this.setState({});
                            }}  pos={'absolute'}  bottom={'-20px'} right={'-40px'} transform={'translateX(-50%)'} w={'fit-content'} border='2px solid' borderColor='green.500'  borderRadius='50%' p={'4px'}  bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"}>
                                <Avatar color={"tema1.300"} h='30px' w={'30px'} icon={<FiArrowDownRight size='26px'/>}  bg={"green.500"} overflow={'hidden'} />
                            </Stack>:null} 
                        </CardHeader>
                        <CardBody>
                            <Flex direction='column' w={'100%'} > 
                                <Text textTransform={'uppercase'} fontSize='10px' pb={'5px'} color='tema1.100' fontWeight={'bold'} noOfLines={1}  >
                                  <Text as='span' color={'green.500'} >Chave:</Text>  {G.painel.chaveB=='A'?'Balanceado':G.painel.chaveB=='E'?"Esquerda":"Direita"}
                                </Text>   
                                <Flex justify='space-between' w='100%' gap={0}>
                                    <Button direction='column'  opacity={G.painel.chaveB == 'E'?'1':'0.3'} _hover={{opacity:'1'}}   fontSize='12px' h='30px' minW={'30px'} lineHeight={'30px'} p='0' bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                        e.preventDefault();      
                                        this.mudarChave('E');
                                         
                                    }}>
                                        <Icon as={FaArrowAltCircleLeft} h={'30px'}  fontSize={'20px'} /> 
                                    </Button>
                                    <Button direction='column'   opacity={G.painel.chaveB == 'A'?'1':'0.3'}  _hover={{opacity:'1'}}     fontSize='12px' h='30px' minW={'30px'} lineHeight={'30px'} bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                        e.preventDefault();    
                                        this.mudarChave('A');  
                                         
                                    }}>
                                        <Text fontSize='12px'    >AUTO</Text> 
                                    </Button>
                                    <Button direction='column'   opacity={G.painel.chaveB == 'D'?'1':'0.3'} _hover={{opacity:'1'}}     fontSize='12px' h='30px' minW={'30px'} lineHeight={'30px'} p='0' bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                        e.preventDefault();    
                                        this.mudarChave('D');
                                    }}>
                                        <Icon as={FaArrowAltCircleRight} h={'30px'}  fontSize={'20px'} /> 
                                    </Button>
                                    {/* <Flex direction='column' gap={0}>
                                        <Button direction='column'     fontSize='12px' h='39px' lineHeight={'39px'} bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                            e.preventDefault();    
                                            this.atencaoB = <BoxWindow titulo = {'Extrato Geral'} mensagem={'No momento este recurso está desativado, logo mais será implementado!'} onClose={()=>{
                                                this.atencaoB = null;
                                                this.setState({});
                                            }} />
                                            this.setState({});
                                        }}>
                                            <Stack direction='column' w={'16px'}  p={'0px'} minW={'20px'}   align={'center'} justify={'100%'} lineHeight={'15px'}  gap={'0'} >
                                                <Icon as={FaInfo} h={'8px'} p={'0px'}  fontSize={'8px'} size={'18px'}/>
                                                <Text fontSize='8px'  pt={'3px'} lineHeight={'10px'} >DADOS</Text> 
                                            </Stack> 
                                        </Button>
                                    </Flex>
                                    <Flex direction='column'>
                                        <Button direction='column'     fontSize='12px' h='39px' lineHeight={'39px'} bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                            e.preventDefault();    
                                            this.atencaoB = <BoxWindow titulo = {'Extrato Geral'} mensagem={'No momento este recurso está desativado, logo mais será implementado!'} onClose={()=>{
                                                this.atencaoB = null;
                                                this.setState({});
                                            }} />
                                            this.setState({});
                                        }}>
                                            <Stack direction='column' w={'16px'}  p={'0px'} minW={'20px'}   align={'center'} justify={'100%'} lineHeight={'15px'}  gap={'0'} >
                                                <Icon as={FaInfo} h={'8px'} p={'0px'}  fontSize={'8px'} size={'18px'}/>
                                                <Text fontSize='8px'  pt={'3px'} lineHeight={'10px'} >DADOS</Text> 
                                            </Stack> 
                                        </Button>
                                    </Flex> */}
                                </Flex>
                            </Flex>
                        </CardBody> 
                    </Card>
                </Flex>  
            );
        }else{
            return  (
                <Flex position={'relative'}  w={'180px'}  border='2px solid'  borderColor='green.500' p={'3px'} borderRadius= "24px" bg='rgba(58, 255, 202,0.3)'>
                    <Card  h='100%' alignSelf='flex-start' >
                        <CardHeader mb='18px' pt='25px'>
                            {nivel==0?<Stack opacity={(typeof dadoE.id !== 'undefined'?'1':'0.3')} pos={'absolute'} left={'-175px'}   borderRadius={'20px 0 0 0'}  borderTop='2px solid' borderLeft='2px solid' borderColor='green.500'  w={'170px'} top={'calc(50% - 2px)'} h={'110px'}></Stack> :null}
                            {nivel==0?<Stack  opacity={(typeof dadoD.id !== 'undefined'?'1':'0.3')} pos={'absolute'} right={'-175px'}  borderRadius={'0 20px 0 0'}  borderTop='2px solid' borderRight='2px solid' borderColor='green.500'  w={'170px'} top={'calc(50% - 2px)'} h={'110px'}></Stack> :null}
                            
                            {nivel==1&& this.niv2?<Stack opacity={(typeof dadoE.id !== 'undefined'?'1':'0.3')} pos={'absolute'} left={'-72px'}   borderRadius={'20px 0 0 0'}  borderTop='2px solid' borderLeft='2px solid' borderColor='green.500'  w={'66px'} top={'calc(50% - 2px)'} h={'110px'}></Stack> :null}
                            {nivel==1&& this.niv2?<Stack opacity={(typeof dadoD.id !== 'undefined'?'1':'0.3')} pos={'absolute'} right={'-72px'}  borderRadius={'0 20px 0 0'}  borderTop='2px solid' borderRight='2px solid' borderColor='green.500'  w={'66px'} top={'calc(50% - 2px)'} h={'110px'}></Stack> :null}
                            
                            <Stack   pos={'absolute'}  top={'-35px'} left={'50%'} transform={'translateX(-50%)'} w={'fit-content'} border='2px solid' borderColor='green.500'  borderRadius='50%' p={'4px'}  bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"}>
                                <Avatar color={"tema1.300"} h='60px' w={'60px'} icon={<FaUser size='60px'/>} pt='5px' bg={"green.500"} overflow={'hidden'} />
                            </Stack>
                            <Stack   pos={'absolute'}  top={'5px'} left={'50%'} transform={'translateX(-50%)'} w={'fit-content'}   p={'4px'} >
                                
                                <Button direction='column'  leftIcon={<FaProjectDiagram/>}    fontSize='10px' h='30px' lineHeight={'30px'} bg={'tema1.200'} color={'green.500'} variant='solid'  onClick={(e)=>{ 
                                    this.paiID = { 
                                        usuario :   dado.usuario, 
                                        pontos  :   0
                                    };
                                    this.setState({});
                                }}>
                                    VER REDE
                                </Button>    
                            </Stack>
                            <Flex justify='center' w='100%'> 
                                <Text textTransform={'uppercase'} fontSize='sm' color='tema1.100' fontWeight={'bold'} noOfLines={1}  >
                                    {dado.usuario}
                                </Text>   
                            </Flex>
                            {nivel==2 && typeof dadoE.id !== 'undefined' ?<Stack zIndex={2} cursor={'pointer'} onClick={()=>{
                                this.paiID = { 
                                    usuario :   dadoE.usuario, 
                                    pontos  :   0
                                }
                                this.setState({});
                            }}   pos={'absolute'}  bottom={'-20px'} left={'0'} transform={'translateX(-50%)'} w={'fit-content'} border='2px solid' borderColor='green.500'  borderRadius='50%' p={'4px'}  bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"}>
                                <Avatar color={"tema1.300"} h='30px' w={'30px'} icon={<FiArrowDownLeft size='26px'/>}  bg={"green.500"} overflow={'hidden'} />
                            </Stack>:null}
                            {nivel==2 && typeof dadoD.id !== 'undefined'?<Stack zIndex={2}  cursor={'pointer'} onClick={()=>{
                                this.paiID = { 
                                    usuario :   dadoD.usuario, 
                                    pontos  :   0
                                }
                                this.setState({});
                            }}  pos={'absolute'}  bottom={'-20px'} right={'-40px'} transform={'translateX(-50%)'} w={'fit-content'} border='2px solid' borderColor='green.500'  borderRadius='50%' p={'4px'}  bg={"linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"}>
                                <Avatar color={"tema1.300"} h='30px' w={'30px'} icon={<FiArrowDownRight size='26px'/>}  bg={"green.500"} overflow={'hidden'} />
                            </Stack>:null} 
                        </CardHeader>
                        <CardBody>
                            <Flex direction='column' w={'100%'} > 
                                <Flex justify='space-between' w='100%' gap={0}>
                                    <Flex direction='column' gap={0}>
                                        <Text fontSize='xs' color='#fff' >
                                            {dado.pontos}
                                        </Text>
                                        <Text color='gray.400' fontSize='sm'>
                                            PONTOS
                                        </Text>
                                    </Flex>
                                    <Flex direction='column'>
                                        <Button direction='column'     fontSize='12px' h='39px' lineHeight={'39px'} bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                            e.preventDefault();    
                                            this.atencaoB = <BoxWindow titulo = {'Extrato Geral'} mensagem={'No momento este recurso está desativado, logo mais será implementado!'} onClose={()=>{
                                                this.atencaoB = null;
                                                this.setState({});
                                            }} />
                                            this.setState({});
                                        }}>
                                            <Stack direction='column' w={'16px'}  p={'0px'} minW={'20px'}   align={'center'} justify={'100%'} lineHeight={'15px'}  gap={'0'} >
                                                <Icon as={FaInfo} h={'8px'} p={'0px'}  fontSize={'8px'} size={'18px'}/>
                                                <Text fontSize='8px'  pt={'3px'} lineHeight={'10px'} >DADOS</Text> 
                                            </Stack> 
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </CardBody>
                    </Card>
                </Flex>  
            );
        }   
    }
    render(){  
        this.gerarDadosMatriz(); 
        var dado ={}; 
        if(this.load){
            return ( 
                <Flex position={'relative'} direction={'column'} w={'max-content'} p={'30px'} m={'0 auto'}>
                    <Box position={'absolute'} left={'0px'} top={'10px'} opacity={'0.4'} borderRadius='18px 0 0 18px' h={'calc(100% - 10px)'} bg='green.500' w={'calc(50%)'}></Box>
                    <Box position={'absolute'} left={'calc(50%)'} top={'10px'} opacity={'0.4'} borderRadius='0 18px 18px 0' h={'calc(100% - 10px)'} bg='tema1.300' w={'calc(50%)'}></Box>
                        
                    <Flex pos={'relative'} mt='30px'  flexDir={'column'}   maxW={'100%'} w={'100%'}    align={'center'} justify={'center'} >
                        {this.subPai.map((dado)=>{
                            return this.addBoxPai(dado)
                        })}
                    </Flex>
                    <Flex pos={'relative'} mt='30px'  flexDir={'row'}   maxW={'100%'} w={'100%'} minH={{base:'80px',md:'140px'}}  mb='30px'  align={'center'} justify={'center'} >
                        {this.addBoxUser(this.matriz['N0'],this.matriz['N1P1E'],this.matriz['N1P1D'],0)} 
                    </Flex>
                    <Flex pos={'relative'} mt='30px'  flexDir={'row'}   maxW={'100%'} w={'100%'} minH={{base:'80px',md:'140px'}}  mb='30px'  align={'center'} justify={'center'}  gap={'30px'}>
                        {this.addBoxUser(this.matriz['N1P1E'],this.matriz['N2P1E'],this.matriz['N2P1D'],1)} 
                        <Flex w={'280px'} p={'3px'} ></Flex>
                        {this.addBoxUser(this.matriz['N1P1D'],this.matriz['N2P2E'],this.matriz['N2P2D'],1)} 
                    </Flex>
                    {(this.niv2)?
                        <Flex pos={'relative'} mt='30px'  flexDir={'row'}   maxW={'100%'} w={'100%'} minH={{base:'80px',md:'140px'}}  mb='30px'  align={'center'} justify={'center'}  gap={'30px'}>
                            {this.addBoxUser(this.matriz['N2P1E'],this.matriz['N3P1E'],this.matriz['N3P1D'],2)}  
                            <Flex w={'70px'} p={'3px'} ></Flex> 
                            {this.addBoxUser(this.matriz['N2P1D'],this.matriz['N3P2E'],this.matriz['N3P2D'],2)}   
                            {this.addBoxUser(this.matriz['N2P2E'],this.matriz['N3P3E'],this.matriz['N3P3D'],2)} 
                            <Flex w={'70px'} p={'3px'} ></Flex> 
                            {this.addBoxUser(this.matriz['N2P2D'],this.matriz['N3P4E'],this.matriz['N3P4D'],2)} 
                        </Flex>:null
                    }   
                    {this.atencao}
                    {this.atencaoB}
                </Flex>
                 
            )
        }else{
            return <BoxWindow load={true}/>
        }
       
    }
}
export default ListaBinario;