
import { Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import G from "./WebSocket"
import { Navigate } from "react-router-dom";
class ServidorSessao extends React.Component  { 
    logado      =   false;
    tempUSU     =   null;
    sessao      =   false;
    tempInter   =   null;
    atencao     =   null;
    buscarSession(){
        if(G.painel != null ){ 
            if(this.tempUSU != JSON.stringify(G.painel)){
                this.tempUSU = JSON.stringify(G.painel);  
                if(typeof G.painel.usuario !=='undefined'){
                    this.logado = true;
                }else{
                    this.logado = false;
                }
                // console.log(this.props.logado , this.logado);
                if(this.props.logado == this.logado){
                    this.sessao =    true; 

                    this.setState({})
                }else{ 
                    this.sessao =    false; 
                    this.atencao = <Navigate to={this.props.pageLoad} />
                    this.setState({});
                }  
                
            } 
        }  
    }
    componentDidMount(){ 
         
        this.tempInter =setInterval(()=>{
            this.buscarSession()  
        },200) 
        this.buscarSession()  
    } 
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    render(){  
        if(this.sessao){
            return (
                <>
                {this.props.children}   
                </>
            )
        }else{
            return (
                <Modal isCentered isOpen={true}  size={{base:'sm',sm:(typeof this.props.size=='undefined'?'md':this.props.size)}}  >
                    {this.atencao}
                    <ModalOverlay
                        bg='tema1.100' 
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                    />
                    <ModalContent bg={'tema1.200'} w={'280px'}  >
                        <ModalHeader color={'green.500'}>Autenticando Sessão</ModalHeader> 
                            <ModalBody  color={'#ffffff'}>
                                <Flex flexDirection='row' align='center' justify='center'>
                                    <Spinner color='green.500' /> <Text ml='15px' >Aguarde um momento...</Text>
                                </Flex> 
                            </ModalBody> 
                        <ModalFooter/>
                    </ModalContent>
                </Modal>
            )
        }
       
    }
}
export default ServidorSessao;