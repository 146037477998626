import * as React from "react"  
import {
    Avatar,
    Box,
    Button,   
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    Icon,
    Image,
    LightMode,
    List,
    ListIcon,
    ListItem,
    Progress,
    Spacer,
    Stack,
    Switch,
    Text,
    Tooltip, 
  } from '@chakra-ui/react';
import { FaCheckCircle, FaCopy, FaExchangeAlt, FaFileAlt, FaHistory, FaPeace, FaPen, FaPenAlt, FaPlusSquare, FaPowerOff, FaRegStar, FaRocket, FaTimes, FaTrashAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { RiFolderHistoryFill } from 'react-icons/ri'; 

import G  from '../../components/WebSocket'
import BoxWindow from "../../components/BoxWindow/BoxWindow"; 
import { FaUsb } from "react-icons/fa6";
import { SlackWhiteIcon } from "../../components/VisionUI/Icons/Icons";
import IconBox from "../../components/VisionUI/Icons/IconBox";
import { HSeparator } from "../../components/VisionUI/Separator/Separator";
import Card from "../../components/VisionUI/Card/Card";
import CardBody from "../../components/VisionUI/Card/CardBody";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
class ListaPacotes extends React.Component { 
    viewPedidos = null
    robos       = {};
    tempLanc    = null;
    grupo       =   'C';
    ordem       =   'ASC';
    sorte       =   'T';
    lancamentos =   [];
    tipos       =   {};
    rede = [];
    dados  =   [];
    db= {
        saldos:{}
    }
    load        =   false;
    lancPAC     =   {};
    buscarJogos(){
        var relaod = false;  
        if(G.geral!= null && (typeof G.geral.pacotes != 'undefined' && this.tempLanc != JSON.stringify(G.geral.pacotes))){ 
            this.tempLanc       =   JSON.stringify(G.geral.pacotes);  
            this.lancamentos    = [];
            if(typeof G.geral.pacotes != 'undefined'){  
                Object.values(G.geral.pacotes).forEach((lanc)=>{
                    this.lancamentos.push(lanc);
                }); 
                
            } 
            relaod = true;
        } 
        if(G.geral!= null && (typeof G.geral.lancamentos != 'undefined' && this.tempLancDEB != JSON.stringify(G.geral.lancamentos))){ 
            this.tempLancDEB       =   JSON.stringify(G.geral.lancamentos);  
            this.lancPAC    = {};
            if(typeof G.geral.lancamentos != 'undefined'){  
                Object.values(G.geral.lancamentos).forEach((lanc)=>{
                    if(lanc.debpac!= null){
                        if(typeof this.lancPAC[lanc.debpac] == 'undefined'){
                            this.lancPAC[lanc.debpac] = {
                                valorR      :     0,
                                valorP      :     0,
                                extrato     :     []
                            }
                            
                        }
                        switch (lanc.debtip) {
                            case 'R':
                                this.lancPAC[lanc.debpac].valorR+=lanc.valor;
                                this.lancPAC[lanc.debpac].extrato.push(lanc)
                            break; 
                            case 'P':
                                this.lancPAC[lanc.debpac].valorP+=lanc.valor;
                                this.lancPAC[lanc.debpac].extrato.push(lanc)
                            break;
                        }
                    } 
                });  
            }
            this.load = true;  
            this.tipos = {};
            G.geral.lancamentosTipo.forEach((lt)=>{
                this.tipos[lt.id]=lt;
            }) 
            this.load =true;
            relaod = true;
        } 
        if(relaod){
            this.setState({});
        }   
    }
    cDelay(){
        clearTimeout(this.tempD);
        this.tempD = setTimeout(()=>{
            this.setState({});
        },500);
    }
    pag = {
        min:0,
        max:10,
        n:10,
        aba:0,
        p:0
    }
    statusB={}; 
    processarExtrato(){
        var bgT = 'linear-gradient(180deg,rgba(25, 31, 50,0.6) 17.33%,rgba(25, 31, 50,0))'; 
        bgT = 'transparent'; 
        var view =  <Text align={'center'}  py='10px'  px={{base:'0px',md:'20px'}}  justify='center'  color={'tema1.400'}  opacity={'0.5'} fontSize={'xs'}   textAlign={{base:'center'}}>  Nenhum registro no momento  </Text>
 
        if(this.lancamentos.length>0){
            view = []
            var dados     = this.lancamentos; 
            this.pag.p    = parseInt((dados.length/this.pag.n).toString().split('.')[0]);
            if((dados.length%this.pag.n)){
              this.pag.p++;
            }
            var viewPag   =  [];
            var add = true;
            for(var n=0;n<this.pag.p;n++){ 
              if(this.pag.p>5){
                add = false;
                if(n==0 || n == (this.pag.p-1)){
                  add=true;
                }else if(this.pag.aba==0 || this.pag.aba==1 || this.pag.aba==2){
                  if(n == 1 || n == 2|| n == 3){
                    add=true; 
                  }
                }else if( this.pag.aba == (this.pag.p-3) || this.pag.aba == (this.pag.p-2)|| this.pag.aba == (this.pag.p-1)){ 
                    if(n == (this.pag.p-4) || n == (this.pag.p-3)|| n == (this.pag.p-2)){
                      add=true; 
                    }   
                }else if(n==this.pag.aba-1|| n==this.pag.aba || n==this.pag.aba+1){
                  add=true;   
                } 
              } 
              if(add){
                const pos = n; 
                viewPag.push(<Button onClick={()=>{ 
                  this.pag.aba = pos;
                  this.setState({});
                }} key={'pag'+pos}  colorScheme={this.pag.aba==pos?'green':'gray'} fontSize={'small'} p={0} h='24px' minW='24px'>{pos+1}</Button>)
              } 
            } 
            this.pag.min=(this.pag.n*this.pag.aba);
            this.pag.max=(this.pag.n*this.pag.aba)+this.pag.n;  
            var viewErro = '';
            if(this.lancamentos.length>0){
                this.lancamentos.sort((a, b)=>{  
                    return a.criacao > b.criacao ? -1 : a.criacao < b.criacao ? 1 : 0; 
                }).forEach((dado,i)=>{  
                    if(i>=this.pag.min && i<this.pag.max){  
                        var status = {
                            valorP : 0,
                            valorR : 0,
                            porcP : 0,
                            porcR : 0,
                            extrato:[]
                        }
                        if(typeof this.lancPAC[dado.id]!='undefined'){
                            status.valorP =  this.lancPAC[dado.id].valorP;
                            status.valorR =  this.lancPAC[dado.id].valorR;
                            status.porcP =  (this.lancPAC[dado.id].valorP*100/(dado.valor*2)).toFixed(2);
                            status.porcR =  (this.lancPAC[dado.id].valorR*100/(dado.valor*2)).toFixed(2); 
                            status.extrato =  this.lancPAC[dado.id].extrato;
                        }
                        view.push( 
                            <GridItem key={'bot'+dado.id} justify='center' align='center'  w={'full'} py='0px' colSpan={1} h='auto'   >
                                <Card w={'280px'} h='100%' alignSelf='flex-start'>
                                    <CardHeader mb='18px'>
                                        <Flex justify='space-between' w='100%'>
                                            <Flex>
                                                <IconBox bg='green.500' color='tema1.200' w='60px' h='60px' me='22px'>
                                                <FaRocket
                                                    size='30px'  
                                                />
                                                </IconBox>
                                                <Flex direction='column'>
                                                    <Text fontSize='sm' color='tema1.100' fontWeight={'bold'} mb='8px'>
                                                        PLANO {G.parseValor(dado.valor,1).f} USDT
                                                    </Text>
                                                    <Text fontSize='xs' color={new Date(dado.dataI).getTime()<=new Date().getTime()?'gray.500':'orange.500'} mb='8px' fontWeight={'800'}>
                                                        {new Date(dado.dataI).getTime()<=new Date().getTime()?'Iniciado em ':'Inicia em '}  <Text as='span' color={'green.500'}>{new Date(dado.dataI).toISOString().split('T')[0]}</Text> 
                                                    </Text> 
                                                </Flex>
                                            </Flex> 
                                        </Flex>
                                    </CardHeader>
                                    <CardBody>
                                        <Flex direction='column' w={'100%'} >
                                            <Stack w={'100%'}   > 
                                                <Flex direction='column' textAlign={'left'}>
                                                    <Flex justify='space-between' mb='8px'>
                                                        <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                            Performance
                                                        </Text>
                                                        <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                        {G.parseValor(status.valorP).p},{G.parseValor(status.valorP,2).d}/{dado.valor*2} USDT
                                                        </Text>
                                                    </Flex>
                                                    <LightMode>
                                                        <Progress
                                                        bg='#2D2E5F'
                                                        colorScheme='green'
                                                        size='xs'
                                                        value={status.porcP}
                                                        borderRadius='15px'></Progress>
                                                    </LightMode>
                                                </Flex>
                                                <Flex direction='column' textAlign={'left'}>
                                                    <Flex justify='space-between' mb='8px'>
                                                        <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                            Rede
                                                        </Text>
                                                        <Text fontSize='xs' color='gray.400' fontWeight='500'>
                                                        {G.parseValor(status.valorR).p},{G.parseValor(status.valorR,2).d}/{dado.valor*2} USDT
                                                        </Text>
                                                    </Flex>
                                                    <LightMode>
                                                        <Progress
                                                        bg='#2D2E5F'
                                                        colorScheme='green'
                                                        size='xs'
                                                        value={status.porcR}
                                                        borderRadius='15px'></Progress>
                                                    </LightMode>
                                                </Flex>
                                            </Stack> 
                                        <Flex justify='space-between' mt={'20px'} w='100%'>
                                            <Flex direction='column'>
                                                <Text fontSize='xs' color='#fff' mb='6px'>
                                                    {status.extrato.length}
                                                </Text>
                                            <Text color='gray.400' fontSize='sm'>
                                                Lançamentos
                                            </Text>
                                            </Flex>
                                            <Flex direction='column'>
                                                <Button direction='column'     fontSize='12px' h='45px' lineHeight={'45px'} bg={'green.500'} color={'tema1.200'} variant='solid'  onClick={(e)=>{ 
                                                    e.preventDefault();   
                                                  
                                                    this.atencaoB = <BoxWindow titulo = {'Extrato Geral'} mensagem={'No momento este recurso está desativado, logo mais será implementado!'} onClose={()=>{
                                                        this.atencaoB = null;
                                                        this.setState({});
                                                    }} />
                                                    this.setState({});
                                                }}>
                                                    <Stack direction='column' w={'100'} py={'5px'} align={'center'} justify={'100%'} lineHeight={'15px'}  gap={'0'} >
                                                        <Icon as={FaFileAlt} h={'20px'} p={'0px'}  fontSize={'18px'} size={'18px'}/>
                                                        <Text fontSize='10px'  pt={'3px'} lineHeight={'10px'} >EXTRATO</Text> 
                                                    </Stack>
                                                    
                                                </Button>
                                            </Flex>
                                        </Flex>
                                        </Flex>
                                    </CardBody>
                                </Card>
                                 
                            </GridItem>  
                        );  
                    }
                })
            }else{
                viewErro = <Text color='green.500' w={'100%'} textAlign={'center'}>Nenhum pacote encontrado</Text> 
            }
            
            
        } 
      
       
        return (
            <Flex flexDir={'column'}   w={'full'}  minH={'100%'} bg={{base:'transparent',md:bgT}} >
                 {this.pag.p>1?
                  <Stack flexDir={'row'} pos={'absolute'} top='0px' right={'30px'}>
                    {viewPag} 
                  </Stack>
                :null
                }  
                <Grid  gap={'20px'}   bg={{base:bgT   ,md:'transparent'}}  w='full' py='0px' px={'10px'} pb={'0px'} fontSize={'sm'} color={'green.500'} align='left'   templateColumns= {{base :'repeat(1, 1fr)',  sm :'repeat(2, 1fr)',  md :'repeat(1, 1fr)', xl :'repeat(3, 1fr)', '2xl' :'repeat(4, 1fr)' }} >
                    {view}
                </Grid>
                {viewErro}
            </Flex>  
        );
    }
    componentDidMount(){
        this.buscarJogos();
        this.setState({});
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    render(){  
        var viewLista   =   null;  
        viewLista = this.processarExtrato(); 
    
        return ( 
            <Flex pos={'relative'}  flexDir={'column'}   maxW={'100%'} w={'100%'} minH={{base:'80px',md:'140px'}}  mb='30px'   >
                {viewLista}   
                {this.atencao }
                {this.atencaoB}
            </Flex>   
        )
    }
}
export default ListaPacotes;