import * as React from "react" 
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Spacer,
  Center,
  FormControl,
  FormLabel,
  Textarea,
  Link,
  Card,
  CardHeader,
  Icon,
  PinInput,
  PinInputField,
} from '@chakra-ui/react'
import { FaArrowLeft, FaChartLine, FaCheck, FaCheckCircle, FaCopy, FaEraser, FaPaypal, FaProjectDiagram, FaRocket, FaTimes } from 'react-icons/fa'
import { ChevronDownIcon } from "@chakra-ui/icons";
import G from '../../components/WebSocket'; 
import { MdPayment, MdPix, MdRateReview } from "react-icons/md";
import BoxWindow from "../BoxWindow/BoxWindow"; 
import QRCode from "react-qr-code";
import { BsFillBarChartLineFill } from "react-icons/bs";
import GradientBorder from "../VisionUI/GradientBorder";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { RiMastercardFill } from "react-icons/ri";
import { SiTether } from "react-icons/si";
import { HSeparator } from "../VisionUI/Separator/Separator";
import { Navigate } from "react-router-dom";


class BoxPlanos extends React.Component   {   
    plano   =   'M';
    codpla  =   1;
    nomes={  'M':"Mensal",'T':"Trimestral",'S':"Semestral",'A':"Anual"}
    meses={  'M':1,'T':3,'S':6,'A':12}
    planos      =   [];
    pedidos     =   {};
    pagamento   =   {};
    bestPlan    =   {};
    buscarJogos(){ 
        if(G.geral!= null && typeof G.geral.pedidos !=='undefined' && this.tempPed != JSON.stringify(G.geral.pedidos)){    
            this.tempPed = JSON.stringify(G.geral.pedidos); 
            this.pedidos = G.geral.pedidos;  
            if(G.geral.pedidos.length>0){
                this.pagamento = {}
                G.geral.pedidos.forEach((ped)=>{
                    if(ped.situa == 'P' && ped.carteira != null && typeof this.pagamento.id =='undefined'){
                        this.pagamento = ped;
                    }
                })
               
            }else{
                this.pagamento = {};
            } 
            this.setState({});
        }  
        if(G.geral!= null && typeof G.geral.planos !=='undefined' && this.tempStat != JSON.stringify(G.geral.planos)){    
            this.tempStat = JSON.stringify(G.geral.planos);
            this.planos = G.geral.planos;  
            this.bestPlan   = G.geral.planos[4]
            // console.log(G.geral.planos[4]);
            this.setState({});
        }  
    }
    componentDidMount(){
        this.buscarJogos();
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200) 
       
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    }
    dadosP = {
        plano   :   {},
        forma   :   0, 
        chave  :   undefined,
        upgrade :   false
    }
    nB=20;
    loadPedido = false;
    sendReinvestimento(){
        var pla= this.dadosP; 
        G.socket.removeAllListeners('sendReinvestimento').on('sendReinvestimento',(result)=>{ 
            G.socket.removeAllListeners('sendReinvestimento');   
            if(result.hash!=null && result.hash.cmd == 'email'){
                this.sendKey = true;
            }
            this.atencao = <BoxWindow titulo = {result.titulo} mensagem={result.mensagem} onClose={()=>{
                if(!result.erro){   
                    this.atencao = <Navigate to={'/mypackages'} />  
                    G.viewPlanos = null;
                    this.setState({}); 
                     
                }else{
                    this.atencao = null;
                }
                
                
                this.setState({}); 
            }}/> 
            this.setState({});  
        });  
        G.socket.emit("sendReinvestimento", {
            plano: pla.plano.id, 
            chave: pla.chave,
            idioma:G.idioma.id
        });  
        this.atencao = <BoxWindow   load={true}/>;
    }
    render(){  
         
        var pAtual = {
            n   :   '',
            p   :   '',
            d   :   0, 
            pd  :   0.0133
        } 
        if(G.painel.bot>0){
            pAtual.n = G.painel.bot+' '+(G.painel.bot>1?'BOTs':'BOT')
        }else{
            pAtual.n = 'FREE';
        }

        if(G.painel.validade==null){
            pAtual.p = 'SEM PLANO'
        }else{
            if(new Date(G.painel.validade).getTime()<new Date().getTime()){
                pAtual.p = 'VENCIDO';
            }else{
                pAtual.p = G.dataTempo((new Date().getTime() - new Date(G.painel.validade).getTime())).nome;
            } 
        }

        let sp = {valor:{}};
        let bp = {valor:{}}; 
        var viewBOx = null

        if(typeof this.pagamento.valor!=="undefined"){ 
            this.atencao = null;
            this.loadPedido = false;
            var pg = this.pagamento;
            viewBOx = '';
            var pla = {}; 
            G.geral.planos.forEach((p)=>{
                if(p.id ==pg.codpla){ 
                    pla =p;
                }
            }) 
            
            switch (pg.codtip) { 
                case 1:
                    viewBOx =  (
                        <Stack  rounded={'xl'}  p={{ base: 4, sm: 6, md: 8 }}    maxW={{ lg: 'lg' }}  > 
                            <Stack spacing={0}   align='center' justify='center'>  
                                <Stack align='center' justify={'center'} pb='20px' flexDir={'row'}   w={{base:'270px',lg:'370px'}} spacing={1}> 
                                    <Text  color='tema1.400'>
                                        Finalize o pagamento via USDT BEP-20
                                    </Text>    
                                </Stack> 
                                <Stack  align='center' justify={'center'} flexDir={'column'}   spacing={1}>
                                    <Box bg='tema1.400' w={{base:'270px'}} h={{base:'270px'}} borderRadius={'lg'}> 
                                        <Center p='20px' >
                                            <QRCode value={pg.carteira}  bgColor="transparent" size={230}  /> 
                                        </Center> 
                                    </Box>
                                    <Flex direction={{ base: 'row', md: 'row' }}  textAlign="center"> 
                                        <Stack  w={'100%'} textAlign="center" justify="center"  > 
                                            <Text as={'b'} mt={'15px'} mb={'5px'} fontSize="lg" color={'tema1.400'} px={'30px'}>
                                                Resumo do Pedido
                                            </Text> 
                                            <Box textTransform={'uppercase'} p={4} w={'300px'} m='0 auto' shadow="base" borderWidth="1px" alignSelf={{ base: 'center', lg: 'flex-start' }}  borderColor={'tema1.500'} borderRadius={'xl'}> 
                                                <Stack  direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                                    <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                                        Valor Plano
                                                    </Text>
                                                    <Spacer/>
                                                    <Text as={'b'} fontSize="small" color={'gray.500'} px={'0px'}>
                                                        {pla.valor} USDT
                                                    </Text>
                                                </Stack>
                                                <Stack  direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                                    <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                                        Taxa de Depósito
                                                        
                                                    </Text>
                                                    <Spacer/>
                                                    <Text as={'b'} fontSize="small" color={'gray.500'} px={'0px'}> 
                                                    {pla.valor*0.05} USDT
                                                    </Text>
                                                </Stack> 
                                                <Stack mt='10px' direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                                    <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                                        Total a Pagar
                                                        
                                                    </Text>
                                                    <Spacer/>
                                                    <Text as={'b'} fontSize="lg" color={'green.500'} px={'0px'}> 
                                                    {pla.valor*1.05} USDT
                                                    </Text>
                                                </Stack> 
                                            </Box>  
                                        </Stack>
                                    </Flex>
                                    <Card mt={'20px'}  >
                                        <CardHeader> 
                                            <Flex
                                                justify='space-between'
                                                align='center'
                                                pb='0px' 
                                                >  
                                                <Text fontSize='sm' lineHeight='30px' color='#fff' fontWeight='bold'>
                                                    Carteira BEP-20
                                                </Text>
                                                <Button  onClick={async (e)=>{ 
                                                    e.preventDefault();   
                                                    var Url = document.getElementById("inopay");
                                                    Url.select() 
                                                    document.execCommand("copy");
                                                    this.atencaoB = <BoxWindow titulo = {'Cópia realizada com sucesso!'} mensagem={'Você copiou a carteira BEP-20 na memória, agora só transferir o valor exato para essa carteira, e assim  finalizar o pagamento'} onClose={()=>{
                                                        this.atencaoB = null;
                                                        this.setState({});
                                                    }}/>
                                                    this.setState({});
                                                }} p={'0px 10px'} h={'30px'} leftIcon={<FaCopy fontSize={'18px'}/>} fontSize='12px' bg={'green.500'} color={'tema1.200'} variant='solid'>
                                                    Copiar
                                                </Button>
                                            </Flex>
                                        </CardHeader>
                                        <Flex
                                            direction={{ sm: "column", md: "row" }}
                                            align='center' 
                                            justify='center'
                                        >
                                            <GradientBorder 
                                            w='100%'
                                            borderRadius='10px'>
                                                <Flex
                                                    p='0'
                                                    bg='tema1.200'
                                                    border='transparent'
                                                    borderRadius='10px'
                                                    align='center'
                                                    w='100%'>
                                                     
                                                    <Textarea id="inopay" textAlign={'center'}  overflow='hidden' p='20px 10px'  lineHeight={'20px'}  borderRadius='10px' outline={'none'} color='#fff' minH={'60px'} fontSize='sm' isReadOnly placeholder='Here is a sample placeholder' w={{base:'270px',lg:'370px'}} resize='none' onClick={(e)=>{ 
                                                        e.preventDefault();   
                                                        var Url = document.getElementById("copyText");
                                                        Url.select() 
                                                        document.execCommand("copy");
                                                        this.atencaoB = <BoxWindow titulo = {'Cópia realizada com sucesso!'} mensagem={'Você copiou a carteira BEP-20 na memória, agora só transferir o valor exato para essa carteira, e assim  finalizar o pagamento'} onClose={()=>{
                                                            this.atencaoB = null;
                                                            this.setState({});
                                                        }} />
                                                        this.setState({});
                                                    }}
                                                    defaultValue={pg.carteira}
                                                    /> 
                                                </Flex>
                                            </GradientBorder> 
                                        </Flex>
                                    </Card>   
                                    <Stack  align='center' justify={'center'} flexDir={'column'} pt='5' spacing={1}> 
                                        <Button
                                            fontFamily={'heading'} 
                                            w={'full'}
                                            bg="transparent"
                                            color={'red'}
                                            fontSize={'12px'}
                                            _hover={{
                                                bg: 'red',
                                                boxShadow: 'xl',
                                                color:'tema1.200'
                                            }}
                                            onClick={(e)=>{ 
                                                e.preventDefault();    
                                                this.atencaoB = <BoxWindow titulo = {'Cancelamento de Pedido'} mensagem={'Depois de cancelado o sistema não aceitará mais o pagamentos na carteira gerada, você deseja cancelar realmente cancelar esse pedido?'}
                                                botao={
                                                    <Stack   align='center' justify={'center'} flexDir={'row'} pt='5' spacing={'0'}>
                                                        <Button fontFamily={'heading'}   w={'full'} bgGradient="linear(to-r, tem1.100,tem1.100)"  color={'white'}  onClick={(e)=>{ 
                                                                e.preventDefault();    
                                                                //console.log(pg);
                                                                // console.log('cancelar o pedido')
                                                                G.socket.removeAllListeners('sendCancelarPedido').on('sendCancelarPedido',(result)=>{ 
                                                        
                                                                    G.socket.removeAllListeners('sendCancelarPedido'); 
                                                                  
                                                                    this.atencaoB = <BoxWindow titulo = {result.titulo} mensagem={result.mensagem} onClose={()=>{
                                                                        this.atencaoB = null; 
                                                                        G.socket.emit("dadosGeral");
                                                                        this.setState({}); 
                                                                    }}/> 
                                                                    G.socket.emit("dadosGeral");
                                                                    setTimeout(()=>{
                                                                        G.socket.emit("dadosGeral");
                                                                        this.setState({});
                                                                    },500)
                                                                    this.setState({});
                                                                });  
                                                                G.socket.emit("sendCancelarPedido", {pedido:pg.id,idioma:G.idioma.id}); 

                                                            }} _hover={{  bgGradient: 'linear(to-r,tema1.100,tema1.100)',  boxShadow: 'xl', color:'tema1.200' }} 
                                                        >
                                                            Confirmar Cancelar
                                                        </Button>  
                                                        <Button fontFamily={'heading'}  w={'full'} bg="transparent" color={'red'} _hover={{  bg: 'red', boxShadow: 'xl', color:'tema1.200'  }}
                                                            onClick={(e)=>{ 
                                                                e.preventDefault();   
                                                                this.atencaoB =null
                                                                this.setState({});
                                                            }}
                                                            cursor={'pointer'}   
                                                            leftIcon={<FaTimes/>}
                                                        >
                                                            Não Cancelar
                                                        </Button> 
                                                    </Stack>
                                                }
                                                
                                                onClose={()=>{
                                                    this.atencaoB = null;
                                                    this.setState({});
                                                }}/>
                                                this.setState({});
                                            }}
                                            cursor={'pointer'}   
                                            leftIcon={<FaTimes/>}  >
                                            Cancelar Pedido
                                        </Button> 
                                    </Stack> 
                                </Stack>   
                            </Stack>
                        </Stack>
                    )
                break; 
                case 2:
                    viewBOx =  (
                        <Stack  rounded={'xl'}  p={{ base: 4, sm: 6, md: 8 }}    maxW={{ lg: 'lg' }}  > 
                            <Stack spacing={0}   align='center' justify='center'>  
                                <Stack align='center' justify={'center'} pb='20px' flexDir={'row'} pt='5' w={{base:'270px',lg:'370px'}} spacing={1}> 
                                    <Text  color='tema1.500'>
                                        Faça o pagamento do pix via QRCODE ou via copiar e colar e aguarde a ativação automaticamente!
                                    </Text>   
                                </Stack>
                                <Box bg='tema1.400' w={{base:'270px'}} h={{base:'270px'}} borderRadius={'lg'}> 
                                    <Center p='20px' >
                                        <QRCode value={pg.linkPix}  bgColor="transparent" size={230}  /> 
                                    </Center> 
                                </Box> 
                                <Stack align='center' justify={'center'} flexDir={'row'} pt='5' spacing={1}>  
                                    <Text  color='tema1.500'>
                                        <Textarea id="copyText"  overflow='hidden' bg='tema1.300'   border='0.5px solid' borderColor='tema1.500' isReadOnly placeholder='Here is a sample placeholder' w={{base:'270px',lg:'370px'}} resize='none' onClick={(e)=>{ 
                                            e.preventDefault();   
                                            var Url = document.getElementById("copyText");
                                            Url.select() 
                                            document.execCommand("copy");
                                            this.atencaoB = <BoxWindow titulo = {'Cópia realizada com sucesso!'} mensagem={'Você copiou a carteira BEP-20 na memória, agora só transferir o valor exato para essa carteira, e assim  finalizar o pagamento'} onClose={()=>{
                                                this.atencaoB = null;
                                                this.setState({});
                                            }} />
                                            this.setState({});
                                        }}
                                        defaultValue={pg.linkPix}
                                        />
                                    </Text>
                                </Stack> 
                                <Stack  align='center' justify={'center'} flexDir={'column'} pt='5' spacing={1}>
                                    <Button
                                        fontFamily={'heading'} 
                                        w={'full'}
                                        bgGradient="linear(to-r, tem1.100,tem1.100)"
                                        color={'white'} 
                                        onClick={(e)=>{ 
                                            e.preventDefault();   
                                            var Url = document.getElementById("copyText");
                                            Url.select() 
                                            document.execCommand("copy");
                                            this.atencaoB = <BoxWindow titulo = {'Cópia realizada com sucesso!'} mensagem={'Você copiou a carteira BEP-20 na memória, agora só transferir o valor exato para essa carteira, e assim  finalizar o pagamento'} onClose={()=>{
                                                this.atencaoB = null;
                                                this.setState({});
                                            }}/>
                                            this.setState({});
                                        }}
                                        _hover={{
                                            bgGradient: 'linear(to-r,tema1.100,tema1.100)',
                                            boxShadow: 'xl',
                                            color:'tema1.200'
                                        }} 
                                    >
                                        Copiar PIX
                                    </Button> 
                                    <Button
                                        fontFamily={'heading'} 
                                        w={'full'}
                                        bg="transparent"
                                        color={'red'}
                                        fontSize={'12px'}
                                        _hover={{
                                            bg: 'red',
                                            boxShadow: 'xl',
                                            color:'tema1.200'
                                        }}
                                        onClick={(e)=>{ 
                                            e.preventDefault();    
                                            this.atencaoB = <BoxWindow titulo = {'Cancelamento de Pedido'} mensagem={'Depois de cancelado o sistema não aceitará mais o pagamento do PIX, você deseja cancelar realmente cancelar esse pedido?'}
                                            botao={
                                                <Stack   align='center' justify={'center'} flexDir={'row'} pt='5' spacing={3}>
                                                    <Button fontFamily={'heading'}   w={'full'} bgGradient="linear(to-r, tem1.100,tem1.100)"  color={'white'}  onClick={(e)=>{ 
                                                            e.preventDefault();    
                                                            // console.log('cancelar o pedido')
                                                            const json = {
                                                                id:pg.id
                                                            };   
                                                            // console.log(json)
                                                            G.sendForm({modulo:'usuario',cmd:'cancelarPedido',form:json},(result)=>{ 
                                                                this.pagamento     =   {};
                                                                this.atencaoB    =   null;
                                                                this.setState({}); 
                                                                
                                                            })
                                                            this.atencaoB = <BoxWindow load = {true}/>
                                                            this.setState({});


                                                        }} _hover={{  bgGradient: 'linear(to-r,tema1.100,tema1.100)',  boxShadow: 'xl', color:'tema1.200' }} 
                                                    >
                                                        Confirmar Cancelar
                                                    </Button>  
                                                    <Button fontFamily={'heading'}  w={'full'} bg="transparent" color={'red'} _hover={{  bg: 'red', boxShadow: 'xl', color:'tema1.200'  }}
                                                        onClick={(e)=>{ 
                                                            e.preventDefault();   
                                                            this.atencaoB =null
                                                            this.setState({});
                                                        }}
                                                        cursor={'pointer'}   
                                                        leftIcon={<FaTimes/>}
                                                    >
                                                        Não Cancelar
                                                    </Button> 
                                                </Stack>
                                            }
                                            
                                            onClose={()=>{
                                                this.atencaoB = null;
                                                this.setState({});
                                            }}/>
                                            this.setState({});
                                        }}
                                        cursor={'pointer'}   
                                        leftIcon={<FaTimes/>}  >
                                        Cancelar Pedido
                                    </Button> 
                                </Stack>   
                            </Stack>
                        </Stack>
                    )
                break; 
            }

            return  ( 
                <Modal autoFocus={false} trapFocus={false}  isCentered isOpen={true} size={{base :'full',lg:'md'}} scrollBehavior={'inside'} onClose={this.props.cmdFechar}  >
                   <ModalOverlay bg='rgba(58, 255, 202, 0.5)'  backdropFilter='blur(10px) hue-rotate(90deg)'/> 
                       <ModalContent bg={'tema1.200'} p='0px'  > 
                           <ModalBody color={'#ffffff'} px={{base :'0px',lg:6}}>
                               {viewBOx}
                               {this.atencao}
                               {this.atencaoB}
                           </ModalBody> 
                       </ModalContent>  
               </Modal>  
           );
        }else if(this.loadPedido){
            return (
                <>
                    <BoxWindow load={true} />
                    {this.atencao}
                </>
            )
        }else{
            this.atencaoB = null; 
            if(Object.keys(this.dadosP.plano).length==0){
                viewBOx = (
                    <Box py={0}>
                        <VStack spacing={2} textAlign="center"> 
                            <Text fontSize="lg" color={'green.500'} px={'30px'}>
                                Selecione um plano abaixo
                            </Text>
                            <Stack
                                direction={{ base: 'column', md: 'row' }}
                                textAlign="center"
                                justify="center"
                                spacing={{ base: 4, lg: 4 }} > 
                                <Menu mt='20px'>
                                    <MenuButton as={Button} onChange={()=>{
                                        // console.log('change')
                                    }}  rightIcon={<FaRocket  size='18px' ml/>}>
                                        {this.nB}  USDT 
                                    </MenuButton>
                                    <MenuList>
                                        {this.planos.map((p)=>{  
                                            if(this.codpla == p.id){
                                                sp  =   p; 
                                                this.nB  =   p.valor;
                                            }
                                            if(p.id == 4){
                                                bp =p;
                                            }
                                            
                                            var add =true;
                                            if(this.props.tipo=='reinvestimento'){
                                                add =false 
                                            } 
                                            if(add){
                                                return (
                                                    <MenuItem key={'plano'+p.id} onClick={()=>{
                                                        this.codpla = p.id;
                                                        this.nB  =   p.valor;
                                                        this.setState({});
                                                    }}>{p.nome}</MenuItem>
                                                );
                                            }
                                            
                                        })} 
                                    </MenuList>
                                </Menu> 
                            </Stack> 
                            <Stack
                                direction={{ base: 'column', md: 'row' }}
                                textAlign="center"
                                justify="center"
                                spacing={{ base: 4, lg: 4 }}
                                py={{base:0,md:8}}> 
                                {this.planos.length>0?
                                    <Box
                                        mb={4}
                                        shadow="base"
                                        mt={'18px'}
                                        borderWidth="1px"
                                        alignSelf={{ base: 'center', lg: 'flex-start' }}
                                        borderColor={'tema1.500'}
                                        borderRadius={'xl'}>
                                            <Box pt={4} px={12}>
                                                <Text fontWeight="500" fontSize="lg">
                                                    {sp.nome}
                                                </Text>
                                                <HStack justifyContent="center">
                                                    
                                                    <Text fontSize="2xl" fontWeight="900"> 
                                                        {sp.valor[this.plano]>0?(sp.valor[this.plano]).toFixed(2):''}
                                                    </Text> 
                                                    {/* <Text fontSize="lg" color="gray.500">
                                                        /month{this.plano!='M'?'*':''}
                                                    </Text> */}
    
                                                </HStack>
                                                {/* {this.plano!='M'?<HStack justifyContent="center">
                                                    <Text fontSize="sm" color={'tema1.500'} fontWeight="600">
                                                    TOTAL {sp.valor[this.plano]}€
                                                    </Text> 
                                                </HStack>:''} */}
                                            </Box>
                                            <VStack
                                                bg={'tema1.200'}
                                                py={4}
                                                borderBottomRadius={'xl'}>
                                                    <List spacing={3} textAlign="start" px={12}>
                                                        <ListItem  >
                                                            <ListIcon as={FaCheck} fontSize={'22px'}   color="green.500" />
                                                            <Text as='span' ml={'5px'}>Valor {sp.valor} USDT</Text>
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListIcon as={FaCheck} fontSize={'22px'}   color="green.500" />
                                                            <Text as='span' ml={'5px'}>Taxa depósito {sp.valor*0.05} USDT</Text>
                                                            
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListIcon as={FaCheck} fontSize={'22px'}   color="green.500" />
                                                            <Text as='span' ml={'5px'}>Até 200% com Performance</Text>
                                                            
                                                        </ListItem> 
                                                        <ListItem>
                                                            <ListIcon as={FaCheck} fontSize={'22px'}   color="green.500" />
                                                            <Text as='span' ml={'5px'}>Até 200% com Rede</Text> 
                                                        </ListItem> 
                                                    </List>
                                                    <Box w="80%" pt={7}>
                                                    <Button onClick={()=>{  
                                                        this.dadosP.plano= sp;
                                                        this.setState({});
                                                    }} w="full" colorScheme="green.500" variant="outline">
                                                        Assinar
                                                    </Button>
                                                </Box>
                                            </VStack>
                                    </Box>
                                :null}
                                 {this.props.tipo == 'reinvestimento'?null:
                                <Box
                                mb={4}
                                shadow="base"
                                borderWidth="1px"
                                alignSelf={{ base: 'center', lg: 'flex-start' }}
                                borderColor={'tema1.500'}
                                borderRadius={'xl'}>
                                <Box position="relative">
                                    <Box
                                    position="absolute"
                                    top="-16px"
                                    left="50%"
                                    w={'160px'}
                                    style={{ transform: 'translate(-50%)' }}>
                                    <Text
                                        textTransform="uppercase"
                                        bg={'green.500'}
                                        px={3}
                                        py={1}
                                        color={'tema1.300'}
                                        fontSize="sm"
                                        fontWeight="600"
                                        rounded="xl">
                                        Mais Popular
                                    </Text>
                                    </Box>
                                    <Box pt={4} px={12}>
                                    <Text fontWeight="500" mt={'10px'} fontSize="2xl">
                                        {this.bestPlan.valor} USDT
                                    </Text>
                                    <HStack justifyContent="center">
                                        
                                        <Text fontSize="3xl" fontWeight="900">
                                            {bp.valor[this.plano]>0?(bp.valor[this.plano]).toFixed(2):''}
                                        </Text>  
                                    </HStack> 
                                    
                                    
                                    </Box>
                                    <VStack 
                                    py={4}
                                    borderBottomRadius={'xl'}>
                                        <List spacing={3} textAlign="start" px={12}>
                                            <ListItem  >
                                                <ListIcon as={FaCheck} fontSize={'22px'}   color="green.500" />
                                                <Text as='span' ml={'5px'}>Valor {this.bestPlan.valor} USDT</Text>
                                            </ListItem>
                                            <ListItem>
                                                <ListIcon as={FaCheck} fontSize={'22px'}   color="green.500" />
                                                <Text as='span' ml={'5px'}>Taxa depósito {this.bestPlan.valor*0.05} USDT</Text>
                                                
                                            </ListItem>
                                            <ListItem>
                                                <ListIcon as={FaCheck} fontSize={'22px'}   color="green.500" />
                                                <Text as='span' ml={'5px'}>Até 200% com Performance</Text>
                                                
                                            </ListItem> 
                                            <ListItem>
                                                <ListIcon as={FaCheck} fontSize={'22px'}   color="green.500" />
                                                <Text as='span' ml={'5px'}>Até 200% com Rede</Text> 
                                            </ListItem>  
                                        </List>
                                    <Box w="80%" pt={7}>
                                        <Button onClick={()=>{
                                            this.dadosP.plano= this.bestPlan;
                                            this.setState({});
                                        }} w="full" bg={'green.500'} colorScheme="green">
                                            Assinar Agora!
                                        </Button>
                                    </Box>
                                    </VStack>
                                </Box>
                            </Box>    
                                 }   
                                
                            </Stack>      
                        </VStack>
                    </Box>
                );
            }else if(typeof this.formPAG == 'undefined'){
                var pla= this.dadosP.plano;  
                var viewReinvest = (
                    <Flex direction={'column'} w={'fit-content'} > 
                        <HSeparator my='20px' />
                        <Text
                            color={'tema1.1000'}
                            fontSize='md'
                            fontWeight='bold'
                            mb='20px'>
                            Chave de Segurança
                        </Text>
                        <Stack w='100%' direction={'row'} align={'center'} justify={'center'}> 
                            <PinInput id='keyPast' size={'lg'}  value={this.chave} defaultValue={this.chave}    onChange={(el)=>{
                                this.chave = el; 
                                if(this.chave.length==6){
                                    this.dadosP.chave = this.chave;
                                    this.sendReinvestimento();
                                    this.chave = '';
                                }
                                this.setState({});
                                //console.log('mudou',el);
                            }}  >
                                <PinInputField bg={'tema1.300'} border={0} borderRadius={'10px'} _hover={{border:0}}/>
                                <PinInputField bg={'tema1.300'} border={0} borderRadius={'10px'} _hover={{border:0}}/>
                                <PinInputField bg={'tema1.300'} border={0} borderRadius={'10px'} _hover={{border:0}}/>
                                <Text>-</Text>
                                <PinInputField bg={'tema1.300'} border={0} borderRadius={'10px'} _hover={{border:0}}/>
                                <PinInputField bg={'tema1.300'} border={0} borderRadius={'10px'} _hover={{border:0}}/>
                                <PinInputField bg={'tema1.300'} border={0} borderRadius={'10px'} _hover={{border:0}}/>
                            </PinInput>
                        </Stack> 
                        <Text fontSize='sm' color={'green.500'} fontWeight='bold' mt={'10px'} textAlign={'right'} cursor={'pointer'}> REENVIAR E-MAIL </Text>
                        <Stack w='100%' direction={'row'} align={'center'} justify={'center'} gap='20px' py='10px'>  
                            <Button onClick={async ()=>{ 
                                try{
                                    if(isNaN(parseInt(await navigator.clipboard.readText()).toString())){
                                        this.chave ='';
                                    }else{ 
                                        this.chave = parseInt(await navigator.clipboard.readText()).toString(); 
                                        
                                    }
                                    if(this.chave.length==6){
                                        this.dadosP.chave = this.chave;
                                        this.sendReinvestimento();
                                    } 
                                }catch(e){
                                    this.chave ='';
                                }
                                this.setState({})
                                // Url.target.value = Url.value;
                            }} fontSize={'sm'}  m='10px 0 10px 0' h={'30px'} color={'green.500'} variant={'outline'} colorScheme="green">COLAR</Button>
                            <Button onClick={async ()=>{ 

                                this.chave = '';
                                this.setState({})
                                // Url.target.value = Url.value;
                            }} fontSize={'sm'}  m='10px 0 10px 0' h={'30px'} color={'red.500'} variant={'outline'} colorScheme="red" leftIcon={<FaEraser/>}>LIMPAR</Button>
                        </Stack>
                    </Flex>
                ) 
                viewBOx = (
                    <Box pb={'50px'}>
                         <Flex direction={{ base: 'row', md: 'row' }} spacing={2} textAlign="center"> 
                            <Stack  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                <Text as={'b'} fontSize="lg" color={'tema1.400'} px={'30px'}>
                                    Resumo do Pedido
                                </Text>
                                <Stack direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                    <Text as={'b'} fontSize="xl" color={'tema1.400'} px={'30px'}>
                                        Total a Pagar
                                    </Text> 
                                    <Text as={'b'}  fontSize="xl" color={'green.500'} px={'30px'}>
                                        {pla.valor*1.05} USDT
                                    </Text>
                                </Stack>
                                <Box textTransform={'uppercase'} p={4} w={'300px'} m='0 auto' shadow="base" borderWidth="1px" alignSelf={{ base: 'center', lg: 'flex-start' }}  borderColor={'tema1.500'} borderRadius={'xl'}> 
                                    <Stack  direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                        <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                            Valor Plano
                                        </Text>
                                        <Spacer/>
                                        <Text as={'b'} fontSize="small" color={'green.500'} px={'0px'}>
                                            {pla.valor} USDT
                                        </Text>
                                    </Stack>
                                    <Stack  direction={{ base: 'row', md: 'row' }}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                        <Text fontSize="small" color={'tema1.400'} px={'0px'}>
                                            Taxa de Depósito
                                            
                                        </Text>
                                        <Spacer/>
                                        <Text as={'b'} fontSize="small" color={'green.500'} px={'0px'}> 
                                        {pla.valor*0.05} USDT
                                        </Text>
                                    </Stack>
                                    <Stack mt={'10px'} direction={{ base: 'row'}}  w={'100%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                        <Text as={'b'} fontSize="small" color={'tema1.100'} px={'0px'}>
                                            Performance
                                        </Text>
                                        <Spacer/> 
                                        <Text  as={'b'} fontSize="small" color={'tema1.400'}  >
                                            {pla.info}
                                        </Text>
                                    </Stack> 
                                </Box> 
                                <Stack direction={'row'} align={'center'} justify={'center'} w="full"  > 
                                    {(this.props.tipo == 'reinvestimento')?
                                        (this.sendKey)?
                                            viewReinvest:
                                            <> 
                                                <Button variant='outline' onClick={()=>{
                                                    this.dadosP.plano = {};
                                                    this.setState({});
                                                }} leftIcon={<FaArrowLeft></FaArrowLeft>} w="150px"  colorScheme="green">
                                                    Alterar Plano
                                                </Button>
                                                <Button onClick={()=>{
                                                    this.sendReinvestimento(); 
                                                    this.setState({});  
                                                }} leftIcon={<MdPayment/>} w="150px" bg={'green.500'} colorScheme="green">
                                                    Debitar Saldo
                                                </Button>
                                            </>
                                        :
                                        (this.sendKey)?
                                            viewReinvest:
                                            <Button onClick={()=>{
                                                this.dadosP.plano = {};
                                                this.setState({});
                                            }} leftIcon={<FaArrowLeft></FaArrowLeft>} w="150px" bg={'green.500'} colorScheme="green">
                                                Alterar Plano
                                            </Button>
                                    }
                                </Stack>
                               
                            </Stack>
                            {(this.props.tipo == 'reinvestimento')?null:
                                <Stack  w={'50%'} textAlign="center" justify="center" spacing={{ base: 4, lg: 4 }} > 
                                    <Stack
                                        direction={{ base: 'column', md: 'column' }}
                                        textAlign="center"
                                        justify="center"
                                        spacing={{ base: 4, lg: 4 }}
                                        py={{base:0,md:8}}>   
                                        <Text as={'b'} fontSize="lg" color={'tema1.400'} px={'30px'}>
                                            Forma de Pagamento
                                        </Text>
                                        <Stack 
                                            margin='0 auto'
                                            shadow="base"
                                            borderWidth="1px"
                                            alignSelf={{ base: 'center', lg: 'flex-start' }}
                                            borderColor={'tema1.500'}
                                            borderRadius={'xl'}> 
                                            <VStack 
                                                p={6}
                                                borderBottomRadius={'xl'}>
                                                
                                                <Box w="100%" >
                                                    <Button onClick={()=>{   
                                                        G.socket.removeAllListeners('sendPedido').on('sendPedido',(result)=>{ 
                                                            G.socket.removeAllListeners('sendPacote');   
                                                            
                                                            this.atencao = <BoxWindow titulo = {result.titulo} mensagem={result.mensagem} onClose={()=>{
                                                                this.atencao = null; 
                                                                this.loadPedido = true;
                                                                this.setState({}); 
                                                            }}/> 
                                                            this.setState({});  
                                                        }); 
                                                        G.socket.emit("sendPedido", {
                                                            id: pla.id,
                                                            forma:'deposito',
                                                            idioma:G.idioma.id
                                                        });  
                                                        this.atencao = <BoxWindow   load={true}/>;
                                                        this.setState({});  
                                                    }} maxW={'260px'} leftIcon={<SiTether/>}   bg={'green.500'} colorScheme="green">
                                                        USDT BEP-20
                                                    </Button> 
                                                </Box>
                                                <Text textTransform="uppercase"   py={1} color={'tema1.400'} fontSize="sm" fontWeight="600" ounded="xl">
                                                    OU
                                                </Text>
                                                <Box w="100%" >
                                                    <Button onClick={()=>{ 
                                                        this.sendReinvestimento(); 
                                                        this.setState({});  
                                                    }} leftIcon={<GiReceiveMoney/>} w="full" bg={'green.500'} colorScheme="green">
                                                        Com Saldo
                                                    </Button>
                                                </Box>
                                                <Text textTransform="uppercase"   py={1} color={'tema1.400'} fontSize="sm" fontWeight="600" ounded="xl">
                                                    OU
                                                </Text>
                                                <Box w="100%" >
                                                    <Button onClick={()=>{ 
                                                        // G.socket.removeAllListeners('sendPedido').on('sendPedido',(result)=>{ 
                                                        //     G.socket.removeAllListeners('sendPacote');   
                                                            
                                                        //     this.atencao = <BoxWindow titulo = {result.titulo} mensagem={result.mensagem} onClose={()=>{
                                                        //         this.atencao = null; 
                                                        //         this.loadPedido = true;
                                                        //         this.setState({}); 
                                                        //     }}/> 
                                                        //     this.setState({});  
                                                        // }); 
                                                        // G.socket.emit("sendPedido", {
                                                        //     id: pla.id,
                                                        //     forma:'deposito',
                                                        //     idioma:G.idioma.id
                                                        // });  
                                                        // this.atencao = <BoxWindow   load={true}/>;
                                                        // this.setState({});
                                                    }} leftIcon={<RiMastercardFill/>} w="full" bg={'green.500'} colorScheme="green">
                                                        Com InoPay
                                                    </Button>
                                                </Box>
                                            </VStack> 
                                        </Stack>   
                                    </Stack> 
                                </Stack>    
                            }
                              
                        </Flex>
                    </Box>
                );
            }
            return  ( 
                 <Modal autoFocus={false} trapFocus={false}  isCentered isOpen={true} size={{base :'full',lg:(this.props.tipo == 'reinvestimento')?'lg':'4xl'}} scrollBehavior={'inside'} onClose={this.props.cmdFechar}  >
                    <ModalOverlay bg='rgba(58, 255, 202, 0.5)'  backdropFilter='blur(10px) hue-rotate(90deg)'/> 
                        <ModalContent bg={'tema1.200'} p='0px'  >
                            <ModalHeader color={'tema1.400'} >
                                <Heading as="h1" fontSize="4xl"> {(this.props.tipo=='reinvestimento'?'Planos Reinvestimento':'Planos Inovatyon')}</Heading></ModalHeader>
                            <ModalCloseButton  color={'green.500'} mt='15px' mr='15px'  /> 
                            <ModalBody color={'#ffffff'} px={{base :'0px',lg:6}}>
                                {viewBOx}
                                {this.atencao}
                                {this.atencaoB}
                            </ModalBody> 
                        </ModalContent>  
                </Modal>  
            );
        }
        
        
    } 
}
export default BoxPlanos;
    