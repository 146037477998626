import * as React from "react"  
import { Avatar, Box, Button,  Flex, Grid, Icon, SimpleGrid, Spacer, Stack, Stat, StatHelpText, StatLabel, StatNumber, Text } from "@chakra-ui/react"; 
import {Helmet} from "react-helmet"; 
import G from '../../components/WebSocket';
import { FaChartBar, FaChartLine, FaChartPie, FaCheck, FaCoins, FaDollarSign, FaExchangeAlt, FaFilter, FaPencilAlt, FaPlus, FaProjectDiagram, FaRegCalendarAlt, FaRobot, FaRocket, FaStackExchange, FaUsers } from "react-icons/fa";
import IconBox from "../../components/VisionUI/Icons/IconBox";
import Card from "../../components/VisionUI/Card/Card";
import CardBody from "../../components/VisionUI/Card/CardBody";
import CardHeader from "../../components/VisionUI/Card/CardHeader"; 
import { TbNumber1, TbNumber2 , TbNumber3 , TbNumber4 , TbNumber5 , TbNumber6 } from "react-icons/tb";

import ListaPacotes from "./ListaPacotes";
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import { GiPayMoney } from "react-icons/gi";
import { Link } from "react-router-dom";
class PageRede extends React.Component { 
    jogos = 0;
    robos = 0;
    rede = [];
    load  = false;
    db = {
        saldos:{}
    }
    buscarJogos(){
        var reload = false;
          
        if(typeof G.geral!=='undefined' && G.geral!= null && typeof G.geral.saldos!=='undefined' && this.tempSaldos != JSON.stringify(G.geral.saldos)){
            this.tempSaldos = JSON.stringify(G.geral.saldos); 
            this.db.saldos = G.geral.saldos; 
            this.load = true; 
            reload = true;
        } 

        // if(typeof G.geral!=='undefined' && G.geral!= null &&  typeof G.geral.rede!=='undefined' && this.tempStat != JSON.stringify(G.geral.rede)){
        //     this.tempStat = JSON.stringify(G.geral.rede);  
        //     reload = true;
        //     this.load = true; 
        // }  
        if(reload){
            this.setState({}); 
        }
    } 
    componentDidMount(){
        this.buscarJogos();
        this.tempInter = setInterval(()=>{
            this.buscarJogos();
        },200)
    }
    componentWillUnmount(){
        clearInterval(this.tempInter);
    } 
    render(){  
        var usu = G.painel; 
        var bg = 'transparent linear-gradient(0deg, rgba(46, 52, 68,0) 20%, rgba(46, 52, 68,1) 100%) 0% 0% no-repeat padding-box;'
        
        var nivel = [
            {nome:'Nível 1',id:0,icon: <TbNumber1  />},
            {nome:'Nível 2',id:1,icon: <TbNumber2  />},
            {nome:'Nível 3',id:1,icon: <TbNumber3  />},
            {nome:'Nível 4',id:3,icon: <TbNumber4  />},
            {nome:'Nível 5',id:4,icon: <TbNumber5  />},
            {nome:'Nível 6',id:5,icon: <TbNumber6  />},
        ]

        if(this.load){
            // console.log(this.db.saldos)
            return (
                <>
                    <Helmet> 
                        <title>Inovatyon - Pacotes</title>
                        <meta name="title" content="Inovatyon" />
                        <meta name="description" content="" />
    
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content="https://inovatyon.com" />
                        <meta property="og:title" content="Inovatyon Tach labs" />
                        <meta property="og:description" content="" />
                        <meta property="og:image" content="https://inovatyon.com/assets/img/logobk.png" />
    
                        <meta property="twitter:card" content="summary_large_image" />
                        <meta property="twitter:url" content="https://inovatyon.com" />
                        <meta property="twitter:title" content="Inovatyon Tach labs" />
                        <meta property="twitter:description" content="" />
                        <meta property="twitter:image" content="https://inovatyon.com/assets/img/logobk.png" />
                    </Helmet>  
                    <SimpleGrid columns={{ base: "2" , sm: "4"}} spacing={{base:'10px',sm:'20px'}}   p={{base:'10px 10px',sm:'10px 20px'}} pt={{base:'10px',sm:'30px'}}>
                        <Card minH={{base:'60px',md:'83px'}} p={{base:'12px',md:'15px',md:'20px'}}>
                            <CardBody >
                                <Flex flexDirection='row' align='center' justify='center' textAlign={'left'}  w='100%'>
                                    <Stat me='auto' >
                                        <StatLabel fontSize={{base:'xs',md:'xs'}} color='gray.200' pb='2px'>
                                            Ganho Total
                                        </StatLabel>
                                        <Flex>
                                            <Stack gap={0}>
                                                <StatNumber fontSize={{base:'sm',lg:'lg'}} color='#fff'>
                                                {G.parseValor(this.db.saldos.indicacao+this.db.saldos.indicacaoI+this.db.saldos.rendimento+this.db.saldos.residual,4).p},<Text as='span' fontSize={'10px'}>{G.parseValor(this.db.saldos.indicacao+this.db.saldos.indicacaoI+this.db.saldos.rendimento+this.db.saldos.residual,4).d}</Text>
                                                </StatNumber> 
                                                <StatHelpText
                                                    textAlign={'left'}
                                                    alignSelf='center'
                                                    justifySelf='flex-start'
                                                    m='0px'
                                                    w='100%'
                                                    color='green.500'
                                                    fontWeight='bold' 
                                                    fontSize={{base:'xs',lg:'xs'}} >
                                                    USDT
                                                </StatHelpText> 
                                            </Stack>
                                            
                                        </Flex> 
                                    </Stat>
                                    <IconBox as='box' h={{base:'30px',lg:'45px'}} w={{base:'30px',lg:'45px'}} bg='green.500' >
                                        <Icon as={FaCoins} color={'tema1.300'} fontSize={{base:'16px',lg:'22px'}}></Icon>
                                    </IconBox>
                                </Flex>
                            </CardBody> 
                        </Card>
                        <Card minH={{base:'60px',md:'83px'}} p={{base:'12px',md:'15px',md:'20px'}}>
                            <CardBody >
                                <Flex flexDirection='row' align='center' justify='center' textAlign={'left'}  w='100%'>
                                    <Stat me='auto' >
                                        <StatLabel fontSize={{base:'xs',md:'xs'}} color='gray.200' pb='2px'>
                                            Performance Total
                                        </StatLabel>
                                        <Flex>
                                            <Stack gap={0}>
                                                <StatNumber fontSize={{base:'sm',lg:'lg'}} color='#fff'>
                                                    {G.parseValor(this.db.saldos.rendimento,4).p},<Text as='span' fontSize={'10px'}>{G.parseValor(this.db.saldos.rendimento,4).d}</Text>
                                                </StatNumber> 
                                                <StatHelpText
                                                    textAlign={'left'}
                                                    alignSelf='center'
                                                    justifySelf='flex-start'
                                                    m='0px'
                                                    w='100%'
                                                    color='green.500'
                                                    fontWeight='bold' 
                                                    fontSize={{base:'xs',lg:'xs'}} >
                                                    USDT
                                                </StatHelpText> 
                                            </Stack>
                                            
                                        </Flex> 
                                    </Stat>
                                    <IconBox as='box' h={{base:'30px',lg:'45px'}} w={{base:'30px',lg:'45px'}} bg='green.500' >
                                        <Icon as={FaRocket} color={'tema1.300'} fontSize={{base:'16px',lg:'22px'}}></Icon>
                                    </IconBox>
                                </Flex>
                            </CardBody> 
                        </Card>
                        <Card minH={{base:'60px',md:'83px'}} p={{base:'12px',md:'15px',md:'20px'}}>
                            <CardBody >
                                <Flex flexDirection='row' align='center' justify='center' textAlign={'left'}  w='100%'>
                                    <Stat me='auto' >
                                        <StatLabel fontSize={{base:'xs',md:'xs'}} color='gray.200' pb='2px'>
                                            Indicação Total
                                        </StatLabel>
                                        <Flex>
                                            <Stack gap={0}>
                                                <StatNumber fontSize={{base:'sm',lg:'lg'}} color='#fff'>
                                                    {G.parseValor(this.db.saldos.indicacao+this.db.saldos.indicacaoT,4).p},<Text as='span' fontSize={'10px'}>{G.parseValor(this.db.saldos.indicacao+this.db.saldos.indicacaoT,4).d}</Text>
                                                </StatNumber> 
                                                <StatHelpText
                                                    textAlign={'left'}
                                                    alignSelf='center'
                                                    justifySelf='flex-start'
                                                    m='0px'
                                                    w='100%'
                                                    color='green.500'
                                                    fontWeight='bold' 
                                                    fontSize={{base:'xs',lg:'xs'}} >
                                                    USDT
                                                </StatHelpText> 
                                            </Stack>
                                            
                                        </Flex> 
                                    </Stat>
                                    <IconBox as='box' h={{base:'30px',lg:'45px'}} w={{base:'30px',lg:'45px'}} bg='green.500' >
                                        <Icon as={FaUsers} color={'tema1.300'} fontSize={{base:'16px',lg:'22px'}}></Icon>
                                    </IconBox>
                                </Flex>
                            </CardBody> 
                        </Card>
                        <Card minH={{base:'60px',md:'83px'}} p={{base:'12px',md:'15px',md:'20px'}}>
                            <CardBody >
                                <Flex flexDirection='row' align='center' justify='center' textAlign={'left'}  w='100%'>
                                    <Stat me='auto' >
                                        <StatLabel fontSize={{base:'xs',md:'xs'}} color='gray.200' pb='2px'>
                                            Binário
                                        </StatLabel>
                                        <Flex>
                                            <Stack gap={0}>
                                                <StatNumber fontSize={{base:'sm',lg:'lg'}} color='#fff'>
                                                    {G.parseValor(this.db.saldos.binario,4).p},<Text as='span' fontSize={'10px'}>{G.parseValor(this.db.saldos.binario,4).d}</Text>
                                                </StatNumber> 
                                                <StatHelpText
                                                    textAlign={'left'}
                                                    alignSelf='center'
                                                    justifySelf='flex-start'
                                                    m='0px'
                                                    w='100%'
                                                    color='green.500'
                                                    fontWeight='bold' 
                                                    fontSize={{base:'xs',lg:'xs'}} >
                                                    USDT
                                                </StatHelpText> 
                                            </Stack>
                                            
                                        </Flex> 
                                    </Stat>
                                    <IconBox as='box' h={{base:'30px',lg:'45px'}} w={{base:'30px',lg:'45px'}} bg='green.500' >
                                        <Icon as={FaProjectDiagram} color={'tema1.300'} fontSize={{base:'16px',lg:'22px'}}></Icon>
                                    </IconBox>
                                </Flex>
                            </CardBody> 
                        </Card> 
                    </SimpleGrid>
                    
                    <Flex direction={{base:'column'}}  w={{base:'100%'}} spacing={0} pt={{base:'0px',sm:'0px'}} px={{base:'10px',sm:'20px'}}>  
                        <Stack direction='column'  w={{base:'100%',lg:'100%'}} spacing={{base:'0px',lg:'20px'}} p={{base:'0px 0px',sm:'10px 0px'}}  > 
                             <Card p='0 16px' mt={{base:'0px',lg:'0'}} >
                                <CardHeader>
                                    <Flex
                                        justify='center'
                                        align='center'
                                        minHeight='60px'
                                        w='100%'>
                                        <Text fontSize='lg' color='#fff' fontWeight='bold'>
                                        Pacotes Ativos
                                        </Text>
                                        
                                    </Flex>
                                </CardHeader>
                                <CardBody  >
                                    <ListaPacotes    />
                                </CardBody>
                            </Card> 
                        </Stack>
                        
                    </Flex> 
                </>
            )
        }else{
            return <BoxWindow load ={true} />
        }
        
    }
}
export default PageRede;