import * as React from "react"  
import { Box, Button, Center, Flex, FormControl, FormLabel, Grid, Heading, Icon, Image, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Spacer, Stack, Text, Textarea } from "@chakra-ui/react";
 
import  InputMask  from 'react-input-mask';   
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import G            from '../../components/ADMSocket';
import QRCode from "react-qr-code";
import Card from "../../components/VisionUI/Card/Card";
import CardHeader from "../../components/VisionUI/Card/CardHeader";
import { FaChartLine, FaCopy, FaInfo, FaRocket, FaUser } from "react-icons/fa";
import GradientBorder from "../../components/VisionUI/GradientBorder";
import InputAutocomplete from "../../components/Padrao/InputAutocomplete";

class BoxNovoVoucher extends React.Component { 
    open = null;
    show1 = false;
    show2 = false;
    show3 = false;
    atencao =   null; 
    render(){ 
        var lan =this.props.lan; 
        var usu =this.props.usu; 
        var pla =this.props.pla; 
        var show = false;  
        return (
            <>
                <Modal isCentered isOpen={true}  size={{base:'2xl' }} onClose={this.props.cmdFechar}> 
                    <ModalOverlay
                        bg='rgba(0,200,0,0.5)' 
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                    />
                    <ModalContent bg={'tema1.200'}>
                        <ModalHeader color={'green.500'} w='auto'>Novo Voucher</ModalHeader> 
                        <ModalCloseButton  color={'tema1.100'}/> 
                        <Stack   rounded={'xl'}  p={{ base: '20px' }}  pt ='0' spacing={{ base: 0}}  align={'center'} justify={'center'}>
                            <Flex  w={{base:'100%'}}  direction={{ base: 'column', md: 'column' }}  textAlign="center"> 
                                <Box   as={'form'}  onSubmit={(e)=>{
                                    e.preventDefault();   
                                    const formdata = new FormData(e.target);
                                    const json = {};  

                                    formdata.forEach(function(value, prop){ 
                                        json[prop] = value;
                                    });   
                                    G.socket.removeAllListeners('formDados').on('formDados',(response)=>{     
                                        if(response.cmd === "cadastroVoucher"){    
                                            this.atencao = <BoxWindow titulo = {response.result.titulo} mensagem={response.result.mensagem} onClose={()=>{
                                                if(!response.result.erro){ 
                                                    this.props.cmdFechar(); 
                                                }
                                                this.atencao = null;
                                                this.setState({});
                                            }}/> 
                                            this.setState({});  
                                        } 
                                    }); 
                                    G.socket.emit("formDados", {
                                        modulo  :   'financeiro',
                                        cmd     :   'cadastroVoucher',
                                        form    :   json
                                    });   
                                
                                    this.atencao = <BoxWindow load = {true}/>
                                    this.setState({});
                                }}   bg='transparent' w={{base:'100%'}}  borderRadius={'lg'}> 
                                    <InputAutocomplete label={'Afiliado'} name='codusu'  placeholder='Selecione um afiliado...'  leftIcon={FaUser} lista={Object.values(usu).map((u)=>{
                                        return {valor:u.id,nome:u.usuario+' - '+u.nome}
                                    })}/> 
                                    <Stack direction={{base:'column',md:'row'}} gap={{base:'0px',md:'30px'}}>
                                        <InputAutocomplete label={'Pacote'} name='id' placeholder='Selecione um plano...'   leftIcon={FaRocket} lista={Object.values(pla).map((u)=>{
                                            return {valor:u.id,nome:u.nome}
                                        })}/> 
                                        <InputAutocomplete  label={'Rendimento'} name='rendimento' placeholder='Selecione um rendimento...'  leftIcon={FaChartLine} lista={
                                            [{valor:0,nome: 'Sem rendimento'},{valor:30,nome:'30% rendimento'},{valor:100,nome:'100% rendimento'}]
                                        }/> 
                                    </Stack> 
                                    <Button
                                        type="submit"
                                        fontFamily={'heading'} 
                                        mt={'20px'}
                                        w={'full'}
                                        bgGradient="linear(to-r, tem1.100,tem1.100)"
                                        color={'white'}
                                         >
                                        Adicionar Voucher
                                    </Button> 
                                </Box> 
                            </Flex> 
                            
                            
                         
                        
                        </Stack>
                        {this.atencao}
                        {this.atencaoB}
                    </ModalContent> 
                </Modal>
            </>
        )
    }
}
export default BoxNovoVoucher;