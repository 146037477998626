import * as React from "react"
import {  
    Box, 
    Text,  
    Flex, 
    Image, 
    Button,
    Menu,
    MenuButton, 
    Stack, 
    Icon,
    Modal,
    ModalOverlay, 
} from "@chakra-ui/react"     
import { FaBars,   FaChartLine, FaCoins,   FaHome,   FaMoneyBill,   FaProjectDiagram,   FaRocket,   FaSignOutAlt,   FaTable,  FaTimes, FaUsers } from "react-icons/fa";
 
import { NavLink } from "react-router-dom";
import G from  '../../components/ADMSocket';       
import "animate.css"; 
import BoxWindow from "../../components/BoxWindow/BoxWindow";
class MenuSite extends React.Component <any,any> {   
    tempUsu = null;  
    buscarUsuario(){
        if(G.painel!= null){ 
            if(this.tempUsu !=JSON.stringify(G.painel)){
                this.tempUsu =JSON.stringify(G.painel); 
                this.setState({})
            }
        }  
    } 
    interD  = null;
    componentDidMount(){
        this.buscarUsuario();
        this.interD = setInterval(()=>{
            this.buscarUsuario();
        },200);
    }
    componentWillUnmount(){
        clearInterval(this.interD);
    }
    boxlogin = null;
    boxFiltro = null;
    viewMenu = false;
    atencao = null;
    render(){ 
        const usu = G.painel; 
        var pag = '';  
        switch (this.props.page.replace('/skyadm','').replace(/[/]/g,'')) { 
            case 'depositos':
                pag='depositos'
            break; 
            case 'kyc':
                pag='kyc'
            break;   
            case 'saques':
                pag='saques'
            break; 
            case 'voucher':
                pag='voucher'
            break;  
            case 'inopay':
                pag='inopay'
            break;
            case 'binario':
                pag='binario'
            break;
            case 'afiliados':
                pag='afiliados'
            break;
            default:
                pag='dashboard'
            break;
        }
        return (
            <>
                <Modal isCentered isOpen={this.viewMenu}   onClose={()=>{
                    this.viewMenu = false;
                    this.setState({});
                }}  >
                        <ModalOverlay
                            bg='rgba(0,0,0,0.5)' 
                            backdropFilter='blur(10px) hue-rotate(90deg)'
                            zIndex={99}
                            onClick={()=>{
                                this.viewMenu = false;
                                this.setState({});
                            }} 
                        />  
                        
                </Modal> 
                <Flex  position={'fixed'} top={'0px'} left={{base:'0px'}} right={'0px'} flexDir={'column'} align={'center'} justify={'center'}   w='60px' h={{base:'60px',lg:'0px'}}  zIndex={'100'} >  
                    <Box  >
                        <Icon as={FaBars} onClick={()=>{
                            this.viewMenu = true;
                            this.setState({})
                        }} h={'60px'} w={'60px'} p={'18px'} color={'green.500'} cursor={'pointer'} transition={'0.5s'} _hover={{background:'green.500',color:'tema1.100'}}></Icon>
                    </Box> 
                    
                    <Flex position={'fixed'} transition={'2s'} display={{base:(this.viewMenu ? 'flex':'none' ),md:'flex'}} top={'0px'} left={{base:'auto', sm:(this.viewMenu ? '0px':'-200px' ),md:'0px'}}  right={{base:(this.viewMenu ? '0px':'-200px' ),sm:'auto'}} flexDir={'column'} align={'center'} justify={'center'}   w={{base:'250px',sm:'210px'}} h={{base:'100vh'}} bg={'tema1.200'} zIndex={'100'} >  

                        <Flex  w={{base:'100%'}}  flexDir={'column'}    py='10px'   align='center' justify='center' >
                            <Image mt={'10px'} w={{base:'160px',sm:'190px',lg:'244px'}}   objectFit='fill' src='/assets/img/logo_b.png' alt='Logo inovatyon' /> 
                            <Stack flexDir={'row'} h={'0px'} mt={'20px'}  >
                                {/* <Image w={{base:'25px'}} h='20px'  objectFit='fill' src='/assets/img/flags/en.jpg' alt='Logo inovatyon' /> 
                                <Image w={{base:'25px'}} h='20px'  objectFit='fill' src='/assets/img/flags/pt-BR.jpg' alt='Logo inovatyon' />
                                <Image w={{base:'25px'}} h='20px'  objectFit='fill' src='/assets/img/flags/es.jpg' alt='Logo inovatyon' /> */}
                            </Stack> 
                            <Button onClick={()=>{
                                G.openPlano();
                            }} colorScheme={'green'} leftIcon={<FaChartLine />} bg={'green.500'} lineHeight={'30px'} color={'tema1.200'} _hover={{color:'tema1.300'}}>Assinar Pacote</Button> 
                        </Flex> 
                        <Flex flexDir={'column'} w={{base:'100%'}}    h='100%'    align='center' justify='top' >
                            <Stack    align='top' justify='center'   fontSize={'sm'}  color={'tema1.500'}  >
                                <Menu>
                                    <NavLink to={'/skyadm/'} onClick={()=>{
                                        this.viewMenu = false;
                                        this.setState({});
                                    }}  >
                                        <MenuButton my='5px' color={(pag=='dashboard')?'green.500':'tema1.100'}   textTransform={'uppercase'}  w={'210px'} h={'30px'} fontSize={'small'} fontWeight={'bold'} borderRight={'4px solid'} transition={'0.5s'} borderColor={(pag=='dashboard')?'green.500':'transparent'}  _hover={{color:'green.500'}}   >
                                        <Stack pl='20px' justify={'left'} align={'center'} fontSize={'18px'} flexDir={'row'}>
                                            <Stack bg={(pag=='dashboard')?'tema1.300':'tema1.200'} color={(pag=='dashboard')?'green.500':'tema1.100'}  justify={'left'}  align={'center'} p={'8px'} borderRadius={'8px'}>
                                                <Icon as={FaChartLine} fontSize={'14px'}   />
                                            </Stack> 
                                            <Text fontSize={'14px'} textShadow={'xl'}>DASHBOARD</Text>
                                        </Stack>
                                            
                                        </MenuButton>
                                    </NavLink> 
                                    <NavLink to={'/skyadm/afiliados/'} onClick={()=>{
                                        this.viewMenu = false;
                                        this.setState({});
                                    }}  >
                                        <MenuButton my='5px' color={(pag=='afiliados')?'green.500':'tema1.100'}   textTransform={'uppercase'}  w={'210px'} h={'30px'} fontSize={'small'} fontWeight={'bold'} borderRight={'4px solid'} transition={'0.5s'} borderColor={(pag=='afiliados')?'green.500':'transparent'}  _hover={{color:'green.500'}}   >
                                            <Stack pl='20px' justify={'left'} align={'center'} fontSize={'18px'} flexDir={'row'}>
                                                <Stack bg={(pag=='afiliados')?'tema1.300':'tema1.200'} color={(pag=='afiliados')?'green.500':'tema1.100'}  justify={'left'}  align={'center'} p={'8px'} borderRadius={'8px'}>
                                                    <Icon as={FaCoins} fontSize={'14px'}   />
                                                </Stack> 
                                                <Text fontSize={'14px'} textShadow={'xl'}>AFILIADOS</Text>
                                            </Stack> 
                                        </MenuButton>
                                    </NavLink> 
                                    <NavLink to={'/skyadm/depositos/'} onClick={()=>{
                                        this.viewMenu = false;
                                        this.setState({});
                                    }}  >
                                        <MenuButton my='5px' color={(pag=='depositos')?'green.500':'tema1.100'}   textTransform={'uppercase'}  w={'210px'} h={'30px'} fontSize={'small'} fontWeight={'bold'} borderRight={'4px solid'} transition={'0.5s'} borderColor={(pag=='depositos')?'green.500':'transparent'}  _hover={{color:'green.500'}}   >
                                            <Stack pl='20px' justify={'left'} align={'center'} fontSize={'18px'} flexDir={'row'}>
                                                <Stack bg={(pag=='depositos')?'tema1.300':'tema1.200'} color={(pag=='depositos')?'green.500':'tema1.100'}  justify={'left'}  align={'center'} p={'8px'} borderRadius={'8px'}>
                                                    <Icon as={FaCoins} fontSize={'14px'}   />
                                                </Stack> 
                                                <Text fontSize={'14px'} textShadow={'xl'}>DEPÓSITOS</Text>
                                            </Stack> 
                                        </MenuButton>
                                    </NavLink> 
                                    <NavLink to={'/skyadm/saques/'} onClick={()=>{
                                        this.viewMenu = false;
                                        this.setState({});
                                    }}  >
                                        <MenuButton my='5px' color={(pag=='saques')?'green.500':'tema1.100'}   textTransform={'uppercase'}  w={'210px'} h={'30px'} fontSize={'small'} fontWeight={'bold'} borderRight={'4px solid'} transition={'0.5s'} borderColor={(pag=='saques')?'green.500':'transparent'}  _hover={{color:'green.500'}}   >
                                            <Stack pl='20px' justify={'left'} align={'center'} fontSize={'18px'} flexDir={'row'}>
                                                <Stack bg={(pag=='saques')?'tema1.300':'tema1.200'} color={(pag=='saques')?'green.500':'tema1.100'}  justify={'left'}  align={'center'} p={'8px'} borderRadius={'8px'}>
                                                    <Icon as={FaMoneyBill} fontSize={'14px'}   />
                                                </Stack> 
                                                <Text fontSize={'14px'} textShadow={'xl'}>SAQUES</Text>
                                            </Stack> 
                                        </MenuButton>
                                    </NavLink> 
                                    <NavLink to={'/skyadm/voucher/'} onClick={()=>{
                                        this.viewMenu = false;
                                        this.setState({});
                                    }}  >
                                        <MenuButton my='5px' color={(pag=='voucher')?'green.500':'tema1.100'}   textTransform={'uppercase'}  w={'210px'} h={'30px'} fontSize={'small'} fontWeight={'bold'} borderRight={'4px solid'} transition={'0.5s'} borderColor={(pag=='voucher')?'green.500':'transparent'}  _hover={{color:'green.500'}}   >
                                            <Stack pl='20px' justify={'left'} align={'center'} fontSize={'18px'} flexDir={'row'}>
                                                <Stack bg={(pag=='voucher')?'tema1.300':'tema1.200'} color={(pag=='voucher')?'green.500':'tema1.100'}  justify={'left'}  align={'center'} p={'8px'} borderRadius={'8px'}>
                                                    <Icon as={FaCoins} fontSize={'14px'}   />
                                                </Stack> 
                                                <Text fontSize={'14px'} textShadow={'xl'}>VOUCHER</Text>
                                            </Stack> 
                                        </MenuButton>
                                    </NavLink> 
                                    <NavLink to={'/skyadm/kyc/'} onClick={()=>{
                                        this.viewMenu = false;
                                        this.setState({});
                                    }}  >
                                        <MenuButton my='5px' color={(pag=='kyc')?'green.500':'tema1.100'}   textTransform={'uppercase'}  w={'210px'} h={'30px'} fontSize={'small'} fontWeight={'bold'} borderRight={'4px solid'} transition={'0.5s'} borderColor={(pag=='kyc')?'green.500':'transparent'}  _hover={{color:'green.500'}}   >
                                            <Stack pl='20px' justify={'left'} align={'center'} fontSize={'18px'} flexDir={'row'}>
                                                <Stack bg={(pag=='kyc')?'tema1.300':'tema1.200'} color={(pag=='kyc')?'green.500':'tema1.100'}  justify={'left'}  align={'center'} p={'8px'} borderRadius={'8px'}>
                                                    <Icon as={FaCoins} fontSize={'14px'}   />
                                                </Stack> 
                                                <Text fontSize={'14px'} textShadow={'xl'}>KYC</Text>
                                            </Stack> 
                                        </MenuButton>
                                    </NavLink> 
                                    <MenuButton my='5px' color={(pag=='logoff')?'tema1.300':'tema1.100'}   textTransform={'uppercase'}  w={'210px'} h={'30px'} fontSize={'small'} fontWeight={'bold'} borderRight={'4px solid'} transition={'0.5s'} borderColor={(pag=='logoff')?'green.500':'transparent'}  _hover={{color:'green.500'}} onClick={(e)=>{
                                        e.preventDefault();    
                                        this.atencao = <BoxWindow titulo = {'Sair do Sistema'} mensagem={'Deseja realmente sair do sistema?'}
                                        botao={
                                            <Stack   align='center' justify={'center'} flexDir={'row'} pt='5' spacing={'0'}>
                                                <Button fontFamily={'heading'}   w={'full'} bgGradient="linear(to-r, tem1.100,tem1.100)"  color={'white'}  onClick={(e)=>{ 
                                                        localStorage.setItem('skt'+G.sessao,''); 
                                                        G.painel ={};
                                                        this.setState({}); 
                                                    }} _hover={{  bgGradient: 'linear(to-r,tema1.100,tema1.100)',  boxShadow: 'xl', color:'tema1.200' }} 
                                                >
                                                    Fazer Logoff
                                                </Button>  
                                                <Button fontFamily={'heading'}  w={'full'} bg="transparent" color={'red'} _hover={{  bg: 'red', boxShadow: 'xl', color:'tema1.200'  }}
                                                    onClick={(e)=>{ 
                                                        e.preventDefault();   
                                                        this.atencao =null
                                                        this.setState({});
                                                    }}
                                                    cursor={'pointer'}   
                                                    leftIcon={<FaTimes/>}
                                                >
                                                    Cancelar
                                                </Button> 
                                            </Stack>
                                        }
                                        onClose={()=>{
                                            this.atencao = null;
                                            this.setState({});
                                        }}/>
                                        this.setState({}); 
                                    }}   >
                                        <Stack pl='20px' justify={'left'}  align={'center'} fontSize={'18px'} flexDir={'row'}>
                                            <Stack bg={(pag=='logoff')?'tema1.300':'tema1.200'} color={(pag=='logoff')?'green.500':'tema1.100'}  justify={'left'}  align={'center'} p={'8px'} borderRadius={'8px'}>
                                                <Icon as={FaSignOutAlt} fontSize={'14px'}   />
                                            </Stack> 
                                            <Text fontSize={'14px'} textShadow={'xl'}>Logoff</Text>
                                        </Stack>
                                    </MenuButton> 



                                    


                                    {/* <NavLink to={'/financial'} onClick={()=>{
                                        this.viewMenu = false;
                                        this.setState({});
                                    }}  >
                                        <MenuButton my='5px' color={(pag=='history')?'green.500':'tema1.500'}  textTransform={'uppercase'}  w={'210px'} h={'30px'} fontSize={'small'} fontWeight={'bold'} borderRight={'4px solid'} transition={'0.5s'}  borderColor={(pag=='history')?'green.500':'transparent'}  _hover={{color:'tema1.400'}}   >
                                        <Stack pl='20px' justify={'left'} align={'center'} fontSize={'18px'} flexDir={'row'}>
                                            <Icon as={FaExchangeAlt} fontSize={'18px'}   mr='5px' /><Text fontSize={'14px'}>Bônus Equipe</Text>
                                        </Stack>
                                        </MenuButton>
                                    </NavLink> 
                                    <NavLink to={'/network'} onClick={()=>{
                                        this.viewMenu = false;
                                        this.setState({});
                                    }}  >
                                        <MenuButton my='5px' color={(pag=='history')?'green.500':'tema1.500'}  textTransform={'uppercase'}  w={'210px'} h={'30px'} fontSize={'small'} fontWeight={'bold'} borderRight={'4px solid'} transition={'0.5s'}  borderColor={(pag=='history')?'green.500':'transparent'}  _hover={{color:'tema1.400'}}   >
                                        <Stack pl='20px' justify={'left'} align={'center'} fontSize={'18px'} flexDir={'row'}>
                                            <Icon as={FaExchangeAlt} fontSize={'18px'}   mr='5px' /><Text fontSize={'14px'}>Rede</Text>
                                        </Stack>
                                        </MenuButton>
                                    </NavLink> 
                                    <NavLink to={'/mypackage'} onClick={()=>{
                                        this.viewMenu = false;
                                        this.setState({});
                                    }}  >
                                        <MenuButton my='5px' color={(pag=='mybots')?'green.500':'tema1.500'}  textTransform={'uppercase'}  w={'210px'} h={'30px'} fontSize={'small'} fontWeight={'bold'} borderRight={'4px solid'} transition={'0.5s'}  borderColor={(pag=='mybots')?'green.500':'transparent'}  _hover={{color:'tema1.400'}}   >
                                        <Stack pl='20px' justify={'left'} align={'center'} fontSize={'18px'} flexDir={'row'}>
                                                <Icon as={FaRobot} fontSize={'18px'}   mr='5px' /><Text fontSize={'14px'}>Meus Pacotes</Text>
                                        </Stack>
                                        </MenuButton>
                                    </NavLink> 
                                    <NavLink to={'/financial'} onClick={()=>{
                                        this.viewMenu = false;
                                        this.setState({});
                                    }}  >
                                        <MenuButton my='5px' color={(pag=='history')?'green.500':'tema1.500'}  textTransform={'uppercase'}  w={'210px'} h={'30px'} fontSize={'small'} fontWeight={'bold'} borderRight={'4px solid'} transition={'0.5s'}  borderColor={(pag=='history')?'green.500':'transparent'}  _hover={{color:'tema1.400'}}   >
                                        <Stack pl='20px' justify={'left'} align={'center'} fontSize={'18px'} flexDir={'row'}>
                                            <Icon as={FaExchangeAlt} fontSize={'18px'}   mr='5px' /><Text fontSize={'14px'}>Financeiro</Text>
                                        </Stack>
                                        </MenuButton>
                                    </NavLink> */}

                                   
                                    



                                </Menu>
                            </Stack>
                        
                        </Flex> 
                        {/* <Flex  w={{base:'100%'}} fontWeight={'bold'}  flexDir={'column'}  h='150px'  align='center' justify='center' >
                            <Text color={'tema1.400'} fontSize={'xs'}>SUPPORT</Text>
                            <Text color={'tema1.500'} fontSize={'xs'} pb='5px'>SUPPORT@FUTEFY.COM</Text>
                            <Text color={'tema1.400'} fontSize={'xs'} pb='5px'>SOCIAL</Text>
                            <HStack color={'tema1.500'} > 
                                <Link href="" target="new">
                                    <Icon transition={'0.5s'} as={FaTelegramPlane} boxSize={4} _hover={{color:'tema1.400'}} /> 
                                </Link>
                                <Link href="" target="new">
                                    <Icon transition={'0.5s'} as={FaFacebookF} boxSize={3.5} mb='1px' _hover={{color:'tema1.400'}} /> 
                                </Link>
                                <Link href="" target="new"> 
                                    <Icon transition={'0.5s'} as={FaInstagram} boxSize={4} _hover={{color:'tema1.400'}} /> 
                                </Link>
                                <Link href="" target="new">
                                    <Icon transition={'0.5s'} as={FaWhatsapp} boxSize={4} _hover={{color:'tema1.400'}} />
                                </Link> 
                            </HStack> 
                        </Flex>   */}
                    </Flex>
                </Flex>
                {this.atencao}
            </>
            
        )
    }
}
export default MenuSite;
    